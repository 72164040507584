import { useRouteError } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export function RouterErrorComponent() {
    const error = useRouteError() as Error;

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return <div>An error occurred</div>;
}
