export { Homegrown_Userdocumenttype_Enum as INVESTOR_DOC_TYPES } from 'GQL/types-and-hooks';
export { Homegrown_Userdocumenttype_Enum as BUSINESS_OWNER_DOC_TYPES } from 'GQL/types-and-hooks';
export { Homegrown_Dealprivatedocumenttype_Enum as DEAL_PRIVATE_DOC_TYPE } from 'GQL/types-and-hooks.ts';
export { Homegrown_Dealprivatedocumentstatus_Enum as DEAL_PRIVATE_DOC_STATUS } from 'GQL/types-and-hooks.ts';

export const APOLLO_TYPE_POLICIES = {
    users_user: {
        keyFields: ['auth0id'],
    },
    investordata: {
        keyFields: ['user_id'],
    },
    users_businessowner: {
        keyFields: ['user_id'],
    },
    homegrown_dealprivate: {
        keyFields: ['deal_id'],
    },
    plaid_app_dealtransfer: {
        keyFields: ['payout_id'],
    },
};

export const adminAppRoot = '/admin'; // registered callback in auth0
export const clientAppRoot = '/'; // registered callback in auth0
export const secondaryInvestor = '/entity-investor';
export const userTypeSelect = '/usertype-select';

export const clientBankingHandlerRoute = '/client-banking';
export const investorAppRoot = '/investor';
export const businessAppRoot = '/business';

//
export const authRoute = '/auth';

export const bankingPlaidCallbackRoute = '/banking'; //callback route registered in plad

export const SUPPORT_EMAIL = 'support@joinhomegrown.com';
export const INVESTMENTS_EMAIL = 'investments@joinhomegrown.com';

export const AVAILABLE_PAGE_SIZES = [5, 15, 30, 50, 100];
export const DEFAULT_PAGE_SIZE = AVAILABLE_PAGE_SIZES[0];

export const DEFAULT_LOCALE = 'en-US';
export const DARK_THEME_CLASSNAME = 'dark';

export const PHONE_VERIFICATION_CODE_LENGTH = 6;

export const SERVICE_FEE_DEFAULT_VALUE = 0.08;

export const ORDER_DIR: OrderDirType[] = ['asc', 'desc'];

export const PUBLIC_DOCUMENTS_BUCKET = 'public';
export const PRIVATE_DOCUMENT_BUCKET = 'privatedocuments';
export const INVESTOR_PRIVATE_DOCUMENTS_BUCKET = 'usersdocuments';
export const BUSINESS_OWNER_PRIVATE_DOCUMENTS_BUCKET = 'usersdocuments';

export const INVESTOR_ROLE_NAME = 'investor';
export const BUSINESS_OWNER_ROLE_NAME = 'business_owner';

export enum USER_TYPES {
    investor = 'investor',
    business_owner = 'business_owner',
    employee = 'employee',
}

export enum CITIZESNSHIP_TYPES {
    citizen = 'citizen',
    resident = 'resident',
    none = '',
}
export type Citizenship_type = `${CITIZESNSHIP_TYPES}`;

export enum INVESTOR_TYPES {
    individual = 'individual',
    entity = 'entity', // meaning investor business or 'is business'
}

export enum SECONDARY_INVESTOR_INVITE_TYPES {
    USED = 'used',
    PENDING = 'pending',
}

export const ALLOWED_EMPLOYEE_DOMAINS = [
    'joinhomegrown.com',
    'intelligichain.com',
];

export enum DEAL_TYPES {
    RESTAURANTS = 'Restaurants',
    BAR_AND_BREWERIES = 'Bar & Breweries',
    GYMS = 'Gyms',
    SPA = 'Spa',
    CAR_WASH = 'Car Wash',
    RETAIL_STORE = 'Retail Store',
    BEAUTY_SALON_HAIR_CARE = 'Beauty Salon / Hair Care',
    HEALTHCARE = 'Healthcare',
}

export enum DataTypes {
    //hasura data types
    Boolean = 'Boolean',
    Int = 'Int',
    String = 'String',
    bigint = 'bigint',
    Float = 'Float',
    numeric = 'numeric',
    bit = 'bit',
    uuid = 'uuid',
    jsonb = 'jsonb',
    json = 'json',
    int2vector = 'int2vector',
    date = 'date',
    timestamp = 'timestamp',
    timestamptz = 'timestamptz',
    time = 'time',
    timetz = 'timetz',
    bpchar = 'bpchar',
    name = 'name',
    // custom types
    options = 'options',
    percent = 'percent',
}

export const stringTypes = [
    DataTypes.String,
    DataTypes.bpchar,
    DataTypes.name,
] as string[];
export const numberTypes = [
    DataTypes.Int,
    DataTypes.bigint,
    DataTypes.numeric,
    DataTypes.Float,
] as string[];

export enum DealDocumentsStatuses {
    incomplete = 'Incomplete',
    complete = 'Complete',
    in_review = 'In Review',
    attention_requested = 'Attention Requested',
}
