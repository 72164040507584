import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  date: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: any; output: any; }
  smallint: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "storage_buckets" */
export type Buckets = {
  __typename?: 'buckets';
  cacheControl: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  downloadExpiration: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  maxUploadFileSize: Scalars['Int']['output'];
  minUploadFileSize: Scalars['Int']['output'];
  presignedUrlsEnabled: Scalars['Boolean']['output'];
  /** An array relationship */
  storage_buckets_storage_files: Array<Files>;
  /** An aggregate relationship */
  storage_buckets_storage_files_aggregate: Files_Aggregate;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "storage_buckets" */
export type BucketsStorage_Buckets_Storage_FilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "storage_buckets" */
export type BucketsStorage_Buckets_Storage_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** aggregated selection of "storage_buckets" */
export type Buckets_Aggregate = {
  __typename?: 'buckets_aggregate';
  aggregate?: Maybe<Buckets_Aggregate_Fields>;
  nodes: Array<Buckets>;
};

/** aggregate fields of "storage_buckets" */
export type Buckets_Aggregate_Fields = {
  __typename?: 'buckets_aggregate_fields';
  avg?: Maybe<Buckets_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Buckets_Max_Fields>;
  min?: Maybe<Buckets_Min_Fields>;
  stddev?: Maybe<Buckets_Stddev_Fields>;
  stddev_pop?: Maybe<Buckets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Buckets_Stddev_Samp_Fields>;
  sum?: Maybe<Buckets_Sum_Fields>;
  var_pop?: Maybe<Buckets_Var_Pop_Fields>;
  var_samp?: Maybe<Buckets_Var_Samp_Fields>;
  variance?: Maybe<Buckets_Variance_Fields>;
};


/** aggregate fields of "storage_buckets" */
export type Buckets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buckets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Buckets_Avg_Fields = {
  __typename?: 'buckets_avg_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "storage_buckets". All fields are combined with a logical 'AND'. */
export type Buckets_Bool_Exp = {
  _and?: InputMaybe<Array<Buckets_Bool_Exp>>;
  _not?: InputMaybe<Buckets_Bool_Exp>;
  _or?: InputMaybe<Array<Buckets_Bool_Exp>>;
  cacheControl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  downloadExpiration?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  maxUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  minUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  presignedUrlsEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  storage_buckets_storage_files?: InputMaybe<Files_Bool_Exp>;
  storage_buckets_storage_files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage_buckets" */
export enum Buckets_Constraint {
  /** unique or primary key constraint on columns "id" */
  StorageBucketsPkey = 'storage_buckets_pkey'
}

/** input type for incrementing numeric columns in table "storage_buckets" */
export type Buckets_Inc_Input = {
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage_buckets" */
export type Buckets_Insert_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  storage_buckets_storage_files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Buckets_Max_Fields = {
  __typename?: 'buckets_max_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Buckets_Min_Fields = {
  __typename?: 'buckets_min_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "storage_buckets" */
export type Buckets_Mutation_Response = {
  __typename?: 'buckets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Buckets>;
};

/** input type for inserting object relation for remote table "storage_buckets" */
export type Buckets_Obj_Rel_Insert_Input = {
  data: Buckets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};

/** on_conflict condition type for table "storage_buckets" */
export type Buckets_On_Conflict = {
  constraint: Buckets_Constraint;
  update_columns?: Array<Buckets_Update_Column>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

/** Ordering options when selecting data from "storage_buckets". */
export type Buckets_Order_By = {
  cacheControl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadExpiration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maxUploadFileSize?: InputMaybe<Order_By>;
  minUploadFileSize?: InputMaybe<Order_By>;
  presignedUrlsEnabled?: InputMaybe<Order_By>;
  storage_buckets_storage_files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage_buckets */
export type Buckets_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "storage_buckets" */
export enum Buckets_Select_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "storage_buckets" */
export type Buckets_Set_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Buckets_Stddev_Fields = {
  __typename?: 'buckets_stddev_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Buckets_Stddev_Pop_Fields = {
  __typename?: 'buckets_stddev_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Buckets_Stddev_Samp_Fields = {
  __typename?: 'buckets_stddev_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "buckets" */
export type Buckets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Buckets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buckets_Stream_Cursor_Value_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Buckets_Sum_Fields = {
  __typename?: 'buckets_sum_fields';
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "storage_buckets" */
export enum Buckets_Update_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Buckets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Buckets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buckets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buckets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Buckets_Var_Pop_Fields = {
  __typename?: 'buckets_var_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Buckets_Var_Samp_Fields = {
  __typename?: 'buckets_var_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Buckets_Variance_Fields = {
  __typename?: 'buckets_variance_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "storage_files" */
export type Files = {
  __typename?: 'files';
  bucketId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  etag?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  homegrown_dealprivatedocument?: Maybe<Homegrown_Dealprivatedocuments>;
  id: Scalars['uuid']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  plaid_app_dealbusinessrevenue?: Maybe<Plaid_App_Dealbusinessrevenue>;
  size?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  storage_bucket: Buckets;
  updatedAt: Scalars['timestamptz']['output'];
  uploadedByUserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  users_user?: Maybe<Users_User>;
};

/** aggregated selection of "storage_files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Bool_And = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "storage_files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<Files_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
  stddev?: Maybe<Files_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Sum_Fields>;
  var_pop?: Maybe<Files_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Var_Samp_Fields>;
  variance?: Maybe<Files_Variance_Fields>;
};


/** aggregate fields of "storage_files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "storage_files" */
export type Files_Aggregate_Order_By = {
  avg?: InputMaybe<Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
  stddev?: InputMaybe<Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Files_Sum_Order_By>;
  var_pop?: InputMaybe<Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Files_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "storage_files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Avg_Fields = {
  __typename?: 'files_avg_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "storage_files" */
export type Files_Avg_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "storage_files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  bucketId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  etag?: InputMaybe<String_Comparison_Exp>;
  homegrown_dealprivatedocument?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isUploaded?: InputMaybe<Boolean_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  plaid_app_dealbusinessrevenue?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  storage_bucket?: InputMaybe<Buckets_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByUserId?: InputMaybe<String_Comparison_Exp>;
  users_user?: InputMaybe<Users_User_Bool_Exp>;
};

/** unique or primary key constraints on table "storage_files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  StorageFilesPkey = 'storage_files_pkey'
}

/** input type for incrementing numeric columns in table "storage_files" */
export type Files_Inc_Input = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage_files" */
export type Files_Insert_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  homegrown_dealprivatedocument?: InputMaybe<Homegrown_Dealprivatedocuments_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plaid_app_dealbusinessrevenue?: InputMaybe<Plaid_App_Dealbusinessrevenue_Obj_Rel_Insert_Input>;
  size?: InputMaybe<Scalars['Int']['input']>;
  storage_bucket?: InputMaybe<Buckets_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['String']['input']>;
  users_user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "storage_files" */
export type Files_Max_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "storage_files" */
export type Files_Min_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage_files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "storage_files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "storage_files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "storage_files". */
export type Files_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  homegrown_dealprivatedocument?: InputMaybe<Homegrown_Dealprivatedocuments_Order_By>;
  id?: InputMaybe<Order_By>;
  isUploaded?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plaid_app_dealbusinessrevenue?: InputMaybe<Plaid_App_Dealbusinessrevenue_Order_By>;
  size?: InputMaybe<Order_By>;
  storage_bucket?: InputMaybe<Buckets_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
  users_user?: InputMaybe<Users_User_Order_By>;
};

/** primary key columns input for table: storage_files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "storage_files" */
export enum Files_Select_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId'
}

/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage_files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded'
}

/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage_files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded'
}

/** input type for updating data in table "storage_files" */
export type Files_Set_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Files_Stddev_Fields = {
  __typename?: 'files_stddev_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "storage_files" */
export type Files_Stddev_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Stddev_Pop_Fields = {
  __typename?: 'files_stddev_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "storage_files" */
export type Files_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Stddev_Samp_Fields = {
  __typename?: 'files_stddev_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "storage_files" */
export type Files_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Files_Sum_Fields = {
  __typename?: 'files_sum_fields';
  size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "storage_files" */
export type Files_Sum_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** update columns of table "storage_files" */
export enum Files_Update_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId'
}

export type Files_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Files_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Var_Pop_Fields = {
  __typename?: 'files_var_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "storage_files" */
export type Files_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Var_Samp_Fields = {
  __typename?: 'files_var_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "storage_files" */
export type Files_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Variance_Fields = {
  __typename?: 'files_variance_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "storage_files" */
export type Files_Variance_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_business" */
export type Homegrown_Business = {
  __typename?: 'homegrown_business';
  active: Scalars['Boolean']['output'];
  area?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  deals: Array<Homegrown_Deal>;
  /** An aggregate relationship */
  deals_aggregate: Homegrown_Deal_Aggregate;
  /** An object relationship */
  flagship_location?: Maybe<Homegrown_Location>;
  flagship_location_id?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['bigint']['output'];
  locations?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  owned_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  owner?: Maybe<Users_Businessowner>;
  /** An array relationship */
  privatedocuments: Array<Homegrown_Businessprivatedocuments>;
  /** An aggregate relationship */
  privatedocuments_aggregate: Homegrown_Businessprivatedocuments_Aggregate;
  type: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  users_businessowner?: Maybe<Users_Businessowner>;
};


/** columns and relationships of "homegrown_business" */
export type Homegrown_BusinessDealsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Deal_Order_By>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


/** columns and relationships of "homegrown_business" */
export type Homegrown_BusinessDeals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Deal_Order_By>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


/** columns and relationships of "homegrown_business" */
export type Homegrown_BusinessPrivatedocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};


/** columns and relationships of "homegrown_business" */
export type Homegrown_BusinessPrivatedocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};

/** aggregated selection of "homegrown_business" */
export type Homegrown_Business_Aggregate = {
  __typename?: 'homegrown_business_aggregate';
  aggregate?: Maybe<Homegrown_Business_Aggregate_Fields>;
  nodes: Array<Homegrown_Business>;
};

/** aggregate fields of "homegrown_business" */
export type Homegrown_Business_Aggregate_Fields = {
  __typename?: 'homegrown_business_aggregate_fields';
  avg?: Maybe<Homegrown_Business_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Business_Max_Fields>;
  min?: Maybe<Homegrown_Business_Min_Fields>;
  stddev?: Maybe<Homegrown_Business_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Business_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Business_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Business_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Business_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Business_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Business_Variance_Fields>;
};


/** aggregate fields of "homegrown_business" */
export type Homegrown_Business_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Business_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Homegrown_Business_Avg_Fields = {
  __typename?: 'homegrown_business_avg_fields';
  flagship_location_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "homegrown_business". All fields are combined with a logical 'AND'. */
export type Homegrown_Business_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Business_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Business_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Business_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  area?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  deals?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deals_aggregate?: InputMaybe<Homegrown_Deal_Aggregate_Bool_Exp>;
  flagship_location?: InputMaybe<Homegrown_Location_Bool_Exp>;
  flagship_location_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  locations?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owned_by_id?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<Users_Businessowner_Bool_Exp>;
  privatedocuments?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
  privatedocuments_aggregate?: InputMaybe<Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users_businessowner?: InputMaybe<Users_Businessowner_Bool_Exp>;
};

/** unique or primary key constraints on table "homegrown_business" */
export enum Homegrown_Business_Constraint {
  /** unique or primary key constraint on columns "flagship_location_id" */
  HomegrownBusinessFlagshipLocationIdKey = 'homegrown_business_flagship_location_id_key',
  /** unique or primary key constraint on columns "owned_by_id" */
  HomegrownBusinessOwnedById_513faa28Uniq = 'homegrown_business_owned_by_id_513faa28_uniq',
  /** unique or primary key constraint on columns "id" */
  HomegrownBusinessPkey = 'homegrown_business_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_business" */
export type Homegrown_Business_Inc_Input = {
  flagship_location_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locations?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "homegrown_business" */
export type Homegrown_Business_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  area?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deals?: InputMaybe<Homegrown_Deal_Arr_Rel_Insert_Input>;
  flagship_location?: InputMaybe<Homegrown_Location_Obj_Rel_Insert_Input>;
  flagship_location_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locations?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owned_by_id?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Users_Businessowner_Obj_Rel_Insert_Input>;
  privatedocuments?: InputMaybe<Homegrown_Businessprivatedocuments_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users_businessowner?: InputMaybe<Users_Businessowner_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Homegrown_Business_Max_Fields = {
  __typename?: 'homegrown_business_max_fields';
  area?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  flagship_location_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locations?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owned_by_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Homegrown_Business_Min_Fields = {
  __typename?: 'homegrown_business_min_fields';
  area?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  flagship_location_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locations?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owned_by_id?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "homegrown_business" */
export type Homegrown_Business_Mutation_Response = {
  __typename?: 'homegrown_business_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Business>;
};

/** input type for inserting object relation for remote table "homegrown_business" */
export type Homegrown_Business_Obj_Rel_Insert_Input = {
  data: Homegrown_Business_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Business_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_business" */
export type Homegrown_Business_On_Conflict = {
  constraint: Homegrown_Business_Constraint;
  update_columns?: Array<Homegrown_Business_Update_Column>;
  where?: InputMaybe<Homegrown_Business_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_business". */
export type Homegrown_Business_Order_By = {
  active?: InputMaybe<Order_By>;
  area?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deals_aggregate?: InputMaybe<Homegrown_Deal_Aggregate_Order_By>;
  flagship_location?: InputMaybe<Homegrown_Location_Order_By>;
  flagship_location_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locations?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owned_by_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Businessowner_Order_By>;
  privatedocuments_aggregate?: InputMaybe<Homegrown_Businessprivatedocuments_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_businessowner?: InputMaybe<Users_Businessowner_Order_By>;
};

/** primary key columns input for table: homegrown_business */
export type Homegrown_Business_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_business" */
export enum Homegrown_Business_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Area = 'area',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FlagshipLocationId = 'flagship_location_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locations = 'locations',
  /** column name */
  Name = 'name',
  /** column name */
  OwnedById = 'owned_by_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "homegrown_business" */
export type Homegrown_Business_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  area?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  flagship_location_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locations?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owned_by_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Business_Stddev_Fields = {
  __typename?: 'homegrown_business_stddev_fields';
  flagship_location_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Business_Stddev_Pop_Fields = {
  __typename?: 'homegrown_business_stddev_pop_fields';
  flagship_location_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Business_Stddev_Samp_Fields = {
  __typename?: 'homegrown_business_stddev_samp_fields';
  flagship_location_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "homegrown_business" */
export type Homegrown_Business_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Business_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Business_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  area?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  flagship_location_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  locations?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owned_by_id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Business_Sum_Fields = {
  __typename?: 'homegrown_business_sum_fields';
  flagship_location_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  locations?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "homegrown_business" */
export enum Homegrown_Business_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Area = 'area',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FlagshipLocationId = 'flagship_location_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locations = 'locations',
  /** column name */
  Name = 'name',
  /** column name */
  OwnedById = 'owned_by_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Homegrown_Business_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Business_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Business_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Business_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Business_Var_Pop_Fields = {
  __typename?: 'homegrown_business_var_pop_fields';
  flagship_location_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Homegrown_Business_Var_Samp_Fields = {
  __typename?: 'homegrown_business_var_samp_fields';
  flagship_location_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Homegrown_Business_Variance_Fields = {
  __typename?: 'homegrown_business_variance_fields';
  flagship_location_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  locations?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments = {
  __typename?: 'homegrown_businessprivatedocuments';
  active: Scalars['Boolean']['output'];
  /** An object relationship */
  business: Homegrown_Business;
  business_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['bigint']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Aggregate = {
  __typename?: 'homegrown_businessprivatedocuments_aggregate';
  aggregate?: Maybe<Homegrown_Businessprivatedocuments_Aggregate_Fields>;
  nodes: Array<Homegrown_Businessprivatedocuments>;
};

export type Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Businessprivatedocuments_Select_Column_Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Businessprivatedocuments_Select_Column_Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Aggregate_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_aggregate_fields';
  avg?: Maybe<Homegrown_Businessprivatedocuments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Businessprivatedocuments_Max_Fields>;
  min?: Maybe<Homegrown_Businessprivatedocuments_Min_Fields>;
  stddev?: Maybe<Homegrown_Businessprivatedocuments_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Businessprivatedocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Businessprivatedocuments_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Businessprivatedocuments_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Businessprivatedocuments_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Businessprivatedocuments_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Businessprivatedocuments_Variance_Fields>;
};


/** aggregate fields of "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Businessprivatedocuments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Businessprivatedocuments_Max_Order_By>;
  min?: InputMaybe<Homegrown_Businessprivatedocuments_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Businessprivatedocuments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Businessprivatedocuments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Businessprivatedocuments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Businessprivatedocuments_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Businessprivatedocuments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Businessprivatedocuments_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Businessprivatedocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Businessprivatedocuments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Businessprivatedocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Businessprivatedocuments_Avg_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_avg_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Avg_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_businessprivatedocuments". All fields are combined with a logical 'AND'. */
export type Homegrown_Businessprivatedocuments_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  business?: InputMaybe<Homegrown_Business_Bool_Exp>;
  business_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_businessprivatedocuments" */
export enum Homegrown_Businessprivatedocuments_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  HomegrownBusinessprivatedocumentsFileIdKey = 'homegrown_businessprivatedocuments_file_id_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownBusinessprivatedocumentsPkey = 'homegrown_businessprivatedocuments_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Inc_Input = {
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business?: InputMaybe<Homegrown_Business_Obj_Rel_Insert_Input>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Businessprivatedocuments_Max_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_max_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Max_Order_By = {
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Businessprivatedocuments_Min_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_min_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Min_Order_By = {
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Mutation_Response = {
  __typename?: 'homegrown_businessprivatedocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Businessprivatedocuments>;
};

/** on_conflict condition type for table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_On_Conflict = {
  constraint: Homegrown_Businessprivatedocuments_Constraint;
  update_columns?: Array<Homegrown_Businessprivatedocuments_Update_Column>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_businessprivatedocuments". */
export type Homegrown_Businessprivatedocuments_Order_By = {
  active?: InputMaybe<Order_By>;
  business?: InputMaybe<Homegrown_Business_Order_By>;
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_businessprivatedocuments */
export type Homegrown_Businessprivatedocuments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_businessprivatedocuments" */
export enum Homegrown_Businessprivatedocuments_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "homegrown_businessprivatedocuments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_businessprivatedocuments" */
export enum Homegrown_Businessprivatedocuments_Select_Column_Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "homegrown_businessprivatedocuments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_businessprivatedocuments" */
export enum Homegrown_Businessprivatedocuments_Select_Column_Homegrown_Businessprivatedocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Businessprivatedocuments_Stddev_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_stddev_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Stddev_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Businessprivatedocuments_Stddev_Pop_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_stddev_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Stddev_Pop_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Businessprivatedocuments_Stddev_Samp_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_stddev_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Stddev_Samp_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Businessprivatedocuments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Businessprivatedocuments_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Businessprivatedocuments_Sum_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_sum_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Sum_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_businessprivatedocuments" */
export enum Homegrown_Businessprivatedocuments_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Homegrown_Businessprivatedocuments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Businessprivatedocuments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Businessprivatedocuments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Businessprivatedocuments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Businessprivatedocuments_Var_Pop_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_var_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Var_Pop_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Businessprivatedocuments_Var_Samp_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_var_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Var_Samp_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Businessprivatedocuments_Variance_Fields = {
  __typename?: 'homegrown_businessprivatedocuments_variance_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_businessprivatedocuments" */
export type Homegrown_Businessprivatedocuments_Variance_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_deal" */
export type Homegrown_Deal = {
  __typename?: 'homegrown_deal';
  active: Scalars['Boolean']['output'];
  /** An array relationship */
  agreement_documents: Array<Homegrown_Signwelldealdocument>;
  /** An aggregate relationship */
  agreement_documents_aggregate: Homegrown_Signwelldealdocument_Aggregate;
  allocated_amount?: Maybe<Scalars['numeric']['output']>;
  /** An object relationship */
  business: Homegrown_Business;
  business_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  deal_image?: Maybe<Files>;
  /** An object relationship */
  deal_video?: Maybe<Files>;
  /** An array relationship */
  dealbusinessrevenues: Array<Plaid_App_Dealbusinessrevenue>;
  /** An aggregate relationship */
  dealbusinessrevenues_aggregate: Plaid_App_Dealbusinessrevenue_Aggregate;
  /** An object relationship */
  dealprivate?: Maybe<Homegrown_Dealprivate>;
  /** An array relationship */
  dealtobusinessplaidlinks: Array<Homegrown_Dealtobusinessplaidlink>;
  /** An aggregate relationship */
  dealtobusinessplaidlinks_aggregate: Homegrown_Dealtobusinessplaidlink_Aggregate;
  /** An array relationship */
  dealtoinvestorlinks: Array<Homegrown_Dealtoinvestorlink>;
  /** An aggregate relationship */
  dealtoinvestorlinks_aggregate: Homegrown_Dealtoinvestorlink_Aggregate;
  /** An array relationship */
  documents: Array<Homegrown_Dealdocument>;
  /** An aggregate relationship */
  documents_aggregate: Homegrown_Dealdocument_Aggregate;
  holdback_rate: Scalars['numeric']['output'];
  id: Scalars['bigint']['output'];
  image_file_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  investments: Array<Investments>;
  /** An aggregate relationship */
  investments_aggregate: Investments_Aggregate;
  is_past: Scalars['Boolean']['output'];
  is_pending: Scalars['Boolean']['output'];
  /** An object relationship */
  location?: Maybe<Homegrown_Location>;
  location_id?: Maybe<Scalars['bigint']['output']>;
  media_metadata?: Maybe<Scalars['jsonb']['output']>;
  min_buy_in?: Maybe<Scalars['numeric']['output']>;
  name: Scalars['String']['output'];
  payback_amount?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  paybacks: Array<Plaid_App_Dealpayback>;
  /** An aggregate relationship */
  paybacks_aggregate: Plaid_App_Dealpayback_Aggregate;
  /** An array relationship */
  payouts: Array<Plaid_App_Dealpayout>;
  /** An aggregate relationship */
  payouts_aggregate: Plaid_App_Dealpayout_Aggregate;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  pending_errors?: Maybe<Scalars['jsonb']['output']>;
  /** A computed field, executes function "all_plaid_app_dealtransfers" */
  plaid_dealtransfers?: Maybe<Array<Plaid_App_Dealtransfer>>;
  /** An array relationship */
  privatedocuments: Array<Homegrown_Dealprivatedocuments>;
  /** An aggregate relationship */
  privatedocuments_aggregate: Homegrown_Dealprivatedocuments_Aggregate;
  service_fee: Scalars['numeric']['output'];
  /** An array relationship */
  signed_documents: Array<Homegrown_Signwellinvestorsigneddocument>;
  /** An aggregate relationship */
  signed_documents_aggregate: Homegrown_Signwellinvestorsigneddocument_Aggregate;
  total_amount?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  video_file_id?: Maybe<Scalars['uuid']['output']>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealAgreement_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwelldealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealAgreement_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwelldealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDealbusinessrevenuesArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDealbusinessrevenues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDealtobusinessplaidlinksArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDealtobusinessplaidlinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDealtoinvestorlinksArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDealtoinvestorlinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealMedia_MetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPaybacksArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayback_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPaybacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayback_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPayoutsArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPayouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPending_ErrorsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPlaid_DealtransfersArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPrivatedocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealPrivatedocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealSigned_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


/** columns and relationships of "homegrown_deal" */
export type Homegrown_DealSigned_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};

/** aggregated selection of "homegrown_deal" */
export type Homegrown_Deal_Aggregate = {
  __typename?: 'homegrown_deal_aggregate';
  aggregate?: Maybe<Homegrown_Deal_Aggregate_Fields>;
  nodes: Array<Homegrown_Deal>;
};

export type Homegrown_Deal_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Deal_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Deal_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Deal_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Deal_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Deal_Select_Column_Homegrown_Deal_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Deal_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Deal_Select_Column_Homegrown_Deal_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Deal_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_deal" */
export type Homegrown_Deal_Aggregate_Fields = {
  __typename?: 'homegrown_deal_aggregate_fields';
  avg?: Maybe<Homegrown_Deal_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Deal_Max_Fields>;
  min?: Maybe<Homegrown_Deal_Min_Fields>;
  stddev?: Maybe<Homegrown_Deal_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Deal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Deal_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Deal_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Deal_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Deal_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Deal_Variance_Fields>;
};


/** aggregate fields of "homegrown_deal" */
export type Homegrown_Deal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_deal" */
export type Homegrown_Deal_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Deal_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Deal_Max_Order_By>;
  min?: InputMaybe<Homegrown_Deal_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Deal_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Deal_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Deal_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Deal_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Deal_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Deal_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Deal_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Homegrown_Deal_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  media_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pending_errors?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "homegrown_deal" */
export type Homegrown_Deal_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Deal_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Deal_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Deal_Avg_Fields = {
  __typename?: 'homegrown_deal_avg_fields';
  allocated_amount?: Maybe<Scalars['Float']['output']>;
  business_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  min_buy_in?: Maybe<Scalars['Float']['output']>;
  payback_amount?: Maybe<Scalars['Float']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Avg_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_deal". All fields are combined with a logical 'AND'. */
export type Homegrown_Deal_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Deal_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Deal_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  agreement_documents?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
  agreement_documents_aggregate?: InputMaybe<Homegrown_Signwelldealdocument_Aggregate_Bool_Exp>;
  allocated_amount?: InputMaybe<Numeric_Comparison_Exp>;
  business?: InputMaybe<Homegrown_Business_Bool_Exp>;
  business_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  deal_image?: InputMaybe<Files_Bool_Exp>;
  deal_video?: InputMaybe<Files_Bool_Exp>;
  dealbusinessrevenues?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
  dealbusinessrevenues_aggregate?: InputMaybe<Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp>;
  dealprivate?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
  dealtobusinessplaidlinks?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
  dealtobusinessplaidlinks_aggregate?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Aggregate_Bool_Exp>;
  dealtoinvestorlinks?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
  dealtoinvestorlinks_aggregate?: InputMaybe<Homegrown_Dealtoinvestorlink_Aggregate_Bool_Exp>;
  documents?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
  documents_aggregate?: InputMaybe<Homegrown_Dealdocument_Aggregate_Bool_Exp>;
  holdback_rate?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  image_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  investments?: InputMaybe<Investments_Bool_Exp>;
  investments_aggregate?: InputMaybe<Investments_Aggregate_Bool_Exp>;
  is_past?: InputMaybe<Boolean_Comparison_Exp>;
  is_pending?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Homegrown_Location_Bool_Exp>;
  location_id?: InputMaybe<Bigint_Comparison_Exp>;
  media_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  min_buy_in?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payback_amount?: InputMaybe<Numeric_Comparison_Exp>;
  paybacks?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
  paybacks_aggregate?: InputMaybe<Plaid_App_Dealpayback_Aggregate_Bool_Exp>;
  payouts?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  payouts_aggregate?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Bool_Exp>;
  payouts_month_avg?: InputMaybe<Numeric_Comparison_Exp>;
  pending_errors?: InputMaybe<Jsonb_Comparison_Exp>;
  plaid_dealtransfers?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
  privatedocuments?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
  privatedocuments_aggregate?: InputMaybe<Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp>;
  service_fee?: InputMaybe<Numeric_Comparison_Exp>;
  signed_documents?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  signed_documents_aggregate?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp>;
  total_amount?: InputMaybe<Numeric_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video_file_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_deal" */
export enum Homegrown_Deal_Constraint {
  /** unique or primary key constraint on columns "location_id" */
  HomegrownDealLocationIdKey = 'homegrown_deal_location_id_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownDealPkey = 'homegrown_deal_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Homegrown_Deal_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
  media_metadata?: InputMaybe<Array<Scalars['String']['input']>>;
  pending_errors?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Homegrown_Deal_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
  media_metadata?: InputMaybe<Scalars['Int']['input']>;
  pending_errors?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Homegrown_Deal_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
  media_metadata?: InputMaybe<Scalars['String']['input']>;
  pending_errors?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "homegrown_deal" */
export type Homegrown_Deal_Inc_Input = {
  allocated_amount?: InputMaybe<Scalars['numeric']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  location_id?: InputMaybe<Scalars['bigint']['input']>;
  min_buy_in?: InputMaybe<Scalars['numeric']['input']>;
  payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
  total_amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "homegrown_deal" */
export type Homegrown_Deal_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  agreement_documents?: InputMaybe<Homegrown_Signwelldealdocument_Arr_Rel_Insert_Input>;
  allocated_amount?: InputMaybe<Scalars['numeric']['input']>;
  business?: InputMaybe<Homegrown_Business_Obj_Rel_Insert_Input>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  deal_image?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  deal_video?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  dealbusinessrevenues?: InputMaybe<Plaid_App_Dealbusinessrevenue_Arr_Rel_Insert_Input>;
  dealprivate?: InputMaybe<Homegrown_Dealprivate_Obj_Rel_Insert_Input>;
  dealtobusinessplaidlinks?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Arr_Rel_Insert_Input>;
  dealtoinvestorlinks?: InputMaybe<Homegrown_Dealtoinvestorlink_Arr_Rel_Insert_Input>;
  documents?: InputMaybe<Homegrown_Dealdocument_Arr_Rel_Insert_Input>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_file_id?: InputMaybe<Scalars['uuid']['input']>;
  investments?: InputMaybe<Investments_Arr_Rel_Insert_Input>;
  is_past?: InputMaybe<Scalars['Boolean']['input']>;
  is_pending?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<Homegrown_Location_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['bigint']['input']>;
  media_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  min_buy_in?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  paybacks?: InputMaybe<Plaid_App_Dealpayback_Arr_Rel_Insert_Input>;
  payouts?: InputMaybe<Plaid_App_Dealpayout_Arr_Rel_Insert_Input>;
  pending_errors?: InputMaybe<Scalars['jsonb']['input']>;
  privatedocuments?: InputMaybe<Homegrown_Dealprivatedocuments_Arr_Rel_Insert_Input>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
  signed_documents?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Arr_Rel_Insert_Input>;
  total_amount?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  video_file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Deal_Max_Fields = {
  __typename?: 'homegrown_deal_max_fields';
  allocated_amount?: Maybe<Scalars['numeric']['output']>;
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_file_id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['bigint']['output']>;
  min_buy_in?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payback_amount?: Maybe<Scalars['numeric']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['numeric']['output']>;
  total_amount?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  video_file_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Max_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_file_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_file_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Deal_Min_Fields = {
  __typename?: 'homegrown_deal_min_fields';
  allocated_amount?: Maybe<Scalars['numeric']['output']>;
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  image_file_id?: Maybe<Scalars['uuid']['output']>;
  location_id?: Maybe<Scalars['bigint']['output']>;
  min_buy_in?: Maybe<Scalars['numeric']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payback_amount?: Maybe<Scalars['numeric']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['numeric']['output']>;
  total_amount?: Maybe<Scalars['numeric']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  video_file_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Min_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_file_id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_file_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_deal" */
export type Homegrown_Deal_Mutation_Response = {
  __typename?: 'homegrown_deal_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Deal>;
};

/** input type for inserting object relation for remote table "homegrown_deal" */
export type Homegrown_Deal_Obj_Rel_Insert_Input = {
  data: Homegrown_Deal_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Deal_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_deal" */
export type Homegrown_Deal_On_Conflict = {
  constraint: Homegrown_Deal_Constraint;
  update_columns?: Array<Homegrown_Deal_Update_Column>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_deal". */
export type Homegrown_Deal_Order_By = {
  active?: InputMaybe<Order_By>;
  agreement_documents_aggregate?: InputMaybe<Homegrown_Signwelldealdocument_Aggregate_Order_By>;
  allocated_amount?: InputMaybe<Order_By>;
  business?: InputMaybe<Homegrown_Business_Order_By>;
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  deal_image?: InputMaybe<Files_Order_By>;
  deal_video?: InputMaybe<Files_Order_By>;
  dealbusinessrevenues_aggregate?: InputMaybe<Plaid_App_Dealbusinessrevenue_Aggregate_Order_By>;
  dealprivate?: InputMaybe<Homegrown_Dealprivate_Order_By>;
  dealtobusinessplaidlinks_aggregate?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Aggregate_Order_By>;
  dealtoinvestorlinks_aggregate?: InputMaybe<Homegrown_Dealtoinvestorlink_Aggregate_Order_By>;
  documents_aggregate?: InputMaybe<Homegrown_Dealdocument_Aggregate_Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_file_id?: InputMaybe<Order_By>;
  investments_aggregate?: InputMaybe<Investments_Aggregate_Order_By>;
  is_past?: InputMaybe<Order_By>;
  is_pending?: InputMaybe<Order_By>;
  location?: InputMaybe<Homegrown_Location_Order_By>;
  location_id?: InputMaybe<Order_By>;
  media_metadata?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  paybacks_aggregate?: InputMaybe<Plaid_App_Dealpayback_Aggregate_Order_By>;
  payouts_aggregate?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Order_By>;
  payouts_month_avg?: InputMaybe<Order_By>;
  pending_errors?: InputMaybe<Order_By>;
  plaid_dealtransfers_aggregate?: InputMaybe<Plaid_App_Dealtransfer_Aggregate_Order_By>;
  privatedocuments_aggregate?: InputMaybe<Homegrown_Dealprivatedocuments_Aggregate_Order_By>;
  service_fee?: InputMaybe<Order_By>;
  signed_documents_aggregate?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Order_By>;
  total_amount?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video_file_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_deal */
export type Homegrown_Deal_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Homegrown_Deal_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
  media_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  pending_errors?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "homegrown_deal" */
export enum Homegrown_Deal_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllocatedAmount = 'allocated_amount',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Data = 'data',
  /** column name */
  HoldbackRate = 'holdback_rate',
  /** column name */
  Id = 'id',
  /** column name */
  ImageFileId = 'image_file_id',
  /** column name */
  IsPast = 'is_past',
  /** column name */
  IsPending = 'is_pending',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MediaMetadata = 'media_metadata',
  /** column name */
  MinBuyIn = 'min_buy_in',
  /** column name */
  Name = 'name',
  /** column name */
  PaybackAmount = 'payback_amount',
  /** column name */
  PendingErrors = 'pending_errors',
  /** column name */
  ServiceFee = 'service_fee',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoFileId = 'video_file_id'
}

/** select "homegrown_deal_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_deal" */
export enum Homegrown_Deal_Select_Column_Homegrown_Deal_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsPast = 'is_past',
  /** column name */
  IsPending = 'is_pending'
}

/** select "homegrown_deal_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_deal" */
export enum Homegrown_Deal_Select_Column_Homegrown_Deal_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsPast = 'is_past',
  /** column name */
  IsPending = 'is_pending'
}

/** input type for updating data in table "homegrown_deal" */
export type Homegrown_Deal_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allocated_amount?: InputMaybe<Scalars['numeric']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_file_id?: InputMaybe<Scalars['uuid']['input']>;
  is_past?: InputMaybe<Scalars['Boolean']['input']>;
  is_pending?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['bigint']['input']>;
  media_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  min_buy_in?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  pending_errors?: InputMaybe<Scalars['jsonb']['input']>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
  total_amount?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  video_file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Deal_Stddev_Fields = {
  __typename?: 'homegrown_deal_stddev_fields';
  allocated_amount?: Maybe<Scalars['Float']['output']>;
  business_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  min_buy_in?: Maybe<Scalars['Float']['output']>;
  payback_amount?: Maybe<Scalars['Float']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Stddev_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Deal_Stddev_Pop_Fields = {
  __typename?: 'homegrown_deal_stddev_pop_fields';
  allocated_amount?: Maybe<Scalars['Float']['output']>;
  business_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  min_buy_in?: Maybe<Scalars['Float']['output']>;
  payback_amount?: Maybe<Scalars['Float']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Stddev_Pop_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Deal_Stddev_Samp_Fields = {
  __typename?: 'homegrown_deal_stddev_samp_fields';
  allocated_amount?: Maybe<Scalars['Float']['output']>;
  business_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  min_buy_in?: Maybe<Scalars['Float']['output']>;
  payback_amount?: Maybe<Scalars['Float']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Stddev_Samp_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_deal" */
export type Homegrown_Deal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Deal_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Deal_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allocated_amount?: InputMaybe<Scalars['numeric']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  image_file_id?: InputMaybe<Scalars['uuid']['input']>;
  is_past?: InputMaybe<Scalars['Boolean']['input']>;
  is_pending?: InputMaybe<Scalars['Boolean']['input']>;
  location_id?: InputMaybe<Scalars['bigint']['input']>;
  media_metadata?: InputMaybe<Scalars['jsonb']['input']>;
  min_buy_in?: InputMaybe<Scalars['numeric']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  pending_errors?: InputMaybe<Scalars['jsonb']['input']>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
  total_amount?: InputMaybe<Scalars['numeric']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  video_file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Deal_Sum_Fields = {
  __typename?: 'homegrown_deal_sum_fields';
  allocated_amount?: Maybe<Scalars['numeric']['output']>;
  business_id?: Maybe<Scalars['bigint']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  location_id?: Maybe<Scalars['bigint']['output']>;
  min_buy_in?: Maybe<Scalars['numeric']['output']>;
  payback_amount?: Maybe<Scalars['numeric']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['numeric']['output']>;
  total_amount?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Sum_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_deal" */
export enum Homegrown_Deal_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllocatedAmount = 'allocated_amount',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Data = 'data',
  /** column name */
  HoldbackRate = 'holdback_rate',
  /** column name */
  Id = 'id',
  /** column name */
  ImageFileId = 'image_file_id',
  /** column name */
  IsPast = 'is_past',
  /** column name */
  IsPending = 'is_pending',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MediaMetadata = 'media_metadata',
  /** column name */
  MinBuyIn = 'min_buy_in',
  /** column name */
  Name = 'name',
  /** column name */
  PaybackAmount = 'payback_amount',
  /** column name */
  PendingErrors = 'pending_errors',
  /** column name */
  ServiceFee = 'service_fee',
  /** column name */
  TotalAmount = 'total_amount',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VideoFileId = 'video_file_id'
}

export type Homegrown_Deal_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Homegrown_Deal_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Homegrown_Deal_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Homegrown_Deal_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Homegrown_Deal_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Deal_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Homegrown_Deal_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Deal_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Deal_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Deal_Var_Pop_Fields = {
  __typename?: 'homegrown_deal_var_pop_fields';
  allocated_amount?: Maybe<Scalars['Float']['output']>;
  business_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  min_buy_in?: Maybe<Scalars['Float']['output']>;
  payback_amount?: Maybe<Scalars['Float']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Var_Pop_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Deal_Var_Samp_Fields = {
  __typename?: 'homegrown_deal_var_samp_fields';
  allocated_amount?: Maybe<Scalars['Float']['output']>;
  business_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  min_buy_in?: Maybe<Scalars['Float']['output']>;
  payback_amount?: Maybe<Scalars['Float']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Var_Samp_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Deal_Variance_Fields = {
  __typename?: 'homegrown_deal_variance_fields';
  allocated_amount?: Maybe<Scalars['Float']['output']>;
  business_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  location_id?: Maybe<Scalars['Float']['output']>;
  min_buy_in?: Maybe<Scalars['Float']['output']>;
  payback_amount?: Maybe<Scalars['Float']['output']>;
  /** Average monthly payments value for all payments, regardless requester permissions */
  payouts_month_avg?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
  total_amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_deal" */
export type Homegrown_Deal_Variance_Order_By = {
  allocated_amount?: InputMaybe<Order_By>;
  business_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  min_buy_in?: InputMaybe<Order_By>;
  payback_amount?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  total_amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_dealdocument" */
export type Homegrown_Dealdocument = {
  __typename?: 'homegrown_dealdocument';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['bigint']['output'];
  is_tearsheet: Scalars['Boolean']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Aggregate = {
  __typename?: 'homegrown_dealdocument_aggregate';
  aggregate?: Maybe<Homegrown_Dealdocument_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealdocument>;
};

export type Homegrown_Dealdocument_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Dealdocument_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Dealdocument_Select_Column_Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Dealdocument_Select_Column_Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Dealdocument_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Aggregate_Fields = {
  __typename?: 'homegrown_dealdocument_aggregate_fields';
  avg?: Maybe<Homegrown_Dealdocument_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealdocument_Max_Fields>;
  min?: Maybe<Homegrown_Dealdocument_Min_Fields>;
  stddev?: Maybe<Homegrown_Dealdocument_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Dealdocument_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Dealdocument_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Dealdocument_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Dealdocument_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Dealdocument_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Dealdocument_Variance_Fields>;
};


/** aggregate fields of "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Dealdocument_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Dealdocument_Max_Order_By>;
  min?: InputMaybe<Homegrown_Dealdocument_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Dealdocument_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Dealdocument_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Dealdocument_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Dealdocument_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Dealdocument_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Dealdocument_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Dealdocument_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Dealdocument_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealdocument_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Dealdocument_Avg_Fields = {
  __typename?: 'homegrown_dealdocument_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_dealdocument". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealdocument_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealdocument_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealdocument_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  is_tearsheet?: InputMaybe<Boolean_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealdocument" */
export enum Homegrown_Dealdocument_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  HomegrownDealdocumentFileIdKey = 'homegrown_dealdocument_file_id_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownDealdocumentPkey = 'homegrown_dealdocument_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_tearsheet?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Dealdocument_Max_Fields = {
  __typename?: 'homegrown_dealdocument_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Dealdocument_Min_Fields = {
  __typename?: 'homegrown_dealdocument_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Mutation_Response = {
  __typename?: 'homegrown_dealdocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealdocument>;
};

/** on_conflict condition type for table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_On_Conflict = {
  constraint: Homegrown_Dealdocument_Constraint;
  update_columns?: Array<Homegrown_Dealdocument_Update_Column>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealdocument". */
export type Homegrown_Dealdocument_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_tearsheet?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_dealdocument */
export type Homegrown_Dealdocument_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_dealdocument" */
export enum Homegrown_Dealdocument_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsTearsheet = 'is_tearsheet',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "homegrown_dealdocument_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_dealdocument" */
export enum Homegrown_Dealdocument_Select_Column_Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsTearsheet = 'is_tearsheet'
}

/** select "homegrown_dealdocument_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_dealdocument" */
export enum Homegrown_Dealdocument_Select_Column_Homegrown_Dealdocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsTearsheet = 'is_tearsheet'
}

/** input type for updating data in table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_tearsheet?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Dealdocument_Stddev_Fields = {
  __typename?: 'homegrown_dealdocument_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Dealdocument_Stddev_Pop_Fields = {
  __typename?: 'homegrown_dealdocument_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Dealdocument_Stddev_Samp_Fields = {
  __typename?: 'homegrown_dealdocument_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealdocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealdocument_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_tearsheet?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Dealdocument_Sum_Fields = {
  __typename?: 'homegrown_dealdocument_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_dealdocument" */
export enum Homegrown_Dealdocument_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsTearsheet = 'is_tearsheet',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Homegrown_Dealdocument_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Dealdocument_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealdocument_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealdocument_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Dealdocument_Var_Pop_Fields = {
  __typename?: 'homegrown_dealdocument_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Dealdocument_Var_Samp_Fields = {
  __typename?: 'homegrown_dealdocument_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Dealdocument_Variance_Fields = {
  __typename?: 'homegrown_dealdocument_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_dealdocument" */
export type Homegrown_Dealdocument_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_dealprivate" */
export type Homegrown_Dealprivate = {
  __typename?: 'homegrown_dealprivate';
  active: Scalars['Boolean']['output'];
  available_payback_amount?: Maybe<Scalars['numeric']['output']>;
  bank_account_number?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  bank_routing_number?: Maybe<Scalars['String']['output']>;
  borrower_address?: Maybe<Scalars['String']['output']>;
  counterparty_link_business_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  funding_account_id?: Maybe<Scalars['String']['output']>;
  is_new_deal_notification_sent: Scalars['Boolean']['output'];
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  plaidlinkbusiness?: Maybe<Plaid_App_Plaidlinkbusiness>;
  tp_account_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  tp_counterpartylink?: Maybe<Treasuryprime_App_Counterpartylinkbusiness>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Aggregate = {
  __typename?: 'homegrown_dealprivate_aggregate';
  aggregate?: Maybe<Homegrown_Dealprivate_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealprivate>;
};

export type Homegrown_Dealprivate_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Dealprivate_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Dealprivate_Select_Column_Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Dealprivate_Select_Column_Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Dealprivate_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Aggregate_Fields = {
  __typename?: 'homegrown_dealprivate_aggregate_fields';
  avg?: Maybe<Homegrown_Dealprivate_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealprivate_Max_Fields>;
  min?: Maybe<Homegrown_Dealprivate_Min_Fields>;
  stddev?: Maybe<Homegrown_Dealprivate_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Dealprivate_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Dealprivate_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Dealprivate_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Dealprivate_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Dealprivate_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Dealprivate_Variance_Fields>;
};


/** aggregate fields of "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Dealprivate_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Dealprivate_Max_Order_By>;
  min?: InputMaybe<Homegrown_Dealprivate_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Dealprivate_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Dealprivate_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Dealprivate_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Dealprivate_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Dealprivate_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Dealprivate_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Dealprivate_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Dealprivate_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealprivate_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Dealprivate_Avg_Fields = {
  __typename?: 'homegrown_dealprivate_avg_fields';
  available_payback_amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Avg_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_dealprivate". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealprivate_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealprivate_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealprivate_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  available_payback_amount?: InputMaybe<Numeric_Comparison_Exp>;
  bank_account_number?: InputMaybe<String_Comparison_Exp>;
  bank_name?: InputMaybe<String_Comparison_Exp>;
  bank_routing_number?: InputMaybe<String_Comparison_Exp>;
  borrower_address?: InputMaybe<String_Comparison_Exp>;
  counterparty_link_business_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  funding_account_id?: InputMaybe<String_Comparison_Exp>;
  is_new_deal_notification_sent?: InputMaybe<Boolean_Comparison_Exp>;
  plaid_link_business_id?: InputMaybe<Bigint_Comparison_Exp>;
  plaidlinkbusiness?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
  tp_account_id?: InputMaybe<String_Comparison_Exp>;
  tp_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealprivate" */
export enum Homegrown_Dealprivate_Constraint {
  /** unique or primary key constraint on columns "deal_id" */
  HomegrownDealprivatePkey = 'homegrown_dealprivate_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Inc_Input = {
  available_payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available_payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  bank_account_number?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  bank_routing_number?: InputMaybe<Scalars['String']['input']>;
  borrower_address?: InputMaybe<Scalars['String']['input']>;
  counterparty_link_business_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  funding_account_id?: InputMaybe<Scalars['String']['input']>;
  is_new_deal_notification_sent?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
  plaidlinkbusiness?: InputMaybe<Plaid_App_Plaidlinkbusiness_Obj_Rel_Insert_Input>;
  tp_account_id?: InputMaybe<Scalars['String']['input']>;
  tp_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Dealprivate_Max_Fields = {
  __typename?: 'homegrown_dealprivate_max_fields';
  available_payback_amount?: Maybe<Scalars['numeric']['output']>;
  bank_account_number?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  bank_routing_number?: Maybe<Scalars['String']['output']>;
  borrower_address?: Maybe<Scalars['String']['output']>;
  counterparty_link_business_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  funding_account_id?: Maybe<Scalars['String']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
  tp_account_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Max_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  bank_account_number?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bank_routing_number?: InputMaybe<Order_By>;
  borrower_address?: InputMaybe<Order_By>;
  counterparty_link_business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  funding_account_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
  tp_account_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Dealprivate_Min_Fields = {
  __typename?: 'homegrown_dealprivate_min_fields';
  available_payback_amount?: Maybe<Scalars['numeric']['output']>;
  bank_account_number?: Maybe<Scalars['String']['output']>;
  bank_name?: Maybe<Scalars['String']['output']>;
  bank_routing_number?: Maybe<Scalars['String']['output']>;
  borrower_address?: Maybe<Scalars['String']['output']>;
  counterparty_link_business_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  funding_account_id?: Maybe<Scalars['String']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
  tp_account_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Min_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  bank_account_number?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bank_routing_number?: InputMaybe<Order_By>;
  borrower_address?: InputMaybe<Order_By>;
  counterparty_link_business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  funding_account_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
  tp_account_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Mutation_Response = {
  __typename?: 'homegrown_dealprivate_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealprivate>;
};

/** input type for inserting object relation for remote table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Obj_Rel_Insert_Input = {
  data: Homegrown_Dealprivate_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealprivate_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_On_Conflict = {
  constraint: Homegrown_Dealprivate_Constraint;
  update_columns?: Array<Homegrown_Dealprivate_Update_Column>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealprivate". */
export type Homegrown_Dealprivate_Order_By = {
  active?: InputMaybe<Order_By>;
  available_payback_amount?: InputMaybe<Order_By>;
  bank_account_number?: InputMaybe<Order_By>;
  bank_name?: InputMaybe<Order_By>;
  bank_routing_number?: InputMaybe<Order_By>;
  borrower_address?: InputMaybe<Order_By>;
  counterparty_link_business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  funding_account_id?: InputMaybe<Order_By>;
  is_new_deal_notification_sent?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
  plaidlinkbusiness?: InputMaybe<Plaid_App_Plaidlinkbusiness_Order_By>;
  tp_account_id?: InputMaybe<Order_By>;
  tp_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_dealprivate */
export type Homegrown_Dealprivate_Pk_Columns_Input = {
  deal_id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_dealprivate" */
export enum Homegrown_Dealprivate_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AvailablePaybackAmount = 'available_payback_amount',
  /** column name */
  BankAccountNumber = 'bank_account_number',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BankRoutingNumber = 'bank_routing_number',
  /** column name */
  BorrowerAddress = 'borrower_address',
  /** column name */
  CounterpartyLinkBusinessId = 'counterparty_link_business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  FundingAccountId = 'funding_account_id',
  /** column name */
  IsNewDealNotificationSent = 'is_new_deal_notification_sent',
  /** column name */
  PlaidLinkBusinessId = 'plaid_link_business_id',
  /** column name */
  TpAccountId = 'tp_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "homegrown_dealprivate_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_dealprivate" */
export enum Homegrown_Dealprivate_Select_Column_Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsNewDealNotificationSent = 'is_new_deal_notification_sent'
}

/** select "homegrown_dealprivate_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_dealprivate" */
export enum Homegrown_Dealprivate_Select_Column_Homegrown_Dealprivate_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsNewDealNotificationSent = 'is_new_deal_notification_sent'
}

/** input type for updating data in table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available_payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  bank_account_number?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  bank_routing_number?: InputMaybe<Scalars['String']['input']>;
  borrower_address?: InputMaybe<Scalars['String']['input']>;
  counterparty_link_business_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  funding_account_id?: InputMaybe<Scalars['String']['input']>;
  is_new_deal_notification_sent?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
  tp_account_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Dealprivate_Stddev_Fields = {
  __typename?: 'homegrown_dealprivate_stddev_fields';
  available_payback_amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Stddev_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Dealprivate_Stddev_Pop_Fields = {
  __typename?: 'homegrown_dealprivate_stddev_pop_fields';
  available_payback_amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Stddev_Pop_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Dealprivate_Stddev_Samp_Fields = {
  __typename?: 'homegrown_dealprivate_stddev_samp_fields';
  available_payback_amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Stddev_Samp_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealprivate_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealprivate_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  available_payback_amount?: InputMaybe<Scalars['numeric']['input']>;
  bank_account_number?: InputMaybe<Scalars['String']['input']>;
  bank_name?: InputMaybe<Scalars['String']['input']>;
  bank_routing_number?: InputMaybe<Scalars['String']['input']>;
  borrower_address?: InputMaybe<Scalars['String']['input']>;
  counterparty_link_business_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  funding_account_id?: InputMaybe<Scalars['String']['input']>;
  is_new_deal_notification_sent?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
  tp_account_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Dealprivate_Sum_Fields = {
  __typename?: 'homegrown_dealprivate_sum_fields';
  available_payback_amount?: Maybe<Scalars['numeric']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Sum_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_dealprivate" */
export enum Homegrown_Dealprivate_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AvailablePaybackAmount = 'available_payback_amount',
  /** column name */
  BankAccountNumber = 'bank_account_number',
  /** column name */
  BankName = 'bank_name',
  /** column name */
  BankRoutingNumber = 'bank_routing_number',
  /** column name */
  BorrowerAddress = 'borrower_address',
  /** column name */
  CounterpartyLinkBusinessId = 'counterparty_link_business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  FundingAccountId = 'funding_account_id',
  /** column name */
  IsNewDealNotificationSent = 'is_new_deal_notification_sent',
  /** column name */
  PlaidLinkBusinessId = 'plaid_link_business_id',
  /** column name */
  TpAccountId = 'tp_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Homegrown_Dealprivate_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Dealprivate_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealprivate_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealprivate_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Dealprivate_Var_Pop_Fields = {
  __typename?: 'homegrown_dealprivate_var_pop_fields';
  available_payback_amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Var_Pop_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Dealprivate_Var_Samp_Fields = {
  __typename?: 'homegrown_dealprivate_var_samp_fields';
  available_payback_amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Var_Samp_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Dealprivate_Variance_Fields = {
  __typename?: 'homegrown_dealprivate_variance_fields';
  available_payback_amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_dealprivate" */
export type Homegrown_Dealprivate_Variance_Order_By = {
  available_payback_amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments = {
  __typename?: 'homegrown_dealprivatedocuments';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['bigint']['output'];
  status?: Maybe<Homegrown_Dealprivatedocumentstatus_Enum>;
  /** An object relationship */
  status_enum?: Maybe<Homegrown_Dealprivatedocumentstatus>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Homegrown_Dealprivatedocumenttype_Enum>;
  /** An object relationship */
  type_enum?: Maybe<Homegrown_Dealprivatedocumenttype>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Aggregate = {
  __typename?: 'homegrown_dealprivatedocuments_aggregate';
  aggregate?: Maybe<Homegrown_Dealprivatedocuments_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealprivatedocuments>;
};

export type Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Dealprivatedocuments_Select_Column_Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Dealprivatedocuments_Select_Column_Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Aggregate_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_aggregate_fields';
  avg?: Maybe<Homegrown_Dealprivatedocuments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealprivatedocuments_Max_Fields>;
  min?: Maybe<Homegrown_Dealprivatedocuments_Min_Fields>;
  stddev?: Maybe<Homegrown_Dealprivatedocuments_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Dealprivatedocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Dealprivatedocuments_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Dealprivatedocuments_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Dealprivatedocuments_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Dealprivatedocuments_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Dealprivatedocuments_Variance_Fields>;
};


/** aggregate fields of "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Dealprivatedocuments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Dealprivatedocuments_Max_Order_By>;
  min?: InputMaybe<Homegrown_Dealprivatedocuments_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Dealprivatedocuments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Dealprivatedocuments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Dealprivatedocuments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Dealprivatedocuments_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Dealprivatedocuments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Dealprivatedocuments_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Dealprivatedocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Dealprivatedocuments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Dealprivatedocuments_Avg_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_dealprivatedocuments". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealprivatedocuments_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Enum_Comparison_Exp>;
  status_enum?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Homegrown_Dealprivatedocumenttype_Enum_Comparison_Exp>;
  type_enum?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealprivatedocuments" */
export enum Homegrown_Dealprivatedocuments_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  HomegrownDealprivatedocumentsFileIdKey = 'homegrown_dealprivatedocuments_file_id_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownDealprivatedocumentsPkey = 'homegrown_dealprivatedocuments_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Enum>;
  status_enum?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Homegrown_Dealprivatedocumenttype_Enum>;
  type_enum?: InputMaybe<Homegrown_Dealprivatedocumenttype_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Dealprivatedocuments_Max_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Dealprivatedocuments_Min_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Mutation_Response = {
  __typename?: 'homegrown_dealprivatedocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealprivatedocuments>;
};

/** input type for inserting object relation for remote table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Obj_Rel_Insert_Input = {
  data: Homegrown_Dealprivatedocuments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocuments_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_On_Conflict = {
  constraint: Homegrown_Dealprivatedocuments_Constraint;
  update_columns?: Array<Homegrown_Dealprivatedocuments_Update_Column>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealprivatedocuments". */
export type Homegrown_Dealprivatedocuments_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  status_enum?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  type_enum?: InputMaybe<Homegrown_Dealprivatedocumenttype_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_dealprivatedocuments */
export type Homegrown_Dealprivatedocuments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_dealprivatedocuments" */
export enum Homegrown_Dealprivatedocuments_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "homegrown_dealprivatedocuments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_dealprivatedocuments" */
export enum Homegrown_Dealprivatedocuments_Select_Column_Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "homegrown_dealprivatedocuments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_dealprivatedocuments" */
export enum Homegrown_Dealprivatedocuments_Select_Column_Homegrown_Dealprivatedocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Enum>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Homegrown_Dealprivatedocumenttype_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Dealprivatedocuments_Stddev_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Dealprivatedocuments_Stddev_Pop_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Dealprivatedocuments_Stddev_Samp_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealprivatedocuments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealprivatedocuments_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Enum>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Homegrown_Dealprivatedocumenttype_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Dealprivatedocuments_Sum_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_dealprivatedocuments" */
export enum Homegrown_Dealprivatedocuments_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Homegrown_Dealprivatedocuments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Dealprivatedocuments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealprivatedocuments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealprivatedocuments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Dealprivatedocuments_Var_Pop_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Dealprivatedocuments_Var_Samp_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Dealprivatedocuments_Variance_Fields = {
  __typename?: 'homegrown_dealprivatedocuments_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_dealprivatedocuments" */
export type Homegrown_Dealprivatedocuments_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus = {
  __typename?: 'homegrown_dealprivatedocumentstatus';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Aggregate = {
  __typename?: 'homegrown_dealprivatedocumentstatus_aggregate';
  aggregate?: Maybe<Homegrown_Dealprivatedocumentstatus_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealprivatedocumentstatus>;
};

/** aggregate fields of "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Aggregate_Fields = {
  __typename?: 'homegrown_dealprivatedocumentstatus_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealprivatedocumentstatus_Max_Fields>;
  min?: Maybe<Homegrown_Dealprivatedocumentstatus_Min_Fields>;
};


/** aggregate fields of "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "homegrown_dealprivatedocumentstatus". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealprivatedocumentstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealprivatedocumentstatus" */
export enum Homegrown_Dealprivatedocumentstatus_Constraint {
  /** unique or primary key constraint on columns "name" */
  HomegrownDealprivatedocumentstatusPkey = 'homegrown_dealprivatedocumentstatus_pkey'
}

export enum Homegrown_Dealprivatedocumentstatus_Enum {
  /** Document Incomplete */
  Incomplete = 'INCOMPLETE',
  /** Document Invalid */
  Invalid = 'INVALID',
  /** Document OK */
  Ok = 'OK'
}

/** Boolean expression to compare columns of type "homegrown_dealprivatedocumentstatus_enum". All fields are combined with logical 'AND'. */
export type Homegrown_Dealprivatedocumentstatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Enum>;
  _in?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Enum>;
  _nin?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Enum>>;
};

/** input type for inserting data into table "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Dealprivatedocumentstatus_Max_Fields = {
  __typename?: 'homegrown_dealprivatedocumentstatus_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Homegrown_Dealprivatedocumentstatus_Min_Fields = {
  __typename?: 'homegrown_dealprivatedocumentstatus_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Mutation_Response = {
  __typename?: 'homegrown_dealprivatedocumentstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealprivatedocumentstatus>;
};

/** input type for inserting object relation for remote table "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Obj_Rel_Insert_Input = {
  data: Homegrown_Dealprivatedocumentstatus_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocumentstatus_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_On_Conflict = {
  constraint: Homegrown_Dealprivatedocumentstatus_Constraint;
  update_columns?: Array<Homegrown_Dealprivatedocumentstatus_Update_Column>;
  where?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealprivatedocumentstatus". */
export type Homegrown_Dealprivatedocumentstatus_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_dealprivatedocumentstatus */
export type Homegrown_Dealprivatedocumentstatus_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "homegrown_dealprivatedocumentstatus" */
export enum Homegrown_Dealprivatedocumentstatus_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "homegrown_dealprivatedocumentstatus" */
export type Homegrown_Dealprivatedocumentstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealprivatedocumentstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealprivatedocumentstatus_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "homegrown_dealprivatedocumentstatus" */
export enum Homegrown_Dealprivatedocumentstatus_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Homegrown_Dealprivatedocumentstatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealprivatedocumentstatus_Bool_Exp;
};

/** columns and relationships of "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype = {
  __typename?: 'homegrown_dealprivatedocumenttype';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Aggregate = {
  __typename?: 'homegrown_dealprivatedocumenttype_aggregate';
  aggregate?: Maybe<Homegrown_Dealprivatedocumenttype_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealprivatedocumenttype>;
};

/** aggregate fields of "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Aggregate_Fields = {
  __typename?: 'homegrown_dealprivatedocumenttype_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealprivatedocumenttype_Max_Fields>;
  min?: Maybe<Homegrown_Dealprivatedocumenttype_Min_Fields>;
};


/** aggregate fields of "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "homegrown_dealprivatedocumenttype". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealprivatedocumenttype_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealprivatedocumenttype" */
export enum Homegrown_Dealprivatedocumenttype_Constraint {
  /** unique or primary key constraint on columns "name" */
  HomegrownDealprivatedocumenttypePkey = 'homegrown_dealprivatedocumenttype_pkey'
}

export enum Homegrown_Dealprivatedocumenttype_Enum {
  /** Balance Sheets */
  Balance = 'BALANCE',
  /** Bank Statements */
  BankStatement = 'BANK_STATEMENT',
  /** Cash Flows */
  CashFlow = 'CASH_FLOW',
  /** Debt Schedules */
  DebtSchedule = 'DEBT_SCHEDULE',
  /** Lease Agreements */
  Lease = 'LEASE',
  /** Profit and Loss Statements */
  ProfitAndLoss = 'PROFIT_AND_LOSS',
  /** Strategic Documentation */
  Strategic = 'STRATEGIC'
}

/** Boolean expression to compare columns of type "homegrown_dealprivatedocumenttype_enum". All fields are combined with logical 'AND'. */
export type Homegrown_Dealprivatedocumenttype_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Homegrown_Dealprivatedocumenttype_Enum>;
  _in?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Homegrown_Dealprivatedocumenttype_Enum>;
  _nin?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Enum>>;
};

/** input type for inserting data into table "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Dealprivatedocumenttype_Max_Fields = {
  __typename?: 'homegrown_dealprivatedocumenttype_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Homegrown_Dealprivatedocumenttype_Min_Fields = {
  __typename?: 'homegrown_dealprivatedocumenttype_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Mutation_Response = {
  __typename?: 'homegrown_dealprivatedocumenttype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealprivatedocumenttype>;
};

/** input type for inserting object relation for remote table "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Obj_Rel_Insert_Input = {
  data: Homegrown_Dealprivatedocumenttype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocumenttype_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_On_Conflict = {
  constraint: Homegrown_Dealprivatedocumenttype_Constraint;
  update_columns?: Array<Homegrown_Dealprivatedocumenttype_Update_Column>;
  where?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealprivatedocumenttype". */
export type Homegrown_Dealprivatedocumenttype_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_dealprivatedocumenttype */
export type Homegrown_Dealprivatedocumenttype_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "homegrown_dealprivatedocumenttype" */
export enum Homegrown_Dealprivatedocumenttype_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "homegrown_dealprivatedocumenttype" */
export type Homegrown_Dealprivatedocumenttype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealprivatedocumenttype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealprivatedocumenttype_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "homegrown_dealprivatedocumenttype" */
export enum Homegrown_Dealprivatedocumenttype_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Homegrown_Dealprivatedocumenttype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealprivatedocumenttype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealprivatedocumenttype_Bool_Exp;
};

/** columns and relationships of "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink = {
  __typename?: 'homegrown_dealtobusinessplaidlink';
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  plaid_link_business_id: Scalars['bigint']['output'];
  /** An object relationship */
  plaidlinkbusiness: Plaid_App_Plaidlinkbusiness;
};

/** aggregated selection of "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Aggregate = {
  __typename?: 'homegrown_dealtobusinessplaidlink_aggregate';
  aggregate?: Maybe<Homegrown_Dealtobusinessplaidlink_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealtobusinessplaidlink>;
};

export type Homegrown_Dealtobusinessplaidlink_Aggregate_Bool_Exp = {
  count?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Dealtobusinessplaidlink_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Aggregate_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_aggregate_fields';
  avg?: Maybe<Homegrown_Dealtobusinessplaidlink_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealtobusinessplaidlink_Max_Fields>;
  min?: Maybe<Homegrown_Dealtobusinessplaidlink_Min_Fields>;
  stddev?: Maybe<Homegrown_Dealtobusinessplaidlink_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Dealtobusinessplaidlink_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Dealtobusinessplaidlink_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Dealtobusinessplaidlink_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Dealtobusinessplaidlink_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Dealtobusinessplaidlink_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Dealtobusinessplaidlink_Variance_Fields>;
};


/** aggregate fields of "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Max_Order_By>;
  min?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Dealtobusinessplaidlink_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealtobusinessplaidlink_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Dealtobusinessplaidlink_Avg_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_dealtobusinessplaidlink". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealtobusinessplaidlink_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Bool_Exp>>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  plaid_link_business_id?: InputMaybe<Bigint_Comparison_Exp>;
  plaidlinkbusiness?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealtobusinessplaidlink" */
export enum Homegrown_Dealtobusinessplaidlink_Constraint {
  /** unique or primary key constraint on columns "plaid_link_business_id", "deal_id" */
  DealPlaidlinkUniq = 'deal_plaidlink_uniq',
  /** unique or primary key constraint on columns "id" */
  HomegrownDealtobusinessplaidlinkPkey = 'homegrown_dealtobusinessplaidlink_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Insert_Input = {
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
  plaidlinkbusiness?: InputMaybe<Plaid_App_Plaidlinkbusiness_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Homegrown_Dealtobusinessplaidlink_Max_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_max_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Max_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Dealtobusinessplaidlink_Min_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_min_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Min_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Mutation_Response = {
  __typename?: 'homegrown_dealtobusinessplaidlink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealtobusinessplaidlink>;
};

/** on_conflict condition type for table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_On_Conflict = {
  constraint: Homegrown_Dealtobusinessplaidlink_Constraint;
  update_columns?: Array<Homegrown_Dealtobusinessplaidlink_Update_Column>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealtobusinessplaidlink". */
export type Homegrown_Dealtobusinessplaidlink_Order_By = {
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
  plaidlinkbusiness?: InputMaybe<Plaid_App_Plaidlinkbusiness_Order_By>;
};

/** primary key columns input for table: homegrown_dealtobusinessplaidlink */
export type Homegrown_Dealtobusinessplaidlink_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_dealtobusinessplaidlink" */
export enum Homegrown_Dealtobusinessplaidlink_Select_Column {
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlaidLinkBusinessId = 'plaid_link_business_id'
}

/** input type for updating data in table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Set_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Dealtobusinessplaidlink_Stddev_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Dealtobusinessplaidlink_Stddev_Pop_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Dealtobusinessplaidlink_Stddev_Samp_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealtobusinessplaidlink_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealtobusinessplaidlink_Stream_Cursor_Value_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Dealtobusinessplaidlink_Sum_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_dealtobusinessplaidlink" */
export enum Homegrown_Dealtobusinessplaidlink_Update_Column {
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlaidLinkBusinessId = 'plaid_link_business_id'
}

export type Homegrown_Dealtobusinessplaidlink_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealtobusinessplaidlink_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Dealtobusinessplaidlink_Var_Pop_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Dealtobusinessplaidlink_Var_Samp_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Dealtobusinessplaidlink_Variance_Fields = {
  __typename?: 'homegrown_dealtobusinessplaidlink_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_dealtobusinessplaidlink" */
export type Homegrown_Dealtobusinessplaidlink_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink = {
  __typename?: 'homegrown_dealtoinvestorlink';
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  plaid?: Maybe<Plaid_App_Plaidlink>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  treasuryprime?: Maybe<Treasuryprime_App_Counterpartylink>;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Aggregate = {
  __typename?: 'homegrown_dealtoinvestorlink_aggregate';
  aggregate?: Maybe<Homegrown_Dealtoinvestorlink_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealtoinvestorlink>;
};

export type Homegrown_Dealtoinvestorlink_Aggregate_Bool_Exp = {
  count?: InputMaybe<Homegrown_Dealtoinvestorlink_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Dealtoinvestorlink_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Aggregate_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_aggregate_fields';
  avg?: Maybe<Homegrown_Dealtoinvestorlink_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealtoinvestorlink_Max_Fields>;
  min?: Maybe<Homegrown_Dealtoinvestorlink_Min_Fields>;
  stddev?: Maybe<Homegrown_Dealtoinvestorlink_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Dealtoinvestorlink_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Dealtoinvestorlink_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Dealtoinvestorlink_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Dealtoinvestorlink_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Dealtoinvestorlink_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Dealtoinvestorlink_Variance_Fields>;
};


/** aggregate fields of "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Dealtoinvestorlink_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Dealtoinvestorlink_Max_Order_By>;
  min?: InputMaybe<Homegrown_Dealtoinvestorlink_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Dealtoinvestorlink_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Dealtoinvestorlink_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Dealtoinvestorlink_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Dealtoinvestorlink_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Dealtoinvestorlink_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Dealtoinvestorlink_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Dealtoinvestorlink_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Dealtoinvestorlink_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Dealtoinvestorlink_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Dealtoinvestorlink_Avg_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_dealtoinvestorlink". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealtoinvestorlink_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Bool_Exp>>;
  counterparty_link_id?: InputMaybe<String_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  plaid_link_id?: InputMaybe<Bigint_Comparison_Exp>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealtoinvestorlink" */
export enum Homegrown_Dealtoinvestorlink_Constraint {
  /** unique or primary key constraint on columns "user_id", "deal_id" */
  DealInvestorLinkUniq = 'deal_investor_link_uniq',
  /** unique or primary key constraint on columns "id" */
  HomegrownDealtoinvestorlinkPkey = 'homegrown_dealtoinvestorlink_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Insert_Input = {
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Obj_Rel_Insert_Input>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Dealtoinvestorlink_Max_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_max_fields';
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Max_Order_By = {
  counterparty_link_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Dealtoinvestorlink_Min_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_min_fields';
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Min_Order_By = {
  counterparty_link_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Mutation_Response = {
  __typename?: 'homegrown_dealtoinvestorlink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealtoinvestorlink>;
};

/** on_conflict condition type for table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_On_Conflict = {
  constraint: Homegrown_Dealtoinvestorlink_Constraint;
  update_columns?: Array<Homegrown_Dealtoinvestorlink_Update_Column>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealtoinvestorlink". */
export type Homegrown_Dealtoinvestorlink_Order_By = {
  counterparty_link_id?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_dealtoinvestorlink */
export type Homegrown_Dealtoinvestorlink_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_dealtoinvestorlink" */
export enum Homegrown_Dealtoinvestorlink_Select_Column {
  /** column name */
  CounterpartyLinkId = 'counterparty_link_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlaidLinkId = 'plaid_link_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Set_Input = {
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Dealtoinvestorlink_Stddev_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Dealtoinvestorlink_Stddev_Pop_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Dealtoinvestorlink_Stddev_Samp_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealtoinvestorlink_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealtoinvestorlink_Stream_Cursor_Value_Input = {
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Dealtoinvestorlink_Sum_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_dealtoinvestorlink" */
export enum Homegrown_Dealtoinvestorlink_Update_Column {
  /** column name */
  CounterpartyLinkId = 'counterparty_link_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlaidLinkId = 'plaid_link_id',
  /** column name */
  UserId = 'user_id'
}

export type Homegrown_Dealtoinvestorlink_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Dealtoinvestorlink_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealtoinvestorlink_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealtoinvestorlink_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Dealtoinvestorlink_Var_Pop_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Dealtoinvestorlink_Var_Samp_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Dealtoinvestorlink_Variance_Fields = {
  __typename?: 'homegrown_dealtoinvestorlink_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_dealtoinvestorlink" */
export type Homegrown_Dealtoinvestorlink_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_dealtype" */
export type Homegrown_Dealtype = {
  __typename?: 'homegrown_dealtype';
  name: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_dealtype" */
export type Homegrown_Dealtype_Aggregate = {
  __typename?: 'homegrown_dealtype_aggregate';
  aggregate?: Maybe<Homegrown_Dealtype_Aggregate_Fields>;
  nodes: Array<Homegrown_Dealtype>;
};

/** aggregate fields of "homegrown_dealtype" */
export type Homegrown_Dealtype_Aggregate_Fields = {
  __typename?: 'homegrown_dealtype_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Dealtype_Max_Fields>;
  min?: Maybe<Homegrown_Dealtype_Min_Fields>;
};


/** aggregate fields of "homegrown_dealtype" */
export type Homegrown_Dealtype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Dealtype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "homegrown_dealtype". All fields are combined with a logical 'AND'. */
export type Homegrown_Dealtype_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Dealtype_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Dealtype_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Dealtype_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_dealtype" */
export enum Homegrown_Dealtype_Constraint {
  /** unique or primary key constraint on columns "name" */
  HomegrownDealtypePkey = 'homegrown_dealtype_pkey'
}

/** input type for inserting data into table "homegrown_dealtype" */
export type Homegrown_Dealtype_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Dealtype_Max_Fields = {
  __typename?: 'homegrown_dealtype_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Homegrown_Dealtype_Min_Fields = {
  __typename?: 'homegrown_dealtype_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "homegrown_dealtype" */
export type Homegrown_Dealtype_Mutation_Response = {
  __typename?: 'homegrown_dealtype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Dealtype>;
};

/** on_conflict condition type for table "homegrown_dealtype" */
export type Homegrown_Dealtype_On_Conflict = {
  constraint: Homegrown_Dealtype_Constraint;
  update_columns?: Array<Homegrown_Dealtype_Update_Column>;
  where?: InputMaybe<Homegrown_Dealtype_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_dealtype". */
export type Homegrown_Dealtype_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_dealtype */
export type Homegrown_Dealtype_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "homegrown_dealtype" */
export enum Homegrown_Dealtype_Select_Column {
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "homegrown_dealtype" */
export type Homegrown_Dealtype_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "homegrown_dealtype" */
export type Homegrown_Dealtype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Dealtype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Dealtype_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "homegrown_dealtype" */
export enum Homegrown_Dealtype_Update_Column {
  /** column name */
  Name = 'name'
}

export type Homegrown_Dealtype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Dealtype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Dealtype_Bool_Exp;
};

/** columns and relationships of "homegrown_location" */
export type Homegrown_Location = {
  __typename?: 'homegrown_location';
  active: Scalars['Boolean']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  homegrown_business?: Maybe<Homegrown_Business>;
  /** An object relationship */
  homegrown_deal?: Maybe<Homegrown_Deal>;
  id: Scalars['bigint']['output'];
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  zip_code?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "homegrown_location" */
export type Homegrown_Location_Aggregate = {
  __typename?: 'homegrown_location_aggregate';
  aggregate?: Maybe<Homegrown_Location_Aggregate_Fields>;
  nodes: Array<Homegrown_Location>;
};

/** aggregate fields of "homegrown_location" */
export type Homegrown_Location_Aggregate_Fields = {
  __typename?: 'homegrown_location_aggregate_fields';
  avg?: Maybe<Homegrown_Location_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Location_Max_Fields>;
  min?: Maybe<Homegrown_Location_Min_Fields>;
  stddev?: Maybe<Homegrown_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Location_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Location_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Location_Variance_Fields>;
};


/** aggregate fields of "homegrown_location" */
export type Homegrown_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Homegrown_Location_Avg_Fields = {
  __typename?: 'homegrown_location_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  zip_code?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "homegrown_location". All fields are combined with a logical 'AND'. */
export type Homegrown_Location_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Location_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Location_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Location_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  homegrown_business?: InputMaybe<Homegrown_Business_Bool_Exp>;
  homegrown_deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  street?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip_code?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_location" */
export enum Homegrown_Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  HomegrownLocationPkey = 'homegrown_location_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_location" */
export type Homegrown_Location_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  zip_code?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "homegrown_location" */
export type Homegrown_Location_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  homegrown_business?: InputMaybe<Homegrown_Business_Obj_Rel_Insert_Input>;
  homegrown_deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zip_code?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Location_Max_Fields = {
  __typename?: 'homegrown_location_max_fields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zip_code?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Homegrown_Location_Min_Fields = {
  __typename?: 'homegrown_location_min_fields';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  zip_code?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "homegrown_location" */
export type Homegrown_Location_Mutation_Response = {
  __typename?: 'homegrown_location_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Location>;
};

/** input type for inserting object relation for remote table "homegrown_location" */
export type Homegrown_Location_Obj_Rel_Insert_Input = {
  data: Homegrown_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Location_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_location" */
export type Homegrown_Location_On_Conflict = {
  constraint: Homegrown_Location_Constraint;
  update_columns?: Array<Homegrown_Location_Update_Column>;
  where?: InputMaybe<Homegrown_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_location". */
export type Homegrown_Location_Order_By = {
  active?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  homegrown_business?: InputMaybe<Homegrown_Business_Order_By>;
  homegrown_deal?: InputMaybe<Homegrown_Deal_Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  street?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_location */
export type Homegrown_Location_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_location" */
export enum Homegrown_Location_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "homegrown_location" */
export type Homegrown_Location_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zip_code?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Location_Stddev_Fields = {
  __typename?: 'homegrown_location_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  zip_code?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Location_Stddev_Pop_Fields = {
  __typename?: 'homegrown_location_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  zip_code?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Location_Stddev_Samp_Fields = {
  __typename?: 'homegrown_location_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  zip_code?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "homegrown_location" */
export type Homegrown_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Location_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  zip_code?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Location_Sum_Fields = {
  __typename?: 'homegrown_location_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  zip_code?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "homegrown_location" */
export enum Homegrown_Location_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

export type Homegrown_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Location_Var_Pop_Fields = {
  __typename?: 'homegrown_location_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  zip_code?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Homegrown_Location_Var_Samp_Fields = {
  __typename?: 'homegrown_location_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  zip_code?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Homegrown_Location_Variance_Fields = {
  __typename?: 'homegrown_location_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  zip_code?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "homegrown_referralcode" */
export type Homegrown_Referralcode = {
  __typename?: 'homegrown_referralcode';
  active: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  usedreferralcodes: Array<Homegrown_Usedreferralcode>;
  /** An aggregate relationship */
  usedreferralcodes_aggregate: Homegrown_Usedreferralcode_Aggregate;
  /** An object relationship */
  user: Users_User;
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "homegrown_referralcode" */
export type Homegrown_ReferralcodeUsedreferralcodesArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Usedreferralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};


/** columns and relationships of "homegrown_referralcode" */
export type Homegrown_ReferralcodeUsedreferralcodes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Usedreferralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};

/** aggregated selection of "homegrown_referralcode" */
export type Homegrown_Referralcode_Aggregate = {
  __typename?: 'homegrown_referralcode_aggregate';
  aggregate?: Maybe<Homegrown_Referralcode_Aggregate_Fields>;
  nodes: Array<Homegrown_Referralcode>;
};

/** aggregate fields of "homegrown_referralcode" */
export type Homegrown_Referralcode_Aggregate_Fields = {
  __typename?: 'homegrown_referralcode_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Referralcode_Max_Fields>;
  min?: Maybe<Homegrown_Referralcode_Min_Fields>;
};


/** aggregate fields of "homegrown_referralcode" */
export type Homegrown_Referralcode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Referralcode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "homegrown_referralcode". All fields are combined with a logical 'AND'. */
export type Homegrown_Referralcode_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Referralcode_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Referralcode_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  usedreferralcodes?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
  usedreferralcodes_aggregate?: InputMaybe<Homegrown_Usedreferralcode_Aggregate_Bool_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_referralcode" */
export enum Homegrown_Referralcode_Constraint {
  /** unique or primary key constraint on columns "code" */
  HomegrownReferralcodeCodeKey = 'homegrown_referralcode_code_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownReferralcodePkey = 'homegrown_referralcode_pkey',
  /** unique or primary key constraint on columns "user_id" */
  ReferralCodeActiveUniq = 'referral_code_active_uniq'
}

/** input type for inserting data into table "homegrown_referralcode" */
export type Homegrown_Referralcode_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  usedreferralcodes?: InputMaybe<Homegrown_Usedreferralcode_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Referralcode_Max_Fields = {
  __typename?: 'homegrown_referralcode_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Homegrown_Referralcode_Min_Fields = {
  __typename?: 'homegrown_referralcode_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "homegrown_referralcode" */
export type Homegrown_Referralcode_Mutation_Response = {
  __typename?: 'homegrown_referralcode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Referralcode>;
};

/** input type for inserting object relation for remote table "homegrown_referralcode" */
export type Homegrown_Referralcode_Obj_Rel_Insert_Input = {
  data: Homegrown_Referralcode_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Referralcode_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_referralcode" */
export type Homegrown_Referralcode_On_Conflict = {
  constraint: Homegrown_Referralcode_Constraint;
  update_columns?: Array<Homegrown_Referralcode_Update_Column>;
  where?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_referralcode". */
export type Homegrown_Referralcode_Order_By = {
  active?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  usedreferralcodes_aggregate?: InputMaybe<Homegrown_Usedreferralcode_Aggregate_Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_referralcode */
export type Homegrown_Referralcode_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "homegrown_referralcode" */
export enum Homegrown_Referralcode_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "homegrown_referralcode" */
export type Homegrown_Referralcode_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "homegrown_referralcode" */
export type Homegrown_Referralcode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Referralcode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Referralcode_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "homegrown_referralcode" */
export enum Homegrown_Referralcode_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Homegrown_Referralcode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Referralcode_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Referralcode_Bool_Exp;
};

/** columns and relationships of "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument = {
  __typename?: 'homegrown_signwelldealdocument';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  investor_deal_signed_doc?: Maybe<Homegrown_Signwellinvestorsigneddocument>;
  is_for_business: Scalars['Boolean']['output'];
  /** An array relationship */
  signed_documents: Array<Homegrown_Signwellinvestorsigneddocument>;
  /** An aggregate relationship */
  signed_documents_aggregate: Homegrown_Signwellinvestorsigneddocument_Aggregate;
  template_id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "homegrown_signwelldealdocument" */
export type Homegrown_SignwelldealdocumentSigned_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


/** columns and relationships of "homegrown_signwelldealdocument" */
export type Homegrown_SignwelldealdocumentSigned_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};

/** aggregated selection of "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Aggregate = {
  __typename?: 'homegrown_signwelldealdocument_aggregate';
  aggregate?: Maybe<Homegrown_Signwelldealdocument_Aggregate_Fields>;
  nodes: Array<Homegrown_Signwelldealdocument>;
};

export type Homegrown_Signwelldealdocument_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Signwelldealdocument_Select_Column_Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Signwelldealdocument_Select_Column_Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Aggregate_Fields = {
  __typename?: 'homegrown_signwelldealdocument_aggregate_fields';
  avg?: Maybe<Homegrown_Signwelldealdocument_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Signwelldealdocument_Max_Fields>;
  min?: Maybe<Homegrown_Signwelldealdocument_Min_Fields>;
  stddev?: Maybe<Homegrown_Signwelldealdocument_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Signwelldealdocument_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Signwelldealdocument_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Signwelldealdocument_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Signwelldealdocument_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Signwelldealdocument_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Signwelldealdocument_Variance_Fields>;
};


/** aggregate fields of "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Signwelldealdocument_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Signwelldealdocument_Max_Order_By>;
  min?: InputMaybe<Homegrown_Signwelldealdocument_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Signwelldealdocument_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Signwelldealdocument_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Signwelldealdocument_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Signwelldealdocument_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Signwelldealdocument_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Signwelldealdocument_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Signwelldealdocument_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Signwelldealdocument_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Signwelldealdocument_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Signwelldealdocument_Avg_Fields = {
  __typename?: 'homegrown_signwelldealdocument_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_signwelldealdocument". All fields are combined with a logical 'AND'. */
export type Homegrown_Signwelldealdocument_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Signwelldealdocument_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Signwelldealdocument_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  investor_deal_signed_doc?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  is_for_business?: InputMaybe<Boolean_Comparison_Exp>;
  signed_documents?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  signed_documents_aggregate?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp>;
  template_id?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_signwelldealdocument" */
export enum Homegrown_Signwelldealdocument_Constraint {
  /** unique or primary key constraint on columns "id" */
  HomegrownSignwelldealdocumentPkey = 'homegrown_signwelldealdocument_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  investor_deal_signed_doc?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Obj_Rel_Insert_Input>;
  is_for_business?: InputMaybe<Scalars['Boolean']['input']>;
  signed_documents?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Arr_Rel_Insert_Input>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Signwelldealdocument_Max_Fields = {
  __typename?: 'homegrown_signwelldealdocument_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  template_id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Signwelldealdocument_Min_Fields = {
  __typename?: 'homegrown_signwelldealdocument_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  template_id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Mutation_Response = {
  __typename?: 'homegrown_signwelldealdocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Signwelldealdocument>;
};

/** input type for inserting object relation for remote table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Obj_Rel_Insert_Input = {
  data: Homegrown_Signwelldealdocument_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Signwelldealdocument_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_On_Conflict = {
  constraint: Homegrown_Signwelldealdocument_Constraint;
  update_columns?: Array<Homegrown_Signwelldealdocument_Update_Column>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_signwelldealdocument". */
export type Homegrown_Signwelldealdocument_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor_deal_signed_doc?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Order_By>;
  is_for_business?: InputMaybe<Order_By>;
  signed_documents_aggregate?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Order_By>;
  template_id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_signwelldealdocument */
export type Homegrown_Signwelldealdocument_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_signwelldealdocument" */
export enum Homegrown_Signwelldealdocument_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsForBusiness = 'is_for_business',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "homegrown_signwelldealdocument_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_signwelldealdocument" */
export enum Homegrown_Signwelldealdocument_Select_Column_Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsForBusiness = 'is_for_business'
}

/** select "homegrown_signwelldealdocument_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_signwelldealdocument" */
export enum Homegrown_Signwelldealdocument_Select_Column_Homegrown_Signwelldealdocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsForBusiness = 'is_for_business'
}

/** input type for updating data in table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_for_business?: InputMaybe<Scalars['Boolean']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Signwelldealdocument_Stddev_Fields = {
  __typename?: 'homegrown_signwelldealdocument_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Signwelldealdocument_Stddev_Pop_Fields = {
  __typename?: 'homegrown_signwelldealdocument_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Signwelldealdocument_Stddev_Samp_Fields = {
  __typename?: 'homegrown_signwelldealdocument_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Signwelldealdocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Signwelldealdocument_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_for_business?: InputMaybe<Scalars['Boolean']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Signwelldealdocument_Sum_Fields = {
  __typename?: 'homegrown_signwelldealdocument_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_signwelldealdocument" */
export enum Homegrown_Signwelldealdocument_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsForBusiness = 'is_for_business',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Homegrown_Signwelldealdocument_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Signwelldealdocument_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Signwelldealdocument_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Signwelldealdocument_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Signwelldealdocument_Var_Pop_Fields = {
  __typename?: 'homegrown_signwelldealdocument_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Signwelldealdocument_Var_Samp_Fields = {
  __typename?: 'homegrown_signwelldealdocument_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Signwelldealdocument_Variance_Fields = {
  __typename?: 'homegrown_signwelldealdocument_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_signwelldealdocument" */
export type Homegrown_Signwelldealdocument_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument = {
  __typename?: 'homegrown_signwellinvestorsigneddocument';
  active: Scalars['Boolean']['output'];
  completed_pdf_url?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  /** An object relationship */
  document?: Maybe<Homegrown_Signwelldealdocument>;
  document_id?: Maybe<Scalars['String']['output']>;
  document_url?: Maybe<Scalars['String']['output']>;
  embedded_preview_url?: Maybe<Scalars['String']['output']>;
  embedded_signing_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  investor?: Maybe<Investordata>;
  signed: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
  template_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Aggregate = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_aggregate';
  aggregate?: Maybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Fields>;
  nodes: Array<Homegrown_Signwellinvestorsigneddocument>;
};

export type Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Signwellinvestorsigneddocument_Select_Column_Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Signwellinvestorsigneddocument_Select_Column_Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Aggregate_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_aggregate_fields';
  avg?: Maybe<Homegrown_Signwellinvestorsigneddocument_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Signwellinvestorsigneddocument_Max_Fields>;
  min?: Maybe<Homegrown_Signwellinvestorsigneddocument_Min_Fields>;
  stddev?: Maybe<Homegrown_Signwellinvestorsigneddocument_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Signwellinvestorsigneddocument_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Signwellinvestorsigneddocument_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Signwellinvestorsigneddocument_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Signwellinvestorsigneddocument_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Signwellinvestorsigneddocument_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Signwellinvestorsigneddocument_Variance_Fields>;
};


/** aggregate fields of "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Max_Order_By>;
  min?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Signwellinvestorsigneddocument_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Signwellinvestorsigneddocument_Avg_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_signwellinvestorsigneddocument". All fields are combined with a logical 'AND'. */
export type Homegrown_Signwellinvestorsigneddocument_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  completed_pdf_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  document?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
  document_id?: InputMaybe<String_Comparison_Exp>;
  document_url?: InputMaybe<String_Comparison_Exp>;
  embedded_preview_url?: InputMaybe<String_Comparison_Exp>;
  embedded_signing_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  investor?: InputMaybe<Investordata_Bool_Exp>;
  signed?: InputMaybe<Boolean_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  template_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_signwellinvestorsigneddocument" */
export enum Homegrown_Signwellinvestorsigneddocument_Constraint {
  /** unique or primary key constraint on columns "embedded_signing_url" */
  HomegrownSignwellinvestorsigneddocumeEmbeddedSigningUrlKey = 'homegrown_signwellinvestorsigneddocume_embedded_signing_url_key',
  /** unique or primary key constraint on columns "document_url" */
  HomegrownSignwellinvestorsigneddocumentDocumentUrlKey = 'homegrown_signwellinvestorsigneddocument_document_url_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownSignwellinvestorsigneddocumentPkey = 'homegrown_signwellinvestorsigneddocument_pkey',
  /** unique or primary key constraint on columns "user_id", "template_id", "deal_id" */
  InvestorDealDocUniq = 'investor_deal_doc_uniq',
  /** unique or primary key constraint on columns "document_id", "user_id" */
  InvestorSignedUniq = 'investor_signed_uniq'
}

/** input type for incrementing numeric columns in table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_pdf_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  document?: InputMaybe<Homegrown_Signwelldealdocument_Obj_Rel_Insert_Input>;
  document_id?: InputMaybe<Scalars['String']['input']>;
  document_url?: InputMaybe<Scalars['String']['input']>;
  embedded_preview_url?: InputMaybe<Scalars['String']['input']>;
  embedded_signing_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Signwellinvestorsigneddocument_Max_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_max_fields';
  completed_pdf_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  document_id?: Maybe<Scalars['String']['output']>;
  document_url?: Maybe<Scalars['String']['output']>;
  embedded_preview_url?: Maybe<Scalars['String']['output']>;
  embedded_signing_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Max_Order_By = {
  completed_pdf_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  embedded_preview_url?: InputMaybe<Order_By>;
  embedded_signing_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Signwellinvestorsigneddocument_Min_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_min_fields';
  completed_pdf_url?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  document_id?: Maybe<Scalars['String']['output']>;
  document_url?: Maybe<Scalars['String']['output']>;
  embedded_preview_url?: Maybe<Scalars['String']['output']>;
  embedded_signing_url?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Min_Order_By = {
  completed_pdf_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  document_id?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  embedded_preview_url?: InputMaybe<Order_By>;
  embedded_signing_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Mutation_Response = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Signwellinvestorsigneddocument>;
};

/** input type for inserting object relation for remote table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Obj_Rel_Insert_Input = {
  data: Homegrown_Signwellinvestorsigneddocument_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_On_Conflict = {
  constraint: Homegrown_Signwellinvestorsigneddocument_Constraint;
  update_columns?: Array<Homegrown_Signwellinvestorsigneddocument_Update_Column>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_signwellinvestorsigneddocument". */
export type Homegrown_Signwellinvestorsigneddocument_Order_By = {
  active?: InputMaybe<Order_By>;
  completed_pdf_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  document?: InputMaybe<Homegrown_Signwelldealdocument_Order_By>;
  document_id?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  embedded_preview_url?: InputMaybe<Order_By>;
  embedded_signing_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor?: InputMaybe<Investordata_Order_By>;
  signed?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_signwellinvestorsigneddocument */
export type Homegrown_Signwellinvestorsigneddocument_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_signwellinvestorsigneddocument" */
export enum Homegrown_Signwellinvestorsigneddocument_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompletedPdfUrl = 'completed_pdf_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  EmbeddedPreviewUrl = 'embedded_preview_url',
  /** column name */
  EmbeddedSigningUrl = 'embedded_signing_url',
  /** column name */
  Id = 'id',
  /** column name */
  Signed = 'signed',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "homegrown_signwellinvestorsigneddocument_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_signwellinvestorsigneddocument" */
export enum Homegrown_Signwellinvestorsigneddocument_Select_Column_Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Signed = 'signed'
}

/** select "homegrown_signwellinvestorsigneddocument_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_signwellinvestorsigneddocument" */
export enum Homegrown_Signwellinvestorsigneddocument_Select_Column_Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  Signed = 'signed'
}

/** input type for updating data in table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_pdf_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  document_id?: InputMaybe<Scalars['String']['input']>;
  document_url?: InputMaybe<Scalars['String']['input']>;
  embedded_preview_url?: InputMaybe<Scalars['String']['input']>;
  embedded_signing_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Signwellinvestorsigneddocument_Stddev_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Signwellinvestorsigneddocument_Stddev_Pop_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Signwellinvestorsigneddocument_Stddev_Samp_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Signwellinvestorsigneddocument_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Signwellinvestorsigneddocument_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  completed_pdf_url?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  document_id?: InputMaybe<Scalars['String']['input']>;
  document_url?: InputMaybe<Scalars['String']['input']>;
  embedded_preview_url?: InputMaybe<Scalars['String']['input']>;
  embedded_signing_url?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  signed?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Signwellinvestorsigneddocument_Sum_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_signwellinvestorsigneddocument" */
export enum Homegrown_Signwellinvestorsigneddocument_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompletedPdfUrl = 'completed_pdf_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  EmbeddedPreviewUrl = 'embedded_preview_url',
  /** column name */
  EmbeddedSigningUrl = 'embedded_signing_url',
  /** column name */
  Id = 'id',
  /** column name */
  Signed = 'signed',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Homegrown_Signwellinvestorsigneddocument_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Signwellinvestorsigneddocument_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Signwellinvestorsigneddocument_Var_Pop_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Signwellinvestorsigneddocument_Var_Samp_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Signwellinvestorsigneddocument_Variance_Fields = {
  __typename?: 'homegrown_signwellinvestorsigneddocument_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_signwellinvestorsigneddocument" */
export type Homegrown_Signwellinvestorsigneddocument_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments = {
  __typename?: 'homegrown_taxdocuments';
  active: Scalars['Boolean']['output'];
  /** An object relationship */
  business_owner?: Maybe<Users_Businessowner>;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  created_by?: Maybe<Users_User>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  investor?: Maybe<Investordata>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users_User;
  user_id: Scalars['String']['output'];
  year?: Maybe<Scalars['smallint']['output']>;
};

/** aggregated selection of "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Aggregate = {
  __typename?: 'homegrown_taxdocuments_aggregate';
  aggregate?: Maybe<Homegrown_Taxdocuments_Aggregate_Fields>;
  nodes: Array<Homegrown_Taxdocuments>;
};

export type Homegrown_Taxdocuments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Taxdocuments_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Taxdocuments_Select_Column_Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Taxdocuments_Select_Column_Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Taxdocuments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Aggregate_Fields = {
  __typename?: 'homegrown_taxdocuments_aggregate_fields';
  avg?: Maybe<Homegrown_Taxdocuments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Taxdocuments_Max_Fields>;
  min?: Maybe<Homegrown_Taxdocuments_Min_Fields>;
  stddev?: Maybe<Homegrown_Taxdocuments_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Taxdocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Taxdocuments_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Taxdocuments_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Taxdocuments_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Taxdocuments_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Taxdocuments_Variance_Fields>;
};


/** aggregate fields of "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Taxdocuments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Taxdocuments_Max_Order_By>;
  min?: InputMaybe<Homegrown_Taxdocuments_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Taxdocuments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Taxdocuments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Taxdocuments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Taxdocuments_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Taxdocuments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Taxdocuments_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Taxdocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Taxdocuments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Taxdocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Taxdocuments_Avg_Fields = {
  __typename?: 'homegrown_taxdocuments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_taxdocuments". All fields are combined with a logical 'AND'. */
export type Homegrown_Taxdocuments_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Taxdocuments_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Taxdocuments_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  business_owner?: InputMaybe<Users_Businessowner_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Users_User_Bool_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  investor?: InputMaybe<Investordata_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  year?: InputMaybe<Smallint_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_taxdocuments" */
export enum Homegrown_Taxdocuments_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  HomegrownTaxdocumentsFileIdKey = 'homegrown_taxdocuments_file_id_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownTaxdocumentsPkey = 'homegrown_taxdocuments_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  year?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_owner?: InputMaybe<Users_Businessowner_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Taxdocuments_Max_Fields = {
  __typename?: 'homegrown_taxdocuments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['smallint']['output']>;
};

/** order by max() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Taxdocuments_Min_Fields = {
  __typename?: 'homegrown_taxdocuments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['smallint']['output']>;
};

/** order by min() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Mutation_Response = {
  __typename?: 'homegrown_taxdocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Taxdocuments>;
};

/** on_conflict condition type for table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_On_Conflict = {
  constraint: Homegrown_Taxdocuments_Constraint;
  update_columns?: Array<Homegrown_Taxdocuments_Update_Column>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_taxdocuments". */
export type Homegrown_Taxdocuments_Order_By = {
  active?: InputMaybe<Order_By>;
  business_owner?: InputMaybe<Users_Businessowner_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Users_User_Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor?: InputMaybe<Investordata_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_taxdocuments */
export type Homegrown_Taxdocuments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_taxdocuments" */
export enum Homegrown_Taxdocuments_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Year = 'year'
}

/** select "homegrown_taxdocuments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_taxdocuments" */
export enum Homegrown_Taxdocuments_Select_Column_Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "homegrown_taxdocuments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_taxdocuments" */
export enum Homegrown_Taxdocuments_Select_Column_Homegrown_Taxdocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Taxdocuments_Stddev_Fields = {
  __typename?: 'homegrown_taxdocuments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Taxdocuments_Stddev_Pop_Fields = {
  __typename?: 'homegrown_taxdocuments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Taxdocuments_Stddev_Samp_Fields = {
  __typename?: 'homegrown_taxdocuments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Taxdocuments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Taxdocuments_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['smallint']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Taxdocuments_Sum_Fields = {
  __typename?: 'homegrown_taxdocuments_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  year?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_taxdocuments" */
export enum Homegrown_Taxdocuments_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Year = 'year'
}

export type Homegrown_Taxdocuments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Taxdocuments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Taxdocuments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Taxdocuments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Taxdocuments_Var_Pop_Fields = {
  __typename?: 'homegrown_taxdocuments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Taxdocuments_Var_Samp_Fields = {
  __typename?: 'homegrown_taxdocuments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Taxdocuments_Variance_Fields = {
  __typename?: 'homegrown_taxdocuments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_taxdocuments" */
export type Homegrown_Taxdocuments_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode = {
  __typename?: 'homegrown_usedreferralcode';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  referral_code_id: Scalars['uuid']['output'];
  /** An object relationship */
  referralcode: Homegrown_Referralcode;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users_User;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Aggregate = {
  __typename?: 'homegrown_usedreferralcode_aggregate';
  aggregate?: Maybe<Homegrown_Usedreferralcode_Aggregate_Fields>;
  nodes: Array<Homegrown_Usedreferralcode>;
};

export type Homegrown_Usedreferralcode_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Usedreferralcode_Select_Column_Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Usedreferralcode_Select_Column_Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Aggregate_Fields = {
  __typename?: 'homegrown_usedreferralcode_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Usedreferralcode_Max_Fields>;
  min?: Maybe<Homegrown_Usedreferralcode_Min_Fields>;
};


/** aggregate fields of "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Usedreferralcode_Max_Order_By>;
  min?: InputMaybe<Homegrown_Usedreferralcode_Min_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Usedreferralcode_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Usedreferralcode_On_Conflict>;
};

/** Boolean expression to filter rows from the table "homegrown_usedreferralcode". All fields are combined with a logical 'AND'. */
export type Homegrown_Usedreferralcode_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Usedreferralcode_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Usedreferralcode_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  referral_code_id?: InputMaybe<Uuid_Comparison_Exp>;
  referralcode?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_usedreferralcode" */
export enum Homegrown_Usedreferralcode_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  HomegrownUsedreferralcodePkey = 'homegrown_usedreferralcode_pkey'
}

/** input type for inserting data into table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  referral_code_id?: InputMaybe<Scalars['uuid']['input']>;
  referralcode?: InputMaybe<Homegrown_Referralcode_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Usedreferralcode_Max_Fields = {
  __typename?: 'homegrown_usedreferralcode_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  referral_code_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  referral_code_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Usedreferralcode_Min_Fields = {
  __typename?: 'homegrown_usedreferralcode_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  referral_code_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  referral_code_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Mutation_Response = {
  __typename?: 'homegrown_usedreferralcode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Usedreferralcode>;
};

/** on_conflict condition type for table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_On_Conflict = {
  constraint: Homegrown_Usedreferralcode_Constraint;
  update_columns?: Array<Homegrown_Usedreferralcode_Update_Column>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_usedreferralcode". */
export type Homegrown_Usedreferralcode_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  referral_code_id?: InputMaybe<Order_By>;
  referralcode?: InputMaybe<Homegrown_Referralcode_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_usedreferralcode */
export type Homegrown_Usedreferralcode_Pk_Columns_Input = {
  user_id: Scalars['String']['input'];
};

/** select columns of table "homegrown_usedreferralcode" */
export enum Homegrown_Usedreferralcode_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ReferralCodeId = 'referral_code_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "homegrown_usedreferralcode_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_usedreferralcode" */
export enum Homegrown_Usedreferralcode_Select_Column_Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "homegrown_usedreferralcode_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_usedreferralcode" */
export enum Homegrown_Usedreferralcode_Select_Column_Homegrown_Usedreferralcode_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  referral_code_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "homegrown_usedreferralcode" */
export type Homegrown_Usedreferralcode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Usedreferralcode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Usedreferralcode_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  referral_code_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "homegrown_usedreferralcode" */
export enum Homegrown_Usedreferralcode_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ReferralCodeId = 'referral_code_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Homegrown_Usedreferralcode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Usedreferralcode_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Usedreferralcode_Bool_Exp;
};

/** columns and relationships of "homegrown_userdocuments" */
export type Homegrown_Userdocuments = {
  __typename?: 'homegrown_userdocuments';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['bigint']['output'];
  type: Homegrown_Userdocumenttype_Enum;
  /** An object relationship */
  type_enum: Homegrown_Userdocumenttype;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Aggregate = {
  __typename?: 'homegrown_userdocuments_aggregate';
  aggregate?: Maybe<Homegrown_Userdocuments_Aggregate_Fields>;
  nodes: Array<Homegrown_Userdocuments>;
};

export type Homegrown_Userdocuments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Homegrown_Userdocuments_Aggregate_Bool_Exp_Count>;
};

export type Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Homegrown_Userdocuments_Select_Column_Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Homegrown_Userdocuments_Select_Column_Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Homegrown_Userdocuments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Aggregate_Fields = {
  __typename?: 'homegrown_userdocuments_aggregate_fields';
  avg?: Maybe<Homegrown_Userdocuments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Userdocuments_Max_Fields>;
  min?: Maybe<Homegrown_Userdocuments_Min_Fields>;
  stddev?: Maybe<Homegrown_Userdocuments_Stddev_Fields>;
  stddev_pop?: Maybe<Homegrown_Userdocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Homegrown_Userdocuments_Stddev_Samp_Fields>;
  sum?: Maybe<Homegrown_Userdocuments_Sum_Fields>;
  var_pop?: Maybe<Homegrown_Userdocuments_Var_Pop_Fields>;
  var_samp?: Maybe<Homegrown_Userdocuments_Var_Samp_Fields>;
  variance?: Maybe<Homegrown_Userdocuments_Variance_Fields>;
};


/** aggregate fields of "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Aggregate_Order_By = {
  avg?: InputMaybe<Homegrown_Userdocuments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Homegrown_Userdocuments_Max_Order_By>;
  min?: InputMaybe<Homegrown_Userdocuments_Min_Order_By>;
  stddev?: InputMaybe<Homegrown_Userdocuments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Homegrown_Userdocuments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Homegrown_Userdocuments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Homegrown_Userdocuments_Sum_Order_By>;
  var_pop?: InputMaybe<Homegrown_Userdocuments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Homegrown_Userdocuments_Var_Samp_Order_By>;
  variance?: InputMaybe<Homegrown_Userdocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Arr_Rel_Insert_Input = {
  data: Array<Homegrown_Userdocuments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Userdocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type Homegrown_Userdocuments_Avg_Fields = {
  __typename?: 'homegrown_userdocuments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "homegrown_userdocuments". All fields are combined with a logical 'AND'. */
export type Homegrown_Userdocuments_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Userdocuments_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Userdocuments_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum_Comparison_Exp>;
  type_enum?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_userdocuments" */
export enum Homegrown_Userdocuments_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  HomegrownUserdocumentsFileIdKey = 'homegrown_userdocuments_file_id_key',
  /** unique or primary key constraint on columns "id" */
  HomegrownUserdocumentsPkey = 'homegrown_userdocuments_pkey'
}

/** input type for incrementing numeric columns in table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  type_enum?: InputMaybe<Homegrown_Userdocumenttype_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Userdocuments_Max_Fields = {
  __typename?: 'homegrown_userdocuments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Homegrown_Userdocuments_Min_Fields = {
  __typename?: 'homegrown_userdocuments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Mutation_Response = {
  __typename?: 'homegrown_userdocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Userdocuments>;
};

/** on_conflict condition type for table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_On_Conflict = {
  constraint: Homegrown_Userdocuments_Constraint;
  update_columns?: Array<Homegrown_Userdocuments_Update_Column>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_userdocuments". */
export type Homegrown_Userdocuments_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  type_enum?: InputMaybe<Homegrown_Userdocumenttype_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_userdocuments */
export type Homegrown_Userdocuments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "homegrown_userdocuments" */
export enum Homegrown_Userdocuments_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "homegrown_userdocuments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "homegrown_userdocuments" */
export enum Homegrown_Userdocuments_Select_Column_Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "homegrown_userdocuments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "homegrown_userdocuments" */
export enum Homegrown_Userdocuments_Select_Column_Homegrown_Userdocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Homegrown_Userdocuments_Stddev_Fields = {
  __typename?: 'homegrown_userdocuments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Homegrown_Userdocuments_Stddev_Pop_Fields = {
  __typename?: 'homegrown_userdocuments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Homegrown_Userdocuments_Stddev_Samp_Fields = {
  __typename?: 'homegrown_userdocuments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Userdocuments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Userdocuments_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Homegrown_Userdocuments_Sum_Fields = {
  __typename?: 'homegrown_userdocuments_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "homegrown_userdocuments" */
export enum Homegrown_Userdocuments_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Homegrown_Userdocuments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Homegrown_Userdocuments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Userdocuments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Userdocuments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Homegrown_Userdocuments_Var_Pop_Fields = {
  __typename?: 'homegrown_userdocuments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Homegrown_Userdocuments_Var_Samp_Fields = {
  __typename?: 'homegrown_userdocuments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Homegrown_Userdocuments_Variance_Fields = {
  __typename?: 'homegrown_userdocuments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "homegrown_userdocuments" */
export type Homegrown_Userdocuments_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype = {
  __typename?: 'homegrown_userdocumenttype';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Aggregate = {
  __typename?: 'homegrown_userdocumenttype_aggregate';
  aggregate?: Maybe<Homegrown_Userdocumenttype_Aggregate_Fields>;
  nodes: Array<Homegrown_Userdocumenttype>;
};

/** aggregate fields of "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Aggregate_Fields = {
  __typename?: 'homegrown_userdocumenttype_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Homegrown_Userdocumenttype_Max_Fields>;
  min?: Maybe<Homegrown_Userdocumenttype_Min_Fields>;
};


/** aggregate fields of "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Homegrown_Userdocumenttype_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "homegrown_userdocumenttype". All fields are combined with a logical 'AND'. */
export type Homegrown_Userdocumenttype_Bool_Exp = {
  _and?: InputMaybe<Array<Homegrown_Userdocumenttype_Bool_Exp>>;
  _not?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
  _or?: InputMaybe<Array<Homegrown_Userdocumenttype_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "homegrown_userdocumenttype" */
export enum Homegrown_Userdocumenttype_Constraint {
  /** unique or primary key constraint on columns "name" */
  HomegrownUserdocumenttypePkey = 'homegrown_userdocumenttype_pkey'
}

export enum Homegrown_Userdocumenttype_Enum {
  IssuedId = 'issued_id',
  Proof = 'proof'
}

/** Boolean expression to compare columns of type "homegrown_userdocumenttype_enum". All fields are combined with logical 'AND'. */
export type Homegrown_Userdocumenttype_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  _in?: InputMaybe<Array<Homegrown_Userdocumenttype_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  _nin?: InputMaybe<Array<Homegrown_Userdocumenttype_Enum>>;
};

/** input type for inserting data into table "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Homegrown_Userdocumenttype_Max_Fields = {
  __typename?: 'homegrown_userdocumenttype_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Homegrown_Userdocumenttype_Min_Fields = {
  __typename?: 'homegrown_userdocumenttype_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Mutation_Response = {
  __typename?: 'homegrown_userdocumenttype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Homegrown_Userdocumenttype>;
};

/** input type for inserting object relation for remote table "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Obj_Rel_Insert_Input = {
  data: Homegrown_Userdocumenttype_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Homegrown_Userdocumenttype_On_Conflict>;
};

/** on_conflict condition type for table "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_On_Conflict = {
  constraint: Homegrown_Userdocumenttype_Constraint;
  update_columns?: Array<Homegrown_Userdocumenttype_Update_Column>;
  where?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
};

/** Ordering options when selecting data from "homegrown_userdocumenttype". */
export type Homegrown_Userdocumenttype_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: homegrown_userdocumenttype */
export type Homegrown_Userdocumenttype_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "homegrown_userdocumenttype" */
export enum Homegrown_Userdocumenttype_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "homegrown_userdocumenttype" */
export type Homegrown_Userdocumenttype_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Homegrown_Userdocumenttype_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Homegrown_Userdocumenttype_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "homegrown_userdocumenttype" */
export enum Homegrown_Userdocumenttype_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Homegrown_Userdocumenttype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Homegrown_Userdocumenttype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Homegrown_Userdocumenttype_Bool_Exp;
};

/** columns and relationships of "plaid_app_investment" */
export type Investments = {
  __typename?: 'investments';
  active: Scalars['Boolean']['output'];
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  error_msg?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  invested_amount: Scalars['numeric']['output'];
  investment_status: Scalars['String']['output'];
  /** An object relationship */
  investor?: Maybe<Investordata>;
  payout_change_reason?: Maybe<Scalars['String']['output']>;
  payout_invested_amount: Scalars['numeric']['output'];
  /** An object relationship */
  plaid?: Maybe<Plaid_App_Plaidlink>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  status: Scalars['String']['output'];
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  transaction?: Maybe<Plaid_App_Plaidtransfer>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  treasuryprime?: Maybe<Treasuryprime_App_Counterpartylink>;
  /** An object relationship */
  treasuryprime_achtransfer?: Maybe<Treasuryprime_App_Achtransfer>;
  update_reason?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user: Users_User;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "plaid_app_investment" */
export type Investments_Aggregate = {
  __typename?: 'investments_aggregate';
  aggregate?: Maybe<Investments_Aggregate_Fields>;
  nodes: Array<Investments>;
};

export type Investments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Investments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Investments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Investments_Aggregate_Bool_Exp_Count>;
};

export type Investments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Investments_Select_Column_Investments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Investments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Investments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Investments_Select_Column_Investments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Investments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Investments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Investments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Investments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plaid_app_investment" */
export type Investments_Aggregate_Fields = {
  __typename?: 'investments_aggregate_fields';
  avg?: Maybe<Investments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Investments_Max_Fields>;
  min?: Maybe<Investments_Min_Fields>;
  stddev?: Maybe<Investments_Stddev_Fields>;
  stddev_pop?: Maybe<Investments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investments_Stddev_Samp_Fields>;
  sum?: Maybe<Investments_Sum_Fields>;
  var_pop?: Maybe<Investments_Var_Pop_Fields>;
  var_samp?: Maybe<Investments_Var_Samp_Fields>;
  variance?: Maybe<Investments_Variance_Fields>;
};


/** aggregate fields of "plaid_app_investment" */
export type Investments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_investment" */
export type Investments_Aggregate_Order_By = {
  avg?: InputMaybe<Investments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Investments_Max_Order_By>;
  min?: InputMaybe<Investments_Min_Order_By>;
  stddev?: InputMaybe<Investments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Investments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Investments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Investments_Sum_Order_By>;
  var_pop?: InputMaybe<Investments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Investments_Var_Samp_Order_By>;
  variance?: InputMaybe<Investments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plaid_app_investment" */
export type Investments_Arr_Rel_Insert_Input = {
  data: Array<Investments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Investments_On_Conflict>;
};

/** aggregate avg on columns */
export type Investments_Avg_Fields = {
  __typename?: 'investments_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invested_amount?: Maybe<Scalars['Float']['output']>;
  payout_invested_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_investment" */
export type Investments_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_investment". All fields are combined with a logical 'AND'. */
export type Investments_Bool_Exp = {
  _and?: InputMaybe<Array<Investments_Bool_Exp>>;
  _not?: InputMaybe<Investments_Bool_Exp>;
  _or?: InputMaybe<Array<Investments_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  counterparty_link_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  error_msg?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invested_amount?: InputMaybe<Numeric_Comparison_Exp>;
  investment_status?: InputMaybe<String_Comparison_Exp>;
  investor?: InputMaybe<Investordata_Bool_Exp>;
  payout_change_reason?: InputMaybe<String_Comparison_Exp>;
  payout_invested_amount?: InputMaybe<Numeric_Comparison_Exp>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  plaid_link_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tp_transfer_id?: InputMaybe<String_Comparison_Exp>;
  transaction?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
  transaction_id_id?: InputMaybe<String_Comparison_Exp>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  treasuryprime_achtransfer?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  update_reason?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by_id?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_investment" */
export enum Investments_Constraint {
  /** unique or primary key constraint on columns "user_id", "deal_id" */
  InvestmentUniq = 'investment_uniq',
  /** unique or primary key constraint on columns "id" */
  PlaidAppInvestmentPkey = 'plaid_app_investment_pkey',
  /** unique or primary key constraint on columns "tp_transfer_id" */
  PlaidAppInvestmentTpTransferIdKey = 'plaid_app_investment_tp_transfer_id_key',
  /** unique or primary key constraint on columns "transaction_id_id" */
  PlaidAppInvestmentTransactionIdIdKey = 'plaid_app_investment_transaction_id_id_key'
}

/** input type for incrementing numeric columns in table "plaid_app_investment" */
export type Investments_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  payout_invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "plaid_app_investment" */
export type Investments_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  investment_status?: InputMaybe<Scalars['String']['input']>;
  investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  payout_change_reason?: InputMaybe<Scalars['String']['input']>;
  payout_invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Obj_Rel_Insert_Input>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction?: InputMaybe<Plaid_App_Plaidtransfer_Obj_Rel_Insert_Input>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Obj_Rel_Insert_Input>;
  treasuryprime_achtransfer?: InputMaybe<Treasuryprime_App_Achtransfer_Obj_Rel_Insert_Input>;
  update_reason?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_id?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Investments_Max_Fields = {
  __typename?: 'investments_max_fields';
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  error_msg?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  invested_amount?: Maybe<Scalars['numeric']['output']>;
  investment_status?: Maybe<Scalars['String']['output']>;
  payout_change_reason?: Maybe<Scalars['String']['output']>;
  payout_invested_amount?: Maybe<Scalars['numeric']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  update_reason?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "plaid_app_investment" */
export type Investments_Max_Order_By = {
  counterparty_link_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investment_status?: InputMaybe<Order_By>;
  payout_change_reason?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  update_reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Investments_Min_Fields = {
  __typename?: 'investments_min_fields';
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  error_msg?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  invested_amount?: Maybe<Scalars['numeric']['output']>;
  investment_status?: Maybe<Scalars['String']['output']>;
  payout_change_reason?: Maybe<Scalars['String']['output']>;
  payout_invested_amount?: Maybe<Scalars['numeric']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  update_reason?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "plaid_app_investment" */
export type Investments_Min_Order_By = {
  counterparty_link_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investment_status?: InputMaybe<Order_By>;
  payout_change_reason?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  update_reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_investment" */
export type Investments_Mutation_Response = {
  __typename?: 'investments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Investments>;
};

/** input type for inserting object relation for remote table "plaid_app_investment" */
export type Investments_Obj_Rel_Insert_Input = {
  data: Investments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Investments_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_investment" */
export type Investments_On_Conflict = {
  constraint: Investments_Constraint;
  update_columns?: Array<Investments_Update_Column>;
  where?: InputMaybe<Investments_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_investment". */
export type Investments_Order_By = {
  active?: InputMaybe<Order_By>;
  counterparty_link_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  investment_status?: InputMaybe<Order_By>;
  investor?: InputMaybe<Investordata_Order_By>;
  payout_change_reason?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction?: InputMaybe<Plaid_App_Plaidtransfer_Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Order_By>;
  treasuryprime_achtransfer?: InputMaybe<Treasuryprime_App_Achtransfer_Order_By>;
  update_reason?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_investment */
export type Investments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "plaid_app_investment" */
export enum Investments_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CounterpartyLinkId = 'counterparty_link_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  Id = 'id',
  /** column name */
  InvestedAmount = 'invested_amount',
  /** column name */
  InvestmentStatus = 'investment_status',
  /** column name */
  PayoutChangeReason = 'payout_change_reason',
  /** column name */
  PayoutInvestedAmount = 'payout_invested_amount',
  /** column name */
  PlaidLinkId = 'plaid_link_id',
  /** column name */
  Status = 'status',
  /** column name */
  TpTransferId = 'tp_transfer_id',
  /** column name */
  TransactionIdId = 'transaction_id_id',
  /** column name */
  UpdateReason = 'update_reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  UserId = 'user_id'
}

/** select "investments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plaid_app_investment" */
export enum Investments_Select_Column_Investments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "investments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plaid_app_investment" */
export enum Investments_Select_Column_Investments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "plaid_app_investment" */
export type Investments_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  investment_status?: InputMaybe<Scalars['String']['input']>;
  payout_change_reason?: InputMaybe<Scalars['String']['input']>;
  payout_invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  update_reason?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Investments_Stddev_Fields = {
  __typename?: 'investments_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invested_amount?: Maybe<Scalars['Float']['output']>;
  payout_invested_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_investment" */
export type Investments_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Investments_Stddev_Pop_Fields = {
  __typename?: 'investments_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invested_amount?: Maybe<Scalars['Float']['output']>;
  payout_invested_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_investment" */
export type Investments_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Investments_Stddev_Samp_Fields = {
  __typename?: 'investments_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invested_amount?: Maybe<Scalars['Float']['output']>;
  payout_invested_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_investment" */
export type Investments_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "investments" */
export type Investments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investments_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  investment_status?: InputMaybe<Scalars['String']['input']>;
  payout_change_reason?: InputMaybe<Scalars['String']['input']>;
  payout_invested_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  update_reason?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by_id?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Investments_Sum_Fields = {
  __typename?: 'investments_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  invested_amount?: Maybe<Scalars['numeric']['output']>;
  payout_invested_amount?: Maybe<Scalars['numeric']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "plaid_app_investment" */
export type Investments_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_investment" */
export enum Investments_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CounterpartyLinkId = 'counterparty_link_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  Id = 'id',
  /** column name */
  InvestedAmount = 'invested_amount',
  /** column name */
  InvestmentStatus = 'investment_status',
  /** column name */
  PayoutChangeReason = 'payout_change_reason',
  /** column name */
  PayoutInvestedAmount = 'payout_invested_amount',
  /** column name */
  PlaidLinkId = 'plaid_link_id',
  /** column name */
  Status = 'status',
  /** column name */
  TpTransferId = 'tp_transfer_id',
  /** column name */
  TransactionIdId = 'transaction_id_id',
  /** column name */
  UpdateReason = 'update_reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedById = 'updated_by_id',
  /** column name */
  UserId = 'user_id'
}

export type Investments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Investments_Var_Pop_Fields = {
  __typename?: 'investments_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invested_amount?: Maybe<Scalars['Float']['output']>;
  payout_invested_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_investment" */
export type Investments_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Investments_Var_Samp_Fields = {
  __typename?: 'investments_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invested_amount?: Maybe<Scalars['Float']['output']>;
  payout_invested_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_investment" */
export type Investments_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Investments_Variance_Fields = {
  __typename?: 'investments_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  invested_amount?: Maybe<Scalars['Float']['output']>;
  payout_invested_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_investment" */
export type Investments_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invested_amount?: InputMaybe<Order_By>;
  payout_invested_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users_investordata" */
export type Investordata = {
  __typename?: 'investordata';
  active: Scalars['Boolean']['output'];
  allowed_operations: Scalars['Boolean']['output'];
  /** An object relationship */
  business?: Maybe<Users_Investorbusiness>;
  /** An object relationship */
  business2?: Maybe<Users_Investorbusiness>;
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  date_of_birth?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  documents: Array<Homegrown_Userdocuments>;
  /** An aggregate relationship */
  documents_aggregate: Homegrown_Userdocuments_Aggregate;
  first_name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "users_investordata_full_name" */
  full_name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  interests: Array<Users_Userinterests>;
  /** An aggregate relationship */
  interests_aggregate: Users_Userinterests_Aggregate;
  /** An array relationship */
  investments: Array<Investments>;
  /** An aggregate relationship */
  investments_aggregate: Investments_Aggregate;
  is_entity?: Maybe<Scalars['Boolean']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['jsonb']['output']>;
  /** An array relationship */
  notifications: Array<Users_Usernotifications>;
  /** An aggregate relationship */
  notifications_aggregate: Users_Usernotifications_Aggregate;
  onboarding_complete: Scalars['Boolean']['output'];
  /** An array relationship */
  payouts: Array<Plaid_App_Dealpayout>;
  /** An aggregate relationship */
  payouts_aggregate: Plaid_App_Dealpayout_Aggregate;
  phone_number?: Maybe<Scalars['String']['output']>;
  phone_verified: Scalars['Boolean']['output'];
  plaid_connected: Scalars['Boolean']['output'];
  /** An array relationship */
  plaidlink: Array<Plaid_App_Plaidlink>;
  /** An aggregate relationship */
  plaidlink_aggregate: Plaid_App_Plaidlink_Aggregate;
  /** An object relationship */
  preferences?: Maybe<Users_Userpreferences>;
  profile?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  profile_photo?: Maybe<Files>;
  profile_picture_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  signed_documents: Array<Homegrown_Signwellinvestorsigneddocument>;
  /** An aggregate relationship */
  signed_documents_aggregate: Homegrown_Signwellinvestorsigneddocument_Aggregate;
  ssn_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  tax_documents: Array<Homegrown_Taxdocuments>;
  /** An aggregate relationship */
  tax_documents_aggregate: Homegrown_Taxdocuments_Aggregate;
  /** An array relationship */
  tp_counterpartylink: Array<Treasuryprime_App_Counterpartylink>;
  /** An aggregate relationship */
  tp_counterpartylink_aggregate: Treasuryprime_App_Counterpartylink_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "users_investordata" */
export type InvestordataDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataInterestsArgs = {
  distinct_on?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userinterests_Order_By>>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataInterests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userinterests_Order_By>>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataLocationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usernotifications_Order_By>>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usernotifications_Order_By>>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataPayoutsArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataPayouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataPlaidlinkArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlink_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataPlaidlink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlink_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataProfileArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataSigned_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataSigned_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataTax_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataTax_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataTp_CounterpartylinkArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};


/** columns and relationships of "users_investordata" */
export type InvestordataTp_Counterpartylink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};

/** aggregated selection of "users_investordata" */
export type Investordata_Aggregate = {
  __typename?: 'investordata_aggregate';
  aggregate?: Maybe<Investordata_Aggregate_Fields>;
  nodes: Array<Investordata>;
};

/** aggregate fields of "users_investordata" */
export type Investordata_Aggregate_Fields = {
  __typename?: 'investordata_aggregate_fields';
  avg?: Maybe<Investordata_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Investordata_Max_Fields>;
  min?: Maybe<Investordata_Min_Fields>;
  stddev?: Maybe<Investordata_Stddev_Fields>;
  stddev_pop?: Maybe<Investordata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Investordata_Stddev_Samp_Fields>;
  sum?: Maybe<Investordata_Sum_Fields>;
  var_pop?: Maybe<Investordata_Var_Pop_Fields>;
  var_samp?: Maybe<Investordata_Var_Samp_Fields>;
  variance?: Maybe<Investordata_Variance_Fields>;
};


/** aggregate fields of "users_investordata" */
export type Investordata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Investordata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Investordata_Append_Input = {
  location?: InputMaybe<Scalars['jsonb']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Investordata_Avg_Fields = {
  __typename?: 'investordata_avg_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users_investordata". All fields are combined with a logical 'AND'. */
export type Investordata_Bool_Exp = {
  _and?: InputMaybe<Array<Investordata_Bool_Exp>>;
  _not?: InputMaybe<Investordata_Bool_Exp>;
  _or?: InputMaybe<Array<Investordata_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  allowed_operations?: InputMaybe<Boolean_Comparison_Exp>;
  business?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
  business2?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
  business_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  documents?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
  documents_aggregate?: InputMaybe<Homegrown_Userdocuments_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  interests?: InputMaybe<Users_Userinterests_Bool_Exp>;
  interests_aggregate?: InputMaybe<Users_Userinterests_Aggregate_Bool_Exp>;
  investments?: InputMaybe<Investments_Bool_Exp>;
  investments_aggregate?: InputMaybe<Investments_Aggregate_Bool_Exp>;
  is_entity?: InputMaybe<Boolean_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  notifications?: InputMaybe<Users_Usernotifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Users_Usernotifications_Aggregate_Bool_Exp>;
  onboarding_complete?: InputMaybe<Boolean_Comparison_Exp>;
  payouts?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  payouts_aggregate?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  phone_verified?: InputMaybe<Boolean_Comparison_Exp>;
  plaid_connected?: InputMaybe<Boolean_Comparison_Exp>;
  plaidlink?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  plaidlink_aggregate?: InputMaybe<Plaid_App_Plaidlink_Aggregate_Bool_Exp>;
  preferences?: InputMaybe<Users_Userpreferences_Bool_Exp>;
  profile?: InputMaybe<Jsonb_Comparison_Exp>;
  profile_photo?: InputMaybe<Files_Bool_Exp>;
  profile_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  signed_documents?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
  signed_documents_aggregate?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Bool_Exp>;
  ssn_id?: InputMaybe<String_Comparison_Exp>;
  tax_documents?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
  tax_documents_aggregate?: InputMaybe<Homegrown_Taxdocuments_Aggregate_Bool_Exp>;
  tp_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  tp_counterpartylink_aggregate?: InputMaybe<Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  us_citizenship_status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_investordata" */
export enum Investordata_Constraint {
  /** unique or primary key constraint on columns "phone_number" */
  InvestordataVerifiedPhoneNumberUniq = 'investordata_verified_phone_number_uniq',
  /** unique or primary key constraint on columns "business_id" */
  UsersInvestordataBusinessIdKey = 'users_investordata_business_id_key',
  /** unique or primary key constraint on columns "user_id" */
  UsersInvestordataPkey = 'users_investordata_pkey',
  /** unique or primary key constraint on columns "profile_picture_id" */
  UsersInvestordataProfilePictureId_655c0786Uniq = 'users_investordata_profile_picture_id_655c0786_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Investordata_Delete_At_Path_Input = {
  location?: InputMaybe<Array<Scalars['String']['input']>>;
  profile?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Investordata_Delete_Elem_Input = {
  location?: InputMaybe<Scalars['Int']['input']>;
  profile?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Investordata_Delete_Key_Input = {
  location?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users_investordata" */
export type Investordata_Inc_Input = {
  business_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_investordata" */
export type Investordata_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_operations?: InputMaybe<Scalars['Boolean']['input']>;
  business?: InputMaybe<Users_Investorbusiness_Obj_Rel_Insert_Input>;
  business2?: InputMaybe<Users_Investorbusiness_Obj_Rel_Insert_Input>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  documents?: InputMaybe<Homegrown_Userdocuments_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  interests?: InputMaybe<Users_Userinterests_Arr_Rel_Insert_Input>;
  investments?: InputMaybe<Investments_Arr_Rel_Insert_Input>;
  is_entity?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  notifications?: InputMaybe<Users_Usernotifications_Arr_Rel_Insert_Input>;
  onboarding_complete?: InputMaybe<Scalars['Boolean']['input']>;
  payouts?: InputMaybe<Plaid_App_Dealpayout_Arr_Rel_Insert_Input>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_connected?: InputMaybe<Scalars['Boolean']['input']>;
  plaidlink?: InputMaybe<Plaid_App_Plaidlink_Arr_Rel_Insert_Input>;
  preferences?: InputMaybe<Users_Userpreferences_Obj_Rel_Insert_Input>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
  profile_photo?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  profile_picture_id?: InputMaybe<Scalars['uuid']['input']>;
  signed_documents?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Arr_Rel_Insert_Input>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  tax_documents?: InputMaybe<Homegrown_Taxdocuments_Arr_Rel_Insert_Input>;
  tp_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylink_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Investordata_Max_Fields = {
  __typename?: 'investordata_max_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "users_investordata_full_name" */
  full_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_picture_id?: Maybe<Scalars['uuid']['output']>;
  ssn_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Investordata_Min_Fields = {
  __typename?: 'investordata_min_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "users_investordata_full_name" */
  full_name?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_picture_id?: Maybe<Scalars['uuid']['output']>;
  ssn_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_investordata" */
export type Investordata_Mutation_Response = {
  __typename?: 'investordata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Investordata>;
};

/** input type for inserting object relation for remote table "users_investordata" */
export type Investordata_Obj_Rel_Insert_Input = {
  data: Investordata_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Investordata_On_Conflict>;
};

/** on_conflict condition type for table "users_investordata" */
export type Investordata_On_Conflict = {
  constraint: Investordata_Constraint;
  update_columns?: Array<Investordata_Update_Column>;
  where?: InputMaybe<Investordata_Bool_Exp>;
};

/** Ordering options when selecting data from "users_investordata". */
export type Investordata_Order_By = {
  active?: InputMaybe<Order_By>;
  allowed_operations?: InputMaybe<Order_By>;
  business?: InputMaybe<Users_Investorbusiness_Order_By>;
  business2?: InputMaybe<Users_Investorbusiness_Order_By>;
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Homegrown_Userdocuments_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  interests_aggregate?: InputMaybe<Users_Userinterests_Aggregate_Order_By>;
  investments_aggregate?: InputMaybe<Investments_Aggregate_Order_By>;
  is_entity?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Users_Usernotifications_Aggregate_Order_By>;
  onboarding_complete?: InputMaybe<Order_By>;
  payouts_aggregate?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  phone_verified?: InputMaybe<Order_By>;
  plaid_connected?: InputMaybe<Order_By>;
  plaidlink_aggregate?: InputMaybe<Plaid_App_Plaidlink_Aggregate_Order_By>;
  preferences?: InputMaybe<Users_Userpreferences_Order_By>;
  profile?: InputMaybe<Order_By>;
  profile_photo?: InputMaybe<Files_Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
  signed_documents_aggregate?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Aggregate_Order_By>;
  ssn_id?: InputMaybe<Order_By>;
  tax_documents_aggregate?: InputMaybe<Homegrown_Taxdocuments_Aggregate_Order_By>;
  tp_counterpartylink_aggregate?: InputMaybe<Treasuryprime_App_Counterpartylink_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_citizenship_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_investordata */
export type Investordata_Pk_Columns_Input = {
  user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Investordata_Prepend_Input = {
  location?: InputMaybe<Scalars['jsonb']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_investordata" */
export enum Investordata_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowedOperations = 'allowed_operations',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  IsEntity = 'is_entity',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Location = 'location',
  /** column name */
  OnboardingComplete = 'onboarding_complete',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  PlaidConnected = 'plaid_connected',
  /** column name */
  Profile = 'profile',
  /** column name */
  ProfilePictureId = 'profile_picture_id',
  /** column name */
  SsnId = 'ssn_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsCitizenshipStatus = 'us_citizenship_status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_investordata" */
export type Investordata_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_operations?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  is_entity?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  onboarding_complete?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_connected?: InputMaybe<Scalars['Boolean']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
  profile_picture_id?: InputMaybe<Scalars['uuid']['input']>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Investordata_Stddev_Fields = {
  __typename?: 'investordata_stddev_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Investordata_Stddev_Pop_Fields = {
  __typename?: 'investordata_stddev_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Investordata_Stddev_Samp_Fields = {
  __typename?: 'investordata_stddev_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "investordata" */
export type Investordata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investordata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investordata_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_operations?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  is_entity?: InputMaybe<Scalars['Boolean']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  onboarding_complete?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_connected?: InputMaybe<Scalars['Boolean']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
  profile_picture_id?: InputMaybe<Scalars['uuid']['input']>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Investordata_Sum_Fields = {
  __typename?: 'investordata_sum_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users_investordata" */
export enum Investordata_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowedOperations = 'allowed_operations',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  IsEntity = 'is_entity',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Location = 'location',
  /** column name */
  OnboardingComplete = 'onboarding_complete',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  PlaidConnected = 'plaid_connected',
  /** column name */
  Profile = 'profile',
  /** column name */
  ProfilePictureId = 'profile_picture_id',
  /** column name */
  SsnId = 'ssn_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsCitizenshipStatus = 'us_citizenship_status',
  /** column name */
  UserId = 'user_id'
}

export type Investordata_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Investordata_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Investordata_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Investordata_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Investordata_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Investordata_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Investordata_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Investordata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Investordata_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Investordata_Var_Pop_Fields = {
  __typename?: 'investordata_var_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Investordata_Var_Samp_Fields = {
  __typename?: 'investordata_var_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Investordata_Variance_Fields = {
  __typename?: 'investordata_variance_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete single row from the table: "storage_buckets" */
  deleteBucket?: Maybe<Buckets>;
  /** delete data from the table: "storage_buckets" */
  deleteBuckets?: Maybe<Buckets_Mutation_Response>;
  /** delete single row from the table: "storage_files" */
  deleteFile?: Maybe<Files>;
  /** delete data from the table: "storage_files" */
  deleteFiles?: Maybe<Files_Mutation_Response>;
  /** delete data from the table: "homegrown_business" */
  delete_homegrown_business?: Maybe<Homegrown_Business_Mutation_Response>;
  /** delete single row from the table: "homegrown_business" */
  delete_homegrown_business_by_pk?: Maybe<Homegrown_Business>;
  /** delete data from the table: "homegrown_businessprivatedocuments" */
  delete_homegrown_businessprivatedocuments?: Maybe<Homegrown_Businessprivatedocuments_Mutation_Response>;
  /** delete single row from the table: "homegrown_businessprivatedocuments" */
  delete_homegrown_businessprivatedocuments_by_pk?: Maybe<Homegrown_Businessprivatedocuments>;
  /** delete data from the table: "homegrown_deal" */
  delete_homegrown_deal?: Maybe<Homegrown_Deal_Mutation_Response>;
  /** delete single row from the table: "homegrown_deal" */
  delete_homegrown_deal_by_pk?: Maybe<Homegrown_Deal>;
  /** delete data from the table: "homegrown_dealdocument" */
  delete_homegrown_dealdocument?: Maybe<Homegrown_Dealdocument_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealdocument" */
  delete_homegrown_dealdocument_by_pk?: Maybe<Homegrown_Dealdocument>;
  /** delete data from the table: "homegrown_dealprivate" */
  delete_homegrown_dealprivate?: Maybe<Homegrown_Dealprivate_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealprivate" */
  delete_homegrown_dealprivate_by_pk?: Maybe<Homegrown_Dealprivate>;
  /** delete data from the table: "homegrown_dealprivatedocuments" */
  delete_homegrown_dealprivatedocuments?: Maybe<Homegrown_Dealprivatedocuments_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealprivatedocuments" */
  delete_homegrown_dealprivatedocuments_by_pk?: Maybe<Homegrown_Dealprivatedocuments>;
  /** delete data from the table: "homegrown_dealprivatedocumentstatus" */
  delete_homegrown_dealprivatedocumentstatus?: Maybe<Homegrown_Dealprivatedocumentstatus_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealprivatedocumentstatus" */
  delete_homegrown_dealprivatedocumentstatus_by_pk?: Maybe<Homegrown_Dealprivatedocumentstatus>;
  /** delete data from the table: "homegrown_dealprivatedocumenttype" */
  delete_homegrown_dealprivatedocumenttype?: Maybe<Homegrown_Dealprivatedocumenttype_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealprivatedocumenttype" */
  delete_homegrown_dealprivatedocumenttype_by_pk?: Maybe<Homegrown_Dealprivatedocumenttype>;
  /** delete data from the table: "homegrown_dealtobusinessplaidlink" */
  delete_homegrown_dealtobusinessplaidlink?: Maybe<Homegrown_Dealtobusinessplaidlink_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealtobusinessplaidlink" */
  delete_homegrown_dealtobusinessplaidlink_by_pk?: Maybe<Homegrown_Dealtobusinessplaidlink>;
  /** delete data from the table: "homegrown_dealtoinvestorlink" */
  delete_homegrown_dealtoinvestorlink?: Maybe<Homegrown_Dealtoinvestorlink_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealtoinvestorlink" */
  delete_homegrown_dealtoinvestorlink_by_pk?: Maybe<Homegrown_Dealtoinvestorlink>;
  /** delete data from the table: "homegrown_dealtype" */
  delete_homegrown_dealtype?: Maybe<Homegrown_Dealtype_Mutation_Response>;
  /** delete single row from the table: "homegrown_dealtype" */
  delete_homegrown_dealtype_by_pk?: Maybe<Homegrown_Dealtype>;
  /** delete data from the table: "homegrown_location" */
  delete_homegrown_location?: Maybe<Homegrown_Location_Mutation_Response>;
  /** delete single row from the table: "homegrown_location" */
  delete_homegrown_location_by_pk?: Maybe<Homegrown_Location>;
  /** delete data from the table: "homegrown_referralcode" */
  delete_homegrown_referralcode?: Maybe<Homegrown_Referralcode_Mutation_Response>;
  /** delete single row from the table: "homegrown_referralcode" */
  delete_homegrown_referralcode_by_pk?: Maybe<Homegrown_Referralcode>;
  /** delete data from the table: "homegrown_signwelldealdocument" */
  delete_homegrown_signwelldealdocument?: Maybe<Homegrown_Signwelldealdocument_Mutation_Response>;
  /** delete single row from the table: "homegrown_signwelldealdocument" */
  delete_homegrown_signwelldealdocument_by_pk?: Maybe<Homegrown_Signwelldealdocument>;
  /** delete data from the table: "homegrown_signwellinvestorsigneddocument" */
  delete_homegrown_signwellinvestorsigneddocument?: Maybe<Homegrown_Signwellinvestorsigneddocument_Mutation_Response>;
  /** delete single row from the table: "homegrown_signwellinvestorsigneddocument" */
  delete_homegrown_signwellinvestorsigneddocument_by_pk?: Maybe<Homegrown_Signwellinvestorsigneddocument>;
  /** delete data from the table: "homegrown_taxdocuments" */
  delete_homegrown_taxdocuments?: Maybe<Homegrown_Taxdocuments_Mutation_Response>;
  /** delete single row from the table: "homegrown_taxdocuments" */
  delete_homegrown_taxdocuments_by_pk?: Maybe<Homegrown_Taxdocuments>;
  /** delete data from the table: "homegrown_usedreferralcode" */
  delete_homegrown_usedreferralcode?: Maybe<Homegrown_Usedreferralcode_Mutation_Response>;
  /** delete single row from the table: "homegrown_usedreferralcode" */
  delete_homegrown_usedreferralcode_by_pk?: Maybe<Homegrown_Usedreferralcode>;
  /** delete data from the table: "homegrown_userdocuments" */
  delete_homegrown_userdocuments?: Maybe<Homegrown_Userdocuments_Mutation_Response>;
  /** delete single row from the table: "homegrown_userdocuments" */
  delete_homegrown_userdocuments_by_pk?: Maybe<Homegrown_Userdocuments>;
  /** delete data from the table: "homegrown_userdocumenttype" */
  delete_homegrown_userdocumenttype?: Maybe<Homegrown_Userdocumenttype_Mutation_Response>;
  /** delete single row from the table: "homegrown_userdocumenttype" */
  delete_homegrown_userdocumenttype_by_pk?: Maybe<Homegrown_Userdocumenttype>;
  /** delete data from the table: "plaid_app_investment" */
  delete_investments?: Maybe<Investments_Mutation_Response>;
  /** delete single row from the table: "plaid_app_investment" */
  delete_investments_by_pk?: Maybe<Investments>;
  /** delete data from the table: "users_investordata" */
  delete_investordata?: Maybe<Investordata_Mutation_Response>;
  /** delete single row from the table: "users_investordata" */
  delete_investordata_by_pk?: Maybe<Investordata>;
  /** delete data from the table: "plaid_app_dealbusinessrevenue" */
  delete_plaid_app_dealbusinessrevenue?: Maybe<Plaid_App_Dealbusinessrevenue_Mutation_Response>;
  /** delete single row from the table: "plaid_app_dealbusinessrevenue" */
  delete_plaid_app_dealbusinessrevenue_by_pk?: Maybe<Plaid_App_Dealbusinessrevenue>;
  /** delete data from the table: "plaid_app_dealpayback" */
  delete_plaid_app_dealpayback?: Maybe<Plaid_App_Dealpayback_Mutation_Response>;
  /** delete single row from the table: "plaid_app_dealpayback" */
  delete_plaid_app_dealpayback_by_pk?: Maybe<Plaid_App_Dealpayback>;
  /** delete data from the table: "plaid_app_dealpayout" */
  delete_plaid_app_dealpayout?: Maybe<Plaid_App_Dealpayout_Mutation_Response>;
  /** delete single row from the table: "plaid_app_dealpayout" */
  delete_plaid_app_dealpayout_by_pk?: Maybe<Plaid_App_Dealpayout>;
  /** delete data from the table: "plaid_app_dealtransfer" */
  delete_plaid_app_dealtransfer?: Maybe<Plaid_App_Dealtransfer_Mutation_Response>;
  /** delete single row from the table: "plaid_app_dealtransfer" */
  delete_plaid_app_dealtransfer_by_pk?: Maybe<Plaid_App_Dealtransfer>;
  /** delete data from the table: "plaid_app_plaidlink" */
  delete_plaid_app_plaidlink?: Maybe<Plaid_App_Plaidlink_Mutation_Response>;
  /** delete single row from the table: "plaid_app_plaidlink" */
  delete_plaid_app_plaidlink_by_pk?: Maybe<Plaid_App_Plaidlink>;
  /** delete data from the table: "plaid_app_plaidlinkbusiness" */
  delete_plaid_app_plaidlinkbusiness?: Maybe<Plaid_App_Plaidlinkbusiness_Mutation_Response>;
  /** delete single row from the table: "plaid_app_plaidlinkbusiness" */
  delete_plaid_app_plaidlinkbusiness_by_pk?: Maybe<Plaid_App_Plaidlinkbusiness>;
  /** delete data from the table: "plaid_app_plaidlinkstatus" */
  delete_plaid_app_plaidlinkstatus?: Maybe<Plaid_App_Plaidlinkstatus_Mutation_Response>;
  /** delete single row from the table: "plaid_app_plaidlinkstatus" */
  delete_plaid_app_plaidlinkstatus_by_pk?: Maybe<Plaid_App_Plaidlinkstatus>;
  /** delete data from the table: "plaid_app_plaidtransfer" */
  delete_plaid_app_plaidtransfer?: Maybe<Plaid_App_Plaidtransfer_Mutation_Response>;
  /** delete single row from the table: "plaid_app_plaidtransfer" */
  delete_plaid_app_plaidtransfer_by_pk?: Maybe<Plaid_App_Plaidtransfer>;
  /** delete data from the table: "plaid_app_plaidtransferevent" */
  delete_plaid_app_plaidtransferevent?: Maybe<Plaid_App_Plaidtransferevent_Mutation_Response>;
  /** delete single row from the table: "plaid_app_plaidtransferevent" */
  delete_plaid_app_plaidtransferevent_by_pk?: Maybe<Plaid_App_Plaidtransferevent>;
  /** delete data from the table: "plaid_app_revenuetransaction" */
  delete_plaid_app_revenuetransaction?: Maybe<Plaid_App_Revenuetransaction_Mutation_Response>;
  /** delete single row from the table: "plaid_app_revenuetransaction" */
  delete_plaid_app_revenuetransaction_by_pk?: Maybe<Plaid_App_Revenuetransaction>;
  /** delete data from the table: "treasuryprime_app_achtransfer" */
  delete_treasuryprime_app_achtransfer?: Maybe<Treasuryprime_App_Achtransfer_Mutation_Response>;
  /** delete single row from the table: "treasuryprime_app_achtransfer" */
  delete_treasuryprime_app_achtransfer_by_pk?: Maybe<Treasuryprime_App_Achtransfer>;
  /** delete data from the table: "treasuryprime_app_counterpartylink" */
  delete_treasuryprime_app_counterpartylink?: Maybe<Treasuryprime_App_Counterpartylink_Mutation_Response>;
  /** delete single row from the table: "treasuryprime_app_counterpartylink" */
  delete_treasuryprime_app_counterpartylink_by_pk?: Maybe<Treasuryprime_App_Counterpartylink>;
  /** delete data from the table: "treasuryprime_app_counterpartylinkbusiness" */
  delete_treasuryprime_app_counterpartylinkbusiness?: Maybe<Treasuryprime_App_Counterpartylinkbusiness_Mutation_Response>;
  /** delete single row from the table: "treasuryprime_app_counterpartylinkbusiness" */
  delete_treasuryprime_app_counterpartylinkbusiness_by_pk?: Maybe<Treasuryprime_App_Counterpartylinkbusiness>;
  /** delete data from the table: "users_businessowner" */
  delete_users_businessowner?: Maybe<Users_Businessowner_Mutation_Response>;
  /** delete single row from the table: "users_businessowner" */
  delete_users_businessowner_by_pk?: Maybe<Users_Businessowner>;
  /** delete data from the table: "users_employee" */
  delete_users_employee?: Maybe<Users_Employee_Mutation_Response>;
  /** delete single row from the table: "users_employee" */
  delete_users_employee_by_pk?: Maybe<Users_Employee>;
  /** delete data from the table: "users_investorbusiness" */
  delete_users_investorbusiness?: Maybe<Users_Investorbusiness_Mutation_Response>;
  /** delete single row from the table: "users_investorbusiness" */
  delete_users_investorbusiness_by_pk?: Maybe<Users_Investorbusiness>;
  /** delete data from the table: "users_roleorder" */
  delete_users_roleorder?: Maybe<Users_Roleorder_Mutation_Response>;
  /** delete single row from the table: "users_roleorder" */
  delete_users_roleorder_by_pk?: Maybe<Users_Roleorder>;
  /** delete data from the table: "users_secondaryinvestordata" */
  delete_users_secondaryinvestordata?: Maybe<Users_Secondaryinvestordata_Mutation_Response>;
  /** delete single row from the table: "users_secondaryinvestordata" */
  delete_users_secondaryinvestordata_by_pk?: Maybe<Users_Secondaryinvestordata>;
  /** delete data from the table: "users_secondaryinvestordocuments" */
  delete_users_secondaryinvestordocuments?: Maybe<Users_Secondaryinvestordocuments_Mutation_Response>;
  /** delete single row from the table: "users_secondaryinvestordocuments" */
  delete_users_secondaryinvestordocuments_by_pk?: Maybe<Users_Secondaryinvestordocuments>;
  /** delete data from the table: "users_secondaryinvestorinvite" */
  delete_users_secondaryinvestorinvite?: Maybe<Users_Secondaryinvestorinvite_Mutation_Response>;
  /** delete single row from the table: "users_secondaryinvestorinvite" */
  delete_users_secondaryinvestorinvite_by_pk?: Maybe<Users_Secondaryinvestorinvite>;
  /** delete data from the table: "users_user" */
  delete_users_user?: Maybe<Users_User_Mutation_Response>;
  /** delete single row from the table: "users_user" */
  delete_users_user_by_pk?: Maybe<Users_User>;
  /** delete data from the table: "users_userinterests" */
  delete_users_userinterests?: Maybe<Users_Userinterests_Mutation_Response>;
  /** delete single row from the table: "users_userinterests" */
  delete_users_userinterests_by_pk?: Maybe<Users_Userinterests>;
  /** delete data from the table: "users_usernotifications" */
  delete_users_usernotifications?: Maybe<Users_Usernotifications_Mutation_Response>;
  /** delete single row from the table: "users_usernotifications" */
  delete_users_usernotifications_by_pk?: Maybe<Users_Usernotifications>;
  /** delete data from the table: "users_userpreferences" */
  delete_users_userpreferences?: Maybe<Users_Userpreferences_Mutation_Response>;
  /** delete single row from the table: "users_userpreferences" */
  delete_users_userpreferences_by_pk?: Maybe<Users_Userpreferences>;
  /** delete data from the table: "users_usersession" */
  delete_users_usersession?: Maybe<Users_Usersession_Mutation_Response>;
  /** delete single row from the table: "users_usersession" */
  delete_users_usersession_by_pk?: Maybe<Users_Usersession>;
  /** insert a single row into the table: "storage_buckets" */
  insertBucket?: Maybe<Buckets>;
  /** insert data into the table: "storage_buckets" */
  insertBuckets?: Maybe<Buckets_Mutation_Response>;
  /** insert a single row into the table: "storage_files" */
  insertFile?: Maybe<Files>;
  /** insert data into the table: "storage_files" */
  insertFiles?: Maybe<Files_Mutation_Response>;
  /** insert data into the table: "homegrown_business" */
  insert_homegrown_business?: Maybe<Homegrown_Business_Mutation_Response>;
  /** insert a single row into the table: "homegrown_business" */
  insert_homegrown_business_one?: Maybe<Homegrown_Business>;
  /** insert data into the table: "homegrown_businessprivatedocuments" */
  insert_homegrown_businessprivatedocuments?: Maybe<Homegrown_Businessprivatedocuments_Mutation_Response>;
  /** insert a single row into the table: "homegrown_businessprivatedocuments" */
  insert_homegrown_businessprivatedocuments_one?: Maybe<Homegrown_Businessprivatedocuments>;
  /** insert data into the table: "homegrown_deal" */
  insert_homegrown_deal?: Maybe<Homegrown_Deal_Mutation_Response>;
  /** insert a single row into the table: "homegrown_deal" */
  insert_homegrown_deal_one?: Maybe<Homegrown_Deal>;
  /** insert data into the table: "homegrown_dealdocument" */
  insert_homegrown_dealdocument?: Maybe<Homegrown_Dealdocument_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealdocument" */
  insert_homegrown_dealdocument_one?: Maybe<Homegrown_Dealdocument>;
  /** insert data into the table: "homegrown_dealprivate" */
  insert_homegrown_dealprivate?: Maybe<Homegrown_Dealprivate_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealprivate" */
  insert_homegrown_dealprivate_one?: Maybe<Homegrown_Dealprivate>;
  /** insert data into the table: "homegrown_dealprivatedocuments" */
  insert_homegrown_dealprivatedocuments?: Maybe<Homegrown_Dealprivatedocuments_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealprivatedocuments" */
  insert_homegrown_dealprivatedocuments_one?: Maybe<Homegrown_Dealprivatedocuments>;
  /** insert data into the table: "homegrown_dealprivatedocumentstatus" */
  insert_homegrown_dealprivatedocumentstatus?: Maybe<Homegrown_Dealprivatedocumentstatus_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealprivatedocumentstatus" */
  insert_homegrown_dealprivatedocumentstatus_one?: Maybe<Homegrown_Dealprivatedocumentstatus>;
  /** insert data into the table: "homegrown_dealprivatedocumenttype" */
  insert_homegrown_dealprivatedocumenttype?: Maybe<Homegrown_Dealprivatedocumenttype_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealprivatedocumenttype" */
  insert_homegrown_dealprivatedocumenttype_one?: Maybe<Homegrown_Dealprivatedocumenttype>;
  /** insert data into the table: "homegrown_dealtobusinessplaidlink" */
  insert_homegrown_dealtobusinessplaidlink?: Maybe<Homegrown_Dealtobusinessplaidlink_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealtobusinessplaidlink" */
  insert_homegrown_dealtobusinessplaidlink_one?: Maybe<Homegrown_Dealtobusinessplaidlink>;
  /** insert data into the table: "homegrown_dealtoinvestorlink" */
  insert_homegrown_dealtoinvestorlink?: Maybe<Homegrown_Dealtoinvestorlink_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealtoinvestorlink" */
  insert_homegrown_dealtoinvestorlink_one?: Maybe<Homegrown_Dealtoinvestorlink>;
  /** insert data into the table: "homegrown_dealtype" */
  insert_homegrown_dealtype?: Maybe<Homegrown_Dealtype_Mutation_Response>;
  /** insert a single row into the table: "homegrown_dealtype" */
  insert_homegrown_dealtype_one?: Maybe<Homegrown_Dealtype>;
  /** insert data into the table: "homegrown_location" */
  insert_homegrown_location?: Maybe<Homegrown_Location_Mutation_Response>;
  /** insert a single row into the table: "homegrown_location" */
  insert_homegrown_location_one?: Maybe<Homegrown_Location>;
  /** insert data into the table: "homegrown_referralcode" */
  insert_homegrown_referralcode?: Maybe<Homegrown_Referralcode_Mutation_Response>;
  /** insert a single row into the table: "homegrown_referralcode" */
  insert_homegrown_referralcode_one?: Maybe<Homegrown_Referralcode>;
  /** insert data into the table: "homegrown_signwelldealdocument" */
  insert_homegrown_signwelldealdocument?: Maybe<Homegrown_Signwelldealdocument_Mutation_Response>;
  /** insert a single row into the table: "homegrown_signwelldealdocument" */
  insert_homegrown_signwelldealdocument_one?: Maybe<Homegrown_Signwelldealdocument>;
  /** insert data into the table: "homegrown_signwellinvestorsigneddocument" */
  insert_homegrown_signwellinvestorsigneddocument?: Maybe<Homegrown_Signwellinvestorsigneddocument_Mutation_Response>;
  /** insert a single row into the table: "homegrown_signwellinvestorsigneddocument" */
  insert_homegrown_signwellinvestorsigneddocument_one?: Maybe<Homegrown_Signwellinvestorsigneddocument>;
  /** insert data into the table: "homegrown_taxdocuments" */
  insert_homegrown_taxdocuments?: Maybe<Homegrown_Taxdocuments_Mutation_Response>;
  /** insert a single row into the table: "homegrown_taxdocuments" */
  insert_homegrown_taxdocuments_one?: Maybe<Homegrown_Taxdocuments>;
  /** insert data into the table: "homegrown_usedreferralcode" */
  insert_homegrown_usedreferralcode?: Maybe<Homegrown_Usedreferralcode_Mutation_Response>;
  /** insert a single row into the table: "homegrown_usedreferralcode" */
  insert_homegrown_usedreferralcode_one?: Maybe<Homegrown_Usedreferralcode>;
  /** insert data into the table: "homegrown_userdocuments" */
  insert_homegrown_userdocuments?: Maybe<Homegrown_Userdocuments_Mutation_Response>;
  /** insert a single row into the table: "homegrown_userdocuments" */
  insert_homegrown_userdocuments_one?: Maybe<Homegrown_Userdocuments>;
  /** insert data into the table: "homegrown_userdocumenttype" */
  insert_homegrown_userdocumenttype?: Maybe<Homegrown_Userdocumenttype_Mutation_Response>;
  /** insert a single row into the table: "homegrown_userdocumenttype" */
  insert_homegrown_userdocumenttype_one?: Maybe<Homegrown_Userdocumenttype>;
  /** insert data into the table: "plaid_app_investment" */
  insert_investments?: Maybe<Investments_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_investment" */
  insert_investments_one?: Maybe<Investments>;
  /** insert data into the table: "users_investordata" */
  insert_investordata?: Maybe<Investordata_Mutation_Response>;
  /** insert a single row into the table: "users_investordata" */
  insert_investordata_one?: Maybe<Investordata>;
  /** insert data into the table: "plaid_app_dealbusinessrevenue" */
  insert_plaid_app_dealbusinessrevenue?: Maybe<Plaid_App_Dealbusinessrevenue_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_dealbusinessrevenue" */
  insert_plaid_app_dealbusinessrevenue_one?: Maybe<Plaid_App_Dealbusinessrevenue>;
  /** insert data into the table: "plaid_app_dealpayback" */
  insert_plaid_app_dealpayback?: Maybe<Plaid_App_Dealpayback_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_dealpayback" */
  insert_plaid_app_dealpayback_one?: Maybe<Plaid_App_Dealpayback>;
  /** insert data into the table: "plaid_app_dealpayout" */
  insert_plaid_app_dealpayout?: Maybe<Plaid_App_Dealpayout_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_dealpayout" */
  insert_plaid_app_dealpayout_one?: Maybe<Plaid_App_Dealpayout>;
  /** insert data into the table: "plaid_app_dealtransfer" */
  insert_plaid_app_dealtransfer?: Maybe<Plaid_App_Dealtransfer_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_dealtransfer" */
  insert_plaid_app_dealtransfer_one?: Maybe<Plaid_App_Dealtransfer>;
  /** insert data into the table: "plaid_app_plaidlink" */
  insert_plaid_app_plaidlink?: Maybe<Plaid_App_Plaidlink_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_plaidlink" */
  insert_plaid_app_plaidlink_one?: Maybe<Plaid_App_Plaidlink>;
  /** insert data into the table: "plaid_app_plaidlinkbusiness" */
  insert_plaid_app_plaidlinkbusiness?: Maybe<Plaid_App_Plaidlinkbusiness_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_plaidlinkbusiness" */
  insert_plaid_app_plaidlinkbusiness_one?: Maybe<Plaid_App_Plaidlinkbusiness>;
  /** insert data into the table: "plaid_app_plaidlinkstatus" */
  insert_plaid_app_plaidlinkstatus?: Maybe<Plaid_App_Plaidlinkstatus_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_plaidlinkstatus" */
  insert_plaid_app_plaidlinkstatus_one?: Maybe<Plaid_App_Plaidlinkstatus>;
  /** insert data into the table: "plaid_app_plaidtransfer" */
  insert_plaid_app_plaidtransfer?: Maybe<Plaid_App_Plaidtransfer_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_plaidtransfer" */
  insert_plaid_app_plaidtransfer_one?: Maybe<Plaid_App_Plaidtransfer>;
  /** insert data into the table: "plaid_app_plaidtransferevent" */
  insert_plaid_app_plaidtransferevent?: Maybe<Plaid_App_Plaidtransferevent_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_plaidtransferevent" */
  insert_plaid_app_plaidtransferevent_one?: Maybe<Plaid_App_Plaidtransferevent>;
  /** insert data into the table: "plaid_app_revenuetransaction" */
  insert_plaid_app_revenuetransaction?: Maybe<Plaid_App_Revenuetransaction_Mutation_Response>;
  /** insert a single row into the table: "plaid_app_revenuetransaction" */
  insert_plaid_app_revenuetransaction_one?: Maybe<Plaid_App_Revenuetransaction>;
  /** insert data into the table: "treasuryprime_app_achtransfer" */
  insert_treasuryprime_app_achtransfer?: Maybe<Treasuryprime_App_Achtransfer_Mutation_Response>;
  /** insert a single row into the table: "treasuryprime_app_achtransfer" */
  insert_treasuryprime_app_achtransfer_one?: Maybe<Treasuryprime_App_Achtransfer>;
  /** insert data into the table: "treasuryprime_app_counterpartylink" */
  insert_treasuryprime_app_counterpartylink?: Maybe<Treasuryprime_App_Counterpartylink_Mutation_Response>;
  /** insert a single row into the table: "treasuryprime_app_counterpartylink" */
  insert_treasuryprime_app_counterpartylink_one?: Maybe<Treasuryprime_App_Counterpartylink>;
  /** insert data into the table: "treasuryprime_app_counterpartylinkbusiness" */
  insert_treasuryprime_app_counterpartylinkbusiness?: Maybe<Treasuryprime_App_Counterpartylinkbusiness_Mutation_Response>;
  /** insert a single row into the table: "treasuryprime_app_counterpartylinkbusiness" */
  insert_treasuryprime_app_counterpartylinkbusiness_one?: Maybe<Treasuryprime_App_Counterpartylinkbusiness>;
  /** insert data into the table: "users_businessowner" */
  insert_users_businessowner?: Maybe<Users_Businessowner_Mutation_Response>;
  /** insert a single row into the table: "users_businessowner" */
  insert_users_businessowner_one?: Maybe<Users_Businessowner>;
  /** insert data into the table: "users_employee" */
  insert_users_employee?: Maybe<Users_Employee_Mutation_Response>;
  /** insert a single row into the table: "users_employee" */
  insert_users_employee_one?: Maybe<Users_Employee>;
  /** insert data into the table: "users_investorbusiness" */
  insert_users_investorbusiness?: Maybe<Users_Investorbusiness_Mutation_Response>;
  /** insert a single row into the table: "users_investorbusiness" */
  insert_users_investorbusiness_one?: Maybe<Users_Investorbusiness>;
  /** insert data into the table: "users_roleorder" */
  insert_users_roleorder?: Maybe<Users_Roleorder_Mutation_Response>;
  /** insert a single row into the table: "users_roleorder" */
  insert_users_roleorder_one?: Maybe<Users_Roleorder>;
  /** insert data into the table: "users_secondaryinvestordata" */
  insert_users_secondaryinvestordata?: Maybe<Users_Secondaryinvestordata_Mutation_Response>;
  /** insert a single row into the table: "users_secondaryinvestordata" */
  insert_users_secondaryinvestordata_one?: Maybe<Users_Secondaryinvestordata>;
  /** insert data into the table: "users_secondaryinvestordocuments" */
  insert_users_secondaryinvestordocuments?: Maybe<Users_Secondaryinvestordocuments_Mutation_Response>;
  /** insert a single row into the table: "users_secondaryinvestordocuments" */
  insert_users_secondaryinvestordocuments_one?: Maybe<Users_Secondaryinvestordocuments>;
  /** insert data into the table: "users_secondaryinvestorinvite" */
  insert_users_secondaryinvestorinvite?: Maybe<Users_Secondaryinvestorinvite_Mutation_Response>;
  /** insert a single row into the table: "users_secondaryinvestorinvite" */
  insert_users_secondaryinvestorinvite_one?: Maybe<Users_Secondaryinvestorinvite>;
  /** insert data into the table: "users_user" */
  insert_users_user?: Maybe<Users_User_Mutation_Response>;
  /** insert a single row into the table: "users_user" */
  insert_users_user_one?: Maybe<Users_User>;
  /** insert data into the table: "users_userinterests" */
  insert_users_userinterests?: Maybe<Users_Userinterests_Mutation_Response>;
  /** insert a single row into the table: "users_userinterests" */
  insert_users_userinterests_one?: Maybe<Users_Userinterests>;
  /** insert data into the table: "users_usernotifications" */
  insert_users_usernotifications?: Maybe<Users_Usernotifications_Mutation_Response>;
  /** insert a single row into the table: "users_usernotifications" */
  insert_users_usernotifications_one?: Maybe<Users_Usernotifications>;
  /** insert data into the table: "users_userpreferences" */
  insert_users_userpreferences?: Maybe<Users_Userpreferences_Mutation_Response>;
  /** insert a single row into the table: "users_userpreferences" */
  insert_users_userpreferences_one?: Maybe<Users_Userpreferences>;
  /** insert data into the table: "users_usersession" */
  insert_users_usersession?: Maybe<Users_Usersession_Mutation_Response>;
  /** insert a single row into the table: "users_usersession" */
  insert_users_usersession_one?: Maybe<Users_Usersession>;
  /** update single row of the table: "storage_buckets" */
  updateBucket?: Maybe<Buckets>;
  /** update data of the table: "storage_buckets" */
  updateBuckets?: Maybe<Buckets_Mutation_Response>;
  /** update single row of the table: "storage_files" */
  updateFile?: Maybe<Files>;
  /** update data of the table: "storage_files" */
  updateFiles?: Maybe<Files_Mutation_Response>;
  /** update multiples rows of table: "storage_buckets" */
  update_buckets_many?: Maybe<Array<Maybe<Buckets_Mutation_Response>>>;
  /** update multiples rows of table: "storage_files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "homegrown_business" */
  update_homegrown_business?: Maybe<Homegrown_Business_Mutation_Response>;
  /** update single row of the table: "homegrown_business" */
  update_homegrown_business_by_pk?: Maybe<Homegrown_Business>;
  /** update multiples rows of table: "homegrown_business" */
  update_homegrown_business_many?: Maybe<Array<Maybe<Homegrown_Business_Mutation_Response>>>;
  /** update data of the table: "homegrown_businessprivatedocuments" */
  update_homegrown_businessprivatedocuments?: Maybe<Homegrown_Businessprivatedocuments_Mutation_Response>;
  /** update single row of the table: "homegrown_businessprivatedocuments" */
  update_homegrown_businessprivatedocuments_by_pk?: Maybe<Homegrown_Businessprivatedocuments>;
  /** update multiples rows of table: "homegrown_businessprivatedocuments" */
  update_homegrown_businessprivatedocuments_many?: Maybe<Array<Maybe<Homegrown_Businessprivatedocuments_Mutation_Response>>>;
  /** update data of the table: "homegrown_deal" */
  update_homegrown_deal?: Maybe<Homegrown_Deal_Mutation_Response>;
  /** update single row of the table: "homegrown_deal" */
  update_homegrown_deal_by_pk?: Maybe<Homegrown_Deal>;
  /** update multiples rows of table: "homegrown_deal" */
  update_homegrown_deal_many?: Maybe<Array<Maybe<Homegrown_Deal_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealdocument" */
  update_homegrown_dealdocument?: Maybe<Homegrown_Dealdocument_Mutation_Response>;
  /** update single row of the table: "homegrown_dealdocument" */
  update_homegrown_dealdocument_by_pk?: Maybe<Homegrown_Dealdocument>;
  /** update multiples rows of table: "homegrown_dealdocument" */
  update_homegrown_dealdocument_many?: Maybe<Array<Maybe<Homegrown_Dealdocument_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealprivate" */
  update_homegrown_dealprivate?: Maybe<Homegrown_Dealprivate_Mutation_Response>;
  /** update single row of the table: "homegrown_dealprivate" */
  update_homegrown_dealprivate_by_pk?: Maybe<Homegrown_Dealprivate>;
  /** update multiples rows of table: "homegrown_dealprivate" */
  update_homegrown_dealprivate_many?: Maybe<Array<Maybe<Homegrown_Dealprivate_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealprivatedocuments" */
  update_homegrown_dealprivatedocuments?: Maybe<Homegrown_Dealprivatedocuments_Mutation_Response>;
  /** update single row of the table: "homegrown_dealprivatedocuments" */
  update_homegrown_dealprivatedocuments_by_pk?: Maybe<Homegrown_Dealprivatedocuments>;
  /** update multiples rows of table: "homegrown_dealprivatedocuments" */
  update_homegrown_dealprivatedocuments_many?: Maybe<Array<Maybe<Homegrown_Dealprivatedocuments_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealprivatedocumentstatus" */
  update_homegrown_dealprivatedocumentstatus?: Maybe<Homegrown_Dealprivatedocumentstatus_Mutation_Response>;
  /** update single row of the table: "homegrown_dealprivatedocumentstatus" */
  update_homegrown_dealprivatedocumentstatus_by_pk?: Maybe<Homegrown_Dealprivatedocumentstatus>;
  /** update multiples rows of table: "homegrown_dealprivatedocumentstatus" */
  update_homegrown_dealprivatedocumentstatus_many?: Maybe<Array<Maybe<Homegrown_Dealprivatedocumentstatus_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealprivatedocumenttype" */
  update_homegrown_dealprivatedocumenttype?: Maybe<Homegrown_Dealprivatedocumenttype_Mutation_Response>;
  /** update single row of the table: "homegrown_dealprivatedocumenttype" */
  update_homegrown_dealprivatedocumenttype_by_pk?: Maybe<Homegrown_Dealprivatedocumenttype>;
  /** update multiples rows of table: "homegrown_dealprivatedocumenttype" */
  update_homegrown_dealprivatedocumenttype_many?: Maybe<Array<Maybe<Homegrown_Dealprivatedocumenttype_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealtobusinessplaidlink" */
  update_homegrown_dealtobusinessplaidlink?: Maybe<Homegrown_Dealtobusinessplaidlink_Mutation_Response>;
  /** update single row of the table: "homegrown_dealtobusinessplaidlink" */
  update_homegrown_dealtobusinessplaidlink_by_pk?: Maybe<Homegrown_Dealtobusinessplaidlink>;
  /** update multiples rows of table: "homegrown_dealtobusinessplaidlink" */
  update_homegrown_dealtobusinessplaidlink_many?: Maybe<Array<Maybe<Homegrown_Dealtobusinessplaidlink_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealtoinvestorlink" */
  update_homegrown_dealtoinvestorlink?: Maybe<Homegrown_Dealtoinvestorlink_Mutation_Response>;
  /** update single row of the table: "homegrown_dealtoinvestorlink" */
  update_homegrown_dealtoinvestorlink_by_pk?: Maybe<Homegrown_Dealtoinvestorlink>;
  /** update multiples rows of table: "homegrown_dealtoinvestorlink" */
  update_homegrown_dealtoinvestorlink_many?: Maybe<Array<Maybe<Homegrown_Dealtoinvestorlink_Mutation_Response>>>;
  /** update data of the table: "homegrown_dealtype" */
  update_homegrown_dealtype?: Maybe<Homegrown_Dealtype_Mutation_Response>;
  /** update single row of the table: "homegrown_dealtype" */
  update_homegrown_dealtype_by_pk?: Maybe<Homegrown_Dealtype>;
  /** update multiples rows of table: "homegrown_dealtype" */
  update_homegrown_dealtype_many?: Maybe<Array<Maybe<Homegrown_Dealtype_Mutation_Response>>>;
  /** update data of the table: "homegrown_location" */
  update_homegrown_location?: Maybe<Homegrown_Location_Mutation_Response>;
  /** update single row of the table: "homegrown_location" */
  update_homegrown_location_by_pk?: Maybe<Homegrown_Location>;
  /** update multiples rows of table: "homegrown_location" */
  update_homegrown_location_many?: Maybe<Array<Maybe<Homegrown_Location_Mutation_Response>>>;
  /** update data of the table: "homegrown_referralcode" */
  update_homegrown_referralcode?: Maybe<Homegrown_Referralcode_Mutation_Response>;
  /** update single row of the table: "homegrown_referralcode" */
  update_homegrown_referralcode_by_pk?: Maybe<Homegrown_Referralcode>;
  /** update multiples rows of table: "homegrown_referralcode" */
  update_homegrown_referralcode_many?: Maybe<Array<Maybe<Homegrown_Referralcode_Mutation_Response>>>;
  /** update data of the table: "homegrown_signwelldealdocument" */
  update_homegrown_signwelldealdocument?: Maybe<Homegrown_Signwelldealdocument_Mutation_Response>;
  /** update single row of the table: "homegrown_signwelldealdocument" */
  update_homegrown_signwelldealdocument_by_pk?: Maybe<Homegrown_Signwelldealdocument>;
  /** update multiples rows of table: "homegrown_signwelldealdocument" */
  update_homegrown_signwelldealdocument_many?: Maybe<Array<Maybe<Homegrown_Signwelldealdocument_Mutation_Response>>>;
  /** update data of the table: "homegrown_signwellinvestorsigneddocument" */
  update_homegrown_signwellinvestorsigneddocument?: Maybe<Homegrown_Signwellinvestorsigneddocument_Mutation_Response>;
  /** update single row of the table: "homegrown_signwellinvestorsigneddocument" */
  update_homegrown_signwellinvestorsigneddocument_by_pk?: Maybe<Homegrown_Signwellinvestorsigneddocument>;
  /** update multiples rows of table: "homegrown_signwellinvestorsigneddocument" */
  update_homegrown_signwellinvestorsigneddocument_many?: Maybe<Array<Maybe<Homegrown_Signwellinvestorsigneddocument_Mutation_Response>>>;
  /** update data of the table: "homegrown_taxdocuments" */
  update_homegrown_taxdocuments?: Maybe<Homegrown_Taxdocuments_Mutation_Response>;
  /** update single row of the table: "homegrown_taxdocuments" */
  update_homegrown_taxdocuments_by_pk?: Maybe<Homegrown_Taxdocuments>;
  /** update multiples rows of table: "homegrown_taxdocuments" */
  update_homegrown_taxdocuments_many?: Maybe<Array<Maybe<Homegrown_Taxdocuments_Mutation_Response>>>;
  /** update data of the table: "homegrown_usedreferralcode" */
  update_homegrown_usedreferralcode?: Maybe<Homegrown_Usedreferralcode_Mutation_Response>;
  /** update single row of the table: "homegrown_usedreferralcode" */
  update_homegrown_usedreferralcode_by_pk?: Maybe<Homegrown_Usedreferralcode>;
  /** update multiples rows of table: "homegrown_usedreferralcode" */
  update_homegrown_usedreferralcode_many?: Maybe<Array<Maybe<Homegrown_Usedreferralcode_Mutation_Response>>>;
  /** update data of the table: "homegrown_userdocuments" */
  update_homegrown_userdocuments?: Maybe<Homegrown_Userdocuments_Mutation_Response>;
  /** update single row of the table: "homegrown_userdocuments" */
  update_homegrown_userdocuments_by_pk?: Maybe<Homegrown_Userdocuments>;
  /** update multiples rows of table: "homegrown_userdocuments" */
  update_homegrown_userdocuments_many?: Maybe<Array<Maybe<Homegrown_Userdocuments_Mutation_Response>>>;
  /** update data of the table: "homegrown_userdocumenttype" */
  update_homegrown_userdocumenttype?: Maybe<Homegrown_Userdocumenttype_Mutation_Response>;
  /** update single row of the table: "homegrown_userdocumenttype" */
  update_homegrown_userdocumenttype_by_pk?: Maybe<Homegrown_Userdocumenttype>;
  /** update multiples rows of table: "homegrown_userdocumenttype" */
  update_homegrown_userdocumenttype_many?: Maybe<Array<Maybe<Homegrown_Userdocumenttype_Mutation_Response>>>;
  /** update data of the table: "plaid_app_investment" */
  update_investments?: Maybe<Investments_Mutation_Response>;
  /** update single row of the table: "plaid_app_investment" */
  update_investments_by_pk?: Maybe<Investments>;
  /** update multiples rows of table: "plaid_app_investment" */
  update_investments_many?: Maybe<Array<Maybe<Investments_Mutation_Response>>>;
  /** update data of the table: "users_investordata" */
  update_investordata?: Maybe<Investordata_Mutation_Response>;
  /** update single row of the table: "users_investordata" */
  update_investordata_by_pk?: Maybe<Investordata>;
  /** update multiples rows of table: "users_investordata" */
  update_investordata_many?: Maybe<Array<Maybe<Investordata_Mutation_Response>>>;
  /** update data of the table: "plaid_app_dealbusinessrevenue" */
  update_plaid_app_dealbusinessrevenue?: Maybe<Plaid_App_Dealbusinessrevenue_Mutation_Response>;
  /** update single row of the table: "plaid_app_dealbusinessrevenue" */
  update_plaid_app_dealbusinessrevenue_by_pk?: Maybe<Plaid_App_Dealbusinessrevenue>;
  /** update multiples rows of table: "plaid_app_dealbusinessrevenue" */
  update_plaid_app_dealbusinessrevenue_many?: Maybe<Array<Maybe<Plaid_App_Dealbusinessrevenue_Mutation_Response>>>;
  /** update data of the table: "plaid_app_dealpayback" */
  update_plaid_app_dealpayback?: Maybe<Plaid_App_Dealpayback_Mutation_Response>;
  /** update single row of the table: "plaid_app_dealpayback" */
  update_plaid_app_dealpayback_by_pk?: Maybe<Plaid_App_Dealpayback>;
  /** update multiples rows of table: "plaid_app_dealpayback" */
  update_plaid_app_dealpayback_many?: Maybe<Array<Maybe<Plaid_App_Dealpayback_Mutation_Response>>>;
  /** update data of the table: "plaid_app_dealpayout" */
  update_plaid_app_dealpayout?: Maybe<Plaid_App_Dealpayout_Mutation_Response>;
  /** update single row of the table: "plaid_app_dealpayout" */
  update_plaid_app_dealpayout_by_pk?: Maybe<Plaid_App_Dealpayout>;
  /** update multiples rows of table: "plaid_app_dealpayout" */
  update_plaid_app_dealpayout_many?: Maybe<Array<Maybe<Plaid_App_Dealpayout_Mutation_Response>>>;
  /** update data of the table: "plaid_app_dealtransfer" */
  update_plaid_app_dealtransfer?: Maybe<Plaid_App_Dealtransfer_Mutation_Response>;
  /** update single row of the table: "plaid_app_dealtransfer" */
  update_plaid_app_dealtransfer_by_pk?: Maybe<Plaid_App_Dealtransfer>;
  /** update multiples rows of table: "plaid_app_dealtransfer" */
  update_plaid_app_dealtransfer_many?: Maybe<Array<Maybe<Plaid_App_Dealtransfer_Mutation_Response>>>;
  /** update data of the table: "plaid_app_plaidlink" */
  update_plaid_app_plaidlink?: Maybe<Plaid_App_Plaidlink_Mutation_Response>;
  /** update single row of the table: "plaid_app_plaidlink" */
  update_plaid_app_plaidlink_by_pk?: Maybe<Plaid_App_Plaidlink>;
  /** update multiples rows of table: "plaid_app_plaidlink" */
  update_plaid_app_plaidlink_many?: Maybe<Array<Maybe<Plaid_App_Plaidlink_Mutation_Response>>>;
  /** update data of the table: "plaid_app_plaidlinkbusiness" */
  update_plaid_app_plaidlinkbusiness?: Maybe<Plaid_App_Plaidlinkbusiness_Mutation_Response>;
  /** update single row of the table: "plaid_app_plaidlinkbusiness" */
  update_plaid_app_plaidlinkbusiness_by_pk?: Maybe<Plaid_App_Plaidlinkbusiness>;
  /** update multiples rows of table: "plaid_app_plaidlinkbusiness" */
  update_plaid_app_plaidlinkbusiness_many?: Maybe<Array<Maybe<Plaid_App_Plaidlinkbusiness_Mutation_Response>>>;
  /** update data of the table: "plaid_app_plaidlinkstatus" */
  update_plaid_app_plaidlinkstatus?: Maybe<Plaid_App_Plaidlinkstatus_Mutation_Response>;
  /** update single row of the table: "plaid_app_plaidlinkstatus" */
  update_plaid_app_plaidlinkstatus_by_pk?: Maybe<Plaid_App_Plaidlinkstatus>;
  /** update multiples rows of table: "plaid_app_plaidlinkstatus" */
  update_plaid_app_plaidlinkstatus_many?: Maybe<Array<Maybe<Plaid_App_Plaidlinkstatus_Mutation_Response>>>;
  /** update data of the table: "plaid_app_plaidtransfer" */
  update_plaid_app_plaidtransfer?: Maybe<Plaid_App_Plaidtransfer_Mutation_Response>;
  /** update single row of the table: "plaid_app_plaidtransfer" */
  update_plaid_app_plaidtransfer_by_pk?: Maybe<Plaid_App_Plaidtransfer>;
  /** update multiples rows of table: "plaid_app_plaidtransfer" */
  update_plaid_app_plaidtransfer_many?: Maybe<Array<Maybe<Plaid_App_Plaidtransfer_Mutation_Response>>>;
  /** update data of the table: "plaid_app_plaidtransferevent" */
  update_plaid_app_plaidtransferevent?: Maybe<Plaid_App_Plaidtransferevent_Mutation_Response>;
  /** update single row of the table: "plaid_app_plaidtransferevent" */
  update_plaid_app_plaidtransferevent_by_pk?: Maybe<Plaid_App_Plaidtransferevent>;
  /** update multiples rows of table: "plaid_app_plaidtransferevent" */
  update_plaid_app_plaidtransferevent_many?: Maybe<Array<Maybe<Plaid_App_Plaidtransferevent_Mutation_Response>>>;
  /** update data of the table: "plaid_app_revenuetransaction" */
  update_plaid_app_revenuetransaction?: Maybe<Plaid_App_Revenuetransaction_Mutation_Response>;
  /** update single row of the table: "plaid_app_revenuetransaction" */
  update_plaid_app_revenuetransaction_by_pk?: Maybe<Plaid_App_Revenuetransaction>;
  /** update multiples rows of table: "plaid_app_revenuetransaction" */
  update_plaid_app_revenuetransaction_many?: Maybe<Array<Maybe<Plaid_App_Revenuetransaction_Mutation_Response>>>;
  /** update data of the table: "treasuryprime_app_achtransfer" */
  update_treasuryprime_app_achtransfer?: Maybe<Treasuryprime_App_Achtransfer_Mutation_Response>;
  /** update single row of the table: "treasuryprime_app_achtransfer" */
  update_treasuryprime_app_achtransfer_by_pk?: Maybe<Treasuryprime_App_Achtransfer>;
  /** update multiples rows of table: "treasuryprime_app_achtransfer" */
  update_treasuryprime_app_achtransfer_many?: Maybe<Array<Maybe<Treasuryprime_App_Achtransfer_Mutation_Response>>>;
  /** update data of the table: "treasuryprime_app_counterpartylink" */
  update_treasuryprime_app_counterpartylink?: Maybe<Treasuryprime_App_Counterpartylink_Mutation_Response>;
  /** update single row of the table: "treasuryprime_app_counterpartylink" */
  update_treasuryprime_app_counterpartylink_by_pk?: Maybe<Treasuryprime_App_Counterpartylink>;
  /** update multiples rows of table: "treasuryprime_app_counterpartylink" */
  update_treasuryprime_app_counterpartylink_many?: Maybe<Array<Maybe<Treasuryprime_App_Counterpartylink_Mutation_Response>>>;
  /** update data of the table: "treasuryprime_app_counterpartylinkbusiness" */
  update_treasuryprime_app_counterpartylinkbusiness?: Maybe<Treasuryprime_App_Counterpartylinkbusiness_Mutation_Response>;
  /** update single row of the table: "treasuryprime_app_counterpartylinkbusiness" */
  update_treasuryprime_app_counterpartylinkbusiness_by_pk?: Maybe<Treasuryprime_App_Counterpartylinkbusiness>;
  /** update multiples rows of table: "treasuryprime_app_counterpartylinkbusiness" */
  update_treasuryprime_app_counterpartylinkbusiness_many?: Maybe<Array<Maybe<Treasuryprime_App_Counterpartylinkbusiness_Mutation_Response>>>;
  /** update data of the table: "users_businessowner" */
  update_users_businessowner?: Maybe<Users_Businessowner_Mutation_Response>;
  /** update single row of the table: "users_businessowner" */
  update_users_businessowner_by_pk?: Maybe<Users_Businessowner>;
  /** update multiples rows of table: "users_businessowner" */
  update_users_businessowner_many?: Maybe<Array<Maybe<Users_Businessowner_Mutation_Response>>>;
  /** update data of the table: "users_employee" */
  update_users_employee?: Maybe<Users_Employee_Mutation_Response>;
  /** update single row of the table: "users_employee" */
  update_users_employee_by_pk?: Maybe<Users_Employee>;
  /** update multiples rows of table: "users_employee" */
  update_users_employee_many?: Maybe<Array<Maybe<Users_Employee_Mutation_Response>>>;
  /** update data of the table: "users_investorbusiness" */
  update_users_investorbusiness?: Maybe<Users_Investorbusiness_Mutation_Response>;
  /** update single row of the table: "users_investorbusiness" */
  update_users_investorbusiness_by_pk?: Maybe<Users_Investorbusiness>;
  /** update multiples rows of table: "users_investorbusiness" */
  update_users_investorbusiness_many?: Maybe<Array<Maybe<Users_Investorbusiness_Mutation_Response>>>;
  /** update data of the table: "users_roleorder" */
  update_users_roleorder?: Maybe<Users_Roleorder_Mutation_Response>;
  /** update single row of the table: "users_roleorder" */
  update_users_roleorder_by_pk?: Maybe<Users_Roleorder>;
  /** update multiples rows of table: "users_roleorder" */
  update_users_roleorder_many?: Maybe<Array<Maybe<Users_Roleorder_Mutation_Response>>>;
  /** update data of the table: "users_secondaryinvestordata" */
  update_users_secondaryinvestordata?: Maybe<Users_Secondaryinvestordata_Mutation_Response>;
  /** update single row of the table: "users_secondaryinvestordata" */
  update_users_secondaryinvestordata_by_pk?: Maybe<Users_Secondaryinvestordata>;
  /** update multiples rows of table: "users_secondaryinvestordata" */
  update_users_secondaryinvestordata_many?: Maybe<Array<Maybe<Users_Secondaryinvestordata_Mutation_Response>>>;
  /** update data of the table: "users_secondaryinvestordocuments" */
  update_users_secondaryinvestordocuments?: Maybe<Users_Secondaryinvestordocuments_Mutation_Response>;
  /** update single row of the table: "users_secondaryinvestordocuments" */
  update_users_secondaryinvestordocuments_by_pk?: Maybe<Users_Secondaryinvestordocuments>;
  /** update multiples rows of table: "users_secondaryinvestordocuments" */
  update_users_secondaryinvestordocuments_many?: Maybe<Array<Maybe<Users_Secondaryinvestordocuments_Mutation_Response>>>;
  /** update data of the table: "users_secondaryinvestorinvite" */
  update_users_secondaryinvestorinvite?: Maybe<Users_Secondaryinvestorinvite_Mutation_Response>;
  /** update single row of the table: "users_secondaryinvestorinvite" */
  update_users_secondaryinvestorinvite_by_pk?: Maybe<Users_Secondaryinvestorinvite>;
  /** update multiples rows of table: "users_secondaryinvestorinvite" */
  update_users_secondaryinvestorinvite_many?: Maybe<Array<Maybe<Users_Secondaryinvestorinvite_Mutation_Response>>>;
  /** update data of the table: "users_user" */
  update_users_user?: Maybe<Users_User_Mutation_Response>;
  /** update single row of the table: "users_user" */
  update_users_user_by_pk?: Maybe<Users_User>;
  /** update multiples rows of table: "users_user" */
  update_users_user_many?: Maybe<Array<Maybe<Users_User_Mutation_Response>>>;
  /** update data of the table: "users_userinterests" */
  update_users_userinterests?: Maybe<Users_Userinterests_Mutation_Response>;
  /** update single row of the table: "users_userinterests" */
  update_users_userinterests_by_pk?: Maybe<Users_Userinterests>;
  /** update multiples rows of table: "users_userinterests" */
  update_users_userinterests_many?: Maybe<Array<Maybe<Users_Userinterests_Mutation_Response>>>;
  /** update data of the table: "users_usernotifications" */
  update_users_usernotifications?: Maybe<Users_Usernotifications_Mutation_Response>;
  /** update single row of the table: "users_usernotifications" */
  update_users_usernotifications_by_pk?: Maybe<Users_Usernotifications>;
  /** update multiples rows of table: "users_usernotifications" */
  update_users_usernotifications_many?: Maybe<Array<Maybe<Users_Usernotifications_Mutation_Response>>>;
  /** update data of the table: "users_userpreferences" */
  update_users_userpreferences?: Maybe<Users_Userpreferences_Mutation_Response>;
  /** update single row of the table: "users_userpreferences" */
  update_users_userpreferences_by_pk?: Maybe<Users_Userpreferences>;
  /** update multiples rows of table: "users_userpreferences" */
  update_users_userpreferences_many?: Maybe<Array<Maybe<Users_Userpreferences_Mutation_Response>>>;
  /** update data of the table: "users_usersession" */
  update_users_usersession?: Maybe<Users_Usersession_Mutation_Response>;
  /** update single row of the table: "users_usersession" */
  update_users_usersession_by_pk?: Maybe<Users_Usersession>;
  /** update multiples rows of table: "users_usersession" */
  update_users_usersession_many?: Maybe<Array<Maybe<Users_Usersession_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDeleteBucketArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDeleteBucketsArgs = {
  where: Buckets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeleteFileArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDeleteFilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_BusinessArgs = {
  where: Homegrown_Business_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Business_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_BusinessprivatedocumentsArgs = {
  where: Homegrown_Businessprivatedocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Businessprivatedocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealArgs = {
  where: Homegrown_Deal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Deal_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealdocumentArgs = {
  where: Homegrown_Dealdocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealdocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealprivateArgs = {
  where: Homegrown_Dealprivate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealprivate_By_PkArgs = {
  deal_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealprivatedocumentsArgs = {
  where: Homegrown_Dealprivatedocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealprivatedocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealprivatedocumentstatusArgs = {
  where: Homegrown_Dealprivatedocumentstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealprivatedocumentstatus_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealprivatedocumenttypeArgs = {
  where: Homegrown_Dealprivatedocumenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealprivatedocumenttype_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealtobusinessplaidlinkArgs = {
  where: Homegrown_Dealtobusinessplaidlink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealtobusinessplaidlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealtoinvestorlinkArgs = {
  where: Homegrown_Dealtoinvestorlink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealtoinvestorlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_DealtypeArgs = {
  where: Homegrown_Dealtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Dealtype_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_LocationArgs = {
  where: Homegrown_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Location_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_ReferralcodeArgs = {
  where: Homegrown_Referralcode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Referralcode_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_SignwelldealdocumentArgs = {
  where: Homegrown_Signwelldealdocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Signwelldealdocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_SignwellinvestorsigneddocumentArgs = {
  where: Homegrown_Signwellinvestorsigneddocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Signwellinvestorsigneddocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_TaxdocumentsArgs = {
  where: Homegrown_Taxdocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Taxdocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_UsedreferralcodeArgs = {
  where: Homegrown_Usedreferralcode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Usedreferralcode_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_UserdocumentsArgs = {
  where: Homegrown_Userdocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Userdocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_UserdocumenttypeArgs = {
  where: Homegrown_Userdocumenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Homegrown_Userdocumenttype_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_InvestmentsArgs = {
  where: Investments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_InvestordataArgs = {
  where: Investordata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Investordata_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_DealbusinessrevenueArgs = {
  where: Plaid_App_Dealbusinessrevenue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Dealbusinessrevenue_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_DealpaybackArgs = {
  where: Plaid_App_Dealpayback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Dealpayback_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_DealpayoutArgs = {
  where: Plaid_App_Dealpayout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Dealpayout_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_DealtransferArgs = {
  where: Plaid_App_Dealtransfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Dealtransfer_By_PkArgs = {
  payout_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_PlaidlinkArgs = {
  where: Plaid_App_Plaidlink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Plaidlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_PlaidlinkbusinessArgs = {
  where: Plaid_App_Plaidlinkbusiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Plaidlinkbusiness_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_PlaidlinkstatusArgs = {
  where: Plaid_App_Plaidlinkstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Plaidlinkstatus_By_PkArgs = {
  name: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_PlaidtransferArgs = {
  where: Plaid_App_Plaidtransfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Plaidtransfer_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_PlaidtransfereventArgs = {
  where: Plaid_App_Plaidtransferevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Plaidtransferevent_By_PkArgs = {
  event_id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_RevenuetransactionArgs = {
  where: Plaid_App_Revenuetransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Plaid_App_Revenuetransaction_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Treasuryprime_App_AchtransferArgs = {
  where: Treasuryprime_App_Achtransfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Treasuryprime_App_Achtransfer_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Treasuryprime_App_CounterpartylinkArgs = {
  where: Treasuryprime_App_Counterpartylink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Treasuryprime_App_Counterpartylink_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Treasuryprime_App_CounterpartylinkbusinessArgs = {
  where: Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Treasuryprime_App_Counterpartylinkbusiness_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_BusinessownerArgs = {
  where: Users_Businessowner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Businessowner_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_EmployeeArgs = {
  where: Users_Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Employee_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_InvestorbusinessArgs = {
  where: Users_Investorbusiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Investorbusiness_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_RoleorderArgs = {
  where: Users_Roleorder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Roleorder_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_SecondaryinvestordataArgs = {
  where: Users_Secondaryinvestordata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Secondaryinvestordata_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_SecondaryinvestordocumentsArgs = {
  where: Users_Secondaryinvestordocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Secondaryinvestordocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_SecondaryinvestorinviteArgs = {
  where: Users_Secondaryinvestorinvite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Secondaryinvestorinvite_By_PkArgs = {
  invite_code: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_UserArgs = {
  where: Users_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_User_By_PkArgs = {
  auth0id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_UserinterestsArgs = {
  where: Users_Userinterests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Userinterests_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_UsernotificationsArgs = {
  where: Users_Usernotifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Usernotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_UserpreferencesArgs = {
  where: Users_Userpreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Userpreferences_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Users_UsersessionArgs = {
  where: Users_Usersession_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_Usersession_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootInsertBucketArgs = {
  object: Buckets_Insert_Input;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertBucketsArgs = {
  objects: Array<Buckets_Insert_Input>;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertFileArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsertFilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_BusinessArgs = {
  objects: Array<Homegrown_Business_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Business_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Business_OneArgs = {
  object: Homegrown_Business_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Business_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_BusinessprivatedocumentsArgs = {
  objects: Array<Homegrown_Businessprivatedocuments_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Businessprivatedocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Businessprivatedocuments_OneArgs = {
  object: Homegrown_Businessprivatedocuments_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Businessprivatedocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealArgs = {
  objects: Array<Homegrown_Deal_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Deal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Deal_OneArgs = {
  object: Homegrown_Deal_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Deal_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealdocumentArgs = {
  objects: Array<Homegrown_Dealdocument_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealdocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealdocument_OneArgs = {
  object: Homegrown_Dealdocument_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealdocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealprivateArgs = {
  objects: Array<Homegrown_Dealprivate_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealprivate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealprivate_OneArgs = {
  object: Homegrown_Dealprivate_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealprivate_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealprivatedocumentsArgs = {
  objects: Array<Homegrown_Dealprivatedocuments_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealprivatedocuments_OneArgs = {
  object: Homegrown_Dealprivatedocuments_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealprivatedocumentstatusArgs = {
  objects: Array<Homegrown_Dealprivatedocumentstatus_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocumentstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealprivatedocumentstatus_OneArgs = {
  object: Homegrown_Dealprivatedocumentstatus_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocumentstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealprivatedocumenttypeArgs = {
  objects: Array<Homegrown_Dealprivatedocumenttype_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocumenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealprivatedocumenttype_OneArgs = {
  object: Homegrown_Dealprivatedocumenttype_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealprivatedocumenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealtobusinessplaidlinkArgs = {
  objects: Array<Homegrown_Dealtobusinessplaidlink_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealtobusinessplaidlink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealtobusinessplaidlink_OneArgs = {
  object: Homegrown_Dealtobusinessplaidlink_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealtobusinessplaidlink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealtoinvestorlinkArgs = {
  objects: Array<Homegrown_Dealtoinvestorlink_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealtoinvestorlink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealtoinvestorlink_OneArgs = {
  object: Homegrown_Dealtoinvestorlink_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealtoinvestorlink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_DealtypeArgs = {
  objects: Array<Homegrown_Dealtype_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Dealtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Dealtype_OneArgs = {
  object: Homegrown_Dealtype_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Dealtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_LocationArgs = {
  objects: Array<Homegrown_Location_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Location_OneArgs = {
  object: Homegrown_Location_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_ReferralcodeArgs = {
  objects: Array<Homegrown_Referralcode_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Referralcode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Referralcode_OneArgs = {
  object: Homegrown_Referralcode_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Referralcode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_SignwelldealdocumentArgs = {
  objects: Array<Homegrown_Signwelldealdocument_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Signwelldealdocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Signwelldealdocument_OneArgs = {
  object: Homegrown_Signwelldealdocument_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Signwelldealdocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_SignwellinvestorsigneddocumentArgs = {
  objects: Array<Homegrown_Signwellinvestorsigneddocument_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Signwellinvestorsigneddocument_OneArgs = {
  object: Homegrown_Signwellinvestorsigneddocument_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_TaxdocumentsArgs = {
  objects: Array<Homegrown_Taxdocuments_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Taxdocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Taxdocuments_OneArgs = {
  object: Homegrown_Taxdocuments_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Taxdocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_UsedreferralcodeArgs = {
  objects: Array<Homegrown_Usedreferralcode_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Usedreferralcode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Usedreferralcode_OneArgs = {
  object: Homegrown_Usedreferralcode_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Usedreferralcode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_UserdocumentsArgs = {
  objects: Array<Homegrown_Userdocuments_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Userdocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Userdocuments_OneArgs = {
  object: Homegrown_Userdocuments_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Userdocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_UserdocumenttypeArgs = {
  objects: Array<Homegrown_Userdocumenttype_Insert_Input>;
  on_conflict?: InputMaybe<Homegrown_Userdocumenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Homegrown_Userdocumenttype_OneArgs = {
  object: Homegrown_Userdocumenttype_Insert_Input;
  on_conflict?: InputMaybe<Homegrown_Userdocumenttype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvestmentsArgs = {
  objects: Array<Investments_Insert_Input>;
  on_conflict?: InputMaybe<Investments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investments_OneArgs = {
  object: Investments_Insert_Input;
  on_conflict?: InputMaybe<Investments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InvestordataArgs = {
  objects: Array<Investordata_Insert_Input>;
  on_conflict?: InputMaybe<Investordata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Investordata_OneArgs = {
  object: Investordata_Insert_Input;
  on_conflict?: InputMaybe<Investordata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_DealbusinessrevenueArgs = {
  objects: Array<Plaid_App_Dealbusinessrevenue_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Dealbusinessrevenue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Dealbusinessrevenue_OneArgs = {
  object: Plaid_App_Dealbusinessrevenue_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Dealbusinessrevenue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_DealpaybackArgs = {
  objects: Array<Plaid_App_Dealpayback_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Dealpayback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Dealpayback_OneArgs = {
  object: Plaid_App_Dealpayback_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Dealpayback_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_DealpayoutArgs = {
  objects: Array<Plaid_App_Dealpayout_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Dealpayout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Dealpayout_OneArgs = {
  object: Plaid_App_Dealpayout_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Dealpayout_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_DealtransferArgs = {
  objects: Array<Plaid_App_Dealtransfer_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Dealtransfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Dealtransfer_OneArgs = {
  object: Plaid_App_Dealtransfer_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Dealtransfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_PlaidlinkArgs = {
  objects: Array<Plaid_App_Plaidlink_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Plaidlink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Plaidlink_OneArgs = {
  object: Plaid_App_Plaidlink_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Plaidlink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_PlaidlinkbusinessArgs = {
  objects: Array<Plaid_App_Plaidlinkbusiness_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Plaidlinkbusiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Plaidlinkbusiness_OneArgs = {
  object: Plaid_App_Plaidlinkbusiness_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Plaidlinkbusiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_PlaidlinkstatusArgs = {
  objects: Array<Plaid_App_Plaidlinkstatus_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Plaidlinkstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Plaidlinkstatus_OneArgs = {
  object: Plaid_App_Plaidlinkstatus_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Plaidlinkstatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_PlaidtransferArgs = {
  objects: Array<Plaid_App_Plaidtransfer_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Plaidtransfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Plaidtransfer_OneArgs = {
  object: Plaid_App_Plaidtransfer_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Plaidtransfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_PlaidtransfereventArgs = {
  objects: Array<Plaid_App_Plaidtransferevent_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Plaidtransferevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Plaidtransferevent_OneArgs = {
  object: Plaid_App_Plaidtransferevent_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Plaidtransferevent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_RevenuetransactionArgs = {
  objects: Array<Plaid_App_Revenuetransaction_Insert_Input>;
  on_conflict?: InputMaybe<Plaid_App_Revenuetransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Plaid_App_Revenuetransaction_OneArgs = {
  object: Plaid_App_Revenuetransaction_Insert_Input;
  on_conflict?: InputMaybe<Plaid_App_Revenuetransaction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Treasuryprime_App_AchtransferArgs = {
  objects: Array<Treasuryprime_App_Achtransfer_Insert_Input>;
  on_conflict?: InputMaybe<Treasuryprime_App_Achtransfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Treasuryprime_App_Achtransfer_OneArgs = {
  object: Treasuryprime_App_Achtransfer_Insert_Input;
  on_conflict?: InputMaybe<Treasuryprime_App_Achtransfer_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Treasuryprime_App_CounterpartylinkArgs = {
  objects: Array<Treasuryprime_App_Counterpartylink_Insert_Input>;
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Treasuryprime_App_Counterpartylink_OneArgs = {
  object: Treasuryprime_App_Counterpartylink_Insert_Input;
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylink_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Treasuryprime_App_CounterpartylinkbusinessArgs = {
  objects: Array<Treasuryprime_App_Counterpartylinkbusiness_Insert_Input>;
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Treasuryprime_App_Counterpartylinkbusiness_OneArgs = {
  object: Treasuryprime_App_Counterpartylinkbusiness_Insert_Input;
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_BusinessownerArgs = {
  objects: Array<Users_Businessowner_Insert_Input>;
  on_conflict?: InputMaybe<Users_Businessowner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Businessowner_OneArgs = {
  object: Users_Businessowner_Insert_Input;
  on_conflict?: InputMaybe<Users_Businessowner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_EmployeeArgs = {
  objects: Array<Users_Employee_Insert_Input>;
  on_conflict?: InputMaybe<Users_Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Employee_OneArgs = {
  object: Users_Employee_Insert_Input;
  on_conflict?: InputMaybe<Users_Employee_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_InvestorbusinessArgs = {
  objects: Array<Users_Investorbusiness_Insert_Input>;
  on_conflict?: InputMaybe<Users_Investorbusiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Investorbusiness_OneArgs = {
  object: Users_Investorbusiness_Insert_Input;
  on_conflict?: InputMaybe<Users_Investorbusiness_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_RoleorderArgs = {
  objects: Array<Users_Roleorder_Insert_Input>;
  on_conflict?: InputMaybe<Users_Roleorder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Roleorder_OneArgs = {
  object: Users_Roleorder_Insert_Input;
  on_conflict?: InputMaybe<Users_Roleorder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_SecondaryinvestordataArgs = {
  objects: Array<Users_Secondaryinvestordata_Insert_Input>;
  on_conflict?: InputMaybe<Users_Secondaryinvestordata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Secondaryinvestordata_OneArgs = {
  object: Users_Secondaryinvestordata_Insert_Input;
  on_conflict?: InputMaybe<Users_Secondaryinvestordata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_SecondaryinvestordocumentsArgs = {
  objects: Array<Users_Secondaryinvestordocuments_Insert_Input>;
  on_conflict?: InputMaybe<Users_Secondaryinvestordocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Secondaryinvestordocuments_OneArgs = {
  object: Users_Secondaryinvestordocuments_Insert_Input;
  on_conflict?: InputMaybe<Users_Secondaryinvestordocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_SecondaryinvestorinviteArgs = {
  objects: Array<Users_Secondaryinvestorinvite_Insert_Input>;
  on_conflict?: InputMaybe<Users_Secondaryinvestorinvite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Secondaryinvestorinvite_OneArgs = {
  object: Users_Secondaryinvestorinvite_Insert_Input;
  on_conflict?: InputMaybe<Users_Secondaryinvestorinvite_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_UserArgs = {
  objects: Array<Users_User_Insert_Input>;
  on_conflict?: InputMaybe<Users_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_User_OneArgs = {
  object: Users_User_Insert_Input;
  on_conflict?: InputMaybe<Users_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_UserinterestsArgs = {
  objects: Array<Users_Userinterests_Insert_Input>;
  on_conflict?: InputMaybe<Users_Userinterests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Userinterests_OneArgs = {
  object: Users_Userinterests_Insert_Input;
  on_conflict?: InputMaybe<Users_Userinterests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_UsernotificationsArgs = {
  objects: Array<Users_Usernotifications_Insert_Input>;
  on_conflict?: InputMaybe<Users_Usernotifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Usernotifications_OneArgs = {
  object: Users_Usernotifications_Insert_Input;
  on_conflict?: InputMaybe<Users_Usernotifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_UserpreferencesArgs = {
  objects: Array<Users_Userpreferences_Insert_Input>;
  on_conflict?: InputMaybe<Users_Userpreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Userpreferences_OneArgs = {
  object: Users_Userpreferences_Insert_Input;
  on_conflict?: InputMaybe<Users_Userpreferences_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_UsersessionArgs = {
  objects: Array<Users_Usersession_Insert_Input>;
  on_conflict?: InputMaybe<Users_Usersession_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Usersession_OneArgs = {
  object: Users_Usersession_Insert_Input;
  on_conflict?: InputMaybe<Users_Usersession_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdateBucketArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  pk_columns: Buckets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateBucketsArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  where: Buckets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdateFileArgs = {
  _inc?: InputMaybe<Files_Inc_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdateFilesArgs = {
  _inc?: InputMaybe<Files_Inc_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Buckets_ManyArgs = {
  updates: Array<Buckets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_BusinessArgs = {
  _inc?: InputMaybe<Homegrown_Business_Inc_Input>;
  _set?: InputMaybe<Homegrown_Business_Set_Input>;
  where: Homegrown_Business_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Business_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Business_Inc_Input>;
  _set?: InputMaybe<Homegrown_Business_Set_Input>;
  pk_columns: Homegrown_Business_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Business_ManyArgs = {
  updates: Array<Homegrown_Business_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_BusinessprivatedocumentsArgs = {
  _inc?: InputMaybe<Homegrown_Businessprivatedocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Businessprivatedocuments_Set_Input>;
  where: Homegrown_Businessprivatedocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Businessprivatedocuments_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Businessprivatedocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Businessprivatedocuments_Set_Input>;
  pk_columns: Homegrown_Businessprivatedocuments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Businessprivatedocuments_ManyArgs = {
  updates: Array<Homegrown_Businessprivatedocuments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealArgs = {
  _append?: InputMaybe<Homegrown_Deal_Append_Input>;
  _delete_at_path?: InputMaybe<Homegrown_Deal_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Homegrown_Deal_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Homegrown_Deal_Delete_Key_Input>;
  _inc?: InputMaybe<Homegrown_Deal_Inc_Input>;
  _prepend?: InputMaybe<Homegrown_Deal_Prepend_Input>;
  _set?: InputMaybe<Homegrown_Deal_Set_Input>;
  where: Homegrown_Deal_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Deal_By_PkArgs = {
  _append?: InputMaybe<Homegrown_Deal_Append_Input>;
  _delete_at_path?: InputMaybe<Homegrown_Deal_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Homegrown_Deal_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Homegrown_Deal_Delete_Key_Input>;
  _inc?: InputMaybe<Homegrown_Deal_Inc_Input>;
  _prepend?: InputMaybe<Homegrown_Deal_Prepend_Input>;
  _set?: InputMaybe<Homegrown_Deal_Set_Input>;
  pk_columns: Homegrown_Deal_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Deal_ManyArgs = {
  updates: Array<Homegrown_Deal_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealdocumentArgs = {
  _inc?: InputMaybe<Homegrown_Dealdocument_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealdocument_Set_Input>;
  where: Homegrown_Dealdocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealdocument_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Dealdocument_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealdocument_Set_Input>;
  pk_columns: Homegrown_Dealdocument_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealdocument_ManyArgs = {
  updates: Array<Homegrown_Dealdocument_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealprivateArgs = {
  _inc?: InputMaybe<Homegrown_Dealprivate_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealprivate_Set_Input>;
  where: Homegrown_Dealprivate_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivate_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Dealprivate_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealprivate_Set_Input>;
  pk_columns: Homegrown_Dealprivate_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivate_ManyArgs = {
  updates: Array<Homegrown_Dealprivate_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealprivatedocumentsArgs = {
  _inc?: InputMaybe<Homegrown_Dealprivatedocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealprivatedocuments_Set_Input>;
  where: Homegrown_Dealprivatedocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivatedocuments_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Dealprivatedocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealprivatedocuments_Set_Input>;
  pk_columns: Homegrown_Dealprivatedocuments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivatedocuments_ManyArgs = {
  updates: Array<Homegrown_Dealprivatedocuments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealprivatedocumentstatusArgs = {
  _set?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Set_Input>;
  where: Homegrown_Dealprivatedocumentstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivatedocumentstatus_By_PkArgs = {
  _set?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Set_Input>;
  pk_columns: Homegrown_Dealprivatedocumentstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivatedocumentstatus_ManyArgs = {
  updates: Array<Homegrown_Dealprivatedocumentstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealprivatedocumenttypeArgs = {
  _set?: InputMaybe<Homegrown_Dealprivatedocumenttype_Set_Input>;
  where: Homegrown_Dealprivatedocumenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivatedocumenttype_By_PkArgs = {
  _set?: InputMaybe<Homegrown_Dealprivatedocumenttype_Set_Input>;
  pk_columns: Homegrown_Dealprivatedocumenttype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealprivatedocumenttype_ManyArgs = {
  updates: Array<Homegrown_Dealprivatedocumenttype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealtobusinessplaidlinkArgs = {
  _inc?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Set_Input>;
  where: Homegrown_Dealtobusinessplaidlink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealtobusinessplaidlink_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Set_Input>;
  pk_columns: Homegrown_Dealtobusinessplaidlink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealtobusinessplaidlink_ManyArgs = {
  updates: Array<Homegrown_Dealtobusinessplaidlink_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealtoinvestorlinkArgs = {
  _inc?: InputMaybe<Homegrown_Dealtoinvestorlink_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealtoinvestorlink_Set_Input>;
  where: Homegrown_Dealtoinvestorlink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealtoinvestorlink_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Dealtoinvestorlink_Inc_Input>;
  _set?: InputMaybe<Homegrown_Dealtoinvestorlink_Set_Input>;
  pk_columns: Homegrown_Dealtoinvestorlink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealtoinvestorlink_ManyArgs = {
  updates: Array<Homegrown_Dealtoinvestorlink_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_DealtypeArgs = {
  _set?: InputMaybe<Homegrown_Dealtype_Set_Input>;
  where: Homegrown_Dealtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealtype_By_PkArgs = {
  _set?: InputMaybe<Homegrown_Dealtype_Set_Input>;
  pk_columns: Homegrown_Dealtype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Dealtype_ManyArgs = {
  updates: Array<Homegrown_Dealtype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_LocationArgs = {
  _inc?: InputMaybe<Homegrown_Location_Inc_Input>;
  _set?: InputMaybe<Homegrown_Location_Set_Input>;
  where: Homegrown_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Location_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Location_Inc_Input>;
  _set?: InputMaybe<Homegrown_Location_Set_Input>;
  pk_columns: Homegrown_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Location_ManyArgs = {
  updates: Array<Homegrown_Location_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_ReferralcodeArgs = {
  _set?: InputMaybe<Homegrown_Referralcode_Set_Input>;
  where: Homegrown_Referralcode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Referralcode_By_PkArgs = {
  _set?: InputMaybe<Homegrown_Referralcode_Set_Input>;
  pk_columns: Homegrown_Referralcode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Referralcode_ManyArgs = {
  updates: Array<Homegrown_Referralcode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_SignwelldealdocumentArgs = {
  _inc?: InputMaybe<Homegrown_Signwelldealdocument_Inc_Input>;
  _set?: InputMaybe<Homegrown_Signwelldealdocument_Set_Input>;
  where: Homegrown_Signwelldealdocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Signwelldealdocument_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Signwelldealdocument_Inc_Input>;
  _set?: InputMaybe<Homegrown_Signwelldealdocument_Set_Input>;
  pk_columns: Homegrown_Signwelldealdocument_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Signwelldealdocument_ManyArgs = {
  updates: Array<Homegrown_Signwelldealdocument_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_SignwellinvestorsigneddocumentArgs = {
  _inc?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Inc_Input>;
  _set?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Set_Input>;
  where: Homegrown_Signwellinvestorsigneddocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Signwellinvestorsigneddocument_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Inc_Input>;
  _set?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Set_Input>;
  pk_columns: Homegrown_Signwellinvestorsigneddocument_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Signwellinvestorsigneddocument_ManyArgs = {
  updates: Array<Homegrown_Signwellinvestorsigneddocument_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_TaxdocumentsArgs = {
  _inc?: InputMaybe<Homegrown_Taxdocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Taxdocuments_Set_Input>;
  where: Homegrown_Taxdocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Taxdocuments_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Taxdocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Taxdocuments_Set_Input>;
  pk_columns: Homegrown_Taxdocuments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Taxdocuments_ManyArgs = {
  updates: Array<Homegrown_Taxdocuments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_UsedreferralcodeArgs = {
  _set?: InputMaybe<Homegrown_Usedreferralcode_Set_Input>;
  where: Homegrown_Usedreferralcode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Usedreferralcode_By_PkArgs = {
  _set?: InputMaybe<Homegrown_Usedreferralcode_Set_Input>;
  pk_columns: Homegrown_Usedreferralcode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Usedreferralcode_ManyArgs = {
  updates: Array<Homegrown_Usedreferralcode_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_UserdocumentsArgs = {
  _inc?: InputMaybe<Homegrown_Userdocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Userdocuments_Set_Input>;
  where: Homegrown_Userdocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Userdocuments_By_PkArgs = {
  _inc?: InputMaybe<Homegrown_Userdocuments_Inc_Input>;
  _set?: InputMaybe<Homegrown_Userdocuments_Set_Input>;
  pk_columns: Homegrown_Userdocuments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Userdocuments_ManyArgs = {
  updates: Array<Homegrown_Userdocuments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_UserdocumenttypeArgs = {
  _set?: InputMaybe<Homegrown_Userdocumenttype_Set_Input>;
  where: Homegrown_Userdocumenttype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Userdocumenttype_By_PkArgs = {
  _set?: InputMaybe<Homegrown_Userdocumenttype_Set_Input>;
  pk_columns: Homegrown_Userdocumenttype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Homegrown_Userdocumenttype_ManyArgs = {
  updates: Array<Homegrown_Userdocumenttype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvestmentsArgs = {
  _inc?: InputMaybe<Investments_Inc_Input>;
  _set?: InputMaybe<Investments_Set_Input>;
  where: Investments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investments_By_PkArgs = {
  _inc?: InputMaybe<Investments_Inc_Input>;
  _set?: InputMaybe<Investments_Set_Input>;
  pk_columns: Investments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investments_ManyArgs = {
  updates: Array<Investments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InvestordataArgs = {
  _append?: InputMaybe<Investordata_Append_Input>;
  _delete_at_path?: InputMaybe<Investordata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investordata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investordata_Delete_Key_Input>;
  _inc?: InputMaybe<Investordata_Inc_Input>;
  _prepend?: InputMaybe<Investordata_Prepend_Input>;
  _set?: InputMaybe<Investordata_Set_Input>;
  where: Investordata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Investordata_By_PkArgs = {
  _append?: InputMaybe<Investordata_Append_Input>;
  _delete_at_path?: InputMaybe<Investordata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Investordata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Investordata_Delete_Key_Input>;
  _inc?: InputMaybe<Investordata_Inc_Input>;
  _prepend?: InputMaybe<Investordata_Prepend_Input>;
  _set?: InputMaybe<Investordata_Set_Input>;
  pk_columns: Investordata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Investordata_ManyArgs = {
  updates: Array<Investordata_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_DealbusinessrevenueArgs = {
  _inc?: InputMaybe<Plaid_App_Dealbusinessrevenue_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealbusinessrevenue_Set_Input>;
  where: Plaid_App_Dealbusinessrevenue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealbusinessrevenue_By_PkArgs = {
  _inc?: InputMaybe<Plaid_App_Dealbusinessrevenue_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealbusinessrevenue_Set_Input>;
  pk_columns: Plaid_App_Dealbusinessrevenue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealbusinessrevenue_ManyArgs = {
  updates: Array<Plaid_App_Dealbusinessrevenue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_DealpaybackArgs = {
  _inc?: InputMaybe<Plaid_App_Dealpayback_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealpayback_Set_Input>;
  where: Plaid_App_Dealpayback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealpayback_By_PkArgs = {
  _inc?: InputMaybe<Plaid_App_Dealpayback_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealpayback_Set_Input>;
  pk_columns: Plaid_App_Dealpayback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealpayback_ManyArgs = {
  updates: Array<Plaid_App_Dealpayback_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_DealpayoutArgs = {
  _inc?: InputMaybe<Plaid_App_Dealpayout_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealpayout_Set_Input>;
  where: Plaid_App_Dealpayout_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealpayout_By_PkArgs = {
  _inc?: InputMaybe<Plaid_App_Dealpayout_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealpayout_Set_Input>;
  pk_columns: Plaid_App_Dealpayout_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealpayout_ManyArgs = {
  updates: Array<Plaid_App_Dealpayout_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_DealtransferArgs = {
  _inc?: InputMaybe<Plaid_App_Dealtransfer_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealtransfer_Set_Input>;
  where: Plaid_App_Dealtransfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealtransfer_By_PkArgs = {
  _inc?: InputMaybe<Plaid_App_Dealtransfer_Inc_Input>;
  _set?: InputMaybe<Plaid_App_Dealtransfer_Set_Input>;
  pk_columns: Plaid_App_Dealtransfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Dealtransfer_ManyArgs = {
  updates: Array<Plaid_App_Dealtransfer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_PlaidlinkArgs = {
  _append?: InputMaybe<Plaid_App_Plaidlink_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidlink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidlink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidlink_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidlink_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidlink_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidlink_Set_Input>;
  where: Plaid_App_Plaidlink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidlink_By_PkArgs = {
  _append?: InputMaybe<Plaid_App_Plaidlink_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidlink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidlink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidlink_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidlink_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidlink_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidlink_Set_Input>;
  pk_columns: Plaid_App_Plaidlink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidlink_ManyArgs = {
  updates: Array<Plaid_App_Plaidlink_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_PlaidlinkbusinessArgs = {
  _append?: InputMaybe<Plaid_App_Plaidlinkbusiness_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidlinkbusiness_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidlinkbusiness_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidlinkbusiness_Set_Input>;
  where: Plaid_App_Plaidlinkbusiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidlinkbusiness_By_PkArgs = {
  _append?: InputMaybe<Plaid_App_Plaidlinkbusiness_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidlinkbusiness_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidlinkbusiness_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidlinkbusiness_Set_Input>;
  pk_columns: Plaid_App_Plaidlinkbusiness_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidlinkbusiness_ManyArgs = {
  updates: Array<Plaid_App_Plaidlinkbusiness_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_PlaidlinkstatusArgs = {
  _set?: InputMaybe<Plaid_App_Plaidlinkstatus_Set_Input>;
  where: Plaid_App_Plaidlinkstatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidlinkstatus_By_PkArgs = {
  _set?: InputMaybe<Plaid_App_Plaidlinkstatus_Set_Input>;
  pk_columns: Plaid_App_Plaidlinkstatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidlinkstatus_ManyArgs = {
  updates: Array<Plaid_App_Plaidlinkstatus_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_PlaidtransferArgs = {
  _append?: InputMaybe<Plaid_App_Plaidtransfer_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidtransfer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidtransfer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidtransfer_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidtransfer_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidtransfer_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidtransfer_Set_Input>;
  where: Plaid_App_Plaidtransfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidtransfer_By_PkArgs = {
  _append?: InputMaybe<Plaid_App_Plaidtransfer_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidtransfer_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidtransfer_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidtransfer_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidtransfer_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidtransfer_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidtransfer_Set_Input>;
  pk_columns: Plaid_App_Plaidtransfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidtransfer_ManyArgs = {
  updates: Array<Plaid_App_Plaidtransfer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_PlaidtransfereventArgs = {
  _append?: InputMaybe<Plaid_App_Plaidtransferevent_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidtransferevent_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidtransferevent_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidtransferevent_Set_Input>;
  where: Plaid_App_Plaidtransferevent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidtransferevent_By_PkArgs = {
  _append?: InputMaybe<Plaid_App_Plaidtransferevent_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Plaidtransferevent_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Plaidtransferevent_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Plaidtransferevent_Set_Input>;
  pk_columns: Plaid_App_Plaidtransferevent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Plaidtransferevent_ManyArgs = {
  updates: Array<Plaid_App_Plaidtransferevent_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_RevenuetransactionArgs = {
  _append?: InputMaybe<Plaid_App_Revenuetransaction_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Revenuetransaction_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Revenuetransaction_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Revenuetransaction_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Revenuetransaction_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Revenuetransaction_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Revenuetransaction_Set_Input>;
  where: Plaid_App_Revenuetransaction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Revenuetransaction_By_PkArgs = {
  _append?: InputMaybe<Plaid_App_Revenuetransaction_Append_Input>;
  _delete_at_path?: InputMaybe<Plaid_App_Revenuetransaction_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Plaid_App_Revenuetransaction_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Plaid_App_Revenuetransaction_Delete_Key_Input>;
  _inc?: InputMaybe<Plaid_App_Revenuetransaction_Inc_Input>;
  _prepend?: InputMaybe<Plaid_App_Revenuetransaction_Prepend_Input>;
  _set?: InputMaybe<Plaid_App_Revenuetransaction_Set_Input>;
  pk_columns: Plaid_App_Revenuetransaction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Plaid_App_Revenuetransaction_ManyArgs = {
  updates: Array<Plaid_App_Revenuetransaction_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_AchtransferArgs = {
  _inc?: InputMaybe<Treasuryprime_App_Achtransfer_Inc_Input>;
  _set?: InputMaybe<Treasuryprime_App_Achtransfer_Set_Input>;
  where: Treasuryprime_App_Achtransfer_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_Achtransfer_By_PkArgs = {
  _inc?: InputMaybe<Treasuryprime_App_Achtransfer_Inc_Input>;
  _set?: InputMaybe<Treasuryprime_App_Achtransfer_Set_Input>;
  pk_columns: Treasuryprime_App_Achtransfer_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_Achtransfer_ManyArgs = {
  updates: Array<Treasuryprime_App_Achtransfer_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_CounterpartylinkArgs = {
  _append?: InputMaybe<Treasuryprime_App_Counterpartylink_Append_Input>;
  _delete_at_path?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_Key_Input>;
  _prepend?: InputMaybe<Treasuryprime_App_Counterpartylink_Prepend_Input>;
  _set?: InputMaybe<Treasuryprime_App_Counterpartylink_Set_Input>;
  where: Treasuryprime_App_Counterpartylink_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_Counterpartylink_By_PkArgs = {
  _append?: InputMaybe<Treasuryprime_App_Counterpartylink_Append_Input>;
  _delete_at_path?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_Key_Input>;
  _prepend?: InputMaybe<Treasuryprime_App_Counterpartylink_Prepend_Input>;
  _set?: InputMaybe<Treasuryprime_App_Counterpartylink_Set_Input>;
  pk_columns: Treasuryprime_App_Counterpartylink_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_Counterpartylink_ManyArgs = {
  updates: Array<Treasuryprime_App_Counterpartylink_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_CounterpartylinkbusinessArgs = {
  _append?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Append_Input>;
  _delete_at_path?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_Key_Input>;
  _prepend?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Prepend_Input>;
  _set?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Set_Input>;
  where: Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_Counterpartylinkbusiness_By_PkArgs = {
  _append?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Append_Input>;
  _delete_at_path?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_Key_Input>;
  _prepend?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Prepend_Input>;
  _set?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Set_Input>;
  pk_columns: Treasuryprime_App_Counterpartylinkbusiness_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Treasuryprime_App_Counterpartylinkbusiness_ManyArgs = {
  updates: Array<Treasuryprime_App_Counterpartylinkbusiness_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_BusinessownerArgs = {
  _append?: InputMaybe<Users_Businessowner_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Businessowner_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Businessowner_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Businessowner_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Businessowner_Inc_Input>;
  _prepend?: InputMaybe<Users_Businessowner_Prepend_Input>;
  _set?: InputMaybe<Users_Businessowner_Set_Input>;
  where: Users_Businessowner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Businessowner_By_PkArgs = {
  _append?: InputMaybe<Users_Businessowner_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Businessowner_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Businessowner_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Businessowner_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Businessowner_Inc_Input>;
  _prepend?: InputMaybe<Users_Businessowner_Prepend_Input>;
  _set?: InputMaybe<Users_Businessowner_Set_Input>;
  pk_columns: Users_Businessowner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Businessowner_ManyArgs = {
  updates: Array<Users_Businessowner_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_EmployeeArgs = {
  _inc?: InputMaybe<Users_Employee_Inc_Input>;
  _set?: InputMaybe<Users_Employee_Set_Input>;
  where: Users_Employee_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Employee_By_PkArgs = {
  _inc?: InputMaybe<Users_Employee_Inc_Input>;
  _set?: InputMaybe<Users_Employee_Set_Input>;
  pk_columns: Users_Employee_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Employee_ManyArgs = {
  updates: Array<Users_Employee_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_InvestorbusinessArgs = {
  _append?: InputMaybe<Users_Investorbusiness_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Investorbusiness_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Investorbusiness_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Investorbusiness_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Investorbusiness_Inc_Input>;
  _prepend?: InputMaybe<Users_Investorbusiness_Prepend_Input>;
  _set?: InputMaybe<Users_Investorbusiness_Set_Input>;
  where: Users_Investorbusiness_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Investorbusiness_By_PkArgs = {
  _append?: InputMaybe<Users_Investorbusiness_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Investorbusiness_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Investorbusiness_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Investorbusiness_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Investorbusiness_Inc_Input>;
  _prepend?: InputMaybe<Users_Investorbusiness_Prepend_Input>;
  _set?: InputMaybe<Users_Investorbusiness_Set_Input>;
  pk_columns: Users_Investorbusiness_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Investorbusiness_ManyArgs = {
  updates: Array<Users_Investorbusiness_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_RoleorderArgs = {
  _inc?: InputMaybe<Users_Roleorder_Inc_Input>;
  _set?: InputMaybe<Users_Roleorder_Set_Input>;
  where: Users_Roleorder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Roleorder_By_PkArgs = {
  _inc?: InputMaybe<Users_Roleorder_Inc_Input>;
  _set?: InputMaybe<Users_Roleorder_Set_Input>;
  pk_columns: Users_Roleorder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Roleorder_ManyArgs = {
  updates: Array<Users_Roleorder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_SecondaryinvestordataArgs = {
  _append?: InputMaybe<Users_Secondaryinvestordata_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Secondaryinvestordata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Secondaryinvestordata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Secondaryinvestordata_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Secondaryinvestordata_Inc_Input>;
  _prepend?: InputMaybe<Users_Secondaryinvestordata_Prepend_Input>;
  _set?: InputMaybe<Users_Secondaryinvestordata_Set_Input>;
  where: Users_Secondaryinvestordata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Secondaryinvestordata_By_PkArgs = {
  _append?: InputMaybe<Users_Secondaryinvestordata_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Secondaryinvestordata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Secondaryinvestordata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Secondaryinvestordata_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Secondaryinvestordata_Inc_Input>;
  _prepend?: InputMaybe<Users_Secondaryinvestordata_Prepend_Input>;
  _set?: InputMaybe<Users_Secondaryinvestordata_Set_Input>;
  pk_columns: Users_Secondaryinvestordata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Secondaryinvestordata_ManyArgs = {
  updates: Array<Users_Secondaryinvestordata_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_SecondaryinvestordocumentsArgs = {
  _inc?: InputMaybe<Users_Secondaryinvestordocuments_Inc_Input>;
  _set?: InputMaybe<Users_Secondaryinvestordocuments_Set_Input>;
  where: Users_Secondaryinvestordocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Secondaryinvestordocuments_By_PkArgs = {
  _inc?: InputMaybe<Users_Secondaryinvestordocuments_Inc_Input>;
  _set?: InputMaybe<Users_Secondaryinvestordocuments_Set_Input>;
  pk_columns: Users_Secondaryinvestordocuments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Secondaryinvestordocuments_ManyArgs = {
  updates: Array<Users_Secondaryinvestordocuments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_SecondaryinvestorinviteArgs = {
  _inc?: InputMaybe<Users_Secondaryinvestorinvite_Inc_Input>;
  _set?: InputMaybe<Users_Secondaryinvestorinvite_Set_Input>;
  where: Users_Secondaryinvestorinvite_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Secondaryinvestorinvite_By_PkArgs = {
  _inc?: InputMaybe<Users_Secondaryinvestorinvite_Inc_Input>;
  _set?: InputMaybe<Users_Secondaryinvestorinvite_Set_Input>;
  pk_columns: Users_Secondaryinvestorinvite_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Secondaryinvestorinvite_ManyArgs = {
  updates: Array<Users_Secondaryinvestorinvite_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_UserArgs = {
  _set?: InputMaybe<Users_User_Set_Input>;
  where: Users_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_User_By_PkArgs = {
  _set?: InputMaybe<Users_User_Set_Input>;
  pk_columns: Users_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_User_ManyArgs = {
  updates: Array<Users_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_UserinterestsArgs = {
  _inc?: InputMaybe<Users_Userinterests_Inc_Input>;
  _set?: InputMaybe<Users_Userinterests_Set_Input>;
  where: Users_Userinterests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Userinterests_By_PkArgs = {
  _inc?: InputMaybe<Users_Userinterests_Inc_Input>;
  _set?: InputMaybe<Users_Userinterests_Set_Input>;
  pk_columns: Users_Userinterests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Userinterests_ManyArgs = {
  updates: Array<Users_Userinterests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_UsernotificationsArgs = {
  _append?: InputMaybe<Users_Usernotifications_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Usernotifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Usernotifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Usernotifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Usernotifications_Prepend_Input>;
  _set?: InputMaybe<Users_Usernotifications_Set_Input>;
  where: Users_Usernotifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Usernotifications_By_PkArgs = {
  _append?: InputMaybe<Users_Usernotifications_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Usernotifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Usernotifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Usernotifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Usernotifications_Prepend_Input>;
  _set?: InputMaybe<Users_Usernotifications_Set_Input>;
  pk_columns: Users_Usernotifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Usernotifications_ManyArgs = {
  updates: Array<Users_Usernotifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_UserpreferencesArgs = {
  _append?: InputMaybe<Users_Userpreferences_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Userpreferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Userpreferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Userpreferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Userpreferences_Prepend_Input>;
  _set?: InputMaybe<Users_Userpreferences_Set_Input>;
  where: Users_Userpreferences_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Userpreferences_By_PkArgs = {
  _append?: InputMaybe<Users_Userpreferences_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Userpreferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Userpreferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Userpreferences_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Userpreferences_Prepend_Input>;
  _set?: InputMaybe<Users_Userpreferences_Set_Input>;
  pk_columns: Users_Userpreferences_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Userpreferences_ManyArgs = {
  updates: Array<Users_Userpreferences_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Users_UsersessionArgs = {
  _append?: InputMaybe<Users_Usersession_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Usersession_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Usersession_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Usersession_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Usersession_Inc_Input>;
  _prepend?: InputMaybe<Users_Usersession_Prepend_Input>;
  _set?: InputMaybe<Users_Usersession_Set_Input>;
  where: Users_Usersession_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Usersession_By_PkArgs = {
  _append?: InputMaybe<Users_Usersession_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Usersession_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Usersession_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Usersession_Delete_Key_Input>;
  _inc?: InputMaybe<Users_Usersession_Inc_Input>;
  _prepend?: InputMaybe<Users_Usersession_Prepend_Input>;
  _set?: InputMaybe<Users_Usersession_Set_Input>;
  pk_columns: Users_Usersession_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_Usersession_ManyArgs = {
  updates: Array<Users_Usersession_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue = {
  __typename?: 'plaid_app_dealbusinessrevenue';
  accepted_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  accepted_by_user?: Maybe<Users_User>;
  accepted_payment: Scalars['numeric']['output'];
  accepted_revenue: Scalars['numeric']['output'];
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  deal_payback_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  dealpayback?: Maybe<Plaid_App_Dealpayback>;
  /** An object relationship */
  file?: Maybe<Files>;
  holdback_rate: Scalars['numeric']['output'];
  id: Scalars['bigint']['output'];
  initial_estimated_payment: Scalars['numeric']['output'];
  initial_estimated_revenue: Scalars['numeric']['output'];
  month: Scalars['date']['output'];
  pdf_report_file_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  revenuetransactions: Array<Plaid_App_Revenuetransaction>;
  /** An aggregate relationship */
  revenuetransactions_aggregate: Plaid_App_Revenuetransaction_Aggregate;
  status: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "plaid_app_dealbusinessrevenue" */
export type Plaid_App_DealbusinessrevenueRevenuetransactionsArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Revenuetransaction_Order_By>>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};


/** columns and relationships of "plaid_app_dealbusinessrevenue" */
export type Plaid_App_DealbusinessrevenueRevenuetransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Revenuetransaction_Order_By>>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};

/** aggregated selection of "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Aggregate = {
  __typename?: 'plaid_app_dealbusinessrevenue_aggregate';
  aggregate?: Maybe<Plaid_App_Dealbusinessrevenue_Aggregate_Fields>;
  nodes: Array<Plaid_App_Dealbusinessrevenue>;
};

export type Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Count>;
};

export type Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plaid_App_Dealbusinessrevenue_Select_Column_Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plaid_App_Dealbusinessrevenue_Select_Column_Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Aggregate_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_aggregate_fields';
  avg?: Maybe<Plaid_App_Dealbusinessrevenue_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Dealbusinessrevenue_Max_Fields>;
  min?: Maybe<Plaid_App_Dealbusinessrevenue_Min_Fields>;
  stddev?: Maybe<Plaid_App_Dealbusinessrevenue_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Dealbusinessrevenue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Dealbusinessrevenue_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Dealbusinessrevenue_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Dealbusinessrevenue_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Dealbusinessrevenue_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Dealbusinessrevenue_Variance_Fields>;
};


/** aggregate fields of "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Aggregate_Order_By = {
  avg?: InputMaybe<Plaid_App_Dealbusinessrevenue_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plaid_App_Dealbusinessrevenue_Max_Order_By>;
  min?: InputMaybe<Plaid_App_Dealbusinessrevenue_Min_Order_By>;
  stddev?: InputMaybe<Plaid_App_Dealbusinessrevenue_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plaid_App_Dealbusinessrevenue_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plaid_App_Dealbusinessrevenue_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plaid_App_Dealbusinessrevenue_Sum_Order_By>;
  var_pop?: InputMaybe<Plaid_App_Dealbusinessrevenue_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plaid_App_Dealbusinessrevenue_Var_Samp_Order_By>;
  variance?: InputMaybe<Plaid_App_Dealbusinessrevenue_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Arr_Rel_Insert_Input = {
  data: Array<Plaid_App_Dealbusinessrevenue_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Dealbusinessrevenue_On_Conflict>;
};

/** aggregate avg on columns */
export type Plaid_App_Dealbusinessrevenue_Avg_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_avg_fields';
  accepted_payment?: Maybe<Scalars['Float']['output']>;
  accepted_revenue?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  deal_payback_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  initial_estimated_payment?: Maybe<Scalars['Float']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Avg_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_dealbusinessrevenue". All fields are combined with a logical 'AND'. */
export type Plaid_App_Dealbusinessrevenue_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Bool_Exp>>;
  accepted_by_id?: InputMaybe<String_Comparison_Exp>;
  accepted_by_user?: InputMaybe<Users_User_Bool_Exp>;
  accepted_payment?: InputMaybe<Numeric_Comparison_Exp>;
  accepted_revenue?: InputMaybe<Numeric_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  deal_payback_id?: InputMaybe<Bigint_Comparison_Exp>;
  dealpayback?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  holdback_rate?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  initial_estimated_payment?: InputMaybe<Numeric_Comparison_Exp>;
  initial_estimated_revenue?: InputMaybe<Numeric_Comparison_Exp>;
  month?: InputMaybe<Date_Comparison_Exp>;
  pdf_report_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  revenuetransactions?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
  revenuetransactions_aggregate?: InputMaybe<Plaid_App_Revenuetransaction_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_dealbusinessrevenue" */
export enum Plaid_App_Dealbusinessrevenue_Constraint {
  /** unique or primary key constraint on columns "month", "deal_id" */
  DealRevenueUniq = 'deal_revenue_uniq',
  /** unique or primary key constraint on columns "pdf_report_file_id" */
  PlaidAppDealbusinessrevenuePdfReportFileIdKey = 'plaid_app_dealbusinessrevenue_pdf_report_file_id_key',
  /** unique or primary key constraint on columns "id" */
  PlaidAppDealbusinessrevenuePkey = 'plaid_app_dealbusinessrevenue_pkey'
}

/** input type for incrementing numeric columns in table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Inc_Input = {
  accepted_payment?: InputMaybe<Scalars['numeric']['input']>;
  accepted_revenue?: InputMaybe<Scalars['numeric']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  deal_payback_id?: InputMaybe<Scalars['bigint']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  initial_estimated_payment?: InputMaybe<Scalars['numeric']['input']>;
  initial_estimated_revenue?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Insert_Input = {
  accepted_by_id?: InputMaybe<Scalars['String']['input']>;
  accepted_by_user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  accepted_payment?: InputMaybe<Scalars['numeric']['input']>;
  accepted_revenue?: InputMaybe<Scalars['numeric']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  deal_payback_id?: InputMaybe<Scalars['bigint']['input']>;
  dealpayback?: InputMaybe<Plaid_App_Dealpayback_Obj_Rel_Insert_Input>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  initial_estimated_payment?: InputMaybe<Scalars['numeric']['input']>;
  initial_estimated_revenue?: InputMaybe<Scalars['numeric']['input']>;
  month?: InputMaybe<Scalars['date']['input']>;
  pdf_report_file_id?: InputMaybe<Scalars['uuid']['input']>;
  revenuetransactions?: InputMaybe<Plaid_App_Revenuetransaction_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Dealbusinessrevenue_Max_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_max_fields';
  accepted_by_id?: Maybe<Scalars['String']['output']>;
  accepted_payment?: Maybe<Scalars['numeric']['output']>;
  accepted_revenue?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  deal_payback_id?: Maybe<Scalars['bigint']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  initial_estimated_payment?: Maybe<Scalars['numeric']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['date']['output']>;
  pdf_report_file_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Max_Order_By = {
  accepted_by_id?: InputMaybe<Order_By>;
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  pdf_report_file_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plaid_App_Dealbusinessrevenue_Min_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_min_fields';
  accepted_by_id?: Maybe<Scalars['String']['output']>;
  accepted_payment?: Maybe<Scalars['numeric']['output']>;
  accepted_revenue?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  deal_payback_id?: Maybe<Scalars['bigint']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  initial_estimated_payment?: Maybe<Scalars['numeric']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['numeric']['output']>;
  month?: Maybe<Scalars['date']['output']>;
  pdf_report_file_id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Min_Order_By = {
  accepted_by_id?: InputMaybe<Order_By>;
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  pdf_report_file_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Mutation_Response = {
  __typename?: 'plaid_app_dealbusinessrevenue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Dealbusinessrevenue>;
};

/** input type for inserting object relation for remote table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Obj_Rel_Insert_Input = {
  data: Plaid_App_Dealbusinessrevenue_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Dealbusinessrevenue_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_On_Conflict = {
  constraint: Plaid_App_Dealbusinessrevenue_Constraint;
  update_columns?: Array<Plaid_App_Dealbusinessrevenue_Update_Column>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_dealbusinessrevenue". */
export type Plaid_App_Dealbusinessrevenue_Order_By = {
  accepted_by_id?: InputMaybe<Order_By>;
  accepted_by_user?: InputMaybe<Users_User_Order_By>;
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  dealpayback?: InputMaybe<Plaid_App_Dealpayback_Order_By>;
  file?: InputMaybe<Files_Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  pdf_report_file_id?: InputMaybe<Order_By>;
  revenuetransactions_aggregate?: InputMaybe<Plaid_App_Revenuetransaction_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_dealbusinessrevenue */
export type Plaid_App_Dealbusinessrevenue_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "plaid_app_dealbusinessrevenue" */
export enum Plaid_App_Dealbusinessrevenue_Select_Column {
  /** column name */
  AcceptedById = 'accepted_by_id',
  /** column name */
  AcceptedPayment = 'accepted_payment',
  /** column name */
  AcceptedRevenue = 'accepted_revenue',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DealPaybackId = 'deal_payback_id',
  /** column name */
  HoldbackRate = 'holdback_rate',
  /** column name */
  Id = 'id',
  /** column name */
  InitialEstimatedPayment = 'initial_estimated_payment',
  /** column name */
  InitialEstimatedRevenue = 'initial_estimated_revenue',
  /** column name */
  Month = 'month',
  /** column name */
  PdfReportFileId = 'pdf_report_file_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "plaid_app_dealbusinessrevenue_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plaid_app_dealbusinessrevenue" */
export enum Plaid_App_Dealbusinessrevenue_Select_Column_Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "plaid_app_dealbusinessrevenue_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plaid_app_dealbusinessrevenue" */
export enum Plaid_App_Dealbusinessrevenue_Select_Column_Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Set_Input = {
  accepted_by_id?: InputMaybe<Scalars['String']['input']>;
  accepted_payment?: InputMaybe<Scalars['numeric']['input']>;
  accepted_revenue?: InputMaybe<Scalars['numeric']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  deal_payback_id?: InputMaybe<Scalars['bigint']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  initial_estimated_payment?: InputMaybe<Scalars['numeric']['input']>;
  initial_estimated_revenue?: InputMaybe<Scalars['numeric']['input']>;
  month?: InputMaybe<Scalars['date']['input']>;
  pdf_report_file_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Dealbusinessrevenue_Stddev_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_stddev_fields';
  accepted_payment?: Maybe<Scalars['Float']['output']>;
  accepted_revenue?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  deal_payback_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  initial_estimated_payment?: Maybe<Scalars['Float']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Stddev_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Dealbusinessrevenue_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_stddev_pop_fields';
  accepted_payment?: Maybe<Scalars['Float']['output']>;
  accepted_revenue?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  deal_payback_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  initial_estimated_payment?: Maybe<Scalars['Float']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Stddev_Pop_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Dealbusinessrevenue_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_stddev_samp_fields';
  accepted_payment?: Maybe<Scalars['Float']['output']>;
  accepted_revenue?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  deal_payback_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  initial_estimated_payment?: Maybe<Scalars['Float']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Stddev_Samp_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Dealbusinessrevenue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Dealbusinessrevenue_Stream_Cursor_Value_Input = {
  accepted_by_id?: InputMaybe<Scalars['String']['input']>;
  accepted_payment?: InputMaybe<Scalars['numeric']['input']>;
  accepted_revenue?: InputMaybe<Scalars['numeric']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  deal_payback_id?: InputMaybe<Scalars['bigint']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  initial_estimated_payment?: InputMaybe<Scalars['numeric']['input']>;
  initial_estimated_revenue?: InputMaybe<Scalars['numeric']['input']>;
  month?: InputMaybe<Scalars['date']['input']>;
  pdf_report_file_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Dealbusinessrevenue_Sum_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_sum_fields';
  accepted_payment?: Maybe<Scalars['numeric']['output']>;
  accepted_revenue?: Maybe<Scalars['numeric']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  deal_payback_id?: Maybe<Scalars['bigint']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  initial_estimated_payment?: Maybe<Scalars['numeric']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Sum_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_dealbusinessrevenue" */
export enum Plaid_App_Dealbusinessrevenue_Update_Column {
  /** column name */
  AcceptedById = 'accepted_by_id',
  /** column name */
  AcceptedPayment = 'accepted_payment',
  /** column name */
  AcceptedRevenue = 'accepted_revenue',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  DealPaybackId = 'deal_payback_id',
  /** column name */
  HoldbackRate = 'holdback_rate',
  /** column name */
  Id = 'id',
  /** column name */
  InitialEstimatedPayment = 'initial_estimated_payment',
  /** column name */
  InitialEstimatedRevenue = 'initial_estimated_revenue',
  /** column name */
  Month = 'month',
  /** column name */
  PdfReportFileId = 'pdf_report_file_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Dealbusinessrevenue_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Dealbusinessrevenue_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Dealbusinessrevenue_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Dealbusinessrevenue_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Dealbusinessrevenue_Var_Pop_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_var_pop_fields';
  accepted_payment?: Maybe<Scalars['Float']['output']>;
  accepted_revenue?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  deal_payback_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  initial_estimated_payment?: Maybe<Scalars['Float']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Var_Pop_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Dealbusinessrevenue_Var_Samp_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_var_samp_fields';
  accepted_payment?: Maybe<Scalars['Float']['output']>;
  accepted_revenue?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  deal_payback_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  initial_estimated_payment?: Maybe<Scalars['Float']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Var_Samp_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plaid_App_Dealbusinessrevenue_Variance_Fields = {
  __typename?: 'plaid_app_dealbusinessrevenue_variance_fields';
  accepted_payment?: Maybe<Scalars['Float']['output']>;
  accepted_revenue?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  deal_payback_id?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  initial_estimated_payment?: Maybe<Scalars['Float']['output']>;
  initial_estimated_revenue?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_dealbusinessrevenue" */
export type Plaid_App_Dealbusinessrevenue_Variance_Order_By = {
  accepted_payment?: InputMaybe<Order_By>;
  accepted_revenue?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  deal_payback_id?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initial_estimated_payment?: InputMaybe<Order_By>;
  initial_estimated_revenue?: InputMaybe<Order_By>;
};

/** columns and relationships of "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback = {
  __typename?: 'plaid_app_dealpayback';
  active: Scalars['Boolean']['output'];
  amount: Scalars['numeric']['output'];
  counterparty_link_business_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  error_msg: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  payback_month?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  plaid?: Maybe<Plaid_App_Plaidlinkbusiness>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  plaidtransaction?: Maybe<Plaid_App_Plaidtransfer>;
  /** An array relationship */
  revenues: Array<Plaid_App_Dealbusinessrevenue>;
  /** An aggregate relationship */
  revenues_aggregate: Plaid_App_Dealbusinessrevenue_Aggregate;
  status: Scalars['String']['output'];
  /** An object relationship */
  tp_transaction?: Maybe<Treasuryprime_App_Achtransfer>;
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  treasuryprime?: Maybe<Treasuryprime_App_Counterpartylinkbusiness>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  users_user?: Maybe<Users_User>;
};


/** columns and relationships of "plaid_app_dealpayback" */
export type Plaid_App_DealpaybackRevenuesArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


/** columns and relationships of "plaid_app_dealpayback" */
export type Plaid_App_DealpaybackRevenues_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};

/** aggregated selection of "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Aggregate = {
  __typename?: 'plaid_app_dealpayback_aggregate';
  aggregate?: Maybe<Plaid_App_Dealpayback_Aggregate_Fields>;
  nodes: Array<Plaid_App_Dealpayback>;
};

export type Plaid_App_Dealpayback_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plaid_App_Dealpayback_Aggregate_Bool_Exp_Count>;
};

export type Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plaid_App_Dealpayback_Select_Column_Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plaid_App_Dealpayback_Select_Column_Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Dealpayback_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Aggregate_Fields = {
  __typename?: 'plaid_app_dealpayback_aggregate_fields';
  avg?: Maybe<Plaid_App_Dealpayback_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Dealpayback_Max_Fields>;
  min?: Maybe<Plaid_App_Dealpayback_Min_Fields>;
  stddev?: Maybe<Plaid_App_Dealpayback_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Dealpayback_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Dealpayback_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Dealpayback_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Dealpayback_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Dealpayback_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Dealpayback_Variance_Fields>;
};


/** aggregate fields of "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Aggregate_Order_By = {
  avg?: InputMaybe<Plaid_App_Dealpayback_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plaid_App_Dealpayback_Max_Order_By>;
  min?: InputMaybe<Plaid_App_Dealpayback_Min_Order_By>;
  stddev?: InputMaybe<Plaid_App_Dealpayback_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plaid_App_Dealpayback_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plaid_App_Dealpayback_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plaid_App_Dealpayback_Sum_Order_By>;
  var_pop?: InputMaybe<Plaid_App_Dealpayback_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plaid_App_Dealpayback_Var_Samp_Order_By>;
  variance?: InputMaybe<Plaid_App_Dealpayback_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Arr_Rel_Insert_Input = {
  data: Array<Plaid_App_Dealpayback_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Dealpayback_On_Conflict>;
};

/** aggregate avg on columns */
export type Plaid_App_Dealpayback_Avg_Fields = {
  __typename?: 'plaid_app_dealpayback_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_dealpayback". All fields are combined with a logical 'AND'. */
export type Plaid_App_Dealpayback_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Dealpayback_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Dealpayback_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  counterparty_link_business_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  error_msg?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payback_month?: InputMaybe<Date_Comparison_Exp>;
  plaid?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
  plaid_link_business_id?: InputMaybe<Bigint_Comparison_Exp>;
  plaidtransaction?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
  revenues?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
  revenues_aggregate?: InputMaybe<Plaid_App_Dealbusinessrevenue_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tp_transaction?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  tp_transfer_id?: InputMaybe<String_Comparison_Exp>;
  transaction_id_id?: InputMaybe<String_Comparison_Exp>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users_user?: InputMaybe<Users_User_Bool_Exp>;
};

/** unique or primary key constraints on table "plaid_app_dealpayback" */
export enum Plaid_App_Dealpayback_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaidAppDealpaybackPkey = 'plaid_app_dealpayback_pkey',
  /** unique or primary key constraint on columns "tp_transfer_id" */
  PlaidAppDealpaybackTpTransferIdKey = 'plaid_app_dealpayback_tp_transfer_id_key',
  /** unique or primary key constraint on columns "transaction_id_id" */
  PlaidAppDealpaybackTransactionIdIdKey = 'plaid_app_dealpayback_transaction_id_id_key'
}

/** input type for incrementing numeric columns in table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  counterparty_link_business_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  payback_month?: InputMaybe<Scalars['date']['input']>;
  plaid?: InputMaybe<Plaid_App_Plaidlinkbusiness_Obj_Rel_Insert_Input>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
  plaidtransaction?: InputMaybe<Plaid_App_Plaidtransfer_Obj_Rel_Insert_Input>;
  revenues?: InputMaybe<Plaid_App_Dealbusinessrevenue_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  tp_transaction?: InputMaybe<Treasuryprime_App_Achtransfer_Obj_Rel_Insert_Input>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  users_user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Plaid_App_Dealpayback_Max_Fields = {
  __typename?: 'plaid_app_dealpayback_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  counterparty_link_business_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  error_msg?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  payback_month?: Maybe<Scalars['date']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  counterparty_link_business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payback_month?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plaid_App_Dealpayback_Min_Fields = {
  __typename?: 'plaid_app_dealpayback_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  counterparty_link_business_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  error_msg?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  payback_month?: Maybe<Scalars['date']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  counterparty_link_business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payback_month?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Mutation_Response = {
  __typename?: 'plaid_app_dealpayback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Dealpayback>;
};

/** input type for inserting object relation for remote table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Obj_Rel_Insert_Input = {
  data: Plaid_App_Dealpayback_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Dealpayback_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_On_Conflict = {
  constraint: Plaid_App_Dealpayback_Constraint;
  update_columns?: Array<Plaid_App_Dealpayback_Update_Column>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_dealpayback". */
export type Plaid_App_Dealpayback_Order_By = {
  active?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  counterparty_link_business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payback_month?: InputMaybe<Order_By>;
  plaid?: InputMaybe<Plaid_App_Plaidlinkbusiness_Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
  plaidtransaction?: InputMaybe<Plaid_App_Plaidtransfer_Order_By>;
  revenues_aggregate?: InputMaybe<Plaid_App_Dealbusinessrevenue_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  tp_transaction?: InputMaybe<Treasuryprime_App_Achtransfer_Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_user?: InputMaybe<Users_User_Order_By>;
};

/** primary key columns input for table: plaid_app_dealpayback */
export type Plaid_App_Dealpayback_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "plaid_app_dealpayback" */
export enum Plaid_App_Dealpayback_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  CounterpartyLinkBusinessId = 'counterparty_link_business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  Id = 'id',
  /** column name */
  PaybackMonth = 'payback_month',
  /** column name */
  PlaidLinkBusinessId = 'plaid_link_business_id',
  /** column name */
  Status = 'status',
  /** column name */
  TpTransferId = 'tp_transfer_id',
  /** column name */
  TransactionIdId = 'transaction_id_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "plaid_app_dealpayback_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plaid_app_dealpayback" */
export enum Plaid_App_Dealpayback_Select_Column_Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "plaid_app_dealpayback_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plaid_app_dealpayback" */
export enum Plaid_App_Dealpayback_Select_Column_Plaid_App_Dealpayback_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  counterparty_link_business_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  payback_month?: InputMaybe<Scalars['date']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Dealpayback_Stddev_Fields = {
  __typename?: 'plaid_app_dealpayback_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Dealpayback_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_dealpayback_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Dealpayback_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_dealpayback_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Dealpayback_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Dealpayback_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  counterparty_link_business_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  payback_month?: InputMaybe<Scalars['date']['input']>;
  plaid_link_business_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Dealpayback_Sum_Fields = {
  __typename?: 'plaid_app_dealpayback_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  plaid_link_business_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_dealpayback" */
export enum Plaid_App_Dealpayback_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  CounterpartyLinkBusinessId = 'counterparty_link_business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  Id = 'id',
  /** column name */
  PaybackMonth = 'payback_month',
  /** column name */
  PlaidLinkBusinessId = 'plaid_link_business_id',
  /** column name */
  Status = 'status',
  /** column name */
  TpTransferId = 'tp_transfer_id',
  /** column name */
  TransactionIdId = 'transaction_id_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Dealpayback_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Dealpayback_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Dealpayback_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Dealpayback_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Dealpayback_Var_Pop_Fields = {
  __typename?: 'plaid_app_dealpayback_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Dealpayback_Var_Samp_Fields = {
  __typename?: 'plaid_app_dealpayback_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plaid_App_Dealpayback_Variance_Fields = {
  __typename?: 'plaid_app_dealpayback_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  plaid_link_business_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_dealpayback" */
export type Plaid_App_Dealpayback_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plaid_link_business_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout = {
  __typename?: 'plaid_app_dealpayout';
  active: Scalars['Boolean']['output'];
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  deal: Homegrown_Deal;
  deal_id: Scalars['bigint']['output'];
  /** An object relationship */
  dealtransfer: Plaid_App_Dealtransfer;
  error_msg: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  /** An object relationship */
  initiator?: Maybe<Users_Employee>;
  /** An object relationship */
  investor?: Maybe<Investordata>;
  is_cash_transaction: Scalars['Boolean']['output'];
  payout_amount: Scalars['numeric']['output'];
  /** An object relationship */
  plaid?: Maybe<Plaid_App_Plaidlink>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  status: Scalars['String']['output'];
  to_user_id: Scalars['String']['output'];
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  transaction?: Maybe<Plaid_App_Plaidtransfer>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  transfer_id: Scalars['String']['output'];
  /** An object relationship */
  treasuryprime?: Maybe<Treasuryprime_App_Counterpartylink>;
  /** An object relationship */
  treasuryprime_achtransfer?: Maybe<Treasuryprime_App_Achtransfer>;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Aggregate = {
  __typename?: 'plaid_app_dealpayout_aggregate';
  aggregate?: Maybe<Plaid_App_Dealpayout_Aggregate_Fields>;
  nodes: Array<Plaid_App_Dealpayout>;
};

export type Plaid_App_Dealpayout_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Bool_Exp_Count>;
};

export type Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plaid_App_Dealpayout_Select_Column_Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plaid_App_Dealpayout_Select_Column_Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Dealpayout_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Aggregate_Fields = {
  __typename?: 'plaid_app_dealpayout_aggregate_fields';
  avg?: Maybe<Plaid_App_Dealpayout_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Dealpayout_Max_Fields>;
  min?: Maybe<Plaid_App_Dealpayout_Min_Fields>;
  stddev?: Maybe<Plaid_App_Dealpayout_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Dealpayout_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Dealpayout_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Dealpayout_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Dealpayout_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Dealpayout_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Dealpayout_Variance_Fields>;
};


/** aggregate fields of "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Aggregate_Order_By = {
  avg?: InputMaybe<Plaid_App_Dealpayout_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plaid_App_Dealpayout_Max_Order_By>;
  min?: InputMaybe<Plaid_App_Dealpayout_Min_Order_By>;
  stddev?: InputMaybe<Plaid_App_Dealpayout_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plaid_App_Dealpayout_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plaid_App_Dealpayout_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plaid_App_Dealpayout_Sum_Order_By>;
  var_pop?: InputMaybe<Plaid_App_Dealpayout_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plaid_App_Dealpayout_Var_Samp_Order_By>;
  variance?: InputMaybe<Plaid_App_Dealpayout_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Arr_Rel_Insert_Input = {
  data: Array<Plaid_App_Dealpayout_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Dealpayout_On_Conflict>;
};

/** aggregate avg on columns */
export type Plaid_App_Dealpayout_Avg_Fields = {
  __typename?: 'plaid_app_dealpayout_avg_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  payout_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Avg_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_dealpayout". All fields are combined with a logical 'AND'. */
export type Plaid_App_Dealpayout_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Dealpayout_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Dealpayout_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  counterparty_link_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  deal?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  deal_id?: InputMaybe<Bigint_Comparison_Exp>;
  dealtransfer?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
  error_msg?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  initiator?: InputMaybe<Users_Employee_Bool_Exp>;
  investor?: InputMaybe<Investordata_Bool_Exp>;
  is_cash_transaction?: InputMaybe<Boolean_Comparison_Exp>;
  payout_amount?: InputMaybe<Numeric_Comparison_Exp>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  plaid_link_id?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  to_user_id?: InputMaybe<String_Comparison_Exp>;
  tp_transfer_id?: InputMaybe<String_Comparison_Exp>;
  transaction?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
  transaction_id_id?: InputMaybe<String_Comparison_Exp>;
  transfer_id?: InputMaybe<String_Comparison_Exp>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  treasuryprime_achtransfer?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_dealpayout" */
export enum Plaid_App_Dealpayout_Constraint {
  /** unique or primary key constraint on columns "transfer_id", "to_user_id", "deal_id" */
  DealPayoutDealIdPayoutId = 'deal_payout_deal_id_payout_id',
  /** unique or primary key constraint on columns "id" */
  PlaidAppDealpayoutPkey = 'plaid_app_dealpayout_pkey',
  /** unique or primary key constraint on columns "tp_transfer_id" */
  PlaidAppDealpayoutTpTransferIdKey = 'plaid_app_dealpayout_tp_transfer_id_key',
  /** unique or primary key constraint on columns "transaction_id_id" */
  PlaidAppDealpayoutTransactionIdIdKey = 'plaid_app_dealpayout_transaction_id_id_key'
}

/** input type for incrementing numeric columns in table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Inc_Input = {
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  payout_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal?: InputMaybe<Homegrown_Deal_Obj_Rel_Insert_Input>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  dealtransfer?: InputMaybe<Plaid_App_Dealtransfer_Obj_Rel_Insert_Input>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  initiator?: InputMaybe<Users_Employee_Obj_Rel_Insert_Input>;
  investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  is_cash_transaction?: InputMaybe<Scalars['Boolean']['input']>;
  payout_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Obj_Rel_Insert_Input>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction?: InputMaybe<Plaid_App_Plaidtransfer_Obj_Rel_Insert_Input>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  transfer_id?: InputMaybe<Scalars['String']['input']>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Obj_Rel_Insert_Input>;
  treasuryprime_achtransfer?: InputMaybe<Treasuryprime_App_Achtransfer_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Dealpayout_Max_Fields = {
  __typename?: 'plaid_app_dealpayout_max_fields';
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  error_msg?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  payout_amount?: Maybe<Scalars['numeric']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  to_user_id?: Maybe<Scalars['String']['output']>;
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  transfer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Max_Order_By = {
  counterparty_link_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  transfer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plaid_App_Dealpayout_Min_Fields = {
  __typename?: 'plaid_app_dealpayout_min_fields';
  counterparty_link_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  deal_id?: Maybe<Scalars['bigint']['output']>;
  error_msg?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  payout_amount?: Maybe<Scalars['numeric']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  to_user_id?: Maybe<Scalars['String']['output']>;
  tp_transfer_id?: Maybe<Scalars['String']['output']>;
  transaction_id_id?: Maybe<Scalars['String']['output']>;
  transfer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Min_Order_By = {
  counterparty_link_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal_id?: InputMaybe<Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  transfer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Mutation_Response = {
  __typename?: 'plaid_app_dealpayout_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Dealpayout>;
};

/** input type for inserting object relation for remote table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Obj_Rel_Insert_Input = {
  data: Plaid_App_Dealpayout_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Dealpayout_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_On_Conflict = {
  constraint: Plaid_App_Dealpayout_Constraint;
  update_columns?: Array<Plaid_App_Dealpayout_Update_Column>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_dealpayout". */
export type Plaid_App_Dealpayout_Order_By = {
  active?: InputMaybe<Order_By>;
  counterparty_link_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  deal?: InputMaybe<Homegrown_Deal_Order_By>;
  deal_id?: InputMaybe<Order_By>;
  dealtransfer?: InputMaybe<Plaid_App_Dealtransfer_Order_By>;
  error_msg?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initiator?: InputMaybe<Users_Employee_Order_By>;
  investor?: InputMaybe<Investordata_Order_By>;
  is_cash_transaction?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid?: InputMaybe<Plaid_App_Plaidlink_Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  tp_transfer_id?: InputMaybe<Order_By>;
  transaction?: InputMaybe<Plaid_App_Plaidtransfer_Order_By>;
  transaction_id_id?: InputMaybe<Order_By>;
  transfer_id?: InputMaybe<Order_By>;
  treasuryprime?: InputMaybe<Treasuryprime_App_Counterpartylink_Order_By>;
  treasuryprime_achtransfer?: InputMaybe<Treasuryprime_App_Achtransfer_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_dealpayout */
export type Plaid_App_Dealpayout_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "plaid_app_dealpayout" */
export enum Plaid_App_Dealpayout_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CounterpartyLinkId = 'counterparty_link_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  Id = 'id',
  /** column name */
  IsCashTransaction = 'is_cash_transaction',
  /** column name */
  PayoutAmount = 'payout_amount',
  /** column name */
  PlaidLinkId = 'plaid_link_id',
  /** column name */
  Status = 'status',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  TpTransferId = 'tp_transfer_id',
  /** column name */
  TransactionIdId = 'transaction_id_id',
  /** column name */
  TransferId = 'transfer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "plaid_app_dealpayout_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plaid_app_dealpayout" */
export enum Plaid_App_Dealpayout_Select_Column_Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsCashTransaction = 'is_cash_transaction'
}

/** select "plaid_app_dealpayout_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plaid_app_dealpayout" */
export enum Plaid_App_Dealpayout_Select_Column_Plaid_App_Dealpayout_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  IsCashTransaction = 'is_cash_transaction'
}

/** input type for updating data in table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_cash_transaction?: InputMaybe<Scalars['Boolean']['input']>;
  payout_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  transfer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Dealpayout_Stddev_Fields = {
  __typename?: 'plaid_app_dealpayout_stddev_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  payout_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Stddev_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Dealpayout_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_dealpayout_stddev_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  payout_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Stddev_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Dealpayout_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_dealpayout_stddev_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  payout_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Stddev_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Dealpayout_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Dealpayout_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  counterparty_link_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  deal_id?: InputMaybe<Scalars['bigint']['input']>;
  error_msg?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  is_cash_transaction?: InputMaybe<Scalars['Boolean']['input']>;
  payout_amount?: InputMaybe<Scalars['numeric']['input']>;
  plaid_link_id?: InputMaybe<Scalars['bigint']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  to_user_id?: InputMaybe<Scalars['String']['input']>;
  tp_transfer_id?: InputMaybe<Scalars['String']['input']>;
  transaction_id_id?: InputMaybe<Scalars['String']['input']>;
  transfer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Dealpayout_Sum_Fields = {
  __typename?: 'plaid_app_dealpayout_sum_fields';
  deal_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  payout_amount?: Maybe<Scalars['numeric']['output']>;
  plaid_link_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Sum_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_dealpayout" */
export enum Plaid_App_Dealpayout_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CounterpartyLinkId = 'counterparty_link_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DealId = 'deal_id',
  /** column name */
  ErrorMsg = 'error_msg',
  /** column name */
  Id = 'id',
  /** column name */
  IsCashTransaction = 'is_cash_transaction',
  /** column name */
  PayoutAmount = 'payout_amount',
  /** column name */
  PlaidLinkId = 'plaid_link_id',
  /** column name */
  Status = 'status',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  TpTransferId = 'tp_transfer_id',
  /** column name */
  TransactionIdId = 'transaction_id_id',
  /** column name */
  TransferId = 'transfer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Dealpayout_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Dealpayout_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Dealpayout_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Dealpayout_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Dealpayout_Var_Pop_Fields = {
  __typename?: 'plaid_app_dealpayout_var_pop_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  payout_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Var_Pop_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Dealpayout_Var_Samp_Fields = {
  __typename?: 'plaid_app_dealpayout_var_samp_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  payout_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Var_Samp_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plaid_App_Dealpayout_Variance_Fields = {
  __typename?: 'plaid_app_dealpayout_variance_fields';
  deal_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  payout_amount?: Maybe<Scalars['Float']['output']>;
  plaid_link_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_dealpayout" */
export type Plaid_App_Dealpayout_Variance_Order_By = {
  deal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payout_amount?: InputMaybe<Order_By>;
  plaid_link_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer = {
  __typename?: 'plaid_app_dealtransfer';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  dealpayouts: Array<Plaid_App_Dealpayout>;
  /** An aggregate relationship */
  dealpayouts_aggregate: Plaid_App_Dealpayout_Aggregate;
  distribution_amount: Scalars['numeric']['output'];
  holdback_rate: Scalars['numeric']['output'];
  monthly_revenue: Scalars['numeric']['output'];
  payout_id: Scalars['String']['output'];
  revenue_month: Scalars['date']['output'];
  service_fee: Scalars['numeric']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "plaid_app_dealtransfer" */
export type Plaid_App_DealtransferDealpayoutsArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


/** columns and relationships of "plaid_app_dealtransfer" */
export type Plaid_App_DealtransferDealpayouts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};

/** aggregated selection of "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Aggregate = {
  __typename?: 'plaid_app_dealtransfer_aggregate';
  aggregate?: Maybe<Plaid_App_Dealtransfer_Aggregate_Fields>;
  nodes: Array<Plaid_App_Dealtransfer>;
};

/** aggregate fields of "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Aggregate_Fields = {
  __typename?: 'plaid_app_dealtransfer_aggregate_fields';
  avg?: Maybe<Plaid_App_Dealtransfer_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Dealtransfer_Max_Fields>;
  min?: Maybe<Plaid_App_Dealtransfer_Min_Fields>;
  stddev?: Maybe<Plaid_App_Dealtransfer_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Dealtransfer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Dealtransfer_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Dealtransfer_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Dealtransfer_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Dealtransfer_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Dealtransfer_Variance_Fields>;
};


/** aggregate fields of "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Dealtransfer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Aggregate_Order_By = {
  avg?: InputMaybe<Plaid_App_Dealtransfer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plaid_App_Dealtransfer_Max_Order_By>;
  min?: InputMaybe<Plaid_App_Dealtransfer_Min_Order_By>;
  stddev?: InputMaybe<Plaid_App_Dealtransfer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plaid_App_Dealtransfer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plaid_App_Dealtransfer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plaid_App_Dealtransfer_Sum_Order_By>;
  var_pop?: InputMaybe<Plaid_App_Dealtransfer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plaid_App_Dealtransfer_Var_Samp_Order_By>;
  variance?: InputMaybe<Plaid_App_Dealtransfer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Plaid_App_Dealtransfer_Avg_Fields = {
  __typename?: 'plaid_app_dealtransfer_avg_fields';
  distribution_amount?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  monthly_revenue?: Maybe<Scalars['Float']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Avg_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_dealtransfer". All fields are combined with a logical 'AND'. */
export type Plaid_App_Dealtransfer_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Dealtransfer_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Dealtransfer_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dealpayouts?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  dealpayouts_aggregate?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Bool_Exp>;
  distribution_amount?: InputMaybe<Numeric_Comparison_Exp>;
  holdback_rate?: InputMaybe<Numeric_Comparison_Exp>;
  monthly_revenue?: InputMaybe<Numeric_Comparison_Exp>;
  payout_id?: InputMaybe<String_Comparison_Exp>;
  revenue_month?: InputMaybe<Date_Comparison_Exp>;
  service_fee?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_dealtransfer" */
export enum Plaid_App_Dealtransfer_Constraint {
  /** unique or primary key constraint on columns "payout_id" */
  PlaidAppDealtransferPkey = 'plaid_app_dealtransfer_pkey'
}

/** input type for incrementing numeric columns in table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Inc_Input = {
  distribution_amount?: InputMaybe<Scalars['numeric']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  monthly_revenue?: InputMaybe<Scalars['numeric']['input']>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dealpayouts?: InputMaybe<Plaid_App_Dealpayout_Arr_Rel_Insert_Input>;
  distribution_amount?: InputMaybe<Scalars['numeric']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  monthly_revenue?: InputMaybe<Scalars['numeric']['input']>;
  payout_id?: InputMaybe<Scalars['String']['input']>;
  revenue_month?: InputMaybe<Scalars['date']['input']>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Dealtransfer_Max_Fields = {
  __typename?: 'plaid_app_dealtransfer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  distribution_amount?: Maybe<Scalars['numeric']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  monthly_revenue?: Maybe<Scalars['numeric']['output']>;
  payout_id?: Maybe<Scalars['String']['output']>;
  revenue_month?: Maybe<Scalars['date']['output']>;
  service_fee?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  payout_id?: InputMaybe<Order_By>;
  revenue_month?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plaid_App_Dealtransfer_Min_Fields = {
  __typename?: 'plaid_app_dealtransfer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  distribution_amount?: Maybe<Scalars['numeric']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  monthly_revenue?: Maybe<Scalars['numeric']['output']>;
  payout_id?: Maybe<Scalars['String']['output']>;
  revenue_month?: Maybe<Scalars['date']['output']>;
  service_fee?: Maybe<Scalars['numeric']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  payout_id?: InputMaybe<Order_By>;
  revenue_month?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Mutation_Response = {
  __typename?: 'plaid_app_dealtransfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Dealtransfer>;
};

/** input type for inserting object relation for remote table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Obj_Rel_Insert_Input = {
  data: Plaid_App_Dealtransfer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Dealtransfer_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_On_Conflict = {
  constraint: Plaid_App_Dealtransfer_Constraint;
  update_columns?: Array<Plaid_App_Dealtransfer_Update_Column>;
  where?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_dealtransfer". */
export type Plaid_App_Dealtransfer_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dealpayouts_aggregate?: InputMaybe<Plaid_App_Dealpayout_Aggregate_Order_By>;
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  payout_id?: InputMaybe<Order_By>;
  revenue_month?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_dealtransfer */
export type Plaid_App_Dealtransfer_Pk_Columns_Input = {
  payout_id: Scalars['String']['input'];
};

/** select columns of table "plaid_app_dealtransfer" */
export enum Plaid_App_Dealtransfer_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistributionAmount = 'distribution_amount',
  /** column name */
  HoldbackRate = 'holdback_rate',
  /** column name */
  MonthlyRevenue = 'monthly_revenue',
  /** column name */
  PayoutId = 'payout_id',
  /** column name */
  RevenueMonth = 'revenue_month',
  /** column name */
  ServiceFee = 'service_fee',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distribution_amount?: InputMaybe<Scalars['numeric']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  monthly_revenue?: InputMaybe<Scalars['numeric']['input']>;
  payout_id?: InputMaybe<Scalars['String']['input']>;
  revenue_month?: InputMaybe<Scalars['date']['input']>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Dealtransfer_Stddev_Fields = {
  __typename?: 'plaid_app_dealtransfer_stddev_fields';
  distribution_amount?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  monthly_revenue?: Maybe<Scalars['Float']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Stddev_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Dealtransfer_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_dealtransfer_stddev_pop_fields';
  distribution_amount?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  monthly_revenue?: Maybe<Scalars['Float']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Stddev_Pop_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Dealtransfer_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_dealtransfer_stddev_samp_fields';
  distribution_amount?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  monthly_revenue?: Maybe<Scalars['Float']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Stddev_Samp_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Dealtransfer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Dealtransfer_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distribution_amount?: InputMaybe<Scalars['numeric']['input']>;
  holdback_rate?: InputMaybe<Scalars['numeric']['input']>;
  monthly_revenue?: InputMaybe<Scalars['numeric']['input']>;
  payout_id?: InputMaybe<Scalars['String']['input']>;
  revenue_month?: InputMaybe<Scalars['date']['input']>;
  service_fee?: InputMaybe<Scalars['numeric']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Dealtransfer_Sum_Fields = {
  __typename?: 'plaid_app_dealtransfer_sum_fields';
  distribution_amount?: Maybe<Scalars['numeric']['output']>;
  holdback_rate?: Maybe<Scalars['numeric']['output']>;
  monthly_revenue?: Maybe<Scalars['numeric']['output']>;
  service_fee?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Sum_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_dealtransfer" */
export enum Plaid_App_Dealtransfer_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DistributionAmount = 'distribution_amount',
  /** column name */
  HoldbackRate = 'holdback_rate',
  /** column name */
  MonthlyRevenue = 'monthly_revenue',
  /** column name */
  PayoutId = 'payout_id',
  /** column name */
  RevenueMonth = 'revenue_month',
  /** column name */
  ServiceFee = 'service_fee',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Dealtransfer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Dealtransfer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Dealtransfer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Dealtransfer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Dealtransfer_Var_Pop_Fields = {
  __typename?: 'plaid_app_dealtransfer_var_pop_fields';
  distribution_amount?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  monthly_revenue?: Maybe<Scalars['Float']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Var_Pop_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Dealtransfer_Var_Samp_Fields = {
  __typename?: 'plaid_app_dealtransfer_var_samp_fields';
  distribution_amount?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  monthly_revenue?: Maybe<Scalars['Float']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Var_Samp_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plaid_App_Dealtransfer_Variance_Fields = {
  __typename?: 'plaid_app_dealtransfer_variance_fields';
  distribution_amount?: Maybe<Scalars['Float']['output']>;
  holdback_rate?: Maybe<Scalars['Float']['output']>;
  monthly_revenue?: Maybe<Scalars['Float']['output']>;
  service_fee?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_dealtransfer" */
export type Plaid_App_Dealtransfer_Variance_Order_By = {
  distribution_amount?: InputMaybe<Order_By>;
  holdback_rate?: InputMaybe<Order_By>;
  monthly_revenue?: InputMaybe<Order_By>;
  service_fee?: InputMaybe<Order_By>;
};

/** columns and relationships of "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink = {
  __typename?: 'plaid_app_plaidlink';
  account_meta_data: Scalars['jsonb']['output'];
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  institution_meta_data: Scalars['jsonb']['output'];
  item_id?: Maybe<Scalars['String']['output']>;
  item_status: Plaid_App_Plaidlinkstatus_Enum;
  permanent_token?: Maybe<Scalars['String']['output']>;
  plaid_user_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "plaid_app_plaidlink" */
export type Plaid_App_PlaidlinkAccount_Meta_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "plaid_app_plaidlink" */
export type Plaid_App_PlaidlinkInstitution_Meta_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Aggregate = {
  __typename?: 'plaid_app_plaidlink_aggregate';
  aggregate?: Maybe<Plaid_App_Plaidlink_Aggregate_Fields>;
  nodes: Array<Plaid_App_Plaidlink>;
};

export type Plaid_App_Plaidlink_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plaid_App_Plaidlink_Aggregate_Bool_Exp_Count>;
};

export type Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plaid_App_Plaidlink_Select_Column_Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plaid_App_Plaidlink_Select_Column_Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Plaidlink_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Aggregate_Fields = {
  __typename?: 'plaid_app_plaidlink_aggregate_fields';
  avg?: Maybe<Plaid_App_Plaidlink_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Plaidlink_Max_Fields>;
  min?: Maybe<Plaid_App_Plaidlink_Min_Fields>;
  stddev?: Maybe<Plaid_App_Plaidlink_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Plaidlink_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Plaidlink_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Plaidlink_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Plaidlink_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Plaidlink_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Plaidlink_Variance_Fields>;
};


/** aggregate fields of "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Aggregate_Order_By = {
  avg?: InputMaybe<Plaid_App_Plaidlink_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plaid_App_Plaidlink_Max_Order_By>;
  min?: InputMaybe<Plaid_App_Plaidlink_Min_Order_By>;
  stddev?: InputMaybe<Plaid_App_Plaidlink_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plaid_App_Plaidlink_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plaid_App_Plaidlink_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plaid_App_Plaidlink_Sum_Order_By>;
  var_pop?: InputMaybe<Plaid_App_Plaidlink_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plaid_App_Plaidlink_Var_Samp_Order_By>;
  variance?: InputMaybe<Plaid_App_Plaidlink_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidlink_Append_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Arr_Rel_Insert_Input = {
  data: Array<Plaid_App_Plaidlink_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Plaidlink_On_Conflict>;
};

/** aggregate avg on columns */
export type Plaid_App_Plaidlink_Avg_Fields = {
  __typename?: 'plaid_app_plaidlink_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_plaidlink". All fields are combined with a logical 'AND'. */
export type Plaid_App_Plaidlink_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Plaidlink_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Plaidlink_Bool_Exp>>;
  account_meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  institution_meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  item_id?: InputMaybe<String_Comparison_Exp>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum_Comparison_Exp>;
  permanent_token?: InputMaybe<String_Comparison_Exp>;
  plaid_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_plaidlink" */
export enum Plaid_App_Plaidlink_Constraint {
  /** unique or primary key constraint on columns "item_id" */
  PlaidAppPlaidlinkItemIdKey = 'plaid_app_plaidlink_item_id_key',
  /** unique or primary key constraint on columns "id" */
  PlaidAppPlaidlinkPkey = 'plaid_app_plaidlink_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Plaid_App_Plaidlink_Delete_At_Path_Input = {
  account_meta_data?: InputMaybe<Array<Scalars['String']['input']>>;
  institution_meta_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Plaid_App_Plaidlink_Delete_Elem_Input = {
  account_meta_data?: InputMaybe<Scalars['Int']['input']>;
  institution_meta_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Plaid_App_Plaidlink_Delete_Key_Input = {
  account_meta_data?: InputMaybe<Scalars['String']['input']>;
  institution_meta_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Insert_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  item_id?: InputMaybe<Scalars['String']['input']>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  permanent_token?: InputMaybe<Scalars['String']['input']>;
  plaid_user_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Plaidlink_Max_Fields = {
  __typename?: 'plaid_app_plaidlink_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  item_id?: Maybe<Scalars['String']['output']>;
  permanent_token?: Maybe<Scalars['String']['output']>;
  plaid_user_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  permanent_token?: InputMaybe<Order_By>;
  plaid_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plaid_App_Plaidlink_Min_Fields = {
  __typename?: 'plaid_app_plaidlink_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  item_id?: Maybe<Scalars['String']['output']>;
  permanent_token?: Maybe<Scalars['String']['output']>;
  plaid_user_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  permanent_token?: InputMaybe<Order_By>;
  plaid_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Mutation_Response = {
  __typename?: 'plaid_app_plaidlink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Plaidlink>;
};

/** input type for inserting object relation for remote table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Obj_Rel_Insert_Input = {
  data: Plaid_App_Plaidlink_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Plaidlink_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_On_Conflict = {
  constraint: Plaid_App_Plaidlink_Constraint;
  update_columns?: Array<Plaid_App_Plaidlink_Update_Column>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_plaidlink". */
export type Plaid_App_Plaidlink_Order_By = {
  account_meta_data?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  institution_meta_data?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
  permanent_token?: InputMaybe<Order_By>;
  plaid_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_plaidlink */
export type Plaid_App_Plaidlink_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidlink_Prepend_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "plaid_app_plaidlink" */
export enum Plaid_App_Plaidlink_Select_Column {
  /** column name */
  AccountMetaData = 'account_meta_data',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionMetaData = 'institution_meta_data',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStatus = 'item_status',
  /** column name */
  PermanentToken = 'permanent_token',
  /** column name */
  PlaidUserId = 'plaid_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "plaid_app_plaidlink_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plaid_app_plaidlink" */
export enum Plaid_App_Plaidlink_Select_Column_Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "plaid_app_plaidlink_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plaid_app_plaidlink" */
export enum Plaid_App_Plaidlink_Select_Column_Plaid_App_Plaidlink_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Set_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  item_id?: InputMaybe<Scalars['String']['input']>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  permanent_token?: InputMaybe<Scalars['String']['input']>;
  plaid_user_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Plaidlink_Stddev_Fields = {
  __typename?: 'plaid_app_plaidlink_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Plaidlink_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_plaidlink_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Plaidlink_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_plaidlink_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Plaidlink_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Plaidlink_Stream_Cursor_Value_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  item_id?: InputMaybe<Scalars['String']['input']>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  permanent_token?: InputMaybe<Scalars['String']['input']>;
  plaid_user_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Plaidlink_Sum_Fields = {
  __typename?: 'plaid_app_plaidlink_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_plaidlink" */
export enum Plaid_App_Plaidlink_Update_Column {
  /** column name */
  AccountMetaData = 'account_meta_data',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionMetaData = 'institution_meta_data',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStatus = 'item_status',
  /** column name */
  PermanentToken = 'permanent_token',
  /** column name */
  PlaidUserId = 'plaid_user_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Plaid_App_Plaidlink_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Plaid_App_Plaidlink_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Plaid_App_Plaidlink_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Plaid_App_Plaidlink_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Plaid_App_Plaidlink_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Plaidlink_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Plaid_App_Plaidlink_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Plaidlink_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Plaidlink_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Plaidlink_Var_Pop_Fields = {
  __typename?: 'plaid_app_plaidlink_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Plaidlink_Var_Samp_Fields = {
  __typename?: 'plaid_app_plaidlink_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plaid_App_Plaidlink_Variance_Fields = {
  __typename?: 'plaid_app_plaidlink_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_plaidlink" */
export type Plaid_App_Plaidlink_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness = {
  __typename?: 'plaid_app_plaidlinkbusiness';
  account_meta_data: Scalars['jsonb']['output'];
  active: Scalars['Boolean']['output'];
  business_owner_id: Scalars['String']['output'];
  /** An object relationship */
  businessowner: Users_Businessowner;
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  dealprivates: Array<Homegrown_Dealprivate>;
  /** An aggregate relationship */
  dealprivates_aggregate: Homegrown_Dealprivate_Aggregate;
  /** An array relationship */
  dealtobusinessplaidlinks: Array<Homegrown_Dealtobusinessplaidlink>;
  /** An aggregate relationship */
  dealtobusinessplaidlinks_aggregate: Homegrown_Dealtobusinessplaidlink_Aggregate;
  id: Scalars['bigint']['output'];
  institution_meta_data: Scalars['jsonb']['output'];
  item_id?: Maybe<Scalars['String']['output']>;
  item_status: Plaid_App_Plaidlinkstatus_Enum;
  permanent_token?: Maybe<Scalars['String']['output']>;
  plaid_user_id: Scalars['uuid']['output'];
  transactions_consent: Scalars['Boolean']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_PlaidlinkbusinessAccount_Meta_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_PlaidlinkbusinessDealprivatesArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivate_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};


/** columns and relationships of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_PlaidlinkbusinessDealprivates_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivate_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};


/** columns and relationships of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_PlaidlinkbusinessDealtobusinessplaidlinksArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


/** columns and relationships of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_PlaidlinkbusinessDealtobusinessplaidlinks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


/** columns and relationships of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_PlaidlinkbusinessInstitution_Meta_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Aggregate = {
  __typename?: 'plaid_app_plaidlinkbusiness_aggregate';
  aggregate?: Maybe<Plaid_App_Plaidlinkbusiness_Aggregate_Fields>;
  nodes: Array<Plaid_App_Plaidlinkbusiness>;
};

export type Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Count>;
};

export type Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plaid_App_Plaidlinkbusiness_Select_Column_Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plaid_App_Plaidlinkbusiness_Select_Column_Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Aggregate_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_aggregate_fields';
  avg?: Maybe<Plaid_App_Plaidlinkbusiness_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Plaidlinkbusiness_Max_Fields>;
  min?: Maybe<Plaid_App_Plaidlinkbusiness_Min_Fields>;
  stddev?: Maybe<Plaid_App_Plaidlinkbusiness_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Plaidlinkbusiness_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Plaidlinkbusiness_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Plaidlinkbusiness_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Plaidlinkbusiness_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Plaidlinkbusiness_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Plaidlinkbusiness_Variance_Fields>;
};


/** aggregate fields of "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Aggregate_Order_By = {
  avg?: InputMaybe<Plaid_App_Plaidlinkbusiness_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plaid_App_Plaidlinkbusiness_Max_Order_By>;
  min?: InputMaybe<Plaid_App_Plaidlinkbusiness_Min_Order_By>;
  stddev?: InputMaybe<Plaid_App_Plaidlinkbusiness_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plaid_App_Plaidlinkbusiness_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plaid_App_Plaidlinkbusiness_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plaid_App_Plaidlinkbusiness_Sum_Order_By>;
  var_pop?: InputMaybe<Plaid_App_Plaidlinkbusiness_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plaid_App_Plaidlinkbusiness_Var_Samp_Order_By>;
  variance?: InputMaybe<Plaid_App_Plaidlinkbusiness_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidlinkbusiness_Append_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Arr_Rel_Insert_Input = {
  data: Array<Plaid_App_Plaidlinkbusiness_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Plaidlinkbusiness_On_Conflict>;
};

/** aggregate avg on columns */
export type Plaid_App_Plaidlinkbusiness_Avg_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_plaidlinkbusiness". All fields are combined with a logical 'AND'. */
export type Plaid_App_Plaidlinkbusiness_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Bool_Exp>>;
  account_meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  business_owner_id?: InputMaybe<String_Comparison_Exp>;
  businessowner?: InputMaybe<Users_Businessowner_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dealprivates?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
  dealprivates_aggregate?: InputMaybe<Homegrown_Dealprivate_Aggregate_Bool_Exp>;
  dealtobusinessplaidlinks?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
  dealtobusinessplaidlinks_aggregate?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  institution_meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
  item_id?: InputMaybe<String_Comparison_Exp>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum_Comparison_Exp>;
  permanent_token?: InputMaybe<String_Comparison_Exp>;
  plaid_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  transactions_consent?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_plaidlinkbusiness" */
export enum Plaid_App_Plaidlinkbusiness_Constraint {
  /** unique or primary key constraint on columns "item_id" */
  PlaidAppPlaidlinkbusinessItemIdKey = 'plaid_app_plaidlinkbusiness_item_id_key',
  /** unique or primary key constraint on columns "id" */
  PlaidAppPlaidlinkbusinessPkey = 'plaid_app_plaidlinkbusiness_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Plaid_App_Plaidlinkbusiness_Delete_At_Path_Input = {
  account_meta_data?: InputMaybe<Array<Scalars['String']['input']>>;
  institution_meta_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Plaid_App_Plaidlinkbusiness_Delete_Elem_Input = {
  account_meta_data?: InputMaybe<Scalars['Int']['input']>;
  institution_meta_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Plaid_App_Plaidlinkbusiness_Delete_Key_Input = {
  account_meta_data?: InputMaybe<Scalars['String']['input']>;
  institution_meta_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Insert_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_owner_id?: InputMaybe<Scalars['String']['input']>;
  businessowner?: InputMaybe<Users_Businessowner_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dealprivates?: InputMaybe<Homegrown_Dealprivate_Arr_Rel_Insert_Input>;
  dealtobusinessplaidlinks?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  item_id?: InputMaybe<Scalars['String']['input']>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  permanent_token?: InputMaybe<Scalars['String']['input']>;
  plaid_user_id?: InputMaybe<Scalars['uuid']['input']>;
  transactions_consent?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Plaidlinkbusiness_Max_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_max_fields';
  business_owner_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  item_id?: Maybe<Scalars['String']['output']>;
  permanent_token?: Maybe<Scalars['String']['output']>;
  plaid_user_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Max_Order_By = {
  business_owner_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  permanent_token?: InputMaybe<Order_By>;
  plaid_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plaid_App_Plaidlinkbusiness_Min_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_min_fields';
  business_owner_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  item_id?: Maybe<Scalars['String']['output']>;
  permanent_token?: Maybe<Scalars['String']['output']>;
  plaid_user_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Min_Order_By = {
  business_owner_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  permanent_token?: InputMaybe<Order_By>;
  plaid_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Mutation_Response = {
  __typename?: 'plaid_app_plaidlinkbusiness_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Plaidlinkbusiness>;
};

/** input type for inserting object relation for remote table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Obj_Rel_Insert_Input = {
  data: Plaid_App_Plaidlinkbusiness_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Plaidlinkbusiness_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_On_Conflict = {
  constraint: Plaid_App_Plaidlinkbusiness_Constraint;
  update_columns?: Array<Plaid_App_Plaidlinkbusiness_Update_Column>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_plaidlinkbusiness". */
export type Plaid_App_Plaidlinkbusiness_Order_By = {
  account_meta_data?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  business_owner_id?: InputMaybe<Order_By>;
  businessowner?: InputMaybe<Users_Businessowner_Order_By>;
  created_at?: InputMaybe<Order_By>;
  dealprivates_aggregate?: InputMaybe<Homegrown_Dealprivate_Aggregate_Order_By>;
  dealtobusinessplaidlinks_aggregate?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  institution_meta_data?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_status?: InputMaybe<Order_By>;
  permanent_token?: InputMaybe<Order_By>;
  plaid_user_id?: InputMaybe<Order_By>;
  transactions_consent?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_plaidlinkbusiness */
export type Plaid_App_Plaidlinkbusiness_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidlinkbusiness_Prepend_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "plaid_app_plaidlinkbusiness" */
export enum Plaid_App_Plaidlinkbusiness_Select_Column {
  /** column name */
  AccountMetaData = 'account_meta_data',
  /** column name */
  Active = 'active',
  /** column name */
  BusinessOwnerId = 'business_owner_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionMetaData = 'institution_meta_data',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStatus = 'item_status',
  /** column name */
  PermanentToken = 'permanent_token',
  /** column name */
  PlaidUserId = 'plaid_user_id',
  /** column name */
  TransactionsConsent = 'transactions_consent',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "plaid_app_plaidlinkbusiness_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plaid_app_plaidlinkbusiness" */
export enum Plaid_App_Plaidlinkbusiness_Select_Column_Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  TransactionsConsent = 'transactions_consent'
}

/** select "plaid_app_plaidlinkbusiness_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plaid_app_plaidlinkbusiness" */
export enum Plaid_App_Plaidlinkbusiness_Select_Column_Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  TransactionsConsent = 'transactions_consent'
}

/** input type for updating data in table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Set_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_owner_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  item_id?: InputMaybe<Scalars['String']['input']>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  permanent_token?: InputMaybe<Scalars['String']['input']>;
  plaid_user_id?: InputMaybe<Scalars['uuid']['input']>;
  transactions_consent?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Plaidlinkbusiness_Stddev_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Plaidlinkbusiness_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Plaidlinkbusiness_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Plaidlinkbusiness_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Plaidlinkbusiness_Stream_Cursor_Value_Input = {
  account_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_owner_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  institution_meta_data?: InputMaybe<Scalars['jsonb']['input']>;
  item_id?: InputMaybe<Scalars['String']['input']>;
  item_status?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  permanent_token?: InputMaybe<Scalars['String']['input']>;
  plaid_user_id?: InputMaybe<Scalars['uuid']['input']>;
  transactions_consent?: InputMaybe<Scalars['Boolean']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Plaidlinkbusiness_Sum_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_plaidlinkbusiness" */
export enum Plaid_App_Plaidlinkbusiness_Update_Column {
  /** column name */
  AccountMetaData = 'account_meta_data',
  /** column name */
  Active = 'active',
  /** column name */
  BusinessOwnerId = 'business_owner_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InstitutionMetaData = 'institution_meta_data',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemStatus = 'item_status',
  /** column name */
  PermanentToken = 'permanent_token',
  /** column name */
  PlaidUserId = 'plaid_user_id',
  /** column name */
  TransactionsConsent = 'transactions_consent',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Plaidlinkbusiness_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Plaid_App_Plaidlinkbusiness_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Plaid_App_Plaidlinkbusiness_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Plaidlinkbusiness_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Plaid_App_Plaidlinkbusiness_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Plaidlinkbusiness_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Plaidlinkbusiness_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Plaidlinkbusiness_Var_Pop_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Plaidlinkbusiness_Var_Samp_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plaid_App_Plaidlinkbusiness_Variance_Fields = {
  __typename?: 'plaid_app_plaidlinkbusiness_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_plaidlinkbusiness" */
export type Plaid_App_Plaidlinkbusiness_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus = {
  __typename?: 'plaid_app_plaidlinkstatus';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_Aggregate = {
  __typename?: 'plaid_app_plaidlinkstatus_aggregate';
  aggregate?: Maybe<Plaid_App_Plaidlinkstatus_Aggregate_Fields>;
  nodes: Array<Plaid_App_Plaidlinkstatus>;
};

/** aggregate fields of "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_Aggregate_Fields = {
  __typename?: 'plaid_app_plaidlinkstatus_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Plaidlinkstatus_Max_Fields>;
  min?: Maybe<Plaid_App_Plaidlinkstatus_Min_Fields>;
};


/** aggregate fields of "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "plaid_app_plaidlinkstatus". All fields are combined with a logical 'AND'. */
export type Plaid_App_Plaidlinkstatus_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Plaidlinkstatus_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_plaidlinkstatus" */
export enum Plaid_App_Plaidlinkstatus_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlaidAppPlaidlinkstatusPkey = 'plaid_app_plaidlinkstatus_pkey'
}

export enum Plaid_App_Plaidlinkstatus_Enum {
  LoginRequired = 'login_required',
  Normal = 'normal',
  PendingExpiration = 'pending_expiration'
}

/** Boolean expression to compare columns of type "plaid_app_plaidlinkstatus_enum". All fields are combined with logical 'AND'. */
export type Plaid_App_Plaidlinkstatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  _in?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Plaid_App_Plaidlinkstatus_Enum>;
  _nin?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Enum>>;
};

/** input type for inserting data into table "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Plaidlinkstatus_Max_Fields = {
  __typename?: 'plaid_app_plaidlinkstatus_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Plaid_App_Plaidlinkstatus_Min_Fields = {
  __typename?: 'plaid_app_plaidlinkstatus_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_Mutation_Response = {
  __typename?: 'plaid_app_plaidlinkstatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Plaidlinkstatus>;
};

/** on_conflict condition type for table "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_On_Conflict = {
  constraint: Plaid_App_Plaidlinkstatus_Constraint;
  update_columns?: Array<Plaid_App_Plaidlinkstatus_Update_Column>;
  where?: InputMaybe<Plaid_App_Plaidlinkstatus_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_plaidlinkstatus". */
export type Plaid_App_Plaidlinkstatus_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_plaidlinkstatus */
export type Plaid_App_Plaidlinkstatus_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "plaid_app_plaidlinkstatus" */
export enum Plaid_App_Plaidlinkstatus_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "plaid_app_plaidlinkstatus" */
export type Plaid_App_Plaidlinkstatus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Plaidlinkstatus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Plaidlinkstatus_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "plaid_app_plaidlinkstatus" */
export enum Plaid_App_Plaidlinkstatus_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name'
}

export type Plaid_App_Plaidlinkstatus_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Plaidlinkstatus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Plaidlinkstatus_Bool_Exp;
};

/** columns and relationships of "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer = {
  __typename?: 'plaid_app_plaidtransfer';
  active: Scalars['Boolean']['output'];
  amount: Scalars['numeric']['output'];
  authorization_body: Scalars['jsonb']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  transfer_body: Scalars['jsonb']['output'];
  transfer_status: Scalars['String']['output'];
  transfer_type: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "plaid_app_plaidtransfer" */
export type Plaid_App_PlaidtransferAuthorization_BodyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "plaid_app_plaidtransfer" */
export type Plaid_App_PlaidtransferTransfer_BodyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Aggregate = {
  __typename?: 'plaid_app_plaidtransfer_aggregate';
  aggregate?: Maybe<Plaid_App_Plaidtransfer_Aggregate_Fields>;
  nodes: Array<Plaid_App_Plaidtransfer>;
};

/** aggregate fields of "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Aggregate_Fields = {
  __typename?: 'plaid_app_plaidtransfer_aggregate_fields';
  avg?: Maybe<Plaid_App_Plaidtransfer_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Plaidtransfer_Max_Fields>;
  min?: Maybe<Plaid_App_Plaidtransfer_Min_Fields>;
  stddev?: Maybe<Plaid_App_Plaidtransfer_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Plaidtransfer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Plaidtransfer_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Plaidtransfer_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Plaidtransfer_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Plaidtransfer_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Plaidtransfer_Variance_Fields>;
};


/** aggregate fields of "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Plaidtransfer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidtransfer_Append_Input = {
  authorization_body?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Plaid_App_Plaidtransfer_Avg_Fields = {
  __typename?: 'plaid_app_plaidtransfer_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "plaid_app_plaidtransfer". All fields are combined with a logical 'AND'. */
export type Plaid_App_Plaidtransfer_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Plaidtransfer_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Plaidtransfer_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  authorization_body?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  transfer_body?: InputMaybe<Jsonb_Comparison_Exp>;
  transfer_status?: InputMaybe<String_Comparison_Exp>;
  transfer_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_plaidtransfer" */
export enum Plaid_App_Plaidtransfer_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlaidAppPlaidtransferPkey = 'plaid_app_plaidtransfer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Plaid_App_Plaidtransfer_Delete_At_Path_Input = {
  authorization_body?: InputMaybe<Array<Scalars['String']['input']>>;
  transfer_body?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Plaid_App_Plaidtransfer_Delete_Elem_Input = {
  authorization_body?: InputMaybe<Scalars['Int']['input']>;
  transfer_body?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Plaid_App_Plaidtransfer_Delete_Key_Input = {
  authorization_body?: InputMaybe<Scalars['String']['input']>;
  transfer_body?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  authorization_body?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  transfer_body?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_status?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Plaidtransfer_Max_Fields = {
  __typename?: 'plaid_app_plaidtransfer_max_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  transfer_status?: Maybe<Scalars['String']['output']>;
  transfer_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Plaid_App_Plaidtransfer_Min_Fields = {
  __typename?: 'plaid_app_plaidtransfer_min_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  transfer_status?: Maybe<Scalars['String']['output']>;
  transfer_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Mutation_Response = {
  __typename?: 'plaid_app_plaidtransfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Plaidtransfer>;
};

/** input type for inserting object relation for remote table "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Obj_Rel_Insert_Input = {
  data: Plaid_App_Plaidtransfer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Plaidtransfer_On_Conflict>;
};

/** on_conflict condition type for table "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_On_Conflict = {
  constraint: Plaid_App_Plaidtransfer_Constraint;
  update_columns?: Array<Plaid_App_Plaidtransfer_Update_Column>;
  where?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_plaidtransfer". */
export type Plaid_App_Plaidtransfer_Order_By = {
  active?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  authorization_body?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transfer_body?: InputMaybe<Order_By>;
  transfer_status?: InputMaybe<Order_By>;
  transfer_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_plaidtransfer */
export type Plaid_App_Plaidtransfer_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidtransfer_Prepend_Input = {
  authorization_body?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "plaid_app_plaidtransfer" */
export enum Plaid_App_Plaidtransfer_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  AuthorizationBody = 'authorization_body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TransferBody = 'transfer_body',
  /** column name */
  TransferStatus = 'transfer_status',
  /** column name */
  TransferType = 'transfer_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  authorization_body?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  transfer_body?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_status?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Plaidtransfer_Stddev_Fields = {
  __typename?: 'plaid_app_plaidtransfer_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Plaidtransfer_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_plaidtransfer_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Plaidtransfer_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_plaidtransfer_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "plaid_app_plaidtransfer" */
export type Plaid_App_Plaidtransfer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Plaidtransfer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Plaidtransfer_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  authorization_body?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  transfer_body?: InputMaybe<Scalars['jsonb']['input']>;
  transfer_status?: InputMaybe<Scalars['String']['input']>;
  transfer_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Plaidtransfer_Sum_Fields = {
  __typename?: 'plaid_app_plaidtransfer_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "plaid_app_plaidtransfer" */
export enum Plaid_App_Plaidtransfer_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  AuthorizationBody = 'authorization_body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TransferBody = 'transfer_body',
  /** column name */
  TransferStatus = 'transfer_status',
  /** column name */
  TransferType = 'transfer_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Plaidtransfer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Plaid_App_Plaidtransfer_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Plaid_App_Plaidtransfer_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Plaid_App_Plaidtransfer_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Plaid_App_Plaidtransfer_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Plaidtransfer_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Plaid_App_Plaidtransfer_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Plaidtransfer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Plaidtransfer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Plaidtransfer_Var_Pop_Fields = {
  __typename?: 'plaid_app_plaidtransfer_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Plaidtransfer_Var_Samp_Fields = {
  __typename?: 'plaid_app_plaidtransfer_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Plaid_App_Plaidtransfer_Variance_Fields = {
  __typename?: 'plaid_app_plaidtransfer_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent = {
  __typename?: 'plaid_app_plaidtransferevent';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  event_body: Scalars['jsonb']['output'];
  event_id: Scalars['Int']['output'];
  event_timestamp: Scalars['timestamptz']['output'];
  event_type: Scalars['String']['output'];
  transfer_id: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "plaid_app_plaidtransferevent" */
export type Plaid_App_PlaidtransfereventEvent_BodyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Aggregate = {
  __typename?: 'plaid_app_plaidtransferevent_aggregate';
  aggregate?: Maybe<Plaid_App_Plaidtransferevent_Aggregate_Fields>;
  nodes: Array<Plaid_App_Plaidtransferevent>;
};

/** aggregate fields of "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Aggregate_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_aggregate_fields';
  avg?: Maybe<Plaid_App_Plaidtransferevent_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Plaidtransferevent_Max_Fields>;
  min?: Maybe<Plaid_App_Plaidtransferevent_Min_Fields>;
  stddev?: Maybe<Plaid_App_Plaidtransferevent_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Plaidtransferevent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Plaidtransferevent_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Plaidtransferevent_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Plaidtransferevent_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Plaidtransferevent_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Plaidtransferevent_Variance_Fields>;
};


/** aggregate fields of "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidtransferevent_Append_Input = {
  event_body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Plaid_App_Plaidtransferevent_Avg_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_avg_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "plaid_app_plaidtransferevent". All fields are combined with a logical 'AND'. */
export type Plaid_App_Plaidtransferevent_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Plaidtransferevent_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_body?: InputMaybe<Jsonb_Comparison_Exp>;
  event_id?: InputMaybe<Int_Comparison_Exp>;
  event_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  transfer_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_plaidtransferevent" */
export enum Plaid_App_Plaidtransferevent_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  PlaidAppPlaidtransfereventPkey = 'plaid_app_plaidtransferevent_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Plaid_App_Plaidtransferevent_Delete_At_Path_Input = {
  event_body?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Plaid_App_Plaidtransferevent_Delete_Elem_Input = {
  event_body?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Plaid_App_Plaidtransferevent_Delete_Key_Input = {
  event_body?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Inc_Input = {
  event_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_body?: InputMaybe<Scalars['jsonb']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  event_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  event_type?: InputMaybe<Scalars['String']['input']>;
  transfer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Plaidtransferevent_Max_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  event_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  transfer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Plaid_App_Plaidtransferevent_Min_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  event_id?: Maybe<Scalars['Int']['output']>;
  event_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  transfer_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Mutation_Response = {
  __typename?: 'plaid_app_plaidtransferevent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Plaidtransferevent>;
};

/** on_conflict condition type for table "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_On_Conflict = {
  constraint: Plaid_App_Plaidtransferevent_Constraint;
  update_columns?: Array<Plaid_App_Plaidtransferevent_Update_Column>;
  where?: InputMaybe<Plaid_App_Plaidtransferevent_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_plaidtransferevent". */
export type Plaid_App_Plaidtransferevent_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_body?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  event_timestamp?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  transfer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_plaidtransferevent */
export type Plaid_App_Plaidtransferevent_Pk_Columns_Input = {
  event_id: Scalars['Int']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Plaidtransferevent_Prepend_Input = {
  event_body?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "plaid_app_plaidtransferevent" */
export enum Plaid_App_Plaidtransferevent_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventBody = 'event_body',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventTimestamp = 'event_timestamp',
  /** column name */
  EventType = 'event_type',
  /** column name */
  TransferId = 'transfer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_body?: InputMaybe<Scalars['jsonb']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  event_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  event_type?: InputMaybe<Scalars['String']['input']>;
  transfer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Plaidtransferevent_Stddev_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_stddev_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Plaidtransferevent_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Plaidtransferevent_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "plaid_app_plaidtransferevent" */
export type Plaid_App_Plaidtransferevent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Plaidtransferevent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Plaidtransferevent_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_body?: InputMaybe<Scalars['jsonb']['input']>;
  event_id?: InputMaybe<Scalars['Int']['input']>;
  event_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  event_type?: InputMaybe<Scalars['String']['input']>;
  transfer_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Plaidtransferevent_Sum_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_sum_fields';
  event_id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "plaid_app_plaidtransferevent" */
export enum Plaid_App_Plaidtransferevent_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventBody = 'event_body',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventTimestamp = 'event_timestamp',
  /** column name */
  EventType = 'event_type',
  /** column name */
  TransferId = 'transfer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Plaidtransferevent_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Plaid_App_Plaidtransferevent_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Plaid_App_Plaidtransferevent_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Plaidtransferevent_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Plaid_App_Plaidtransferevent_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Plaidtransferevent_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Plaidtransferevent_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Plaidtransferevent_Var_Pop_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_var_pop_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Plaidtransferevent_Var_Samp_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_var_samp_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Plaid_App_Plaidtransferevent_Variance_Fields = {
  __typename?: 'plaid_app_plaidtransferevent_variance_fields';
  event_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction = {
  __typename?: 'plaid_app_revenuetransaction';
  account_id?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  amount: Scalars['numeric']['output'];
  created_at: Scalars['timestamptz']['output'];
  date: Scalars['date']['output'];
  datetime?: Maybe<Scalars['timestamptz']['output']>;
  deal_revenue_id: Scalars['bigint']['output'];
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  payment_channel?: Maybe<Scalars['String']['output']>;
  raw_data?: Maybe<Scalars['jsonb']['output']>;
  transaction_code?: Maybe<Scalars['String']['output']>;
  transaction_id: Scalars['String']['output'];
  transaction_type?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "plaid_app_revenuetransaction" */
export type Plaid_App_RevenuetransactionRaw_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Aggregate = {
  __typename?: 'plaid_app_revenuetransaction_aggregate';
  aggregate?: Maybe<Plaid_App_Revenuetransaction_Aggregate_Fields>;
  nodes: Array<Plaid_App_Revenuetransaction>;
};

export type Plaid_App_Revenuetransaction_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Count>;
};

export type Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_And = {
  arguments: Plaid_App_Revenuetransaction_Select_Column_Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Plaid_App_Revenuetransaction_Select_Column_Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Aggregate_Fields = {
  __typename?: 'plaid_app_revenuetransaction_aggregate_fields';
  avg?: Maybe<Plaid_App_Revenuetransaction_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Plaid_App_Revenuetransaction_Max_Fields>;
  min?: Maybe<Plaid_App_Revenuetransaction_Min_Fields>;
  stddev?: Maybe<Plaid_App_Revenuetransaction_Stddev_Fields>;
  stddev_pop?: Maybe<Plaid_App_Revenuetransaction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plaid_App_Revenuetransaction_Stddev_Samp_Fields>;
  sum?: Maybe<Plaid_App_Revenuetransaction_Sum_Fields>;
  var_pop?: Maybe<Plaid_App_Revenuetransaction_Var_Pop_Fields>;
  var_samp?: Maybe<Plaid_App_Revenuetransaction_Var_Samp_Fields>;
  variance?: Maybe<Plaid_App_Revenuetransaction_Variance_Fields>;
};


/** aggregate fields of "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Aggregate_Order_By = {
  avg?: InputMaybe<Plaid_App_Revenuetransaction_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plaid_App_Revenuetransaction_Max_Order_By>;
  min?: InputMaybe<Plaid_App_Revenuetransaction_Min_Order_By>;
  stddev?: InputMaybe<Plaid_App_Revenuetransaction_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Plaid_App_Revenuetransaction_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Plaid_App_Revenuetransaction_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Plaid_App_Revenuetransaction_Sum_Order_By>;
  var_pop?: InputMaybe<Plaid_App_Revenuetransaction_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Plaid_App_Revenuetransaction_Var_Samp_Order_By>;
  variance?: InputMaybe<Plaid_App_Revenuetransaction_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Revenuetransaction_Append_Input = {
  raw_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Arr_Rel_Insert_Input = {
  data: Array<Plaid_App_Revenuetransaction_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plaid_App_Revenuetransaction_On_Conflict>;
};

/** aggregate avg on columns */
export type Plaid_App_Revenuetransaction_Avg_Fields = {
  __typename?: 'plaid_app_revenuetransaction_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_revenue_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "plaid_app_revenuetransaction". All fields are combined with a logical 'AND'. */
export type Plaid_App_Revenuetransaction_Bool_Exp = {
  _and?: InputMaybe<Array<Plaid_App_Revenuetransaction_Bool_Exp>>;
  _not?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
  _or?: InputMaybe<Array<Plaid_App_Revenuetransaction_Bool_Exp>>;
  account_id?: InputMaybe<String_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  datetime?: InputMaybe<Timestamptz_Comparison_Exp>;
  deal_revenue_id?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payment_channel?: InputMaybe<String_Comparison_Exp>;
  raw_data?: InputMaybe<Jsonb_Comparison_Exp>;
  transaction_code?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<String_Comparison_Exp>;
  transaction_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "plaid_app_revenuetransaction" */
export enum Plaid_App_Revenuetransaction_Constraint {
  /** unique or primary key constraint on columns "deal_revenue_id", "transaction_id" */
  DealRevenueTransactionUniq = 'deal_revenue_transaction_uniq',
  /** unique or primary key constraint on columns "id" */
  PlaidAppRevenuetransactionPkey = 'plaid_app_revenuetransaction_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Plaid_App_Revenuetransaction_Delete_At_Path_Input = {
  raw_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Plaid_App_Revenuetransaction_Delete_Elem_Input = {
  raw_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Plaid_App_Revenuetransaction_Delete_Key_Input = {
  raw_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
  deal_revenue_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Insert_Input = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_revenue_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payment_channel?: InputMaybe<Scalars['String']['input']>;
  raw_data?: InputMaybe<Scalars['jsonb']['input']>;
  transaction_code?: InputMaybe<Scalars['String']['input']>;
  transaction_id?: InputMaybe<Scalars['String']['input']>;
  transaction_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Plaid_App_Revenuetransaction_Max_Fields = {
  __typename?: 'plaid_app_revenuetransaction_max_fields';
  account_id?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  datetime?: Maybe<Scalars['timestamptz']['output']>;
  deal_revenue_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payment_channel?: Maybe<Scalars['String']['output']>;
  transaction_code?: Maybe<Scalars['String']['output']>;
  transaction_id?: Maybe<Scalars['String']['output']>;
  transaction_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payment_channel?: InputMaybe<Order_By>;
  transaction_code?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plaid_App_Revenuetransaction_Min_Fields = {
  __typename?: 'plaid_app_revenuetransaction_min_fields';
  account_id?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['numeric']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date?: Maybe<Scalars['date']['output']>;
  datetime?: Maybe<Scalars['timestamptz']['output']>;
  deal_revenue_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payment_channel?: Maybe<Scalars['String']['output']>;
  transaction_code?: Maybe<Scalars['String']['output']>;
  transaction_id?: Maybe<Scalars['String']['output']>;
  transaction_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payment_channel?: InputMaybe<Order_By>;
  transaction_code?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Mutation_Response = {
  __typename?: 'plaid_app_revenuetransaction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Plaid_App_Revenuetransaction>;
};

/** on_conflict condition type for table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_On_Conflict = {
  constraint: Plaid_App_Revenuetransaction_Constraint;
  update_columns?: Array<Plaid_App_Revenuetransaction_Update_Column>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};

/** Ordering options when selecting data from "plaid_app_revenuetransaction". */
export type Plaid_App_Revenuetransaction_Order_By = {
  account_id?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  datetime?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payment_channel?: InputMaybe<Order_By>;
  raw_data?: InputMaybe<Order_By>;
  transaction_code?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  transaction_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plaid_app_revenuetransaction */
export type Plaid_App_Revenuetransaction_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Plaid_App_Revenuetransaction_Prepend_Input = {
  raw_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "plaid_app_revenuetransaction" */
export enum Plaid_App_Revenuetransaction_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DealRevenueId = 'deal_revenue_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentChannel = 'payment_channel',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  TransactionCode = 'transaction_code',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "plaid_app_revenuetransaction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "plaid_app_revenuetransaction" */
export enum Plaid_App_Revenuetransaction_Select_Column_Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "plaid_app_revenuetransaction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "plaid_app_revenuetransaction" */
export enum Plaid_App_Revenuetransaction_Select_Column_Plaid_App_Revenuetransaction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Set_Input = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_revenue_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payment_channel?: InputMaybe<Scalars['String']['input']>;
  raw_data?: InputMaybe<Scalars['jsonb']['input']>;
  transaction_code?: InputMaybe<Scalars['String']['input']>;
  transaction_id?: InputMaybe<Scalars['String']['input']>;
  transaction_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Plaid_App_Revenuetransaction_Stddev_Fields = {
  __typename?: 'plaid_app_revenuetransaction_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_revenue_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Plaid_App_Revenuetransaction_Stddev_Pop_Fields = {
  __typename?: 'plaid_app_revenuetransaction_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_revenue_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Plaid_App_Revenuetransaction_Stddev_Samp_Fields = {
  __typename?: 'plaid_app_revenuetransaction_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_revenue_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plaid_App_Revenuetransaction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plaid_App_Revenuetransaction_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date?: InputMaybe<Scalars['date']['input']>;
  datetime?: InputMaybe<Scalars['timestamptz']['input']>;
  deal_revenue_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payment_channel?: InputMaybe<Scalars['String']['input']>;
  raw_data?: InputMaybe<Scalars['jsonb']['input']>;
  transaction_code?: InputMaybe<Scalars['String']['input']>;
  transaction_id?: InputMaybe<Scalars['String']['input']>;
  transaction_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Plaid_App_Revenuetransaction_Sum_Fields = {
  __typename?: 'plaid_app_revenuetransaction_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
  deal_revenue_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "plaid_app_revenuetransaction" */
export enum Plaid_App_Revenuetransaction_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Datetime = 'datetime',
  /** column name */
  DealRevenueId = 'deal_revenue_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PaymentChannel = 'payment_channel',
  /** column name */
  RawData = 'raw_data',
  /** column name */
  TransactionCode = 'transaction_code',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  TransactionType = 'transaction_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Plaid_App_Revenuetransaction_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Plaid_App_Revenuetransaction_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Plaid_App_Revenuetransaction_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Plaid_App_Revenuetransaction_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Plaid_App_Revenuetransaction_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plaid_App_Revenuetransaction_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Plaid_App_Revenuetransaction_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plaid_App_Revenuetransaction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plaid_App_Revenuetransaction_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plaid_App_Revenuetransaction_Var_Pop_Fields = {
  __typename?: 'plaid_app_revenuetransaction_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_revenue_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Plaid_App_Revenuetransaction_Var_Samp_Fields = {
  __typename?: 'plaid_app_revenuetransaction_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_revenue_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Plaid_App_Revenuetransaction_Variance_Fields = {
  __typename?: 'plaid_app_revenuetransaction_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
  deal_revenue_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "plaid_app_revenuetransaction" */
export type Plaid_App_Revenuetransaction_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  deal_revenue_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "storage_buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage_buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage_buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** fetch data from the table: "storage_files" using primary key columns */
  file?: Maybe<Files>;
  /** fetch data from the table: "storage_files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage_files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table: "homegrown_business" */
  homegrown_business: Array<Homegrown_Business>;
  /** fetch aggregated fields from the table: "homegrown_business" */
  homegrown_business_aggregate: Homegrown_Business_Aggregate;
  /** fetch data from the table: "homegrown_business" using primary key columns */
  homegrown_business_by_pk?: Maybe<Homegrown_Business>;
  /** fetch data from the table: "homegrown_businessprivatedocuments" */
  homegrown_businessprivatedocuments: Array<Homegrown_Businessprivatedocuments>;
  /** fetch aggregated fields from the table: "homegrown_businessprivatedocuments" */
  homegrown_businessprivatedocuments_aggregate: Homegrown_Businessprivatedocuments_Aggregate;
  /** fetch data from the table: "homegrown_businessprivatedocuments" using primary key columns */
  homegrown_businessprivatedocuments_by_pk?: Maybe<Homegrown_Businessprivatedocuments>;
  /** fetch data from the table: "homegrown_deal" */
  homegrown_deal: Array<Homegrown_Deal>;
  /** fetch aggregated fields from the table: "homegrown_deal" */
  homegrown_deal_aggregate: Homegrown_Deal_Aggregate;
  /** fetch data from the table: "homegrown_deal" using primary key columns */
  homegrown_deal_by_pk?: Maybe<Homegrown_Deal>;
  /** fetch data from the table: "homegrown_dealdocument" */
  homegrown_dealdocument: Array<Homegrown_Dealdocument>;
  /** fetch aggregated fields from the table: "homegrown_dealdocument" */
  homegrown_dealdocument_aggregate: Homegrown_Dealdocument_Aggregate;
  /** fetch data from the table: "homegrown_dealdocument" using primary key columns */
  homegrown_dealdocument_by_pk?: Maybe<Homegrown_Dealdocument>;
  /** fetch data from the table: "homegrown_dealprivate" */
  homegrown_dealprivate: Array<Homegrown_Dealprivate>;
  /** fetch aggregated fields from the table: "homegrown_dealprivate" */
  homegrown_dealprivate_aggregate: Homegrown_Dealprivate_Aggregate;
  /** fetch data from the table: "homegrown_dealprivate" using primary key columns */
  homegrown_dealprivate_by_pk?: Maybe<Homegrown_Dealprivate>;
  /** fetch data from the table: "homegrown_dealprivatedocuments" */
  homegrown_dealprivatedocuments: Array<Homegrown_Dealprivatedocuments>;
  /** fetch aggregated fields from the table: "homegrown_dealprivatedocuments" */
  homegrown_dealprivatedocuments_aggregate: Homegrown_Dealprivatedocuments_Aggregate;
  /** fetch data from the table: "homegrown_dealprivatedocuments" using primary key columns */
  homegrown_dealprivatedocuments_by_pk?: Maybe<Homegrown_Dealprivatedocuments>;
  /** fetch data from the table: "homegrown_dealprivatedocumentstatus" */
  homegrown_dealprivatedocumentstatus: Array<Homegrown_Dealprivatedocumentstatus>;
  /** fetch aggregated fields from the table: "homegrown_dealprivatedocumentstatus" */
  homegrown_dealprivatedocumentstatus_aggregate: Homegrown_Dealprivatedocumentstatus_Aggregate;
  /** fetch data from the table: "homegrown_dealprivatedocumentstatus" using primary key columns */
  homegrown_dealprivatedocumentstatus_by_pk?: Maybe<Homegrown_Dealprivatedocumentstatus>;
  /** fetch data from the table: "homegrown_dealprivatedocumenttype" */
  homegrown_dealprivatedocumenttype: Array<Homegrown_Dealprivatedocumenttype>;
  /** fetch aggregated fields from the table: "homegrown_dealprivatedocumenttype" */
  homegrown_dealprivatedocumenttype_aggregate: Homegrown_Dealprivatedocumenttype_Aggregate;
  /** fetch data from the table: "homegrown_dealprivatedocumenttype" using primary key columns */
  homegrown_dealprivatedocumenttype_by_pk?: Maybe<Homegrown_Dealprivatedocumenttype>;
  /** fetch data from the table: "homegrown_dealtobusinessplaidlink" */
  homegrown_dealtobusinessplaidlink: Array<Homegrown_Dealtobusinessplaidlink>;
  /** fetch aggregated fields from the table: "homegrown_dealtobusinessplaidlink" */
  homegrown_dealtobusinessplaidlink_aggregate: Homegrown_Dealtobusinessplaidlink_Aggregate;
  /** fetch data from the table: "homegrown_dealtobusinessplaidlink" using primary key columns */
  homegrown_dealtobusinessplaidlink_by_pk?: Maybe<Homegrown_Dealtobusinessplaidlink>;
  /** fetch data from the table: "homegrown_dealtoinvestorlink" */
  homegrown_dealtoinvestorlink: Array<Homegrown_Dealtoinvestorlink>;
  /** fetch aggregated fields from the table: "homegrown_dealtoinvestorlink" */
  homegrown_dealtoinvestorlink_aggregate: Homegrown_Dealtoinvestorlink_Aggregate;
  /** fetch data from the table: "homegrown_dealtoinvestorlink" using primary key columns */
  homegrown_dealtoinvestorlink_by_pk?: Maybe<Homegrown_Dealtoinvestorlink>;
  /** fetch data from the table: "homegrown_dealtype" */
  homegrown_dealtype: Array<Homegrown_Dealtype>;
  /** fetch aggregated fields from the table: "homegrown_dealtype" */
  homegrown_dealtype_aggregate: Homegrown_Dealtype_Aggregate;
  /** fetch data from the table: "homegrown_dealtype" using primary key columns */
  homegrown_dealtype_by_pk?: Maybe<Homegrown_Dealtype>;
  /** fetch data from the table: "homegrown_location" */
  homegrown_location: Array<Homegrown_Location>;
  /** fetch aggregated fields from the table: "homegrown_location" */
  homegrown_location_aggregate: Homegrown_Location_Aggregate;
  /** fetch data from the table: "homegrown_location" using primary key columns */
  homegrown_location_by_pk?: Maybe<Homegrown_Location>;
  /** fetch data from the table: "homegrown_referralcode" */
  homegrown_referralcode: Array<Homegrown_Referralcode>;
  /** fetch aggregated fields from the table: "homegrown_referralcode" */
  homegrown_referralcode_aggregate: Homegrown_Referralcode_Aggregate;
  /** fetch data from the table: "homegrown_referralcode" using primary key columns */
  homegrown_referralcode_by_pk?: Maybe<Homegrown_Referralcode>;
  /** fetch data from the table: "homegrown_signwelldealdocument" */
  homegrown_signwelldealdocument: Array<Homegrown_Signwelldealdocument>;
  /** fetch aggregated fields from the table: "homegrown_signwelldealdocument" */
  homegrown_signwelldealdocument_aggregate: Homegrown_Signwelldealdocument_Aggregate;
  /** fetch data from the table: "homegrown_signwelldealdocument" using primary key columns */
  homegrown_signwelldealdocument_by_pk?: Maybe<Homegrown_Signwelldealdocument>;
  /** fetch data from the table: "homegrown_signwellinvestorsigneddocument" */
  homegrown_signwellinvestorsigneddocument: Array<Homegrown_Signwellinvestorsigneddocument>;
  /** fetch aggregated fields from the table: "homegrown_signwellinvestorsigneddocument" */
  homegrown_signwellinvestorsigneddocument_aggregate: Homegrown_Signwellinvestorsigneddocument_Aggregate;
  /** fetch data from the table: "homegrown_signwellinvestorsigneddocument" using primary key columns */
  homegrown_signwellinvestorsigneddocument_by_pk?: Maybe<Homegrown_Signwellinvestorsigneddocument>;
  /** fetch data from the table: "homegrown_taxdocuments" */
  homegrown_taxdocuments: Array<Homegrown_Taxdocuments>;
  /** fetch aggregated fields from the table: "homegrown_taxdocuments" */
  homegrown_taxdocuments_aggregate: Homegrown_Taxdocuments_Aggregate;
  /** fetch data from the table: "homegrown_taxdocuments" using primary key columns */
  homegrown_taxdocuments_by_pk?: Maybe<Homegrown_Taxdocuments>;
  /** fetch data from the table: "homegrown_usedreferralcode" */
  homegrown_usedreferralcode: Array<Homegrown_Usedreferralcode>;
  /** fetch aggregated fields from the table: "homegrown_usedreferralcode" */
  homegrown_usedreferralcode_aggregate: Homegrown_Usedreferralcode_Aggregate;
  /** fetch data from the table: "homegrown_usedreferralcode" using primary key columns */
  homegrown_usedreferralcode_by_pk?: Maybe<Homegrown_Usedreferralcode>;
  /** fetch data from the table: "homegrown_userdocuments" */
  homegrown_userdocuments: Array<Homegrown_Userdocuments>;
  /** fetch aggregated fields from the table: "homegrown_userdocuments" */
  homegrown_userdocuments_aggregate: Homegrown_Userdocuments_Aggregate;
  /** fetch data from the table: "homegrown_userdocuments" using primary key columns */
  homegrown_userdocuments_by_pk?: Maybe<Homegrown_Userdocuments>;
  /** fetch data from the table: "homegrown_userdocumenttype" */
  homegrown_userdocumenttype: Array<Homegrown_Userdocumenttype>;
  /** fetch aggregated fields from the table: "homegrown_userdocumenttype" */
  homegrown_userdocumenttype_aggregate: Homegrown_Userdocumenttype_Aggregate;
  /** fetch data from the table: "homegrown_userdocumenttype" using primary key columns */
  homegrown_userdocumenttype_by_pk?: Maybe<Homegrown_Userdocumenttype>;
  /** An array relationship */
  investments: Array<Investments>;
  /** An aggregate relationship */
  investments_aggregate: Investments_Aggregate;
  /** fetch data from the table: "plaid_app_investment" using primary key columns */
  investments_by_pk?: Maybe<Investments>;
  /** fetch data from the table: "users_investordata" */
  investordata: Array<Investordata>;
  /** fetch aggregated fields from the table: "users_investordata" */
  investordata_aggregate: Investordata_Aggregate;
  /** fetch data from the table: "users_investordata" using primary key columns */
  investordata_by_pk?: Maybe<Investordata>;
  /** fetch data from the table: "plaid_app_dealbusinessrevenue" */
  plaid_app_dealbusinessrevenue: Array<Plaid_App_Dealbusinessrevenue>;
  /** fetch aggregated fields from the table: "plaid_app_dealbusinessrevenue" */
  plaid_app_dealbusinessrevenue_aggregate: Plaid_App_Dealbusinessrevenue_Aggregate;
  /** fetch data from the table: "plaid_app_dealbusinessrevenue" using primary key columns */
  plaid_app_dealbusinessrevenue_by_pk?: Maybe<Plaid_App_Dealbusinessrevenue>;
  /** fetch data from the table: "plaid_app_dealpayback" */
  plaid_app_dealpayback: Array<Plaid_App_Dealpayback>;
  /** fetch aggregated fields from the table: "plaid_app_dealpayback" */
  plaid_app_dealpayback_aggregate: Plaid_App_Dealpayback_Aggregate;
  /** fetch data from the table: "plaid_app_dealpayback" using primary key columns */
  plaid_app_dealpayback_by_pk?: Maybe<Plaid_App_Dealpayback>;
  /** fetch data from the table: "plaid_app_dealpayout" */
  plaid_app_dealpayout: Array<Plaid_App_Dealpayout>;
  /** fetch aggregated fields from the table: "plaid_app_dealpayout" */
  plaid_app_dealpayout_aggregate: Plaid_App_Dealpayout_Aggregate;
  /** fetch data from the table: "plaid_app_dealpayout" using primary key columns */
  plaid_app_dealpayout_by_pk?: Maybe<Plaid_App_Dealpayout>;
  /** fetch data from the table: "plaid_app_dealtransfer" */
  plaid_app_dealtransfer: Array<Plaid_App_Dealtransfer>;
  /** fetch aggregated fields from the table: "plaid_app_dealtransfer" */
  plaid_app_dealtransfer_aggregate: Plaid_App_Dealtransfer_Aggregate;
  /** fetch data from the table: "plaid_app_dealtransfer" using primary key columns */
  plaid_app_dealtransfer_by_pk?: Maybe<Plaid_App_Dealtransfer>;
  /** fetch data from the table: "plaid_app_plaidlink" */
  plaid_app_plaidlink: Array<Plaid_App_Plaidlink>;
  /** fetch aggregated fields from the table: "plaid_app_plaidlink" */
  plaid_app_plaidlink_aggregate: Plaid_App_Plaidlink_Aggregate;
  /** fetch data from the table: "plaid_app_plaidlink" using primary key columns */
  plaid_app_plaidlink_by_pk?: Maybe<Plaid_App_Plaidlink>;
  /** fetch data from the table: "plaid_app_plaidlinkbusiness" */
  plaid_app_plaidlinkbusiness: Array<Plaid_App_Plaidlinkbusiness>;
  /** fetch aggregated fields from the table: "plaid_app_plaidlinkbusiness" */
  plaid_app_plaidlinkbusiness_aggregate: Plaid_App_Plaidlinkbusiness_Aggregate;
  /** fetch data from the table: "plaid_app_plaidlinkbusiness" using primary key columns */
  plaid_app_plaidlinkbusiness_by_pk?: Maybe<Plaid_App_Plaidlinkbusiness>;
  /** fetch data from the table: "plaid_app_plaidlinkstatus" */
  plaid_app_plaidlinkstatus: Array<Plaid_App_Plaidlinkstatus>;
  /** fetch aggregated fields from the table: "plaid_app_plaidlinkstatus" */
  plaid_app_plaidlinkstatus_aggregate: Plaid_App_Plaidlinkstatus_Aggregate;
  /** fetch data from the table: "plaid_app_plaidlinkstatus" using primary key columns */
  plaid_app_plaidlinkstatus_by_pk?: Maybe<Plaid_App_Plaidlinkstatus>;
  /** fetch data from the table: "plaid_app_plaidtransfer" */
  plaid_app_plaidtransfer: Array<Plaid_App_Plaidtransfer>;
  /** fetch aggregated fields from the table: "plaid_app_plaidtransfer" */
  plaid_app_plaidtransfer_aggregate: Plaid_App_Plaidtransfer_Aggregate;
  /** fetch data from the table: "plaid_app_plaidtransfer" using primary key columns */
  plaid_app_plaidtransfer_by_pk?: Maybe<Plaid_App_Plaidtransfer>;
  /** fetch data from the table: "plaid_app_plaidtransferevent" */
  plaid_app_plaidtransferevent: Array<Plaid_App_Plaidtransferevent>;
  /** fetch aggregated fields from the table: "plaid_app_plaidtransferevent" */
  plaid_app_plaidtransferevent_aggregate: Plaid_App_Plaidtransferevent_Aggregate;
  /** fetch data from the table: "plaid_app_plaidtransferevent" using primary key columns */
  plaid_app_plaidtransferevent_by_pk?: Maybe<Plaid_App_Plaidtransferevent>;
  /** fetch data from the table: "plaid_app_revenuetransaction" */
  plaid_app_revenuetransaction: Array<Plaid_App_Revenuetransaction>;
  /** fetch aggregated fields from the table: "plaid_app_revenuetransaction" */
  plaid_app_revenuetransaction_aggregate: Plaid_App_Revenuetransaction_Aggregate;
  /** fetch data from the table: "plaid_app_revenuetransaction" using primary key columns */
  plaid_app_revenuetransaction_by_pk?: Maybe<Plaid_App_Revenuetransaction>;
  /** fetch data from the table: "treasuryprime_app_achtransfer" */
  treasuryprime_app_achtransfer: Array<Treasuryprime_App_Achtransfer>;
  /** fetch aggregated fields from the table: "treasuryprime_app_achtransfer" */
  treasuryprime_app_achtransfer_aggregate: Treasuryprime_App_Achtransfer_Aggregate;
  /** fetch data from the table: "treasuryprime_app_achtransfer" using primary key columns */
  treasuryprime_app_achtransfer_by_pk?: Maybe<Treasuryprime_App_Achtransfer>;
  /** fetch data from the table: "treasuryprime_app_counterpartylink" */
  treasuryprime_app_counterpartylink: Array<Treasuryprime_App_Counterpartylink>;
  /** fetch aggregated fields from the table: "treasuryprime_app_counterpartylink" */
  treasuryprime_app_counterpartylink_aggregate: Treasuryprime_App_Counterpartylink_Aggregate;
  /** fetch data from the table: "treasuryprime_app_counterpartylink" using primary key columns */
  treasuryprime_app_counterpartylink_by_pk?: Maybe<Treasuryprime_App_Counterpartylink>;
  /** fetch data from the table: "treasuryprime_app_counterpartylinkbusiness" */
  treasuryprime_app_counterpartylinkbusiness: Array<Treasuryprime_App_Counterpartylinkbusiness>;
  /** fetch aggregated fields from the table: "treasuryprime_app_counterpartylinkbusiness" */
  treasuryprime_app_counterpartylinkbusiness_aggregate: Treasuryprime_App_Counterpartylinkbusiness_Aggregate;
  /** fetch data from the table: "treasuryprime_app_counterpartylinkbusiness" using primary key columns */
  treasuryprime_app_counterpartylinkbusiness_by_pk?: Maybe<Treasuryprime_App_Counterpartylinkbusiness>;
  /** fetch data from the table: "users_businessowner" */
  users_businessowner: Array<Users_Businessowner>;
  /** fetch aggregated fields from the table: "users_businessowner" */
  users_businessowner_aggregate: Users_Businessowner_Aggregate;
  /** fetch data from the table: "users_businessowner" using primary key columns */
  users_businessowner_by_pk?: Maybe<Users_Businessowner>;
  /** fetch data from the table: "users_employee" */
  users_employee: Array<Users_Employee>;
  /** fetch aggregated fields from the table: "users_employee" */
  users_employee_aggregate: Users_Employee_Aggregate;
  /** fetch data from the table: "users_employee" using primary key columns */
  users_employee_by_pk?: Maybe<Users_Employee>;
  /** fetch data from the table: "users_investorbusiness" */
  users_investorbusiness: Array<Users_Investorbusiness>;
  /** fetch aggregated fields from the table: "users_investorbusiness" */
  users_investorbusiness_aggregate: Users_Investorbusiness_Aggregate;
  /** fetch data from the table: "users_investorbusiness" using primary key columns */
  users_investorbusiness_by_pk?: Maybe<Users_Investorbusiness>;
  /** fetch data from the table: "users_roleorder" */
  users_roleorder: Array<Users_Roleorder>;
  /** fetch aggregated fields from the table: "users_roleorder" */
  users_roleorder_aggregate: Users_Roleorder_Aggregate;
  /** fetch data from the table: "users_roleorder" using primary key columns */
  users_roleorder_by_pk?: Maybe<Users_Roleorder>;
  /** fetch data from the table: "users_secondaryinvestordata" */
  users_secondaryinvestordata: Array<Users_Secondaryinvestordata>;
  /** fetch aggregated fields from the table: "users_secondaryinvestordata" */
  users_secondaryinvestordata_aggregate: Users_Secondaryinvestordata_Aggregate;
  /** fetch data from the table: "users_secondaryinvestordata" using primary key columns */
  users_secondaryinvestordata_by_pk?: Maybe<Users_Secondaryinvestordata>;
  /** fetch data from the table: "users_secondaryinvestordocuments" */
  users_secondaryinvestordocuments: Array<Users_Secondaryinvestordocuments>;
  /** fetch aggregated fields from the table: "users_secondaryinvestordocuments" */
  users_secondaryinvestordocuments_aggregate: Users_Secondaryinvestordocuments_Aggregate;
  /** fetch data from the table: "users_secondaryinvestordocuments" using primary key columns */
  users_secondaryinvestordocuments_by_pk?: Maybe<Users_Secondaryinvestordocuments>;
  /** fetch data from the table: "users_secondaryinvestorinvite" */
  users_secondaryinvestorinvite: Array<Users_Secondaryinvestorinvite>;
  /** fetch aggregated fields from the table: "users_secondaryinvestorinvite" */
  users_secondaryinvestorinvite_aggregate: Users_Secondaryinvestorinvite_Aggregate;
  /** fetch data from the table: "users_secondaryinvestorinvite" using primary key columns */
  users_secondaryinvestorinvite_by_pk?: Maybe<Users_Secondaryinvestorinvite>;
  /** fetch data from the table: "users_user" */
  users_user: Array<Users_User>;
  /** fetch aggregated fields from the table: "users_user" */
  users_user_aggregate: Users_User_Aggregate;
  /** fetch data from the table: "users_user" using primary key columns */
  users_user_by_pk?: Maybe<Users_User>;
  /** fetch data from the table: "users_userinterests" */
  users_userinterests: Array<Users_Userinterests>;
  /** fetch aggregated fields from the table: "users_userinterests" */
  users_userinterests_aggregate: Users_Userinterests_Aggregate;
  /** fetch data from the table: "users_userinterests" using primary key columns */
  users_userinterests_by_pk?: Maybe<Users_Userinterests>;
  /** fetch data from the table: "users_usernotifications" */
  users_usernotifications: Array<Users_Usernotifications>;
  /** fetch aggregated fields from the table: "users_usernotifications" */
  users_usernotifications_aggregate: Users_Usernotifications_Aggregate;
  /** fetch data from the table: "users_usernotifications" using primary key columns */
  users_usernotifications_by_pk?: Maybe<Users_Usernotifications>;
  /** fetch data from the table: "users_userpreferences" */
  users_userpreferences: Array<Users_Userpreferences>;
  /** fetch aggregated fields from the table: "users_userpreferences" */
  users_userpreferences_aggregate: Users_Userpreferences_Aggregate;
  /** fetch data from the table: "users_userpreferences" using primary key columns */
  users_userpreferences_by_pk?: Maybe<Users_Userpreferences>;
  /** fetch data from the table: "users_usersession" */
  users_usersession: Array<Users_Usersession>;
  /** fetch aggregated fields from the table: "users_usersession" */
  users_usersession_aggregate: Users_Usersession_Aggregate;
  /** fetch data from the table: "users_usersession" using primary key columns */
  users_usersession_by_pk?: Maybe<Users_Usersession>;
};


export type Query_RootBucketArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Query_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Query_RootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootHomegrown_BusinessArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Business_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Business_Order_By>>;
  where?: InputMaybe<Homegrown_Business_Bool_Exp>;
};


export type Query_RootHomegrown_Business_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Business_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Business_Order_By>>;
  where?: InputMaybe<Homegrown_Business_Bool_Exp>;
};


export type Query_RootHomegrown_Business_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_BusinessprivatedocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Businessprivatedocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Businessprivatedocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_DealArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Deal_Order_By>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


export type Query_RootHomegrown_Deal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Deal_Order_By>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


export type Query_RootHomegrown_Deal_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_DealdocumentArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};


export type Query_RootHomegrown_Dealdocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};


export type Query_RootHomegrown_Dealdocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_DealprivateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivate_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivate_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivate_By_PkArgs = {
  deal_id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_DealprivatedocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivatedocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivatedocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_DealprivatedocumentstatusArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivatedocumentstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivatedocumentstatus_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootHomegrown_DealprivatedocumenttypeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivatedocumenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
};


export type Query_RootHomegrown_Dealprivatedocumenttype_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootHomegrown_DealtobusinessplaidlinkArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


export type Query_RootHomegrown_Dealtobusinessplaidlink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


export type Query_RootHomegrown_Dealtobusinessplaidlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_DealtoinvestorlinkArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};


export type Query_RootHomegrown_Dealtoinvestorlink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};


export type Query_RootHomegrown_Dealtoinvestorlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_DealtypeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtype_Bool_Exp>;
};


export type Query_RootHomegrown_Dealtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtype_Bool_Exp>;
};


export type Query_RootHomegrown_Dealtype_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootHomegrown_LocationArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Location_Order_By>>;
  where?: InputMaybe<Homegrown_Location_Bool_Exp>;
};


export type Query_RootHomegrown_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Location_Order_By>>;
  where?: InputMaybe<Homegrown_Location_Bool_Exp>;
};


export type Query_RootHomegrown_Location_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_ReferralcodeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Referralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Referralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
};


export type Query_RootHomegrown_Referralcode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Referralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Referralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
};


export type Query_RootHomegrown_Referralcode_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootHomegrown_SignwelldealdocumentArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwelldealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};


export type Query_RootHomegrown_Signwelldealdocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwelldealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};


export type Query_RootHomegrown_Signwelldealdocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_SignwellinvestorsigneddocumentArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


export type Query_RootHomegrown_Signwellinvestorsigneddocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


export type Query_RootHomegrown_Signwellinvestorsigneddocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_TaxdocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Taxdocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Taxdocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_UsedreferralcodeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Usedreferralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};


export type Query_RootHomegrown_Usedreferralcode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Usedreferralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};


export type Query_RootHomegrown_Usedreferralcode_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootHomegrown_UserdocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Userdocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


export type Query_RootHomegrown_Userdocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootHomegrown_UserdocumenttypeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
};


export type Query_RootHomegrown_Userdocumenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
};


export type Query_RootHomegrown_Userdocumenttype_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


export type Query_RootInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


export type Query_RootInvestments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootInvestordataArgs = {
  distinct_on?: InputMaybe<Array<Investordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investordata_Order_By>>;
  where?: InputMaybe<Investordata_Bool_Exp>;
};


export type Query_RootInvestordata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investordata_Order_By>>;
  where?: InputMaybe<Investordata_Bool_Exp>;
};


export type Query_RootInvestordata_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootPlaid_App_DealbusinessrevenueArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealbusinessrevenue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealbusinessrevenue_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPlaid_App_DealpaybackArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayback_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealpayback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayback_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealpayback_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPlaid_App_DealpayoutArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealpayout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealpayout_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPlaid_App_DealtransferArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealtransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
};


export type Query_RootPlaid_App_Dealtransfer_By_PkArgs = {
  payout_id: Scalars['String']['input'];
};


export type Query_RootPlaid_App_PlaidlinkArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlink_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidlink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlink_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPlaid_App_PlaidlinkbusinessArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidlinkbusiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidlinkbusiness_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPlaid_App_PlaidlinkstatusArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkstatus_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidlinkstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkstatus_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidlinkstatus_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Query_RootPlaid_App_PlaidtransferArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidtransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidtransfer_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootPlaid_App_PlaidtransfereventArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransferevent_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidtransferevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransferevent_Bool_Exp>;
};


export type Query_RootPlaid_App_Plaidtransferevent_By_PkArgs = {
  event_id: Scalars['Int']['input'];
};


export type Query_RootPlaid_App_RevenuetransactionArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Revenuetransaction_Order_By>>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};


export type Query_RootPlaid_App_Revenuetransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Revenuetransaction_Order_By>>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};


export type Query_RootPlaid_App_Revenuetransaction_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootTreasuryprime_App_AchtransferArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};


export type Query_RootTreasuryprime_App_Achtransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};


export type Query_RootTreasuryprime_App_Achtransfer_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTreasuryprime_App_CounterpartylinkArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};


export type Query_RootTreasuryprime_App_Counterpartylink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};


export type Query_RootTreasuryprime_App_Counterpartylink_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootTreasuryprime_App_CounterpartylinkbusinessArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};


export type Query_RootTreasuryprime_App_Counterpartylinkbusiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};


export type Query_RootTreasuryprime_App_Counterpartylinkbusiness_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Query_RootUsers_BusinessownerArgs = {
  distinct_on?: InputMaybe<Array<Users_Businessowner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Businessowner_Order_By>>;
  where?: InputMaybe<Users_Businessowner_Bool_Exp>;
};


export type Query_RootUsers_Businessowner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Businessowner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Businessowner_Order_By>>;
  where?: InputMaybe<Users_Businessowner_Bool_Exp>;
};


export type Query_RootUsers_Businessowner_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootUsers_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Users_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Employee_Order_By>>;
  where?: InputMaybe<Users_Employee_Bool_Exp>;
};


export type Query_RootUsers_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Employee_Order_By>>;
  where?: InputMaybe<Users_Employee_Bool_Exp>;
};


export type Query_RootUsers_Employee_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUsers_InvestorbusinessArgs = {
  distinct_on?: InputMaybe<Array<Users_Investorbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Investorbusiness_Order_By>>;
  where?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
};


export type Query_RootUsers_Investorbusiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Investorbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Investorbusiness_Order_By>>;
  where?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
};


export type Query_RootUsers_Investorbusiness_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUsers_RoleorderArgs = {
  distinct_on?: InputMaybe<Array<Users_Roleorder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Roleorder_Order_By>>;
  where?: InputMaybe<Users_Roleorder_Bool_Exp>;
};


export type Query_RootUsers_Roleorder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Roleorder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Roleorder_Order_By>>;
  where?: InputMaybe<Users_Roleorder_Bool_Exp>;
};


export type Query_RootUsers_Roleorder_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUsers_SecondaryinvestordataArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordata_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};


export type Query_RootUsers_Secondaryinvestordata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordata_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};


export type Query_RootUsers_Secondaryinvestordata_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUsers_SecondaryinvestordocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordocuments_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};


export type Query_RootUsers_Secondaryinvestordocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordocuments_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};


export type Query_RootUsers_Secondaryinvestordocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUsers_SecondaryinvestorinviteArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestorinvite_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};


export type Query_RootUsers_Secondaryinvestorinvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestorinvite_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};


export type Query_RootUsers_Secondaryinvestorinvite_By_PkArgs = {
  invite_code: Scalars['String']['input'];
};


export type Query_RootUsers_UserArgs = {
  distinct_on?: InputMaybe<Array<Users_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_User_Order_By>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};


export type Query_RootUsers_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_User_Order_By>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};


export type Query_RootUsers_User_By_PkArgs = {
  auth0id: Scalars['String']['input'];
};


export type Query_RootUsers_UserinterestsArgs = {
  distinct_on?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userinterests_Order_By>>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};


export type Query_RootUsers_Userinterests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userinterests_Order_By>>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};


export type Query_RootUsers_Userinterests_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUsers_UsernotificationsArgs = {
  distinct_on?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usernotifications_Order_By>>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};


export type Query_RootUsers_Usernotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usernotifications_Order_By>>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};


export type Query_RootUsers_Usernotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootUsers_UserpreferencesArgs = {
  distinct_on?: InputMaybe<Array<Users_Userpreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userpreferences_Order_By>>;
  where?: InputMaybe<Users_Userpreferences_Bool_Exp>;
};


export type Query_RootUsers_Userpreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Userpreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userpreferences_Order_By>>;
  where?: InputMaybe<Users_Userpreferences_Bool_Exp>;
};


export type Query_RootUsers_Userpreferences_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Query_RootUsers_UsersessionArgs = {
  distinct_on?: InputMaybe<Array<Users_Usersession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usersession_Order_By>>;
  where?: InputMaybe<Users_Usersession_Bool_Exp>;
};


export type Query_RootUsers_Usersession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Usersession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usersession_Order_By>>;
  where?: InputMaybe<Users_Usersession_Bool_Exp>;
};


export type Query_RootUsers_Usersession_By_PkArgs = {
  id: Scalars['bigint']['input'];
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "storage_buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage_buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage_buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** fetch data from the table in a streaming manner: "storage_buckets" */
  buckets_stream: Array<Buckets>;
  /** fetch data from the table: "storage_files" using primary key columns */
  file?: Maybe<Files>;
  /** fetch data from the table: "storage_files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage_files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table in a streaming manner: "storage_files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "homegrown_business" */
  homegrown_business: Array<Homegrown_Business>;
  /** fetch aggregated fields from the table: "homegrown_business" */
  homegrown_business_aggregate: Homegrown_Business_Aggregate;
  /** fetch data from the table: "homegrown_business" using primary key columns */
  homegrown_business_by_pk?: Maybe<Homegrown_Business>;
  /** fetch data from the table in a streaming manner: "homegrown_business" */
  homegrown_business_stream: Array<Homegrown_Business>;
  /** fetch data from the table: "homegrown_businessprivatedocuments" */
  homegrown_businessprivatedocuments: Array<Homegrown_Businessprivatedocuments>;
  /** fetch aggregated fields from the table: "homegrown_businessprivatedocuments" */
  homegrown_businessprivatedocuments_aggregate: Homegrown_Businessprivatedocuments_Aggregate;
  /** fetch data from the table: "homegrown_businessprivatedocuments" using primary key columns */
  homegrown_businessprivatedocuments_by_pk?: Maybe<Homegrown_Businessprivatedocuments>;
  /** fetch data from the table in a streaming manner: "homegrown_businessprivatedocuments" */
  homegrown_businessprivatedocuments_stream: Array<Homegrown_Businessprivatedocuments>;
  /** fetch data from the table: "homegrown_deal" */
  homegrown_deal: Array<Homegrown_Deal>;
  /** fetch aggregated fields from the table: "homegrown_deal" */
  homegrown_deal_aggregate: Homegrown_Deal_Aggregate;
  /** fetch data from the table: "homegrown_deal" using primary key columns */
  homegrown_deal_by_pk?: Maybe<Homegrown_Deal>;
  /** fetch data from the table in a streaming manner: "homegrown_deal" */
  homegrown_deal_stream: Array<Homegrown_Deal>;
  /** fetch data from the table: "homegrown_dealdocument" */
  homegrown_dealdocument: Array<Homegrown_Dealdocument>;
  /** fetch aggregated fields from the table: "homegrown_dealdocument" */
  homegrown_dealdocument_aggregate: Homegrown_Dealdocument_Aggregate;
  /** fetch data from the table: "homegrown_dealdocument" using primary key columns */
  homegrown_dealdocument_by_pk?: Maybe<Homegrown_Dealdocument>;
  /** fetch data from the table in a streaming manner: "homegrown_dealdocument" */
  homegrown_dealdocument_stream: Array<Homegrown_Dealdocument>;
  /** fetch data from the table: "homegrown_dealprivate" */
  homegrown_dealprivate: Array<Homegrown_Dealprivate>;
  /** fetch aggregated fields from the table: "homegrown_dealprivate" */
  homegrown_dealprivate_aggregate: Homegrown_Dealprivate_Aggregate;
  /** fetch data from the table: "homegrown_dealprivate" using primary key columns */
  homegrown_dealprivate_by_pk?: Maybe<Homegrown_Dealprivate>;
  /** fetch data from the table in a streaming manner: "homegrown_dealprivate" */
  homegrown_dealprivate_stream: Array<Homegrown_Dealprivate>;
  /** fetch data from the table: "homegrown_dealprivatedocuments" */
  homegrown_dealprivatedocuments: Array<Homegrown_Dealprivatedocuments>;
  /** fetch aggregated fields from the table: "homegrown_dealprivatedocuments" */
  homegrown_dealprivatedocuments_aggregate: Homegrown_Dealprivatedocuments_Aggregate;
  /** fetch data from the table: "homegrown_dealprivatedocuments" using primary key columns */
  homegrown_dealprivatedocuments_by_pk?: Maybe<Homegrown_Dealprivatedocuments>;
  /** fetch data from the table in a streaming manner: "homegrown_dealprivatedocuments" */
  homegrown_dealprivatedocuments_stream: Array<Homegrown_Dealprivatedocuments>;
  /** fetch data from the table: "homegrown_dealprivatedocumentstatus" */
  homegrown_dealprivatedocumentstatus: Array<Homegrown_Dealprivatedocumentstatus>;
  /** fetch aggregated fields from the table: "homegrown_dealprivatedocumentstatus" */
  homegrown_dealprivatedocumentstatus_aggregate: Homegrown_Dealprivatedocumentstatus_Aggregate;
  /** fetch data from the table: "homegrown_dealprivatedocumentstatus" using primary key columns */
  homegrown_dealprivatedocumentstatus_by_pk?: Maybe<Homegrown_Dealprivatedocumentstatus>;
  /** fetch data from the table in a streaming manner: "homegrown_dealprivatedocumentstatus" */
  homegrown_dealprivatedocumentstatus_stream: Array<Homegrown_Dealprivatedocumentstatus>;
  /** fetch data from the table: "homegrown_dealprivatedocumenttype" */
  homegrown_dealprivatedocumenttype: Array<Homegrown_Dealprivatedocumenttype>;
  /** fetch aggregated fields from the table: "homegrown_dealprivatedocumenttype" */
  homegrown_dealprivatedocumenttype_aggregate: Homegrown_Dealprivatedocumenttype_Aggregate;
  /** fetch data from the table: "homegrown_dealprivatedocumenttype" using primary key columns */
  homegrown_dealprivatedocumenttype_by_pk?: Maybe<Homegrown_Dealprivatedocumenttype>;
  /** fetch data from the table in a streaming manner: "homegrown_dealprivatedocumenttype" */
  homegrown_dealprivatedocumenttype_stream: Array<Homegrown_Dealprivatedocumenttype>;
  /** fetch data from the table: "homegrown_dealtobusinessplaidlink" */
  homegrown_dealtobusinessplaidlink: Array<Homegrown_Dealtobusinessplaidlink>;
  /** fetch aggregated fields from the table: "homegrown_dealtobusinessplaidlink" */
  homegrown_dealtobusinessplaidlink_aggregate: Homegrown_Dealtobusinessplaidlink_Aggregate;
  /** fetch data from the table: "homegrown_dealtobusinessplaidlink" using primary key columns */
  homegrown_dealtobusinessplaidlink_by_pk?: Maybe<Homegrown_Dealtobusinessplaidlink>;
  /** fetch data from the table in a streaming manner: "homegrown_dealtobusinessplaidlink" */
  homegrown_dealtobusinessplaidlink_stream: Array<Homegrown_Dealtobusinessplaidlink>;
  /** fetch data from the table: "homegrown_dealtoinvestorlink" */
  homegrown_dealtoinvestorlink: Array<Homegrown_Dealtoinvestorlink>;
  /** fetch aggregated fields from the table: "homegrown_dealtoinvestorlink" */
  homegrown_dealtoinvestorlink_aggregate: Homegrown_Dealtoinvestorlink_Aggregate;
  /** fetch data from the table: "homegrown_dealtoinvestorlink" using primary key columns */
  homegrown_dealtoinvestorlink_by_pk?: Maybe<Homegrown_Dealtoinvestorlink>;
  /** fetch data from the table in a streaming manner: "homegrown_dealtoinvestorlink" */
  homegrown_dealtoinvestorlink_stream: Array<Homegrown_Dealtoinvestorlink>;
  /** fetch data from the table: "homegrown_dealtype" */
  homegrown_dealtype: Array<Homegrown_Dealtype>;
  /** fetch aggregated fields from the table: "homegrown_dealtype" */
  homegrown_dealtype_aggregate: Homegrown_Dealtype_Aggregate;
  /** fetch data from the table: "homegrown_dealtype" using primary key columns */
  homegrown_dealtype_by_pk?: Maybe<Homegrown_Dealtype>;
  /** fetch data from the table in a streaming manner: "homegrown_dealtype" */
  homegrown_dealtype_stream: Array<Homegrown_Dealtype>;
  /** fetch data from the table: "homegrown_location" */
  homegrown_location: Array<Homegrown_Location>;
  /** fetch aggregated fields from the table: "homegrown_location" */
  homegrown_location_aggregate: Homegrown_Location_Aggregate;
  /** fetch data from the table: "homegrown_location" using primary key columns */
  homegrown_location_by_pk?: Maybe<Homegrown_Location>;
  /** fetch data from the table in a streaming manner: "homegrown_location" */
  homegrown_location_stream: Array<Homegrown_Location>;
  /** fetch data from the table: "homegrown_referralcode" */
  homegrown_referralcode: Array<Homegrown_Referralcode>;
  /** fetch aggregated fields from the table: "homegrown_referralcode" */
  homegrown_referralcode_aggregate: Homegrown_Referralcode_Aggregate;
  /** fetch data from the table: "homegrown_referralcode" using primary key columns */
  homegrown_referralcode_by_pk?: Maybe<Homegrown_Referralcode>;
  /** fetch data from the table in a streaming manner: "homegrown_referralcode" */
  homegrown_referralcode_stream: Array<Homegrown_Referralcode>;
  /** fetch data from the table: "homegrown_signwelldealdocument" */
  homegrown_signwelldealdocument: Array<Homegrown_Signwelldealdocument>;
  /** fetch aggregated fields from the table: "homegrown_signwelldealdocument" */
  homegrown_signwelldealdocument_aggregate: Homegrown_Signwelldealdocument_Aggregate;
  /** fetch data from the table: "homegrown_signwelldealdocument" using primary key columns */
  homegrown_signwelldealdocument_by_pk?: Maybe<Homegrown_Signwelldealdocument>;
  /** fetch data from the table in a streaming manner: "homegrown_signwelldealdocument" */
  homegrown_signwelldealdocument_stream: Array<Homegrown_Signwelldealdocument>;
  /** fetch data from the table: "homegrown_signwellinvestorsigneddocument" */
  homegrown_signwellinvestorsigneddocument: Array<Homegrown_Signwellinvestorsigneddocument>;
  /** fetch aggregated fields from the table: "homegrown_signwellinvestorsigneddocument" */
  homegrown_signwellinvestorsigneddocument_aggregate: Homegrown_Signwellinvestorsigneddocument_Aggregate;
  /** fetch data from the table: "homegrown_signwellinvestorsigneddocument" using primary key columns */
  homegrown_signwellinvestorsigneddocument_by_pk?: Maybe<Homegrown_Signwellinvestorsigneddocument>;
  /** fetch data from the table in a streaming manner: "homegrown_signwellinvestorsigneddocument" */
  homegrown_signwellinvestorsigneddocument_stream: Array<Homegrown_Signwellinvestorsigneddocument>;
  /** fetch data from the table: "homegrown_taxdocuments" */
  homegrown_taxdocuments: Array<Homegrown_Taxdocuments>;
  /** fetch aggregated fields from the table: "homegrown_taxdocuments" */
  homegrown_taxdocuments_aggregate: Homegrown_Taxdocuments_Aggregate;
  /** fetch data from the table: "homegrown_taxdocuments" using primary key columns */
  homegrown_taxdocuments_by_pk?: Maybe<Homegrown_Taxdocuments>;
  /** fetch data from the table in a streaming manner: "homegrown_taxdocuments" */
  homegrown_taxdocuments_stream: Array<Homegrown_Taxdocuments>;
  /** fetch data from the table: "homegrown_usedreferralcode" */
  homegrown_usedreferralcode: Array<Homegrown_Usedreferralcode>;
  /** fetch aggregated fields from the table: "homegrown_usedreferralcode" */
  homegrown_usedreferralcode_aggregate: Homegrown_Usedreferralcode_Aggregate;
  /** fetch data from the table: "homegrown_usedreferralcode" using primary key columns */
  homegrown_usedreferralcode_by_pk?: Maybe<Homegrown_Usedreferralcode>;
  /** fetch data from the table in a streaming manner: "homegrown_usedreferralcode" */
  homegrown_usedreferralcode_stream: Array<Homegrown_Usedreferralcode>;
  /** fetch data from the table: "homegrown_userdocuments" */
  homegrown_userdocuments: Array<Homegrown_Userdocuments>;
  /** fetch aggregated fields from the table: "homegrown_userdocuments" */
  homegrown_userdocuments_aggregate: Homegrown_Userdocuments_Aggregate;
  /** fetch data from the table: "homegrown_userdocuments" using primary key columns */
  homegrown_userdocuments_by_pk?: Maybe<Homegrown_Userdocuments>;
  /** fetch data from the table in a streaming manner: "homegrown_userdocuments" */
  homegrown_userdocuments_stream: Array<Homegrown_Userdocuments>;
  /** fetch data from the table: "homegrown_userdocumenttype" */
  homegrown_userdocumenttype: Array<Homegrown_Userdocumenttype>;
  /** fetch aggregated fields from the table: "homegrown_userdocumenttype" */
  homegrown_userdocumenttype_aggregate: Homegrown_Userdocumenttype_Aggregate;
  /** fetch data from the table: "homegrown_userdocumenttype" using primary key columns */
  homegrown_userdocumenttype_by_pk?: Maybe<Homegrown_Userdocumenttype>;
  /** fetch data from the table in a streaming manner: "homegrown_userdocumenttype" */
  homegrown_userdocumenttype_stream: Array<Homegrown_Userdocumenttype>;
  /** An array relationship */
  investments: Array<Investments>;
  /** An aggregate relationship */
  investments_aggregate: Investments_Aggregate;
  /** fetch data from the table: "plaid_app_investment" using primary key columns */
  investments_by_pk?: Maybe<Investments>;
  /** fetch data from the table in a streaming manner: "plaid_app_investment" */
  investments_stream: Array<Investments>;
  /** fetch data from the table: "users_investordata" */
  investordata: Array<Investordata>;
  /** fetch aggregated fields from the table: "users_investordata" */
  investordata_aggregate: Investordata_Aggregate;
  /** fetch data from the table: "users_investordata" using primary key columns */
  investordata_by_pk?: Maybe<Investordata>;
  /** fetch data from the table in a streaming manner: "users_investordata" */
  investordata_stream: Array<Investordata>;
  /** fetch data from the table: "plaid_app_dealbusinessrevenue" */
  plaid_app_dealbusinessrevenue: Array<Plaid_App_Dealbusinessrevenue>;
  /** fetch aggregated fields from the table: "plaid_app_dealbusinessrevenue" */
  plaid_app_dealbusinessrevenue_aggregate: Plaid_App_Dealbusinessrevenue_Aggregate;
  /** fetch data from the table: "plaid_app_dealbusinessrevenue" using primary key columns */
  plaid_app_dealbusinessrevenue_by_pk?: Maybe<Plaid_App_Dealbusinessrevenue>;
  /** fetch data from the table in a streaming manner: "plaid_app_dealbusinessrevenue" */
  plaid_app_dealbusinessrevenue_stream: Array<Plaid_App_Dealbusinessrevenue>;
  /** fetch data from the table: "plaid_app_dealpayback" */
  plaid_app_dealpayback: Array<Plaid_App_Dealpayback>;
  /** fetch aggregated fields from the table: "plaid_app_dealpayback" */
  plaid_app_dealpayback_aggregate: Plaid_App_Dealpayback_Aggregate;
  /** fetch data from the table: "plaid_app_dealpayback" using primary key columns */
  plaid_app_dealpayback_by_pk?: Maybe<Plaid_App_Dealpayback>;
  /** fetch data from the table in a streaming manner: "plaid_app_dealpayback" */
  plaid_app_dealpayback_stream: Array<Plaid_App_Dealpayback>;
  /** fetch data from the table: "plaid_app_dealpayout" */
  plaid_app_dealpayout: Array<Plaid_App_Dealpayout>;
  /** fetch aggregated fields from the table: "plaid_app_dealpayout" */
  plaid_app_dealpayout_aggregate: Plaid_App_Dealpayout_Aggregate;
  /** fetch data from the table: "plaid_app_dealpayout" using primary key columns */
  plaid_app_dealpayout_by_pk?: Maybe<Plaid_App_Dealpayout>;
  /** fetch data from the table in a streaming manner: "plaid_app_dealpayout" */
  plaid_app_dealpayout_stream: Array<Plaid_App_Dealpayout>;
  /** fetch data from the table: "plaid_app_dealtransfer" */
  plaid_app_dealtransfer: Array<Plaid_App_Dealtransfer>;
  /** fetch aggregated fields from the table: "plaid_app_dealtransfer" */
  plaid_app_dealtransfer_aggregate: Plaid_App_Dealtransfer_Aggregate;
  /** fetch data from the table: "plaid_app_dealtransfer" using primary key columns */
  plaid_app_dealtransfer_by_pk?: Maybe<Plaid_App_Dealtransfer>;
  /** fetch data from the table in a streaming manner: "plaid_app_dealtransfer" */
  plaid_app_dealtransfer_stream: Array<Plaid_App_Dealtransfer>;
  /** fetch data from the table: "plaid_app_plaidlink" */
  plaid_app_plaidlink: Array<Plaid_App_Plaidlink>;
  /** fetch aggregated fields from the table: "plaid_app_plaidlink" */
  plaid_app_plaidlink_aggregate: Plaid_App_Plaidlink_Aggregate;
  /** fetch data from the table: "plaid_app_plaidlink" using primary key columns */
  plaid_app_plaidlink_by_pk?: Maybe<Plaid_App_Plaidlink>;
  /** fetch data from the table in a streaming manner: "plaid_app_plaidlink" */
  plaid_app_plaidlink_stream: Array<Plaid_App_Plaidlink>;
  /** fetch data from the table: "plaid_app_plaidlinkbusiness" */
  plaid_app_plaidlinkbusiness: Array<Plaid_App_Plaidlinkbusiness>;
  /** fetch aggregated fields from the table: "plaid_app_plaidlinkbusiness" */
  plaid_app_plaidlinkbusiness_aggregate: Plaid_App_Plaidlinkbusiness_Aggregate;
  /** fetch data from the table: "plaid_app_plaidlinkbusiness" using primary key columns */
  plaid_app_plaidlinkbusiness_by_pk?: Maybe<Plaid_App_Plaidlinkbusiness>;
  /** fetch data from the table in a streaming manner: "plaid_app_plaidlinkbusiness" */
  plaid_app_plaidlinkbusiness_stream: Array<Plaid_App_Plaidlinkbusiness>;
  /** fetch data from the table: "plaid_app_plaidlinkstatus" */
  plaid_app_plaidlinkstatus: Array<Plaid_App_Plaidlinkstatus>;
  /** fetch aggregated fields from the table: "plaid_app_plaidlinkstatus" */
  plaid_app_plaidlinkstatus_aggregate: Plaid_App_Plaidlinkstatus_Aggregate;
  /** fetch data from the table: "plaid_app_plaidlinkstatus" using primary key columns */
  plaid_app_plaidlinkstatus_by_pk?: Maybe<Plaid_App_Plaidlinkstatus>;
  /** fetch data from the table in a streaming manner: "plaid_app_plaidlinkstatus" */
  plaid_app_plaidlinkstatus_stream: Array<Plaid_App_Plaidlinkstatus>;
  /** fetch data from the table: "plaid_app_plaidtransfer" */
  plaid_app_plaidtransfer: Array<Plaid_App_Plaidtransfer>;
  /** fetch aggregated fields from the table: "plaid_app_plaidtransfer" */
  plaid_app_plaidtransfer_aggregate: Plaid_App_Plaidtransfer_Aggregate;
  /** fetch data from the table: "plaid_app_plaidtransfer" using primary key columns */
  plaid_app_plaidtransfer_by_pk?: Maybe<Plaid_App_Plaidtransfer>;
  /** fetch data from the table in a streaming manner: "plaid_app_plaidtransfer" */
  plaid_app_plaidtransfer_stream: Array<Plaid_App_Plaidtransfer>;
  /** fetch data from the table: "plaid_app_plaidtransferevent" */
  plaid_app_plaidtransferevent: Array<Plaid_App_Plaidtransferevent>;
  /** fetch aggregated fields from the table: "plaid_app_plaidtransferevent" */
  plaid_app_plaidtransferevent_aggregate: Plaid_App_Plaidtransferevent_Aggregate;
  /** fetch data from the table: "plaid_app_plaidtransferevent" using primary key columns */
  plaid_app_plaidtransferevent_by_pk?: Maybe<Plaid_App_Plaidtransferevent>;
  /** fetch data from the table in a streaming manner: "plaid_app_plaidtransferevent" */
  plaid_app_plaidtransferevent_stream: Array<Plaid_App_Plaidtransferevent>;
  /** fetch data from the table: "plaid_app_revenuetransaction" */
  plaid_app_revenuetransaction: Array<Plaid_App_Revenuetransaction>;
  /** fetch aggregated fields from the table: "plaid_app_revenuetransaction" */
  plaid_app_revenuetransaction_aggregate: Plaid_App_Revenuetransaction_Aggregate;
  /** fetch data from the table: "plaid_app_revenuetransaction" using primary key columns */
  plaid_app_revenuetransaction_by_pk?: Maybe<Plaid_App_Revenuetransaction>;
  /** fetch data from the table in a streaming manner: "plaid_app_revenuetransaction" */
  plaid_app_revenuetransaction_stream: Array<Plaid_App_Revenuetransaction>;
  /** fetch data from the table: "treasuryprime_app_achtransfer" */
  treasuryprime_app_achtransfer: Array<Treasuryprime_App_Achtransfer>;
  /** fetch aggregated fields from the table: "treasuryprime_app_achtransfer" */
  treasuryprime_app_achtransfer_aggregate: Treasuryprime_App_Achtransfer_Aggregate;
  /** fetch data from the table: "treasuryprime_app_achtransfer" using primary key columns */
  treasuryprime_app_achtransfer_by_pk?: Maybe<Treasuryprime_App_Achtransfer>;
  /** fetch data from the table in a streaming manner: "treasuryprime_app_achtransfer" */
  treasuryprime_app_achtransfer_stream: Array<Treasuryprime_App_Achtransfer>;
  /** fetch data from the table: "treasuryprime_app_counterpartylink" */
  treasuryprime_app_counterpartylink: Array<Treasuryprime_App_Counterpartylink>;
  /** fetch aggregated fields from the table: "treasuryprime_app_counterpartylink" */
  treasuryprime_app_counterpartylink_aggregate: Treasuryprime_App_Counterpartylink_Aggregate;
  /** fetch data from the table: "treasuryprime_app_counterpartylink" using primary key columns */
  treasuryprime_app_counterpartylink_by_pk?: Maybe<Treasuryprime_App_Counterpartylink>;
  /** fetch data from the table in a streaming manner: "treasuryprime_app_counterpartylink" */
  treasuryprime_app_counterpartylink_stream: Array<Treasuryprime_App_Counterpartylink>;
  /** fetch data from the table: "treasuryprime_app_counterpartylinkbusiness" */
  treasuryprime_app_counterpartylinkbusiness: Array<Treasuryprime_App_Counterpartylinkbusiness>;
  /** fetch aggregated fields from the table: "treasuryprime_app_counterpartylinkbusiness" */
  treasuryprime_app_counterpartylinkbusiness_aggregate: Treasuryprime_App_Counterpartylinkbusiness_Aggregate;
  /** fetch data from the table: "treasuryprime_app_counterpartylinkbusiness" using primary key columns */
  treasuryprime_app_counterpartylinkbusiness_by_pk?: Maybe<Treasuryprime_App_Counterpartylinkbusiness>;
  /** fetch data from the table in a streaming manner: "treasuryprime_app_counterpartylinkbusiness" */
  treasuryprime_app_counterpartylinkbusiness_stream: Array<Treasuryprime_App_Counterpartylinkbusiness>;
  /** fetch data from the table: "users_businessowner" */
  users_businessowner: Array<Users_Businessowner>;
  /** fetch aggregated fields from the table: "users_businessowner" */
  users_businessowner_aggregate: Users_Businessowner_Aggregate;
  /** fetch data from the table: "users_businessowner" using primary key columns */
  users_businessowner_by_pk?: Maybe<Users_Businessowner>;
  /** fetch data from the table in a streaming manner: "users_businessowner" */
  users_businessowner_stream: Array<Users_Businessowner>;
  /** fetch data from the table: "users_employee" */
  users_employee: Array<Users_Employee>;
  /** fetch aggregated fields from the table: "users_employee" */
  users_employee_aggregate: Users_Employee_Aggregate;
  /** fetch data from the table: "users_employee" using primary key columns */
  users_employee_by_pk?: Maybe<Users_Employee>;
  /** fetch data from the table in a streaming manner: "users_employee" */
  users_employee_stream: Array<Users_Employee>;
  /** fetch data from the table: "users_investorbusiness" */
  users_investorbusiness: Array<Users_Investorbusiness>;
  /** fetch aggregated fields from the table: "users_investorbusiness" */
  users_investorbusiness_aggregate: Users_Investorbusiness_Aggregate;
  /** fetch data from the table: "users_investorbusiness" using primary key columns */
  users_investorbusiness_by_pk?: Maybe<Users_Investorbusiness>;
  /** fetch data from the table in a streaming manner: "users_investorbusiness" */
  users_investorbusiness_stream: Array<Users_Investorbusiness>;
  /** fetch data from the table: "users_roleorder" */
  users_roleorder: Array<Users_Roleorder>;
  /** fetch aggregated fields from the table: "users_roleorder" */
  users_roleorder_aggregate: Users_Roleorder_Aggregate;
  /** fetch data from the table: "users_roleorder" using primary key columns */
  users_roleorder_by_pk?: Maybe<Users_Roleorder>;
  /** fetch data from the table in a streaming manner: "users_roleorder" */
  users_roleorder_stream: Array<Users_Roleorder>;
  /** fetch data from the table: "users_secondaryinvestordata" */
  users_secondaryinvestordata: Array<Users_Secondaryinvestordata>;
  /** fetch aggregated fields from the table: "users_secondaryinvestordata" */
  users_secondaryinvestordata_aggregate: Users_Secondaryinvestordata_Aggregate;
  /** fetch data from the table: "users_secondaryinvestordata" using primary key columns */
  users_secondaryinvestordata_by_pk?: Maybe<Users_Secondaryinvestordata>;
  /** fetch data from the table in a streaming manner: "users_secondaryinvestordata" */
  users_secondaryinvestordata_stream: Array<Users_Secondaryinvestordata>;
  /** fetch data from the table: "users_secondaryinvestordocuments" */
  users_secondaryinvestordocuments: Array<Users_Secondaryinvestordocuments>;
  /** fetch aggregated fields from the table: "users_secondaryinvestordocuments" */
  users_secondaryinvestordocuments_aggregate: Users_Secondaryinvestordocuments_Aggregate;
  /** fetch data from the table: "users_secondaryinvestordocuments" using primary key columns */
  users_secondaryinvestordocuments_by_pk?: Maybe<Users_Secondaryinvestordocuments>;
  /** fetch data from the table in a streaming manner: "users_secondaryinvestordocuments" */
  users_secondaryinvestordocuments_stream: Array<Users_Secondaryinvestordocuments>;
  /** fetch data from the table: "users_secondaryinvestorinvite" */
  users_secondaryinvestorinvite: Array<Users_Secondaryinvestorinvite>;
  /** fetch aggregated fields from the table: "users_secondaryinvestorinvite" */
  users_secondaryinvestorinvite_aggregate: Users_Secondaryinvestorinvite_Aggregate;
  /** fetch data from the table: "users_secondaryinvestorinvite" using primary key columns */
  users_secondaryinvestorinvite_by_pk?: Maybe<Users_Secondaryinvestorinvite>;
  /** fetch data from the table in a streaming manner: "users_secondaryinvestorinvite" */
  users_secondaryinvestorinvite_stream: Array<Users_Secondaryinvestorinvite>;
  /** fetch data from the table: "users_user" */
  users_user: Array<Users_User>;
  /** fetch aggregated fields from the table: "users_user" */
  users_user_aggregate: Users_User_Aggregate;
  /** fetch data from the table: "users_user" using primary key columns */
  users_user_by_pk?: Maybe<Users_User>;
  /** fetch data from the table in a streaming manner: "users_user" */
  users_user_stream: Array<Users_User>;
  /** fetch data from the table: "users_userinterests" */
  users_userinterests: Array<Users_Userinterests>;
  /** fetch aggregated fields from the table: "users_userinterests" */
  users_userinterests_aggregate: Users_Userinterests_Aggregate;
  /** fetch data from the table: "users_userinterests" using primary key columns */
  users_userinterests_by_pk?: Maybe<Users_Userinterests>;
  /** fetch data from the table in a streaming manner: "users_userinterests" */
  users_userinterests_stream: Array<Users_Userinterests>;
  /** fetch data from the table: "users_usernotifications" */
  users_usernotifications: Array<Users_Usernotifications>;
  /** fetch aggregated fields from the table: "users_usernotifications" */
  users_usernotifications_aggregate: Users_Usernotifications_Aggregate;
  /** fetch data from the table: "users_usernotifications" using primary key columns */
  users_usernotifications_by_pk?: Maybe<Users_Usernotifications>;
  /** fetch data from the table in a streaming manner: "users_usernotifications" */
  users_usernotifications_stream: Array<Users_Usernotifications>;
  /** fetch data from the table: "users_userpreferences" */
  users_userpreferences: Array<Users_Userpreferences>;
  /** fetch aggregated fields from the table: "users_userpreferences" */
  users_userpreferences_aggregate: Users_Userpreferences_Aggregate;
  /** fetch data from the table: "users_userpreferences" using primary key columns */
  users_userpreferences_by_pk?: Maybe<Users_Userpreferences>;
  /** fetch data from the table in a streaming manner: "users_userpreferences" */
  users_userpreferences_stream: Array<Users_Userpreferences>;
  /** fetch data from the table: "users_usersession" */
  users_usersession: Array<Users_Usersession>;
  /** fetch aggregated fields from the table: "users_usersession" */
  users_usersession_aggregate: Users_Usersession_Aggregate;
  /** fetch data from the table: "users_usersession" using primary key columns */
  users_usersession_by_pk?: Maybe<Users_Usersession>;
  /** fetch data from the table in a streaming manner: "users_usersession" */
  users_usersession_stream: Array<Users_Usersession>;
};


export type Subscription_RootBucketArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootBuckets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Buckets_Stream_Cursor_Input>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};


export type Subscription_RootFileArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootHomegrown_BusinessArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Business_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Business_Order_By>>;
  where?: InputMaybe<Homegrown_Business_Bool_Exp>;
};


export type Subscription_RootHomegrown_Business_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Business_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Business_Order_By>>;
  where?: InputMaybe<Homegrown_Business_Bool_Exp>;
};


export type Subscription_RootHomegrown_Business_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Business_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Business_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Business_Bool_Exp>;
};


export type Subscription_RootHomegrown_BusinessprivatedocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Businessprivatedocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Businessprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Businessprivatedocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Businessprivatedocuments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Businessprivatedocuments_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Businessprivatedocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Deal_Order_By>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


export type Subscription_RootHomegrown_Deal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Deal_Order_By>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


export type Subscription_RootHomegrown_Deal_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Deal_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Deal_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealdocumentArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealdocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealdocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Dealdocument_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealdocument_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealdocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealprivateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivate_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivate_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivate_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivate_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivate_By_PkArgs = {
  deal_id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Dealprivate_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealprivate_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealprivate_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealprivatedocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivatedocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivatedocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Dealprivatedocuments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealprivatedocuments_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealprivatedocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealprivatedocumentstatusArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivatedocumentstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumentstatus_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivatedocumentstatus_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootHomegrown_Dealprivatedocumentstatus_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealprivatedocumentstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumentstatus_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealprivatedocumenttypeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivatedocumenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealprivatedocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealprivatedocumenttype_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootHomegrown_Dealprivatedocumenttype_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealprivatedocumenttype_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealprivatedocumenttype_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealtobusinessplaidlinkArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealtobusinessplaidlink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtobusinessplaidlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealtobusinessplaidlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Dealtobusinessplaidlink_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealtobusinessplaidlink_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealtobusinessplaidlink_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealtoinvestorlinkArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealtoinvestorlink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtoinvestorlink_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealtoinvestorlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Dealtoinvestorlink_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealtoinvestorlink_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealtoinvestorlink_Bool_Exp>;
};


export type Subscription_RootHomegrown_DealtypeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtype_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealtype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Dealtype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Dealtype_Order_By>>;
  where?: InputMaybe<Homegrown_Dealtype_Bool_Exp>;
};


export type Subscription_RootHomegrown_Dealtype_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootHomegrown_Dealtype_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Dealtype_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Dealtype_Bool_Exp>;
};


export type Subscription_RootHomegrown_LocationArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Location_Order_By>>;
  where?: InputMaybe<Homegrown_Location_Bool_Exp>;
};


export type Subscription_RootHomegrown_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Location_Order_By>>;
  where?: InputMaybe<Homegrown_Location_Bool_Exp>;
};


export type Subscription_RootHomegrown_Location_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Location_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Location_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Location_Bool_Exp>;
};


export type Subscription_RootHomegrown_ReferralcodeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Referralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Referralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
};


export type Subscription_RootHomegrown_Referralcode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Referralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Referralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
};


export type Subscription_RootHomegrown_Referralcode_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootHomegrown_Referralcode_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Referralcode_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Referralcode_Bool_Exp>;
};


export type Subscription_RootHomegrown_SignwelldealdocumentArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwelldealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_Signwelldealdocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwelldealdocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwelldealdocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_Signwelldealdocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Signwelldealdocument_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Signwelldealdocument_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Signwelldealdocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_SignwellinvestorsigneddocumentArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_Signwellinvestorsigneddocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Signwellinvestorsigneddocument_Order_By>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_Signwellinvestorsigneddocument_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Signwellinvestorsigneddocument_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Signwellinvestorsigneddocument_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Signwellinvestorsigneddocument_Bool_Exp>;
};


export type Subscription_RootHomegrown_TaxdocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Taxdocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Taxdocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Taxdocuments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Taxdocuments_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_UsedreferralcodeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Usedreferralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};


export type Subscription_RootHomegrown_Usedreferralcode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Usedreferralcode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Usedreferralcode_Order_By>>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};


export type Subscription_RootHomegrown_Usedreferralcode_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Subscription_RootHomegrown_Usedreferralcode_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Usedreferralcode_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Usedreferralcode_Bool_Exp>;
};


export type Subscription_RootHomegrown_UserdocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Userdocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_Userdocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootHomegrown_Userdocuments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Userdocuments_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


export type Subscription_RootHomegrown_UserdocumenttypeArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
};


export type Subscription_RootHomegrown_Userdocumenttype_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocumenttype_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocumenttype_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
};


export type Subscription_RootHomegrown_Userdocumenttype_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootHomegrown_Userdocumenttype_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Homegrown_Userdocumenttype_Stream_Cursor_Input>>;
  where?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
};


export type Subscription_RootInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


export type Subscription_RootInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


export type Subscription_RootInvestments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootInvestments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Investments_Stream_Cursor_Input>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


export type Subscription_RootInvestordataArgs = {
  distinct_on?: InputMaybe<Array<Investordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investordata_Order_By>>;
  where?: InputMaybe<Investordata_Bool_Exp>;
};


export type Subscription_RootInvestordata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investordata_Order_By>>;
  where?: InputMaybe<Investordata_Bool_Exp>;
};


export type Subscription_RootInvestordata_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Subscription_RootInvestordata_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Investordata_Stream_Cursor_Input>>;
  where?: InputMaybe<Investordata_Bool_Exp>;
};


export type Subscription_RootPlaid_App_DealbusinessrevenueArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealbusinessrevenue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealbusinessrevenue_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealbusinessrevenue_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPlaid_App_Dealbusinessrevenue_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Dealbusinessrevenue_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Dealbusinessrevenue_Bool_Exp>;
};


export type Subscription_RootPlaid_App_DealpaybackArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayback_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealpayback_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayback_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayback_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealpayback_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPlaid_App_Dealpayback_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Dealpayback_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Dealpayback_Bool_Exp>;
};


export type Subscription_RootPlaid_App_DealpayoutArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealpayout_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealpayout_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealpayout_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealpayout_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPlaid_App_Dealpayout_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Dealpayout_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
};


export type Subscription_RootPlaid_App_DealtransferArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealtransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Dealtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Dealtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Dealtransfer_By_PkArgs = {
  payout_id: Scalars['String']['input'];
};


export type Subscription_RootPlaid_App_Dealtransfer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Dealtransfer_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Dealtransfer_Bool_Exp>;
};


export type Subscription_RootPlaid_App_PlaidlinkArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlink_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidlink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlink_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidlink_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPlaid_App_Plaidlink_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Plaidlink_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Plaidlink_Bool_Exp>;
};


export type Subscription_RootPlaid_App_PlaidlinkbusinessArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidlinkbusiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidlinkbusiness_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPlaid_App_Plaidlinkbusiness_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Plaidlinkbusiness_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};


export type Subscription_RootPlaid_App_PlaidlinkstatusArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkstatus_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidlinkstatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkstatus_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkstatus_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidlinkstatus_By_PkArgs = {
  name: Scalars['String']['input'];
};


export type Subscription_RootPlaid_App_Plaidlinkstatus_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Plaidlinkstatus_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Plaidlinkstatus_Bool_Exp>;
};


export type Subscription_RootPlaid_App_PlaidtransferArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidtransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransfer_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidtransfer_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootPlaid_App_Plaidtransfer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Plaidtransfer_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Plaidtransfer_Bool_Exp>;
};


export type Subscription_RootPlaid_App_PlaidtransfereventArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransferevent_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidtransferevent_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidtransferevent_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidtransferevent_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Plaidtransferevent_By_PkArgs = {
  event_id: Scalars['Int']['input'];
};


export type Subscription_RootPlaid_App_Plaidtransferevent_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Plaidtransferevent_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Plaidtransferevent_Bool_Exp>;
};


export type Subscription_RootPlaid_App_RevenuetransactionArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Revenuetransaction_Order_By>>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Revenuetransaction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Revenuetransaction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Revenuetransaction_Order_By>>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};


export type Subscription_RootPlaid_App_Revenuetransaction_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPlaid_App_Revenuetransaction_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Plaid_App_Revenuetransaction_Stream_Cursor_Input>>;
  where?: InputMaybe<Plaid_App_Revenuetransaction_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_AchtransferArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_Achtransfer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_Achtransfer_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTreasuryprime_App_Achtransfer_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Treasuryprime_App_Achtransfer_Stream_Cursor_Input>>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_CounterpartylinkArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_Counterpartylink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_Counterpartylink_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTreasuryprime_App_Counterpartylink_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Treasuryprime_App_Counterpartylink_Stream_Cursor_Input>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_CounterpartylinkbusinessArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_Counterpartylinkbusiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};


export type Subscription_RootTreasuryprime_App_Counterpartylinkbusiness_By_PkArgs = {
  id: Scalars['String']['input'];
};


export type Subscription_RootTreasuryprime_App_Counterpartylinkbusiness_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Stream_Cursor_Input>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};


export type Subscription_RootUsers_BusinessownerArgs = {
  distinct_on?: InputMaybe<Array<Users_Businessowner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Businessowner_Order_By>>;
  where?: InputMaybe<Users_Businessowner_Bool_Exp>;
};


export type Subscription_RootUsers_Businessowner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Businessowner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Businessowner_Order_By>>;
  where?: InputMaybe<Users_Businessowner_Bool_Exp>;
};


export type Subscription_RootUsers_Businessowner_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Businessowner_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Businessowner_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Businessowner_Bool_Exp>;
};


export type Subscription_RootUsers_EmployeeArgs = {
  distinct_on?: InputMaybe<Array<Users_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Employee_Order_By>>;
  where?: InputMaybe<Users_Employee_Bool_Exp>;
};


export type Subscription_RootUsers_Employee_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Employee_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Employee_Order_By>>;
  where?: InputMaybe<Users_Employee_Bool_Exp>;
};


export type Subscription_RootUsers_Employee_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUsers_Employee_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Employee_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Employee_Bool_Exp>;
};


export type Subscription_RootUsers_InvestorbusinessArgs = {
  distinct_on?: InputMaybe<Array<Users_Investorbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Investorbusiness_Order_By>>;
  where?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
};


export type Subscription_RootUsers_Investorbusiness_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Investorbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Investorbusiness_Order_By>>;
  where?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
};


export type Subscription_RootUsers_Investorbusiness_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUsers_Investorbusiness_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Investorbusiness_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
};


export type Subscription_RootUsers_RoleorderArgs = {
  distinct_on?: InputMaybe<Array<Users_Roleorder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Roleorder_Order_By>>;
  where?: InputMaybe<Users_Roleorder_Bool_Exp>;
};


export type Subscription_RootUsers_Roleorder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Roleorder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Roleorder_Order_By>>;
  where?: InputMaybe<Users_Roleorder_Bool_Exp>;
};


export type Subscription_RootUsers_Roleorder_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUsers_Roleorder_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Roleorder_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Roleorder_Bool_Exp>;
};


export type Subscription_RootUsers_SecondaryinvestordataArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordata_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};


export type Subscription_RootUsers_Secondaryinvestordata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordata_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};


export type Subscription_RootUsers_Secondaryinvestordata_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUsers_Secondaryinvestordata_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Secondaryinvestordata_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};


export type Subscription_RootUsers_SecondaryinvestordocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordocuments_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};


export type Subscription_RootUsers_Secondaryinvestordocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordocuments_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};


export type Subscription_RootUsers_Secondaryinvestordocuments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUsers_Secondaryinvestordocuments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Secondaryinvestordocuments_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};


export type Subscription_RootUsers_SecondaryinvestorinviteArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestorinvite_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};


export type Subscription_RootUsers_Secondaryinvestorinvite_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestorinvite_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};


export type Subscription_RootUsers_Secondaryinvestorinvite_By_PkArgs = {
  invite_code: Scalars['String']['input'];
};


export type Subscription_RootUsers_Secondaryinvestorinvite_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Secondaryinvestorinvite_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};


export type Subscription_RootUsers_UserArgs = {
  distinct_on?: InputMaybe<Array<Users_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_User_Order_By>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};


export type Subscription_RootUsers_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_User_Order_By>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};


export type Subscription_RootUsers_User_By_PkArgs = {
  auth0id: Scalars['String']['input'];
};


export type Subscription_RootUsers_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};


export type Subscription_RootUsers_UserinterestsArgs = {
  distinct_on?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userinterests_Order_By>>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};


export type Subscription_RootUsers_Userinterests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userinterests_Order_By>>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};


export type Subscription_RootUsers_Userinterests_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUsers_Userinterests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Userinterests_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};


export type Subscription_RootUsers_UsernotificationsArgs = {
  distinct_on?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usernotifications_Order_By>>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};


export type Subscription_RootUsers_Usernotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usernotifications_Order_By>>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};


export type Subscription_RootUsers_Usernotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootUsers_Usernotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Usernotifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};


export type Subscription_RootUsers_UserpreferencesArgs = {
  distinct_on?: InputMaybe<Array<Users_Userpreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userpreferences_Order_By>>;
  where?: InputMaybe<Users_Userpreferences_Bool_Exp>;
};


export type Subscription_RootUsers_Userpreferences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Userpreferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Userpreferences_Order_By>>;
  where?: InputMaybe<Users_Userpreferences_Bool_Exp>;
};


export type Subscription_RootUsers_Userpreferences_By_PkArgs = {
  user_id: Scalars['String']['input'];
};


export type Subscription_RootUsers_Userpreferences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Userpreferences_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Userpreferences_Bool_Exp>;
};


export type Subscription_RootUsers_UsersessionArgs = {
  distinct_on?: InputMaybe<Array<Users_Usersession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usersession_Order_By>>;
  where?: InputMaybe<Users_Usersession_Bool_Exp>;
};


export type Subscription_RootUsers_Usersession_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Usersession_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Usersession_Order_By>>;
  where?: InputMaybe<Users_Usersession_Bool_Exp>;
};


export type Subscription_RootUsers_Usersession_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUsers_Usersession_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Usersession_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Usersession_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer = {
  __typename?: 'treasuryprime_app_achtransfer';
  account_id: Scalars['String']['output'];
  active: Scalars['Boolean']['output'];
  amount: Scalars['numeric']['output'];
  counterparty_id: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  dealpayout?: Maybe<Plaid_App_Dealpayout>;
  direction: Scalars['String']['output'];
  effective_date: Scalars['timestamptz']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** An object relationship */
  investment?: Maybe<Investments>;
  sec_code: Scalars['String']['output'];
  service: Scalars['String']['output'];
  status: Scalars['String']['output'];
  /** An object relationship */
  treasuryprime_counterpartylink: Treasuryprime_App_Counterpartylink;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Aggregate = {
  __typename?: 'treasuryprime_app_achtransfer_aggregate';
  aggregate?: Maybe<Treasuryprime_App_Achtransfer_Aggregate_Fields>;
  nodes: Array<Treasuryprime_App_Achtransfer>;
};

export type Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Count>;
};

export type Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_And = {
  arguments: Treasuryprime_App_Achtransfer_Select_Column_Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Treasuryprime_App_Achtransfer_Select_Column_Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Aggregate_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_aggregate_fields';
  avg?: Maybe<Treasuryprime_App_Achtransfer_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Treasuryprime_App_Achtransfer_Max_Fields>;
  min?: Maybe<Treasuryprime_App_Achtransfer_Min_Fields>;
  stddev?: Maybe<Treasuryprime_App_Achtransfer_Stddev_Fields>;
  stddev_pop?: Maybe<Treasuryprime_App_Achtransfer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Treasuryprime_App_Achtransfer_Stddev_Samp_Fields>;
  sum?: Maybe<Treasuryprime_App_Achtransfer_Sum_Fields>;
  var_pop?: Maybe<Treasuryprime_App_Achtransfer_Var_Pop_Fields>;
  var_samp?: Maybe<Treasuryprime_App_Achtransfer_Var_Samp_Fields>;
  variance?: Maybe<Treasuryprime_App_Achtransfer_Variance_Fields>;
};


/** aggregate fields of "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Aggregate_Order_By = {
  avg?: InputMaybe<Treasuryprime_App_Achtransfer_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Treasuryprime_App_Achtransfer_Max_Order_By>;
  min?: InputMaybe<Treasuryprime_App_Achtransfer_Min_Order_By>;
  stddev?: InputMaybe<Treasuryprime_App_Achtransfer_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Treasuryprime_App_Achtransfer_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Treasuryprime_App_Achtransfer_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Treasuryprime_App_Achtransfer_Sum_Order_By>;
  var_pop?: InputMaybe<Treasuryprime_App_Achtransfer_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Treasuryprime_App_Achtransfer_Var_Samp_Order_By>;
  variance?: InputMaybe<Treasuryprime_App_Achtransfer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Arr_Rel_Insert_Input = {
  data: Array<Treasuryprime_App_Achtransfer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Treasuryprime_App_Achtransfer_On_Conflict>;
};

/** aggregate avg on columns */
export type Treasuryprime_App_Achtransfer_Avg_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_avg_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "treasuryprime_app_achtransfer". All fields are combined with a logical 'AND'. */
export type Treasuryprime_App_Achtransfer_Bool_Exp = {
  _and?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Bool_Exp>>;
  _not?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  _or?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Bool_Exp>>;
  account_id?: InputMaybe<String_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  counterparty_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dealpayout?: InputMaybe<Plaid_App_Dealpayout_Bool_Exp>;
  direction?: InputMaybe<String_Comparison_Exp>;
  effective_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  investment?: InputMaybe<Investments_Bool_Exp>;
  sec_code?: InputMaybe<String_Comparison_Exp>;
  service?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  treasuryprime_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "treasuryprime_app_achtransfer" */
export enum Treasuryprime_App_Achtransfer_Constraint {
  /** unique or primary key constraint on columns "id" */
  TreasuryprimeAppAchtransferPkey = 'treasuryprime_app_achtransfer_pkey'
}

/** input type for incrementing numeric columns in table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Insert_Input = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  counterparty_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  dealpayout?: InputMaybe<Plaid_App_Dealpayout_Obj_Rel_Insert_Input>;
  direction?: InputMaybe<Scalars['String']['input']>;
  effective_date?: InputMaybe<Scalars['timestamptz']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  investment?: InputMaybe<Investments_Obj_Rel_Insert_Input>;
  sec_code?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  treasuryprime_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylink_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Treasuryprime_App_Achtransfer_Max_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_max_fields';
  account_id?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['numeric']['output']>;
  counterparty_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  effective_date?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  sec_code?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sec_code?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Treasuryprime_App_Achtransfer_Min_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_min_fields';
  account_id?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['numeric']['output']>;
  counterparty_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  direction?: Maybe<Scalars['String']['output']>;
  effective_date?: Maybe<Scalars['timestamptz']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  sec_code?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  sec_code?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Mutation_Response = {
  __typename?: 'treasuryprime_app_achtransfer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Treasuryprime_App_Achtransfer>;
};

/** input type for inserting object relation for remote table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Obj_Rel_Insert_Input = {
  data: Treasuryprime_App_Achtransfer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Treasuryprime_App_Achtransfer_On_Conflict>;
};

/** on_conflict condition type for table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_On_Conflict = {
  constraint: Treasuryprime_App_Achtransfer_Constraint;
  update_columns?: Array<Treasuryprime_App_Achtransfer_Update_Column>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};

/** Ordering options when selecting data from "treasuryprime_app_achtransfer". */
export type Treasuryprime_App_Achtransfer_Order_By = {
  account_id?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  counterparty_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dealpayout?: InputMaybe<Plaid_App_Dealpayout_Order_By>;
  direction?: InputMaybe<Order_By>;
  effective_date?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investment?: InputMaybe<Investments_Order_By>;
  sec_code?: InputMaybe<Order_By>;
  service?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  treasuryprime_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylink_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: treasuryprime_app_achtransfer */
export type Treasuryprime_App_Achtransfer_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "treasuryprime_app_achtransfer" */
export enum Treasuryprime_App_Achtransfer_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Direction = 'direction',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  SecCode = 'sec_code',
  /** column name */
  Service = 'service',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "treasuryprime_app_achtransfer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "treasuryprime_app_achtransfer" */
export enum Treasuryprime_App_Achtransfer_Select_Column_Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "treasuryprime_app_achtransfer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "treasuryprime_app_achtransfer" */
export enum Treasuryprime_App_Achtransfer_Select_Column_Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Set_Input = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  counterparty_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  effective_date?: InputMaybe<Scalars['timestamptz']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sec_code?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Treasuryprime_App_Achtransfer_Stddev_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_stddev_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Treasuryprime_App_Achtransfer_Stddev_Pop_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Treasuryprime_App_Achtransfer_Stddev_Samp_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Treasuryprime_App_Achtransfer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Treasuryprime_App_Achtransfer_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<Scalars['numeric']['input']>;
  counterparty_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
  effective_date?: InputMaybe<Scalars['timestamptz']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  sec_code?: InputMaybe<Scalars['String']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Treasuryprime_App_Achtransfer_Sum_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_sum_fields';
  amount?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "treasuryprime_app_achtransfer" */
export enum Treasuryprime_App_Achtransfer_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Active = 'active',
  /** column name */
  Amount = 'amount',
  /** column name */
  CounterpartyId = 'counterparty_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Direction = 'direction',
  /** column name */
  EffectiveDate = 'effective_date',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  SecCode = 'sec_code',
  /** column name */
  Service = 'service',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Treasuryprime_App_Achtransfer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Treasuryprime_App_Achtransfer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Treasuryprime_App_Achtransfer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Treasuryprime_App_Achtransfer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Treasuryprime_App_Achtransfer_Var_Pop_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_var_pop_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Treasuryprime_App_Achtransfer_Var_Samp_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_var_samp_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Treasuryprime_App_Achtransfer_Variance_Fields = {
  __typename?: 'treasuryprime_app_achtransfer_variance_fields';
  amount?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "treasuryprime_app_achtransfer" */
export type Treasuryprime_App_Achtransfer_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink = {
  __typename?: 'treasuryprime_app_counterpartylink';
  ach: Scalars['jsonb']['output'];
  /** An array relationship */
  achtransfers: Array<Treasuryprime_App_Achtransfer>;
  /** An aggregate relationship */
  achtransfers_aggregate: Treasuryprime_App_Achtransfer_Aggregate;
  active: Scalars['Boolean']['output'];
  /** An object relationship */
  businessowner?: Maybe<Users_Businessowner>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  /** An object relationship */
  investor?: Maybe<Investordata>;
  name_on_account: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users_User;
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_CounterpartylinkAchArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_CounterpartylinkAchtransfersArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};


/** columns and relationships of "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_CounterpartylinkAchtransfers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Achtransfer_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
};

/** aggregated selection of "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Aggregate = {
  __typename?: 'treasuryprime_app_counterpartylink_aggregate';
  aggregate?: Maybe<Treasuryprime_App_Counterpartylink_Aggregate_Fields>;
  nodes: Array<Treasuryprime_App_Counterpartylink>;
};

export type Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Count>;
};

export type Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_And = {
  arguments: Treasuryprime_App_Counterpartylink_Select_Column_Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Treasuryprime_App_Counterpartylink_Select_Column_Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Aggregate_Fields = {
  __typename?: 'treasuryprime_app_counterpartylink_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Treasuryprime_App_Counterpartylink_Max_Fields>;
  min?: Maybe<Treasuryprime_App_Counterpartylink_Min_Fields>;
};


/** aggregate fields of "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Treasuryprime_App_Counterpartylink_Max_Order_By>;
  min?: InputMaybe<Treasuryprime_App_Counterpartylink_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Treasuryprime_App_Counterpartylink_Append_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Arr_Rel_Insert_Input = {
  data: Array<Treasuryprime_App_Counterpartylink_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylink_On_Conflict>;
};

/** Boolean expression to filter rows from the table "treasuryprime_app_counterpartylink". All fields are combined with a logical 'AND'. */
export type Treasuryprime_App_Counterpartylink_Bool_Exp = {
  _and?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Bool_Exp>>;
  _not?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
  _or?: InputMaybe<Array<Treasuryprime_App_Counterpartylink_Bool_Exp>>;
  ach?: InputMaybe<Jsonb_Comparison_Exp>;
  achtransfers?: InputMaybe<Treasuryprime_App_Achtransfer_Bool_Exp>;
  achtransfers_aggregate?: InputMaybe<Treasuryprime_App_Achtransfer_Aggregate_Bool_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  businessowner?: InputMaybe<Users_Businessowner_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  investor?: InputMaybe<Investordata_Bool_Exp>;
  name_on_account?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "treasuryprime_app_counterpartylink" */
export enum Treasuryprime_App_Counterpartylink_Constraint {
  /** unique or primary key constraint on columns "id" */
  TreasuryprimeAppCounterpartylinkPkey = 'treasuryprime_app_counterpartylink_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Treasuryprime_App_Counterpartylink_Delete_At_Path_Input = {
  ach?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Treasuryprime_App_Counterpartylink_Delete_Elem_Input = {
  ach?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Treasuryprime_App_Counterpartylink_Delete_Key_Input = {
  ach?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Insert_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
  achtransfers?: InputMaybe<Treasuryprime_App_Achtransfer_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  businessowner?: InputMaybe<Users_Businessowner_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  name_on_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Treasuryprime_App_Counterpartylink_Max_Fields = {
  __typename?: 'treasuryprime_app_counterpartylink_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name_on_account?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_on_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Treasuryprime_App_Counterpartylink_Min_Fields = {
  __typename?: 'treasuryprime_app_counterpartylink_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name_on_account?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_on_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Mutation_Response = {
  __typename?: 'treasuryprime_app_counterpartylink_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Treasuryprime_App_Counterpartylink>;
};

/** input type for inserting object relation for remote table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Obj_Rel_Insert_Input = {
  data: Treasuryprime_App_Counterpartylink_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylink_On_Conflict>;
};

/** on_conflict condition type for table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_On_Conflict = {
  constraint: Treasuryprime_App_Counterpartylink_Constraint;
  update_columns?: Array<Treasuryprime_App_Counterpartylink_Update_Column>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylink_Bool_Exp>;
};

/** Ordering options when selecting data from "treasuryprime_app_counterpartylink". */
export type Treasuryprime_App_Counterpartylink_Order_By = {
  ach?: InputMaybe<Order_By>;
  achtransfers_aggregate?: InputMaybe<Treasuryprime_App_Achtransfer_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  businessowner?: InputMaybe<Users_Businessowner_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investor?: InputMaybe<Investordata_Order_By>;
  name_on_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: treasuryprime_app_counterpartylink */
export type Treasuryprime_App_Counterpartylink_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Treasuryprime_App_Counterpartylink_Prepend_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "treasuryprime_app_counterpartylink" */
export enum Treasuryprime_App_Counterpartylink_Select_Column {
  /** column name */
  Ach = 'ach',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameOnAccount = 'name_on_account',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "treasuryprime_app_counterpartylink_aggregate_bool_exp_bool_and_arguments_columns" columns of table "treasuryprime_app_counterpartylink" */
export enum Treasuryprime_App_Counterpartylink_Select_Column_Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "treasuryprime_app_counterpartylink_aggregate_bool_exp_bool_or_arguments_columns" columns of table "treasuryprime_app_counterpartylink" */
export enum Treasuryprime_App_Counterpartylink_Select_Column_Treasuryprime_App_Counterpartylink_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Set_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name_on_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "treasuryprime_app_counterpartylink" */
export type Treasuryprime_App_Counterpartylink_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Treasuryprime_App_Counterpartylink_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Treasuryprime_App_Counterpartylink_Stream_Cursor_Value_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name_on_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "treasuryprime_app_counterpartylink" */
export enum Treasuryprime_App_Counterpartylink_Update_Column {
  /** column name */
  Ach = 'ach',
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameOnAccount = 'name_on_account',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Treasuryprime_App_Counterpartylink_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Treasuryprime_App_Counterpartylink_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Treasuryprime_App_Counterpartylink_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Treasuryprime_App_Counterpartylink_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Treasuryprime_App_Counterpartylink_Set_Input>;
  /** filter the rows which have to be updated */
  where: Treasuryprime_App_Counterpartylink_Bool_Exp;
};

/** columns and relationships of "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness = {
  __typename?: 'treasuryprime_app_counterpartylinkbusiness';
  ach: Scalars['jsonb']['output'];
  active: Scalars['Boolean']['output'];
  business_owner_id: Scalars['String']['output'];
  /** An object relationship */
  businessowner: Users_Businessowner;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['String']['output'];
  name_on_account: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_CounterpartylinkbusinessAchArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate = {
  __typename?: 'treasuryprime_app_counterpartylinkbusiness_aggregate';
  aggregate?: Maybe<Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Fields>;
  nodes: Array<Treasuryprime_App_Counterpartylinkbusiness>;
};

export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Count>;
};

export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_And = {
  arguments: Treasuryprime_App_Counterpartylinkbusiness_Select_Column_Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Treasuryprime_App_Counterpartylinkbusiness_Select_Column_Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Fields = {
  __typename?: 'treasuryprime_app_counterpartylinkbusiness_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Treasuryprime_App_Counterpartylinkbusiness_Max_Fields>;
  min?: Maybe<Treasuryprime_App_Counterpartylinkbusiness_Min_Fields>;
};


/** aggregate fields of "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Max_Order_By>;
  min?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Treasuryprime_App_Counterpartylinkbusiness_Append_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Arr_Rel_Insert_Input = {
  data: Array<Treasuryprime_App_Counterpartylinkbusiness_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_On_Conflict>;
};

/** Boolean expression to filter rows from the table "treasuryprime_app_counterpartylinkbusiness". All fields are combined with a logical 'AND'. */
export type Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp = {
  _and?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>>;
  _not?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
  _or?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>>;
  ach?: InputMaybe<Jsonb_Comparison_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  business_owner_id?: InputMaybe<String_Comparison_Exp>;
  businessowner?: InputMaybe<Users_Businessowner_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name_on_account?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "treasuryprime_app_counterpartylinkbusiness" */
export enum Treasuryprime_App_Counterpartylinkbusiness_Constraint {
  /** unique or primary key constraint on columns "id" */
  TreasuryprimeAppCounterpartylinkbusinessPkey = 'treasuryprime_app_counterpartylinkbusiness_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Treasuryprime_App_Counterpartylinkbusiness_Delete_At_Path_Input = {
  ach?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Treasuryprime_App_Counterpartylinkbusiness_Delete_Elem_Input = {
  ach?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Treasuryprime_App_Counterpartylinkbusiness_Delete_Key_Input = {
  ach?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Insert_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_owner_id?: InputMaybe<Scalars['String']['input']>;
  businessowner?: InputMaybe<Users_Businessowner_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name_on_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Treasuryprime_App_Counterpartylinkbusiness_Max_Fields = {
  __typename?: 'treasuryprime_app_counterpartylinkbusiness_max_fields';
  business_owner_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name_on_account?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Max_Order_By = {
  business_owner_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_on_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Treasuryprime_App_Counterpartylinkbusiness_Min_Fields = {
  __typename?: 'treasuryprime_app_counterpartylinkbusiness_min_fields';
  business_owner_id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name_on_account?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Min_Order_By = {
  business_owner_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_on_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Mutation_Response = {
  __typename?: 'treasuryprime_app_counterpartylinkbusiness_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Treasuryprime_App_Counterpartylinkbusiness>;
};

/** input type for inserting object relation for remote table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Obj_Rel_Insert_Input = {
  data: Treasuryprime_App_Counterpartylinkbusiness_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_On_Conflict>;
};

/** on_conflict condition type for table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_On_Conflict = {
  constraint: Treasuryprime_App_Counterpartylinkbusiness_Constraint;
  update_columns?: Array<Treasuryprime_App_Counterpartylinkbusiness_Update_Column>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};

/** Ordering options when selecting data from "treasuryprime_app_counterpartylinkbusiness". */
export type Treasuryprime_App_Counterpartylinkbusiness_Order_By = {
  ach?: InputMaybe<Order_By>;
  active?: InputMaybe<Order_By>;
  business_owner_id?: InputMaybe<Order_By>;
  businessowner?: InputMaybe<Users_Businessowner_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_on_account?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: treasuryprime_app_counterpartylinkbusiness */
export type Treasuryprime_App_Counterpartylinkbusiness_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Treasuryprime_App_Counterpartylinkbusiness_Prepend_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "treasuryprime_app_counterpartylinkbusiness" */
export enum Treasuryprime_App_Counterpartylinkbusiness_Select_Column {
  /** column name */
  Ach = 'ach',
  /** column name */
  Active = 'active',
  /** column name */
  BusinessOwnerId = 'business_owner_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameOnAccount = 'name_on_account',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "treasuryprime_app_counterpartylinkbusiness_aggregate_bool_exp_bool_and_arguments_columns" columns of table "treasuryprime_app_counterpartylinkbusiness" */
export enum Treasuryprime_App_Counterpartylinkbusiness_Select_Column_Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "treasuryprime_app_counterpartylinkbusiness_aggregate_bool_exp_bool_or_arguments_columns" columns of table "treasuryprime_app_counterpartylinkbusiness" */
export enum Treasuryprime_App_Counterpartylinkbusiness_Select_Column_Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Set_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_owner_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name_on_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "treasuryprime_app_counterpartylinkbusiness" */
export type Treasuryprime_App_Counterpartylinkbusiness_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Treasuryprime_App_Counterpartylinkbusiness_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Treasuryprime_App_Counterpartylinkbusiness_Stream_Cursor_Value_Input = {
  ach?: InputMaybe<Scalars['jsonb']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_owner_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name_on_account?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "treasuryprime_app_counterpartylinkbusiness" */
export enum Treasuryprime_App_Counterpartylinkbusiness_Update_Column {
  /** column name */
  Ach = 'ach',
  /** column name */
  Active = 'active',
  /** column name */
  BusinessOwnerId = 'business_owner_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NameOnAccount = 'name_on_account',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Treasuryprime_App_Counterpartylinkbusiness_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Set_Input>;
  /** filter the rows which have to be updated */
  where: Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp;
};

/** columns and relationships of "users_businessowner" */
export type Users_Businessowner = {
  __typename?: 'users_businessowner';
  active: Scalars['Boolean']['output'];
  allowed_operations: Scalars['Boolean']['output'];
  /** An object relationship */
  business?: Maybe<Homegrown_Business>;
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamptz']['output'];
  date_of_birth?: Maybe<Scalars['date']['output']>;
  /** get deals for business owner */
  deals_cf?: Maybe<Array<Homegrown_Deal>>;
  /** An array relationship */
  documents: Array<Homegrown_Userdocuments>;
  /** An aggregate relationship */
  documents_aggregate: Homegrown_Userdocuments_Aggregate;
  financial_details?: Maybe<Scalars['jsonb']['output']>;
  full_name: Scalars['String']['output'];
  funding_details?: Maybe<Scalars['jsonb']['output']>;
  onboarding_complete: Scalars['Boolean']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  phone_verified: Scalars['Boolean']['output'];
  plaid_connected: Scalars['Boolean']['output'];
  /** An array relationship */
  plaidlinkbusinesses: Array<Plaid_App_Plaidlinkbusiness>;
  /** An aggregate relationship */
  plaidlinkbusinesses_aggregate: Plaid_App_Plaidlinkbusiness_Aggregate;
  profile?: Maybe<Scalars['jsonb']['output']>;
  /** An object relationship */
  profile_photo?: Maybe<Files>;
  profile_picture_id?: Maybe<Scalars['uuid']['output']>;
  request_submitted: Scalars['Boolean']['output'];
  ssn_id?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  tax_documents: Array<Homegrown_Taxdocuments>;
  /** An aggregate relationship */
  tax_documents_aggregate: Homegrown_Taxdocuments_Aggregate;
  /** An array relationship */
  tp_counterpartylink: Array<Treasuryprime_App_Counterpartylinkbusiness>;
  /** An aggregate relationship */
  tp_counterpartylink_aggregate: Treasuryprime_App_Counterpartylinkbusiness_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user: Users_User;
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerDeals_CfArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Deal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Deal_Order_By>>;
  where?: InputMaybe<Homegrown_Deal_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Userdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Userdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerFinancial_DetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerFunding_DetailsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerPlaidlinkbusinessesArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerPlaidlinkbusinesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Plaid_App_Plaidlinkbusiness_Order_By>>;
  where?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerProfileArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerTax_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerTax_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Homegrown_Taxdocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Homegrown_Taxdocuments_Order_By>>;
  where?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerTp_CounterpartylinkArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};


/** columns and relationships of "users_businessowner" */
export type Users_BusinessownerTp_Counterpartylink_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treasuryprime_App_Counterpartylinkbusiness_Order_By>>;
  where?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
};

/** aggregated selection of "users_businessowner" */
export type Users_Businessowner_Aggregate = {
  __typename?: 'users_businessowner_aggregate';
  aggregate?: Maybe<Users_Businessowner_Aggregate_Fields>;
  nodes: Array<Users_Businessowner>;
};

/** aggregate fields of "users_businessowner" */
export type Users_Businessowner_Aggregate_Fields = {
  __typename?: 'users_businessowner_aggregate_fields';
  avg?: Maybe<Users_Businessowner_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Businessowner_Max_Fields>;
  min?: Maybe<Users_Businessowner_Min_Fields>;
  stddev?: Maybe<Users_Businessowner_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Businessowner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Businessowner_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Businessowner_Sum_Fields>;
  var_pop?: Maybe<Users_Businessowner_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Businessowner_Var_Samp_Fields>;
  variance?: Maybe<Users_Businessowner_Variance_Fields>;
};


/** aggregate fields of "users_businessowner" */
export type Users_Businessowner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Businessowner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Businessowner_Append_Input = {
  financial_details?: InputMaybe<Scalars['jsonb']['input']>;
  funding_details?: InputMaybe<Scalars['jsonb']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Users_Businessowner_Avg_Fields = {
  __typename?: 'users_businessowner_avg_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users_businessowner". All fields are combined with a logical 'AND'. */
export type Users_Businessowner_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Businessowner_Bool_Exp>>;
  _not?: InputMaybe<Users_Businessowner_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Businessowner_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  allowed_operations?: InputMaybe<Boolean_Comparison_Exp>;
  business?: InputMaybe<Homegrown_Business_Bool_Exp>;
  business_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  deals_cf?: InputMaybe<Homegrown_Deal_Bool_Exp>;
  documents?: InputMaybe<Homegrown_Userdocuments_Bool_Exp>;
  documents_aggregate?: InputMaybe<Homegrown_Userdocuments_Aggregate_Bool_Exp>;
  financial_details?: InputMaybe<Jsonb_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  funding_details?: InputMaybe<Jsonb_Comparison_Exp>;
  onboarding_complete?: InputMaybe<Boolean_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  phone_verified?: InputMaybe<Boolean_Comparison_Exp>;
  plaid_connected?: InputMaybe<Boolean_Comparison_Exp>;
  plaidlinkbusinesses?: InputMaybe<Plaid_App_Plaidlinkbusiness_Bool_Exp>;
  plaidlinkbusinesses_aggregate?: InputMaybe<Plaid_App_Plaidlinkbusiness_Aggregate_Bool_Exp>;
  profile?: InputMaybe<Jsonb_Comparison_Exp>;
  profile_photo?: InputMaybe<Files_Bool_Exp>;
  profile_picture_id?: InputMaybe<Uuid_Comparison_Exp>;
  request_submitted?: InputMaybe<Boolean_Comparison_Exp>;
  ssn_id?: InputMaybe<String_Comparison_Exp>;
  tax_documents?: InputMaybe<Homegrown_Taxdocuments_Bool_Exp>;
  tax_documents_aggregate?: InputMaybe<Homegrown_Taxdocuments_Aggregate_Bool_Exp>;
  tp_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Bool_Exp>;
  tp_counterpartylink_aggregate?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  us_citizenship_status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_businessowner" */
export enum Users_Businessowner_Constraint {
  /** unique or primary key constraint on columns "phone_number" */
  BusinessownerVerifiedPhoneNumberUniq = 'businessowner_verified_phone_number_uniq',
  /** unique or primary key constraint on columns "business_id" */
  UsersBusinessownerBusinessIdKey = 'users_businessowner_business_id_key',
  /** unique or primary key constraint on columns "user_id" */
  UsersBusinessownerPkey = 'users_businessowner_pkey',
  /** unique or primary key constraint on columns "profile_picture_id" */
  UsersBusinessownerProfilePictureIdKey = 'users_businessowner_profile_picture_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Businessowner_Delete_At_Path_Input = {
  financial_details?: InputMaybe<Array<Scalars['String']['input']>>;
  funding_details?: InputMaybe<Array<Scalars['String']['input']>>;
  profile?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Businessowner_Delete_Elem_Input = {
  financial_details?: InputMaybe<Scalars['Int']['input']>;
  funding_details?: InputMaybe<Scalars['Int']['input']>;
  profile?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Businessowner_Delete_Key_Input = {
  financial_details?: InputMaybe<Scalars['String']['input']>;
  funding_details?: InputMaybe<Scalars['String']['input']>;
  profile?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users_businessowner" */
export type Users_Businessowner_Inc_Input = {
  business_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_businessowner" */
export type Users_Businessowner_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_operations?: InputMaybe<Scalars['Boolean']['input']>;
  business?: InputMaybe<Homegrown_Business_Obj_Rel_Insert_Input>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  documents?: InputMaybe<Homegrown_Userdocuments_Arr_Rel_Insert_Input>;
  financial_details?: InputMaybe<Scalars['jsonb']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  funding_details?: InputMaybe<Scalars['jsonb']['input']>;
  onboarding_complete?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_connected?: InputMaybe<Scalars['Boolean']['input']>;
  plaidlinkbusinesses?: InputMaybe<Plaid_App_Plaidlinkbusiness_Arr_Rel_Insert_Input>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
  profile_photo?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  profile_picture_id?: InputMaybe<Scalars['uuid']['input']>;
  request_submitted?: InputMaybe<Scalars['Boolean']['input']>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  tax_documents?: InputMaybe<Homegrown_Taxdocuments_Arr_Rel_Insert_Input>;
  tp_counterpartylink?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Businessowner_Max_Fields = {
  __typename?: 'users_businessowner_max_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_picture_id?: Maybe<Scalars['uuid']['output']>;
  ssn_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Businessowner_Min_Fields = {
  __typename?: 'users_businessowner_min_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  full_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_picture_id?: Maybe<Scalars['uuid']['output']>;
  ssn_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_businessowner" */
export type Users_Businessowner_Mutation_Response = {
  __typename?: 'users_businessowner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Businessowner>;
};

/** input type for inserting object relation for remote table "users_businessowner" */
export type Users_Businessowner_Obj_Rel_Insert_Input = {
  data: Users_Businessowner_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Businessowner_On_Conflict>;
};

/** on_conflict condition type for table "users_businessowner" */
export type Users_Businessowner_On_Conflict = {
  constraint: Users_Businessowner_Constraint;
  update_columns?: Array<Users_Businessowner_Update_Column>;
  where?: InputMaybe<Users_Businessowner_Bool_Exp>;
};

/** Ordering options when selecting data from "users_businessowner". */
export type Users_Businessowner_Order_By = {
  active?: InputMaybe<Order_By>;
  allowed_operations?: InputMaybe<Order_By>;
  business?: InputMaybe<Homegrown_Business_Order_By>;
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  deals_cf_aggregate?: InputMaybe<Homegrown_Deal_Aggregate_Order_By>;
  documents_aggregate?: InputMaybe<Homegrown_Userdocuments_Aggregate_Order_By>;
  financial_details?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  funding_details?: InputMaybe<Order_By>;
  onboarding_complete?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  phone_verified?: InputMaybe<Order_By>;
  plaid_connected?: InputMaybe<Order_By>;
  plaidlinkbusinesses_aggregate?: InputMaybe<Plaid_App_Plaidlinkbusiness_Aggregate_Order_By>;
  profile?: InputMaybe<Order_By>;
  profile_photo?: InputMaybe<Files_Order_By>;
  profile_picture_id?: InputMaybe<Order_By>;
  request_submitted?: InputMaybe<Order_By>;
  ssn_id?: InputMaybe<Order_By>;
  tax_documents_aggregate?: InputMaybe<Homegrown_Taxdocuments_Aggregate_Order_By>;
  tp_counterpartylink_aggregate?: InputMaybe<Treasuryprime_App_Counterpartylinkbusiness_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_citizenship_status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_businessowner */
export type Users_Businessowner_Pk_Columns_Input = {
  user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Businessowner_Prepend_Input = {
  financial_details?: InputMaybe<Scalars['jsonb']['input']>;
  funding_details?: InputMaybe<Scalars['jsonb']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_businessowner" */
export enum Users_Businessowner_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowedOperations = 'allowed_operations',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FinancialDetails = 'financial_details',
  /** column name */
  FullName = 'full_name',
  /** column name */
  FundingDetails = 'funding_details',
  /** column name */
  OnboardingComplete = 'onboarding_complete',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  PlaidConnected = 'plaid_connected',
  /** column name */
  Profile = 'profile',
  /** column name */
  ProfilePictureId = 'profile_picture_id',
  /** column name */
  RequestSubmitted = 'request_submitted',
  /** column name */
  SsnId = 'ssn_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsCitizenshipStatus = 'us_citizenship_status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_businessowner" */
export type Users_Businessowner_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_operations?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  financial_details?: InputMaybe<Scalars['jsonb']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  funding_details?: InputMaybe<Scalars['jsonb']['input']>;
  onboarding_complete?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_connected?: InputMaybe<Scalars['Boolean']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
  profile_picture_id?: InputMaybe<Scalars['uuid']['input']>;
  request_submitted?: InputMaybe<Scalars['Boolean']['input']>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Businessowner_Stddev_Fields = {
  __typename?: 'users_businessowner_stddev_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Businessowner_Stddev_Pop_Fields = {
  __typename?: 'users_businessowner_stddev_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Businessowner_Stddev_Samp_Fields = {
  __typename?: 'users_businessowner_stddev_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users_businessowner" */
export type Users_Businessowner_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Businessowner_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Businessowner_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_operations?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  financial_details?: InputMaybe<Scalars['jsonb']['input']>;
  full_name?: InputMaybe<Scalars['String']['input']>;
  funding_details?: InputMaybe<Scalars['jsonb']['input']>;
  onboarding_complete?: InputMaybe<Scalars['Boolean']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  phone_verified?: InputMaybe<Scalars['Boolean']['input']>;
  plaid_connected?: InputMaybe<Scalars['Boolean']['input']>;
  profile?: InputMaybe<Scalars['jsonb']['input']>;
  profile_picture_id?: InputMaybe<Scalars['uuid']['input']>;
  request_submitted?: InputMaybe<Scalars['Boolean']['input']>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Businessowner_Sum_Fields = {
  __typename?: 'users_businessowner_sum_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users_businessowner" */
export enum Users_Businessowner_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AllowedOperations = 'allowed_operations',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  FinancialDetails = 'financial_details',
  /** column name */
  FullName = 'full_name',
  /** column name */
  FundingDetails = 'funding_details',
  /** column name */
  OnboardingComplete = 'onboarding_complete',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhoneVerified = 'phone_verified',
  /** column name */
  PlaidConnected = 'plaid_connected',
  /** column name */
  Profile = 'profile',
  /** column name */
  ProfilePictureId = 'profile_picture_id',
  /** column name */
  RequestSubmitted = 'request_submitted',
  /** column name */
  SsnId = 'ssn_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsCitizenshipStatus = 'us_citizenship_status',
  /** column name */
  UserId = 'user_id'
}

export type Users_Businessowner_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Businessowner_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Businessowner_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Businessowner_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Businessowner_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Businessowner_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Businessowner_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Businessowner_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Businessowner_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Businessowner_Var_Pop_Fields = {
  __typename?: 'users_businessowner_var_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Businessowner_Var_Samp_Fields = {
  __typename?: 'users_businessowner_var_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Businessowner_Variance_Fields = {
  __typename?: 'users_businessowner_variance_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users_employee" */
export type Users_Employee = {
  __typename?: 'users_employee';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  /** An object relationship */
  employee_role?: Maybe<Users_Roleorder>;
  first_name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "users_employee_full_name" */
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users_User>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "users_employee" */
export type Users_Employee_Aggregate = {
  __typename?: 'users_employee_aggregate';
  aggregate?: Maybe<Users_Employee_Aggregate_Fields>;
  nodes: Array<Users_Employee>;
};

/** aggregate fields of "users_employee" */
export type Users_Employee_Aggregate_Fields = {
  __typename?: 'users_employee_aggregate_fields';
  avg?: Maybe<Users_Employee_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Employee_Max_Fields>;
  min?: Maybe<Users_Employee_Min_Fields>;
  stddev?: Maybe<Users_Employee_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Employee_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Employee_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Employee_Sum_Fields>;
  var_pop?: Maybe<Users_Employee_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Employee_Var_Samp_Fields>;
  variance?: Maybe<Users_Employee_Variance_Fields>;
};


/** aggregate fields of "users_employee" */
export type Users_Employee_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Employee_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Users_Employee_Avg_Fields = {
  __typename?: 'users_employee_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users_employee". All fields are combined with a logical 'AND'. */
export type Users_Employee_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Employee_Bool_Exp>>;
  _not?: InputMaybe<Users_Employee_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Employee_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  employee_role?: InputMaybe<Users_Roleorder_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  full_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_User_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_employee" */
export enum Users_Employee_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmployeeEmail_9d0608b5Uniq = 'users_employee_email_9d0608b5_uniq',
  /** unique or primary key constraint on columns "id" */
  UsersEmployeePkey = 'users_employee_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UsersEmployeeUserIdKey = 'users_employee_user_id_key'
}

/** input type for incrementing numeric columns in table "users_employee" */
export type Users_Employee_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_employee" */
export type Users_Employee_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employee_role?: InputMaybe<Users_Roleorder_Obj_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_User_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Employee_Max_Fields = {
  __typename?: 'users_employee_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "users_employee_full_name" */
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Employee_Min_Fields = {
  __typename?: 'users_employee_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "users_employee_full_name" */
  full_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_employee" */
export type Users_Employee_Mutation_Response = {
  __typename?: 'users_employee_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Employee>;
};

/** input type for inserting object relation for remote table "users_employee" */
export type Users_Employee_Obj_Rel_Insert_Input = {
  data: Users_Employee_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Employee_On_Conflict>;
};

/** on_conflict condition type for table "users_employee" */
export type Users_Employee_On_Conflict = {
  constraint: Users_Employee_Constraint;
  update_columns?: Array<Users_Employee_Update_Column>;
  where?: InputMaybe<Users_Employee_Bool_Exp>;
};

/** Ordering options when selecting data from "users_employee". */
export type Users_Employee_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  employee_role?: InputMaybe<Users_Roleorder_Order_By>;
  first_name?: InputMaybe<Order_By>;
  full_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_User_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_employee */
export type Users_Employee_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users_employee" */
export enum Users_Employee_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_employee" */
export type Users_Employee_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Employee_Stddev_Fields = {
  __typename?: 'users_employee_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Employee_Stddev_Pop_Fields = {
  __typename?: 'users_employee_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Employee_Stddev_Samp_Fields = {
  __typename?: 'users_employee_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users_employee" */
export type Users_Employee_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Employee_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Employee_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Employee_Sum_Fields = {
  __typename?: 'users_employee_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users_employee" */
export enum Users_Employee_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Employee_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Employee_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Employee_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Employee_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Employee_Var_Pop_Fields = {
  __typename?: 'users_employee_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Employee_Var_Samp_Fields = {
  __typename?: 'users_employee_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Employee_Variance_Fields = {
  __typename?: 'users_employee_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users_investorbusiness" */
export type Users_Investorbusiness = {
  __typename?: 'users_investorbusiness';
  active: Scalars['Boolean']['output'];
  address?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  ein?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  /** An array relationship */
  investorinvites: Array<Users_Secondaryinvestorinvite>;
  /** An aggregate relationship */
  investorinvites_aggregate: Users_Secondaryinvestorinvite_Aggregate;
  location?: Maybe<Scalars['jsonb']['output']>;
  name: Scalars['String']['output'];
  /** An object relationship */
  primary_investor?: Maybe<Investordata>;
  primary_investor_id: Scalars['String']['output'];
  /** An array relationship */
  secondary_investors: Array<Users_Secondaryinvestordata>;
  /** An aggregate relationship */
  secondary_investors_aggregate: Users_Secondaryinvestordata_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};


/** columns and relationships of "users_investorbusiness" */
export type Users_InvestorbusinessInvestorinvitesArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestorinvite_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};


/** columns and relationships of "users_investorbusiness" */
export type Users_InvestorbusinessInvestorinvites_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestorinvite_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};


/** columns and relationships of "users_investorbusiness" */
export type Users_InvestorbusinessLocationArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users_investorbusiness" */
export type Users_InvestorbusinessSecondary_InvestorsArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordata_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};


/** columns and relationships of "users_investorbusiness" */
export type Users_InvestorbusinessSecondary_Investors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordata_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};

/** aggregated selection of "users_investorbusiness" */
export type Users_Investorbusiness_Aggregate = {
  __typename?: 'users_investorbusiness_aggregate';
  aggregate?: Maybe<Users_Investorbusiness_Aggregate_Fields>;
  nodes: Array<Users_Investorbusiness>;
};

/** aggregate fields of "users_investorbusiness" */
export type Users_Investorbusiness_Aggregate_Fields = {
  __typename?: 'users_investorbusiness_aggregate_fields';
  avg?: Maybe<Users_Investorbusiness_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Investorbusiness_Max_Fields>;
  min?: Maybe<Users_Investorbusiness_Min_Fields>;
  stddev?: Maybe<Users_Investorbusiness_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Investorbusiness_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Investorbusiness_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Investorbusiness_Sum_Fields>;
  var_pop?: Maybe<Users_Investorbusiness_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Investorbusiness_Var_Samp_Fields>;
  variance?: Maybe<Users_Investorbusiness_Variance_Fields>;
};


/** aggregate fields of "users_investorbusiness" */
export type Users_Investorbusiness_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Investorbusiness_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Investorbusiness_Append_Input = {
  location?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Users_Investorbusiness_Avg_Fields = {
  __typename?: 'users_investorbusiness_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users_investorbusiness". All fields are combined with a logical 'AND'. */
export type Users_Investorbusiness_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Investorbusiness_Bool_Exp>>;
  _not?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Investorbusiness_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ein?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  investorinvites?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
  investorinvites_aggregate?: InputMaybe<Users_Secondaryinvestorinvite_Aggregate_Bool_Exp>;
  location?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  primary_investor?: InputMaybe<Investordata_Bool_Exp>;
  primary_investor_id?: InputMaybe<String_Comparison_Exp>;
  secondary_investors?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
  secondary_investors_aggregate?: InputMaybe<Users_Secondaryinvestordata_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_investorbusiness" */
export enum Users_Investorbusiness_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersInvestorbusinessPkey = 'users_investorbusiness_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Investorbusiness_Delete_At_Path_Input = {
  location?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Investorbusiness_Delete_Elem_Input = {
  location?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Investorbusiness_Delete_Key_Input = {
  location?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users_investorbusiness" */
export type Users_Investorbusiness_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_investorbusiness" */
export type Users_Investorbusiness_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  investorinvites?: InputMaybe<Users_Secondaryinvestorinvite_Arr_Rel_Insert_Input>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  primary_investor_id?: InputMaybe<Scalars['String']['input']>;
  secondary_investors?: InputMaybe<Users_Secondaryinvestordata_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Users_Investorbusiness_Max_Fields = {
  __typename?: 'users_investorbusiness_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primary_investor_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Users_Investorbusiness_Min_Fields = {
  __typename?: 'users_investorbusiness_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  ein?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primary_investor_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "users_investorbusiness" */
export type Users_Investorbusiness_Mutation_Response = {
  __typename?: 'users_investorbusiness_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Investorbusiness>;
};

/** input type for inserting object relation for remote table "users_investorbusiness" */
export type Users_Investorbusiness_Obj_Rel_Insert_Input = {
  data: Users_Investorbusiness_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Investorbusiness_On_Conflict>;
};

/** on_conflict condition type for table "users_investorbusiness" */
export type Users_Investorbusiness_On_Conflict = {
  constraint: Users_Investorbusiness_Constraint;
  update_columns?: Array<Users_Investorbusiness_Update_Column>;
  where?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
};

/** Ordering options when selecting data from "users_investorbusiness". */
export type Users_Investorbusiness_Order_By = {
  active?: InputMaybe<Order_By>;
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ein?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investorinvites_aggregate?: InputMaybe<Users_Secondaryinvestorinvite_Aggregate_Order_By>;
  location?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  primary_investor?: InputMaybe<Investordata_Order_By>;
  primary_investor_id?: InputMaybe<Order_By>;
  secondary_investors_aggregate?: InputMaybe<Users_Secondaryinvestordata_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_investorbusiness */
export type Users_Investorbusiness_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Investorbusiness_Prepend_Input = {
  location?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_investorbusiness" */
export enum Users_Investorbusiness_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Ein = 'ein',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryInvestorId = 'primary_investor_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users_investorbusiness" */
export type Users_Investorbusiness_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_investor_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Users_Investorbusiness_Stddev_Fields = {
  __typename?: 'users_investorbusiness_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Investorbusiness_Stddev_Pop_Fields = {
  __typename?: 'users_investorbusiness_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Investorbusiness_Stddev_Samp_Fields = {
  __typename?: 'users_investorbusiness_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users_investorbusiness" */
export type Users_Investorbusiness_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Investorbusiness_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Investorbusiness_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  ein?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  location?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  primary_investor_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Users_Investorbusiness_Sum_Fields = {
  __typename?: 'users_investorbusiness_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users_investorbusiness" */
export enum Users_Investorbusiness_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Ein = 'ein',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  Name = 'name',
  /** column name */
  PrimaryInvestorId = 'primary_investor_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Investorbusiness_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Investorbusiness_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Investorbusiness_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Investorbusiness_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Investorbusiness_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Investorbusiness_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Investorbusiness_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Investorbusiness_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Investorbusiness_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Investorbusiness_Var_Pop_Fields = {
  __typename?: 'users_investorbusiness_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Investorbusiness_Var_Samp_Fields = {
  __typename?: 'users_investorbusiness_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Investorbusiness_Variance_Fields = {
  __typename?: 'users_investorbusiness_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users_roleorder" */
export type Users_Roleorder = {
  __typename?: 'users_roleorder';
  active: Scalars['Boolean']['output'];
  can_assign: Scalars['Boolean']['output'];
  can_remove: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['bigint']['output'];
  order: Scalars['Int']['output'];
  role_name: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "users_roleorder" */
export type Users_Roleorder_Aggregate = {
  __typename?: 'users_roleorder_aggregate';
  aggregate?: Maybe<Users_Roleorder_Aggregate_Fields>;
  nodes: Array<Users_Roleorder>;
};

/** aggregate fields of "users_roleorder" */
export type Users_Roleorder_Aggregate_Fields = {
  __typename?: 'users_roleorder_aggregate_fields';
  avg?: Maybe<Users_Roleorder_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Roleorder_Max_Fields>;
  min?: Maybe<Users_Roleorder_Min_Fields>;
  stddev?: Maybe<Users_Roleorder_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Roleorder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Roleorder_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Roleorder_Sum_Fields>;
  var_pop?: Maybe<Users_Roleorder_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Roleorder_Var_Samp_Fields>;
  variance?: Maybe<Users_Roleorder_Variance_Fields>;
};


/** aggregate fields of "users_roleorder" */
export type Users_Roleorder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Roleorder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Users_Roleorder_Avg_Fields = {
  __typename?: 'users_roleorder_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users_roleorder". All fields are combined with a logical 'AND'. */
export type Users_Roleorder_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Roleorder_Bool_Exp>>;
  _not?: InputMaybe<Users_Roleorder_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Roleorder_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  can_assign?: InputMaybe<Boolean_Comparison_Exp>;
  can_remove?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  role_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_roleorder" */
export enum Users_Roleorder_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersRoleorderPkey = 'users_roleorder_pkey'
}

/** input type for incrementing numeric columns in table "users_roleorder" */
export type Users_Roleorder_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users_roleorder" */
export type Users_Roleorder_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  can_assign?: InputMaybe<Scalars['Boolean']['input']>;
  can_remove?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  role_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Users_Roleorder_Max_Fields = {
  __typename?: 'users_roleorder_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  role_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Users_Roleorder_Min_Fields = {
  __typename?: 'users_roleorder_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  role_name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "users_roleorder" */
export type Users_Roleorder_Mutation_Response = {
  __typename?: 'users_roleorder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Roleorder>;
};

/** input type for inserting object relation for remote table "users_roleorder" */
export type Users_Roleorder_Obj_Rel_Insert_Input = {
  data: Users_Roleorder_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Roleorder_On_Conflict>;
};

/** on_conflict condition type for table "users_roleorder" */
export type Users_Roleorder_On_Conflict = {
  constraint: Users_Roleorder_Constraint;
  update_columns?: Array<Users_Roleorder_Update_Column>;
  where?: InputMaybe<Users_Roleorder_Bool_Exp>;
};

/** Ordering options when selecting data from "users_roleorder". */
export type Users_Roleorder_Order_By = {
  active?: InputMaybe<Order_By>;
  can_assign?: InputMaybe<Order_By>;
  can_remove?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  role_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_roleorder */
export type Users_Roleorder_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users_roleorder" */
export enum Users_Roleorder_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CanAssign = 'can_assign',
  /** column name */
  CanRemove = 'can_remove',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users_roleorder" */
export type Users_Roleorder_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  can_assign?: InputMaybe<Scalars['Boolean']['input']>;
  can_remove?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  role_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Users_Roleorder_Stddev_Fields = {
  __typename?: 'users_roleorder_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Roleorder_Stddev_Pop_Fields = {
  __typename?: 'users_roleorder_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Roleorder_Stddev_Samp_Fields = {
  __typename?: 'users_roleorder_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users_roleorder" */
export type Users_Roleorder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Roleorder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Roleorder_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  can_assign?: InputMaybe<Scalars['Boolean']['input']>;
  can_remove?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  role_name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Users_Roleorder_Sum_Fields = {
  __typename?: 'users_roleorder_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "users_roleorder" */
export enum Users_Roleorder_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CanAssign = 'can_assign',
  /** column name */
  CanRemove = 'can_remove',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Roleorder_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Roleorder_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Roleorder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Roleorder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Roleorder_Var_Pop_Fields = {
  __typename?: 'users_roleorder_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Roleorder_Var_Samp_Fields = {
  __typename?: 'users_roleorder_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Roleorder_Variance_Fields = {
  __typename?: 'users_roleorder_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata = {
  __typename?: 'users_secondaryinvestordata';
  active: Scalars['Boolean']['output'];
  /** An object relationship */
  business: Users_Investorbusiness;
  business_id: Scalars['bigint']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  /** An array relationship */
  documents: Array<Users_Secondaryinvestordocuments>;
  /** An aggregate relationship */
  documents_aggregate: Users_Secondaryinvestordocuments_Aggregate;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  government_id?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['bigint']['output'];
  invite_code_id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  primary_investor?: Maybe<Investordata>;
  ssn_id?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "users_secondaryinvestordata" */
export type Users_SecondaryinvestordataDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordocuments_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};


/** columns and relationships of "users_secondaryinvestordata" */
export type Users_SecondaryinvestordataDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Secondaryinvestordocuments_Order_By>>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};


/** columns and relationships of "users_secondaryinvestordata" */
export type Users_SecondaryinvestordataGovernment_IdArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Aggregate = {
  __typename?: 'users_secondaryinvestordata_aggregate';
  aggregate?: Maybe<Users_Secondaryinvestordata_Aggregate_Fields>;
  nodes: Array<Users_Secondaryinvestordata>;
};

export type Users_Secondaryinvestordata_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Secondaryinvestordata_Aggregate_Bool_Exp_Count>;
};

export type Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Secondaryinvestordata_Select_Column_Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Secondaryinvestordata_Select_Column_Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Secondaryinvestordata_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Aggregate_Fields = {
  __typename?: 'users_secondaryinvestordata_aggregate_fields';
  avg?: Maybe<Users_Secondaryinvestordata_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Secondaryinvestordata_Max_Fields>;
  min?: Maybe<Users_Secondaryinvestordata_Min_Fields>;
  stddev?: Maybe<Users_Secondaryinvestordata_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Secondaryinvestordata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Secondaryinvestordata_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Secondaryinvestordata_Sum_Fields>;
  var_pop?: Maybe<Users_Secondaryinvestordata_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Secondaryinvestordata_Var_Samp_Fields>;
  variance?: Maybe<Users_Secondaryinvestordata_Variance_Fields>;
};


/** aggregate fields of "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Secondaryinvestordata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Secondaryinvestordata_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Secondaryinvestordata_Max_Order_By>;
  min?: InputMaybe<Users_Secondaryinvestordata_Min_Order_By>;
  stddev?: InputMaybe<Users_Secondaryinvestordata_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Secondaryinvestordata_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Secondaryinvestordata_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Secondaryinvestordata_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Secondaryinvestordata_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Secondaryinvestordata_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Secondaryinvestordata_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Secondaryinvestordata_Append_Input = {
  government_id?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Arr_Rel_Insert_Input = {
  data: Array<Users_Secondaryinvestordata_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Secondaryinvestordata_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Secondaryinvestordata_Avg_Fields = {
  __typename?: 'users_secondaryinvestordata_avg_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Avg_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_secondaryinvestordata". All fields are combined with a logical 'AND'. */
export type Users_Secondaryinvestordata_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Secondaryinvestordata_Bool_Exp>>;
  _not?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Secondaryinvestordata_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  business?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
  business_id?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  date_of_birth?: InputMaybe<Date_Comparison_Exp>;
  documents?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
  documents_aggregate?: InputMaybe<Users_Secondaryinvestordocuments_Aggregate_Bool_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  government_id?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  invite_code_id?: InputMaybe<String_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  primary_investor?: InputMaybe<Investordata_Bool_Exp>;
  ssn_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  us_citizenship_status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_secondaryinvestordata" */
export enum Users_Secondaryinvestordata_Constraint {
  /** unique or primary key constraint on columns "invite_code_id" */
  UsersSecondaryinvestordataInviteCodeIdKey = 'users_secondaryinvestordata_invite_code_id_key',
  /** unique or primary key constraint on columns "id" */
  UsersSecondaryinvestordataPkey = 'users_secondaryinvestordata_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Secondaryinvestordata_Delete_At_Path_Input = {
  government_id?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Secondaryinvestordata_Delete_Elem_Input = {
  government_id?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Secondaryinvestordata_Delete_Key_Input = {
  government_id?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Inc_Input = {
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business?: InputMaybe<Users_Investorbusiness_Obj_Rel_Insert_Input>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  documents?: InputMaybe<Users_Secondaryinvestordocuments_Arr_Rel_Insert_Input>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  government_id?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invite_code_id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  primary_investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Secondaryinvestordata_Max_Fields = {
  __typename?: 'users_secondaryinvestordata_max_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  invite_code_id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  ssn_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Max_Order_By = {
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invite_code_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  ssn_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_citizenship_status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Secondaryinvestordata_Min_Fields = {
  __typename?: 'users_secondaryinvestordata_min_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  date_of_birth?: Maybe<Scalars['date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  invite_code_id?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  ssn_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  us_citizenship_status?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Min_Order_By = {
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invite_code_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  ssn_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_citizenship_status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Mutation_Response = {
  __typename?: 'users_secondaryinvestordata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Secondaryinvestordata>;
};

/** input type for inserting object relation for remote table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Obj_Rel_Insert_Input = {
  data: Users_Secondaryinvestordata_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Secondaryinvestordata_On_Conflict>;
};

/** on_conflict condition type for table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_On_Conflict = {
  constraint: Users_Secondaryinvestordata_Constraint;
  update_columns?: Array<Users_Secondaryinvestordata_Update_Column>;
  where?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
};

/** Ordering options when selecting data from "users_secondaryinvestordata". */
export type Users_Secondaryinvestordata_Order_By = {
  active?: InputMaybe<Order_By>;
  business?: InputMaybe<Users_Investorbusiness_Order_By>;
  business_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Users_Secondaryinvestordocuments_Aggregate_Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  government_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invite_code_id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  primary_investor?: InputMaybe<Investordata_Order_By>;
  ssn_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_citizenship_status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_secondaryinvestordata */
export type Users_Secondaryinvestordata_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Secondaryinvestordata_Prepend_Input = {
  government_id?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_secondaryinvestordata" */
export enum Users_Secondaryinvestordata_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GovernmentId = 'government_id',
  /** column name */
  Id = 'id',
  /** column name */
  InviteCodeId = 'invite_code_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SsnId = 'ssn_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsCitizenshipStatus = 'us_citizenship_status'
}

/** select "users_secondaryinvestordata_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users_secondaryinvestordata" */
export enum Users_Secondaryinvestordata_Select_Column_Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "users_secondaryinvestordata_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users_secondaryinvestordata" */
export enum Users_Secondaryinvestordata_Select_Column_Users_Secondaryinvestordata_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  government_id?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invite_code_id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Secondaryinvestordata_Stddev_Fields = {
  __typename?: 'users_secondaryinvestordata_stddev_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Stddev_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Secondaryinvestordata_Stddev_Pop_Fields = {
  __typename?: 'users_secondaryinvestordata_stddev_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Stddev_Pop_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Secondaryinvestordata_Stddev_Samp_Fields = {
  __typename?: 'users_secondaryinvestordata_stddev_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Stddev_Samp_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Secondaryinvestordata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Secondaryinvestordata_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  business_id?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  date_of_birth?: InputMaybe<Scalars['date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  government_id?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  invite_code_id?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  ssn_id?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  us_citizenship_status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Secondaryinvestordata_Sum_Fields = {
  __typename?: 'users_secondaryinvestordata_sum_fields';
  business_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Sum_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "users_secondaryinvestordata" */
export enum Users_Secondaryinvestordata_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  BusinessId = 'business_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  GovernmentId = 'government_id',
  /** column name */
  Id = 'id',
  /** column name */
  InviteCodeId = 'invite_code_id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SsnId = 'ssn_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsCitizenshipStatus = 'us_citizenship_status'
}

export type Users_Secondaryinvestordata_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Secondaryinvestordata_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Secondaryinvestordata_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Secondaryinvestordata_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Secondaryinvestordata_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Secondaryinvestordata_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Secondaryinvestordata_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Secondaryinvestordata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Secondaryinvestordata_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Secondaryinvestordata_Var_Pop_Fields = {
  __typename?: 'users_secondaryinvestordata_var_pop_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Var_Pop_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Secondaryinvestordata_Var_Samp_Fields = {
  __typename?: 'users_secondaryinvestordata_var_samp_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Var_Samp_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Secondaryinvestordata_Variance_Fields = {
  __typename?: 'users_secondaryinvestordata_variance_fields';
  business_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_secondaryinvestordata" */
export type Users_Secondaryinvestordata_Variance_Order_By = {
  business_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments = {
  __typename?: 'users_secondaryinvestordocuments';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  secondary_investor: Users_Secondaryinvestordata;
  secondary_investor_id: Scalars['bigint']['output'];
  type: Homegrown_Userdocumenttype_Enum;
  /** An object relationship */
  type_enum: Homegrown_Userdocumenttype;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Aggregate = {
  __typename?: 'users_secondaryinvestordocuments_aggregate';
  aggregate?: Maybe<Users_Secondaryinvestordocuments_Aggregate_Fields>;
  nodes: Array<Users_Secondaryinvestordocuments>;
};

export type Users_Secondaryinvestordocuments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Count>;
};

export type Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Secondaryinvestordocuments_Select_Column_Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Secondaryinvestordocuments_Select_Column_Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Aggregate_Fields = {
  __typename?: 'users_secondaryinvestordocuments_aggregate_fields';
  avg?: Maybe<Users_Secondaryinvestordocuments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Secondaryinvestordocuments_Max_Fields>;
  min?: Maybe<Users_Secondaryinvestordocuments_Min_Fields>;
  stddev?: Maybe<Users_Secondaryinvestordocuments_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Secondaryinvestordocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Secondaryinvestordocuments_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Secondaryinvestordocuments_Sum_Fields>;
  var_pop?: Maybe<Users_Secondaryinvestordocuments_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Secondaryinvestordocuments_Var_Samp_Fields>;
  variance?: Maybe<Users_Secondaryinvestordocuments_Variance_Fields>;
};


/** aggregate fields of "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Secondaryinvestordocuments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Secondaryinvestordocuments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Secondaryinvestordocuments_Max_Order_By>;
  min?: InputMaybe<Users_Secondaryinvestordocuments_Min_Order_By>;
  stddev?: InputMaybe<Users_Secondaryinvestordocuments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Secondaryinvestordocuments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Secondaryinvestordocuments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Secondaryinvestordocuments_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Secondaryinvestordocuments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Secondaryinvestordocuments_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Secondaryinvestordocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Arr_Rel_Insert_Input = {
  data: Array<Users_Secondaryinvestordocuments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Secondaryinvestordocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Secondaryinvestordocuments_Avg_Fields = {
  __typename?: 'users_secondaryinvestordocuments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  secondary_investor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_secondaryinvestordocuments". All fields are combined with a logical 'AND'. */
export type Users_Secondaryinvestordocuments_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Secondaryinvestordocuments_Bool_Exp>>;
  _not?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Secondaryinvestordocuments_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  secondary_investor?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
  secondary_investor_id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum_Comparison_Exp>;
  type_enum?: InputMaybe<Homegrown_Userdocumenttype_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_secondaryinvestordocuments" */
export enum Users_Secondaryinvestordocuments_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  UsersSecondaryinvestordocumentsFileIdKey = 'users_secondaryinvestordocuments_file_id_key',
  /** unique or primary key constraint on columns "id" */
  UsersSecondaryinvestordocumentsPkey = 'users_secondaryinvestordocuments_pkey'
}

/** input type for incrementing numeric columns in table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  secondary_investor_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  secondary_investor?: InputMaybe<Users_Secondaryinvestordata_Obj_Rel_Insert_Input>;
  secondary_investor_id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  type_enum?: InputMaybe<Homegrown_Userdocumenttype_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Users_Secondaryinvestordocuments_Max_Fields = {
  __typename?: 'users_secondaryinvestordocuments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  secondary_investor_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Secondaryinvestordocuments_Min_Fields = {
  __typename?: 'users_secondaryinvestordocuments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  secondary_investor_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Mutation_Response = {
  __typename?: 'users_secondaryinvestordocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Secondaryinvestordocuments>;
};

/** on_conflict condition type for table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_On_Conflict = {
  constraint: Users_Secondaryinvestordocuments_Constraint;
  update_columns?: Array<Users_Secondaryinvestordocuments_Update_Column>;
  where?: InputMaybe<Users_Secondaryinvestordocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "users_secondaryinvestordocuments". */
export type Users_Secondaryinvestordocuments_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  secondary_investor?: InputMaybe<Users_Secondaryinvestordata_Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  type_enum?: InputMaybe<Homegrown_Userdocumenttype_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_secondaryinvestordocuments */
export type Users_Secondaryinvestordocuments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users_secondaryinvestordocuments" */
export enum Users_Secondaryinvestordocuments_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  SecondaryInvestorId = 'secondary_investor_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "users_secondaryinvestordocuments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users_secondaryinvestordocuments" */
export enum Users_Secondaryinvestordocuments_Select_Column_Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "users_secondaryinvestordocuments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users_secondaryinvestordocuments" */
export enum Users_Secondaryinvestordocuments_Select_Column_Users_Secondaryinvestordocuments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  secondary_investor_id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Users_Secondaryinvestordocuments_Stddev_Fields = {
  __typename?: 'users_secondaryinvestordocuments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  secondary_investor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Secondaryinvestordocuments_Stddev_Pop_Fields = {
  __typename?: 'users_secondaryinvestordocuments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  secondary_investor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Secondaryinvestordocuments_Stddev_Samp_Fields = {
  __typename?: 'users_secondaryinvestordocuments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  secondary_investor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Secondaryinvestordocuments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Secondaryinvestordocuments_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  secondary_investor_id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Homegrown_Userdocumenttype_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Users_Secondaryinvestordocuments_Sum_Fields = {
  __typename?: 'users_secondaryinvestordocuments_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  secondary_investor_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** update columns of table "users_secondaryinvestordocuments" */
export enum Users_Secondaryinvestordocuments_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  SecondaryInvestorId = 'secondary_investor_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Secondaryinvestordocuments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Secondaryinvestordocuments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Secondaryinvestordocuments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Secondaryinvestordocuments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Secondaryinvestordocuments_Var_Pop_Fields = {
  __typename?: 'users_secondaryinvestordocuments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  secondary_investor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Secondaryinvestordocuments_Var_Samp_Fields = {
  __typename?: 'users_secondaryinvestordocuments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  secondary_investor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Secondaryinvestordocuments_Variance_Fields = {
  __typename?: 'users_secondaryinvestordocuments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  secondary_investor_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_secondaryinvestordocuments" */
export type Users_Secondaryinvestordocuments_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  secondary_investor_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite = {
  __typename?: 'users_secondaryinvestorinvite';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  created_by_id?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  entity_id: Scalars['bigint']['output'];
  expires_on: Scalars['timestamptz']['output'];
  /** An object relationship */
  investorbusiness: Users_Investorbusiness;
  invite_code: Scalars['String']['output'];
  legal_name: Scalars['String']['output'];
  phone_number: Scalars['String']['output'];
  /** An object relationship */
  secondaryinvestor?: Maybe<Users_Secondaryinvestordata>;
  status: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Aggregate = {
  __typename?: 'users_secondaryinvestorinvite_aggregate';
  aggregate?: Maybe<Users_Secondaryinvestorinvite_Aggregate_Fields>;
  nodes: Array<Users_Secondaryinvestorinvite>;
};

export type Users_Secondaryinvestorinvite_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Count>;
};

export type Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Secondaryinvestorinvite_Select_Column_Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Secondaryinvestorinvite_Select_Column_Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Aggregate_Fields = {
  __typename?: 'users_secondaryinvestorinvite_aggregate_fields';
  avg?: Maybe<Users_Secondaryinvestorinvite_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Secondaryinvestorinvite_Max_Fields>;
  min?: Maybe<Users_Secondaryinvestorinvite_Min_Fields>;
  stddev?: Maybe<Users_Secondaryinvestorinvite_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Secondaryinvestorinvite_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Secondaryinvestorinvite_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Secondaryinvestorinvite_Sum_Fields>;
  var_pop?: Maybe<Users_Secondaryinvestorinvite_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Secondaryinvestorinvite_Var_Samp_Fields>;
  variance?: Maybe<Users_Secondaryinvestorinvite_Variance_Fields>;
};


/** aggregate fields of "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Secondaryinvestorinvite_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Secondaryinvestorinvite_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Secondaryinvestorinvite_Max_Order_By>;
  min?: InputMaybe<Users_Secondaryinvestorinvite_Min_Order_By>;
  stddev?: InputMaybe<Users_Secondaryinvestorinvite_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Secondaryinvestorinvite_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Secondaryinvestorinvite_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Secondaryinvestorinvite_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Secondaryinvestorinvite_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Secondaryinvestorinvite_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Secondaryinvestorinvite_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Arr_Rel_Insert_Input = {
  data: Array<Users_Secondaryinvestorinvite_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Secondaryinvestorinvite_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Secondaryinvestorinvite_Avg_Fields = {
  __typename?: 'users_secondaryinvestorinvite_avg_fields';
  entity_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Avg_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_secondaryinvestorinvite". All fields are combined with a logical 'AND'. */
export type Users_Secondaryinvestorinvite_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Secondaryinvestorinvite_Bool_Exp>>;
  _not?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Secondaryinvestorinvite_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  entity_id?: InputMaybe<Bigint_Comparison_Exp>;
  expires_on?: InputMaybe<Timestamptz_Comparison_Exp>;
  investorbusiness?: InputMaybe<Users_Investorbusiness_Bool_Exp>;
  invite_code?: InputMaybe<String_Comparison_Exp>;
  legal_name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  secondaryinvestor?: InputMaybe<Users_Secondaryinvestordata_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_secondaryinvestorinvite" */
export enum Users_Secondaryinvestorinvite_Constraint {
  /** unique or primary key constraint on columns "invite_code" */
  UsersSecondaryinvestorinvitePkey = 'users_secondaryinvestorinvite_pkey'
}

/** input type for incrementing numeric columns in table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Inc_Input = {
  entity_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entity_id?: InputMaybe<Scalars['bigint']['input']>;
  expires_on?: InputMaybe<Scalars['timestamptz']['input']>;
  investorbusiness?: InputMaybe<Users_Investorbusiness_Obj_Rel_Insert_Input>;
  invite_code?: InputMaybe<Scalars['String']['input']>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  secondaryinvestor?: InputMaybe<Users_Secondaryinvestordata_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Users_Secondaryinvestorinvite_Max_Fields = {
  __typename?: 'users_secondaryinvestorinvite_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entity_id?: Maybe<Scalars['bigint']['output']>;
  expires_on?: Maybe<Scalars['timestamptz']['output']>;
  invite_code?: Maybe<Scalars['String']['output']>;
  legal_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  expires_on?: InputMaybe<Order_By>;
  invite_code?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Secondaryinvestorinvite_Min_Fields = {
  __typename?: 'users_secondaryinvestorinvite_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_id?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  entity_id?: Maybe<Scalars['bigint']['output']>;
  expires_on?: Maybe<Scalars['timestamptz']['output']>;
  invite_code?: Maybe<Scalars['String']['output']>;
  legal_name?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  expires_on?: InputMaybe<Order_By>;
  invite_code?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Mutation_Response = {
  __typename?: 'users_secondaryinvestorinvite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Secondaryinvestorinvite>;
};

/** on_conflict condition type for table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_On_Conflict = {
  constraint: Users_Secondaryinvestorinvite_Constraint;
  update_columns?: Array<Users_Secondaryinvestorinvite_Update_Column>;
  where?: InputMaybe<Users_Secondaryinvestorinvite_Bool_Exp>;
};

/** Ordering options when selecting data from "users_secondaryinvestorinvite". */
export type Users_Secondaryinvestorinvite_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  entity_id?: InputMaybe<Order_By>;
  expires_on?: InputMaybe<Order_By>;
  investorbusiness?: InputMaybe<Users_Investorbusiness_Order_By>;
  invite_code?: InputMaybe<Order_By>;
  legal_name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  secondaryinvestor?: InputMaybe<Users_Secondaryinvestordata_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_secondaryinvestorinvite */
export type Users_Secondaryinvestorinvite_Pk_Columns_Input = {
  invite_code: Scalars['String']['input'];
};

/** select columns of table "users_secondaryinvestorinvite" */
export enum Users_Secondaryinvestorinvite_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Email = 'email',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  ExpiresOn = 'expires_on',
  /** column name */
  InviteCode = 'invite_code',
  /** column name */
  LegalName = 'legal_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "users_secondaryinvestorinvite_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users_secondaryinvestorinvite" */
export enum Users_Secondaryinvestorinvite_Select_Column_Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** select "users_secondaryinvestorinvite_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users_secondaryinvestorinvite" */
export enum Users_Secondaryinvestorinvite_Select_Column_Users_Secondaryinvestorinvite_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active'
}

/** input type for updating data in table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entity_id?: InputMaybe<Scalars['bigint']['input']>;
  expires_on?: InputMaybe<Scalars['timestamptz']['input']>;
  invite_code?: InputMaybe<Scalars['String']['input']>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Users_Secondaryinvestorinvite_Stddev_Fields = {
  __typename?: 'users_secondaryinvestorinvite_stddev_fields';
  entity_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Stddev_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Secondaryinvestorinvite_Stddev_Pop_Fields = {
  __typename?: 'users_secondaryinvestorinvite_stddev_pop_fields';
  entity_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Stddev_Pop_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Secondaryinvestorinvite_Stddev_Samp_Fields = {
  __typename?: 'users_secondaryinvestorinvite_stddev_samp_fields';
  entity_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Stddev_Samp_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Secondaryinvestorinvite_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Secondaryinvestorinvite_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_id?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  entity_id?: InputMaybe<Scalars['bigint']['input']>;
  expires_on?: InputMaybe<Scalars['timestamptz']['input']>;
  invite_code?: InputMaybe<Scalars['String']['input']>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Users_Secondaryinvestorinvite_Sum_Fields = {
  __typename?: 'users_secondaryinvestorinvite_sum_fields';
  entity_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Sum_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** update columns of table "users_secondaryinvestorinvite" */
export enum Users_Secondaryinvestorinvite_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  Email = 'email',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  ExpiresOn = 'expires_on',
  /** column name */
  InviteCode = 'invite_code',
  /** column name */
  LegalName = 'legal_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Secondaryinvestorinvite_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Secondaryinvestorinvite_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Secondaryinvestorinvite_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Secondaryinvestorinvite_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Secondaryinvestorinvite_Var_Pop_Fields = {
  __typename?: 'users_secondaryinvestorinvite_var_pop_fields';
  entity_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Var_Pop_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Secondaryinvestorinvite_Var_Samp_Fields = {
  __typename?: 'users_secondaryinvestorinvite_var_samp_fields';
  entity_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Var_Samp_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Secondaryinvestorinvite_Variance_Fields = {
  __typename?: 'users_secondaryinvestorinvite_variance_fields';
  entity_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_secondaryinvestorinvite" */
export type Users_Secondaryinvestorinvite_Variance_Order_By = {
  entity_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users_user" */
export type Users_User = {
  __typename?: 'users_user';
  active: Scalars['Boolean']['output'];
  auth0id: Scalars['String']['output'];
  blocked: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  email?: Maybe<Scalars['String']['output']>;
  email_verified: Scalars['Boolean']['output'];
  /** An array relationship */
  investments: Array<Investments>;
  /** An aggregate relationship */
  investments_aggregate: Investments_Aggregate;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user_employee?: Maybe<Users_Employee>;
  /** An object relationship */
  user_investor?: Maybe<Investordata>;
  /** An array relationship */
  users_user_storage_files: Array<Files>;
  /** An aggregate relationship */
  users_user_storage_files_aggregate: Files_Aggregate;
};


/** columns and relationships of "users_user" */
export type Users_UserInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


/** columns and relationships of "users_user" */
export type Users_UserInvestments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};


/** columns and relationships of "users_user" */
export type Users_UserUsers_User_Storage_FilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "users_user" */
export type Users_UserUsers_User_Storage_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** aggregated selection of "users_user" */
export type Users_User_Aggregate = {
  __typename?: 'users_user_aggregate';
  aggregate?: Maybe<Users_User_Aggregate_Fields>;
  nodes: Array<Users_User>;
};

/** aggregate fields of "users_user" */
export type Users_User_Aggregate_Fields = {
  __typename?: 'users_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_User_Max_Fields>;
  min?: Maybe<Users_User_Min_Fields>;
};


/** aggregate fields of "users_user" */
export type Users_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "users_user". All fields are combined with a logical 'AND'. */
export type Users_User_Bool_Exp = {
  _and?: InputMaybe<Array<Users_User_Bool_Exp>>;
  _not?: InputMaybe<Users_User_Bool_Exp>;
  _or?: InputMaybe<Array<Users_User_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  auth0id?: InputMaybe<String_Comparison_Exp>;
  blocked?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  investments?: InputMaybe<Investments_Bool_Exp>;
  investments_aggregate?: InputMaybe<Investments_Aggregate_Bool_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_employee?: InputMaybe<Users_Employee_Bool_Exp>;
  user_investor?: InputMaybe<Investordata_Bool_Exp>;
  users_user_storage_files?: InputMaybe<Files_Bool_Exp>;
  users_user_storage_files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users_user" */
export enum Users_User_Constraint {
  /** unique or primary key constraint on columns "auth0id" */
  UsersUserPkey = 'users_user_pkey'
}

/** input type for inserting data into table "users_user" */
export type Users_User_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  auth0id?: InputMaybe<Scalars['String']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  investments?: InputMaybe<Investments_Arr_Rel_Insert_Input>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_employee?: InputMaybe<Users_Employee_Obj_Rel_Insert_Input>;
  user_investor?: InputMaybe<Investordata_Obj_Rel_Insert_Input>;
  users_user_storage_files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_User_Max_Fields = {
  __typename?: 'users_user_max_fields';
  auth0id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Users_User_Min_Fields = {
  __typename?: 'users_user_min_fields';
  auth0id?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  last_seen?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "users_user" */
export type Users_User_Mutation_Response = {
  __typename?: 'users_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_User>;
};

/** input type for inserting object relation for remote table "users_user" */
export type Users_User_Obj_Rel_Insert_Input = {
  data: Users_User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_User_On_Conflict>;
};

/** on_conflict condition type for table "users_user" */
export type Users_User_On_Conflict = {
  constraint: Users_User_Constraint;
  update_columns?: Array<Users_User_Update_Column>;
  where?: InputMaybe<Users_User_Bool_Exp>;
};

/** Ordering options when selecting data from "users_user". */
export type Users_User_Order_By = {
  active?: InputMaybe<Order_By>;
  auth0id?: InputMaybe<Order_By>;
  blocked?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified?: InputMaybe<Order_By>;
  investments_aggregate?: InputMaybe<Investments_Aggregate_Order_By>;
  last_seen?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_employee?: InputMaybe<Users_Employee_Order_By>;
  user_investor?: InputMaybe<Investordata_Order_By>;
  users_user_storage_files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
};

/** primary key columns input for table: users_user */
export type Users_User_Pk_Columns_Input = {
  auth0id: Scalars['String']['input'];
};

/** select columns of table "users_user" */
export enum Users_User_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Auth0id = 'auth0id',
  /** column name */
  Blocked = 'blocked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users_user" */
export type Users_User_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  auth0id?: InputMaybe<Scalars['String']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users_user" */
export type Users_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_User_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  auth0id?: InputMaybe<Scalars['String']['input']>;
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified?: InputMaybe<Scalars['Boolean']['input']>;
  last_seen?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "users_user" */
export enum Users_User_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Auth0id = 'auth0id',
  /** column name */
  Blocked = 'blocked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_User_Bool_Exp;
};

/** columns and relationships of "users_userinterests" */
export type Users_Userinterests = {
  __typename?: 'users_userinterests';
  id: Scalars['bigint']['output'];
  type?: Maybe<Scalars['String']['output']>;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "users_userinterests" */
export type Users_Userinterests_Aggregate = {
  __typename?: 'users_userinterests_aggregate';
  aggregate?: Maybe<Users_Userinterests_Aggregate_Fields>;
  nodes: Array<Users_Userinterests>;
};

export type Users_Userinterests_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Userinterests_Aggregate_Bool_Exp_Count>;
};

export type Users_Userinterests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Userinterests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_userinterests" */
export type Users_Userinterests_Aggregate_Fields = {
  __typename?: 'users_userinterests_aggregate_fields';
  avg?: Maybe<Users_Userinterests_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Userinterests_Max_Fields>;
  min?: Maybe<Users_Userinterests_Min_Fields>;
  stddev?: Maybe<Users_Userinterests_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Userinterests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Userinterests_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Userinterests_Sum_Fields>;
  var_pop?: Maybe<Users_Userinterests_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Userinterests_Var_Samp_Fields>;
  variance?: Maybe<Users_Userinterests_Variance_Fields>;
};


/** aggregate fields of "users_userinterests" */
export type Users_Userinterests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Userinterests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_userinterests" */
export type Users_Userinterests_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Userinterests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Userinterests_Max_Order_By>;
  min?: InputMaybe<Users_Userinterests_Min_Order_By>;
  stddev?: InputMaybe<Users_Userinterests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Userinterests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Userinterests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Userinterests_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Userinterests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Userinterests_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Userinterests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_userinterests" */
export type Users_Userinterests_Arr_Rel_Insert_Input = {
  data: Array<Users_Userinterests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Userinterests_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Userinterests_Avg_Fields = {
  __typename?: 'users_userinterests_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "users_userinterests" */
export type Users_Userinterests_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_userinterests". All fields are combined with a logical 'AND'. */
export type Users_Userinterests_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Userinterests_Bool_Exp>>;
  _not?: InputMaybe<Users_Userinterests_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Userinterests_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_userinterests" */
export enum Users_Userinterests_Constraint {
  /** unique or primary key constraint on columns "user_id", "type" */
  UserinterestsUniqPair = 'userinterests_uniq_pair',
  /** unique or primary key constraint on columns "id" */
  UsersUserinterestsPkey = 'users_userinterests_pkey'
}

/** input type for incrementing numeric columns in table "users_userinterests" */
export type Users_Userinterests_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_userinterests" */
export type Users_Userinterests_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Userinterests_Max_Fields = {
  __typename?: 'users_userinterests_max_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_userinterests" */
export type Users_Userinterests_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Userinterests_Min_Fields = {
  __typename?: 'users_userinterests_min_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_userinterests" */
export type Users_Userinterests_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_userinterests" */
export type Users_Userinterests_Mutation_Response = {
  __typename?: 'users_userinterests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Userinterests>;
};

/** on_conflict condition type for table "users_userinterests" */
export type Users_Userinterests_On_Conflict = {
  constraint: Users_Userinterests_Constraint;
  update_columns?: Array<Users_Userinterests_Update_Column>;
  where?: InputMaybe<Users_Userinterests_Bool_Exp>;
};

/** Ordering options when selecting data from "users_userinterests". */
export type Users_Userinterests_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_userinterests */
export type Users_Userinterests_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "users_userinterests" */
export enum Users_Userinterests_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_userinterests" */
export type Users_Userinterests_Set_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Userinterests_Stddev_Fields = {
  __typename?: 'users_userinterests_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "users_userinterests" */
export type Users_Userinterests_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Userinterests_Stddev_Pop_Fields = {
  __typename?: 'users_userinterests_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "users_userinterests" */
export type Users_Userinterests_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Userinterests_Stddev_Samp_Fields = {
  __typename?: 'users_userinterests_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "users_userinterests" */
export type Users_Userinterests_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_userinterests" */
export type Users_Userinterests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Userinterests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Userinterests_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Userinterests_Sum_Fields = {
  __typename?: 'users_userinterests_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "users_userinterests" */
export type Users_Userinterests_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "users_userinterests" */
export enum Users_Userinterests_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Users_Userinterests_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Userinterests_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Userinterests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Userinterests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Userinterests_Var_Pop_Fields = {
  __typename?: 'users_userinterests_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "users_userinterests" */
export type Users_Userinterests_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Userinterests_Var_Samp_Fields = {
  __typename?: 'users_userinterests_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "users_userinterests" */
export type Users_Userinterests_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Userinterests_Variance_Fields = {
  __typename?: 'users_userinterests_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "users_userinterests" */
export type Users_Userinterests_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "users_usernotifications" */
export type Users_Usernotifications = {
  __typename?: 'users_usernotifications';
  batch_id: Scalars['uuid']['output'];
  data: Scalars['jsonb']['output'];
  id: Scalars['uuid']['output'];
  is_read: Scalars['Boolean']['output'];
  notification_time: Scalars['timestamptz']['output'];
  type: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "users_usernotifications" */
export type Users_UsernotificationsDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_usernotifications" */
export type Users_Usernotifications_Aggregate = {
  __typename?: 'users_usernotifications_aggregate';
  aggregate?: Maybe<Users_Usernotifications_Aggregate_Fields>;
  nodes: Array<Users_Usernotifications>;
};

export type Users_Usernotifications_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Usernotifications_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Usernotifications_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Usernotifications_Aggregate_Bool_Exp_Count>;
};

export type Users_Usernotifications_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Usernotifications_Select_Column_Users_Usernotifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Usernotifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Usernotifications_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Usernotifications_Select_Column_Users_Usernotifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Usernotifications_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Usernotifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Usernotifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_usernotifications" */
export type Users_Usernotifications_Aggregate_Fields = {
  __typename?: 'users_usernotifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Usernotifications_Max_Fields>;
  min?: Maybe<Users_Usernotifications_Min_Fields>;
};


/** aggregate fields of "users_usernotifications" */
export type Users_Usernotifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Usernotifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users_usernotifications" */
export type Users_Usernotifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Usernotifications_Max_Order_By>;
  min?: InputMaybe<Users_Usernotifications_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Usernotifications_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "users_usernotifications" */
export type Users_Usernotifications_Arr_Rel_Insert_Input = {
  data: Array<Users_Usernotifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Usernotifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users_usernotifications". All fields are combined with a logical 'AND'. */
export type Users_Usernotifications_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Usernotifications_Bool_Exp>>;
  _not?: InputMaybe<Users_Usernotifications_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Usernotifications_Bool_Exp>>;
  batch_id?: InputMaybe<Uuid_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_read?: InputMaybe<Boolean_Comparison_Exp>;
  notification_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_usernotifications" */
export enum Users_Usernotifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersUsernotificationsPkey = 'users_usernotifications_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Usernotifications_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Usernotifications_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Usernotifications_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "users_usernotifications" */
export type Users_Usernotifications_Insert_Input = {
  batch_id?: InputMaybe<Scalars['uuid']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_read?: InputMaybe<Scalars['Boolean']['input']>;
  notification_time?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Usernotifications_Max_Fields = {
  __typename?: 'users_usernotifications_max_fields';
  batch_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_time?: Maybe<Scalars['timestamptz']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "users_usernotifications" */
export type Users_Usernotifications_Max_Order_By = {
  batch_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Usernotifications_Min_Fields = {
  __typename?: 'users_usernotifications_min_fields';
  batch_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_time?: Maybe<Scalars['timestamptz']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "users_usernotifications" */
export type Users_Usernotifications_Min_Order_By = {
  batch_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_usernotifications" */
export type Users_Usernotifications_Mutation_Response = {
  __typename?: 'users_usernotifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Usernotifications>;
};

/** on_conflict condition type for table "users_usernotifications" */
export type Users_Usernotifications_On_Conflict = {
  constraint: Users_Usernotifications_Constraint;
  update_columns?: Array<Users_Usernotifications_Update_Column>;
  where?: InputMaybe<Users_Usernotifications_Bool_Exp>;
};

/** Ordering options when selecting data from "users_usernotifications". */
export type Users_Usernotifications_Order_By = {
  batch_id?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_read?: InputMaybe<Order_By>;
  notification_time?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_usernotifications */
export type Users_Usernotifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Usernotifications_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_usernotifications" */
export enum Users_Usernotifications_Select_Column {
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  NotificationTime = 'notification_time',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

/** select "users_usernotifications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users_usernotifications" */
export enum Users_Usernotifications_Select_Column_Users_Usernotifications_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRead = 'is_read'
}

/** select "users_usernotifications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users_usernotifications" */
export enum Users_Usernotifications_Select_Column_Users_Usernotifications_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRead = 'is_read'
}

/** input type for updating data in table "users_usernotifications" */
export type Users_Usernotifications_Set_Input = {
  batch_id?: InputMaybe<Scalars['uuid']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_read?: InputMaybe<Scalars['Boolean']['input']>;
  notification_time?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_usernotifications" */
export type Users_Usernotifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Usernotifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Usernotifications_Stream_Cursor_Value_Input = {
  batch_id?: InputMaybe<Scalars['uuid']['input']>;
  data?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_read?: InputMaybe<Scalars['Boolean']['input']>;
  notification_time?: InputMaybe<Scalars['timestamptz']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_usernotifications" */
export enum Users_Usernotifications_Update_Column {
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  NotificationTime = 'notification_time',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id'
}

export type Users_Usernotifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Usernotifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Usernotifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Usernotifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Usernotifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Usernotifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Usernotifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Usernotifications_Bool_Exp;
};

/** columns and relationships of "users_userpreferences" */
export type Users_Userpreferences = {
  __typename?: 'users_userpreferences';
  active: Scalars['Boolean']['output'];
  created_at: Scalars['timestamptz']['output'];
  notifications?: Maybe<Scalars['jsonb']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "users_userpreferences" */
export type Users_UserpreferencesNotificationsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_userpreferences" */
export type Users_Userpreferences_Aggregate = {
  __typename?: 'users_userpreferences_aggregate';
  aggregate?: Maybe<Users_Userpreferences_Aggregate_Fields>;
  nodes: Array<Users_Userpreferences>;
};

/** aggregate fields of "users_userpreferences" */
export type Users_Userpreferences_Aggregate_Fields = {
  __typename?: 'users_userpreferences_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Userpreferences_Max_Fields>;
  min?: Maybe<Users_Userpreferences_Min_Fields>;
};


/** aggregate fields of "users_userpreferences" */
export type Users_Userpreferences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Userpreferences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Userpreferences_Append_Input = {
  notifications?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "users_userpreferences". All fields are combined with a logical 'AND'. */
export type Users_Userpreferences_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Userpreferences_Bool_Exp>>;
  _not?: InputMaybe<Users_Userpreferences_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Userpreferences_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  notifications?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_userpreferences" */
export enum Users_Userpreferences_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UsersUserpreferencesPkey = 'users_userpreferences_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Userpreferences_Delete_At_Path_Input = {
  notifications?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Userpreferences_Delete_Elem_Input = {
  notifications?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Userpreferences_Delete_Key_Input = {
  notifications?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "users_userpreferences" */
export type Users_Userpreferences_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  notifications?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Userpreferences_Max_Fields = {
  __typename?: 'users_userpreferences_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Userpreferences_Min_Fields = {
  __typename?: 'users_userpreferences_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_userpreferences" */
export type Users_Userpreferences_Mutation_Response = {
  __typename?: 'users_userpreferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Userpreferences>;
};

/** input type for inserting object relation for remote table "users_userpreferences" */
export type Users_Userpreferences_Obj_Rel_Insert_Input = {
  data: Users_Userpreferences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Userpreferences_On_Conflict>;
};

/** on_conflict condition type for table "users_userpreferences" */
export type Users_Userpreferences_On_Conflict = {
  constraint: Users_Userpreferences_Constraint;
  update_columns?: Array<Users_Userpreferences_Update_Column>;
  where?: InputMaybe<Users_Userpreferences_Bool_Exp>;
};

/** Ordering options when selecting data from "users_userpreferences". */
export type Users_Userpreferences_Order_By = {
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  notifications?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_userpreferences */
export type Users_Userpreferences_Pk_Columns_Input = {
  user_id: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Userpreferences_Prepend_Input = {
  notifications?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_userpreferences" */
export enum Users_Userpreferences_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Notifications = 'notifications',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_userpreferences" */
export type Users_Userpreferences_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  notifications?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "users_userpreferences" */
export type Users_Userpreferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Userpreferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Userpreferences_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  notifications?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "users_userpreferences" */
export enum Users_Userpreferences_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Notifications = 'notifications',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Users_Userpreferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Userpreferences_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Userpreferences_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Userpreferences_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Userpreferences_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Userpreferences_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Userpreferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Userpreferences_Bool_Exp;
};

/** columns and relationships of "users_usersession" */
export type Users_Usersession = {
  __typename?: 'users_usersession';
  id: Scalars['bigint']['output'];
  session_id: Scalars['String']['output'];
  session_meta?: Maybe<Scalars['jsonb']['output']>;
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "users_usersession" */
export type Users_UsersessionSession_MetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "users_usersession" */
export type Users_Usersession_Aggregate = {
  __typename?: 'users_usersession_aggregate';
  aggregate?: Maybe<Users_Usersession_Aggregate_Fields>;
  nodes: Array<Users_Usersession>;
};

/** aggregate fields of "users_usersession" */
export type Users_Usersession_Aggregate_Fields = {
  __typename?: 'users_usersession_aggregate_fields';
  avg?: Maybe<Users_Usersession_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Usersession_Max_Fields>;
  min?: Maybe<Users_Usersession_Min_Fields>;
  stddev?: Maybe<Users_Usersession_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Usersession_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Usersession_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Usersession_Sum_Fields>;
  var_pop?: Maybe<Users_Usersession_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Usersession_Var_Samp_Fields>;
  variance?: Maybe<Users_Usersession_Variance_Fields>;
};


/** aggregate fields of "users_usersession" */
export type Users_Usersession_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Usersession_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Usersession_Append_Input = {
  session_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Users_Usersession_Avg_Fields = {
  __typename?: 'users_usersession_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users_usersession". All fields are combined with a logical 'AND'. */
export type Users_Usersession_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Usersession_Bool_Exp>>;
  _not?: InputMaybe<Users_Usersession_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Usersession_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  session_id?: InputMaybe<String_Comparison_Exp>;
  session_meta?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_usersession" */
export enum Users_Usersession_Constraint {
  /** unique or primary key constraint on columns "session_id", "user_id" */
  UserSessionUniq = 'user_session_uniq',
  /** unique or primary key constraint on columns "id" */
  UsersUsersessionPkey = 'users_usersession_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UsersUsersessionUserId_2873d35eUniq = 'users_usersession_user_id_2873d35e_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Usersession_Delete_At_Path_Input = {
  session_meta?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Usersession_Delete_Elem_Input = {
  session_meta?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Usersession_Delete_Key_Input = {
  session_meta?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "users_usersession" */
export type Users_Usersession_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "users_usersession" */
export type Users_Usersession_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  session_meta?: InputMaybe<Scalars['jsonb']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Users_Usersession_Max_Fields = {
  __typename?: 'users_usersession_max_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  session_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Users_Usersession_Min_Fields = {
  __typename?: 'users_usersession_min_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  session_id?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "users_usersession" */
export type Users_Usersession_Mutation_Response = {
  __typename?: 'users_usersession_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Usersession>;
};

/** on_conflict condition type for table "users_usersession" */
export type Users_Usersession_On_Conflict = {
  constraint: Users_Usersession_Constraint;
  update_columns?: Array<Users_Usersession_Update_Column>;
  where?: InputMaybe<Users_Usersession_Bool_Exp>;
};

/** Ordering options when selecting data from "users_usersession". */
export type Users_Usersession_Order_By = {
  id?: InputMaybe<Order_By>;
  session_id?: InputMaybe<Order_By>;
  session_meta?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_usersession */
export type Users_Usersession_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Usersession_Prepend_Input = {
  session_meta?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "users_usersession" */
export enum Users_Usersession_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  SessionMeta = 'session_meta',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_usersession" */
export type Users_Usersession_Set_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  session_meta?: InputMaybe<Scalars['jsonb']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Users_Usersession_Stddev_Fields = {
  __typename?: 'users_usersession_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Usersession_Stddev_Pop_Fields = {
  __typename?: 'users_usersession_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Usersession_Stddev_Samp_Fields = {
  __typename?: 'users_usersession_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users_usersession" */
export type Users_Usersession_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Usersession_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Usersession_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  session_id?: InputMaybe<Scalars['String']['input']>;
  session_meta?: InputMaybe<Scalars['jsonb']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Users_Usersession_Sum_Fields = {
  __typename?: 'users_usersession_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "users_usersession" */
export enum Users_Usersession_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  SessionMeta = 'session_meta',
  /** column name */
  UserId = 'user_id'
}

export type Users_Usersession_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Usersession_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Usersession_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Usersession_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Usersession_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Usersession_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Usersession_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Usersession_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Usersession_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Usersession_Var_Pop_Fields = {
  __typename?: 'users_usersession_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Usersession_Var_Samp_Fields = {
  __typename?: 'users_usersession_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Usersession_Variance_Fields = {
  __typename?: 'users_usersession_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};
