import React, { FC } from 'react';

type RequiredLabelProps = {
    label: string;
};

export const RequiredLabel: FC<RequiredLabelProps> = ({ label }) => (
    <span>
        {label} <span style={{ color: 'red' }}>*</span>
    </span>
);
