import {
    createContext,
    FC,
    ReactNode,
    useContext,
    useMemo,
    CSSProperties,
} from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { PaletteMode } from '@mui/material';
import useLocalStorage from 'Helpers/hooks/useLocalStorage.tsx';

declare module '@mui/material' {
    interface BreakpointOverrides {
        tablet: true;
        tiny: true;
    }

    interface TypographyPropsVariantOverrides {
        custom_caption_manrope: true;
        manrope_12: true;
        manrope_14: true;
        manrope_15: true;
        manrope_16: true;
        manrope_20: true;
        manrope_24: true;
        roboto_12: true;
        roboto_14: true;
        roboto_16: true;
        spectral_30: true;
        spectral_32: true;
        h4_manrope: true;
        h4_spectral: true;
        h4_spectral_small: true;
        custom_body2: true;
        custom_subtitle2: true;
    }

    interface TypographyClasses {
        custom_caption_manrope?: string;
        manrope_12?: string;
        manrope_14?: string;
        manrope_15?: string;
        manrope_16?: string;
        manrope_20?: string;
        manrope_24?: string;
        roboto_12?: string;
        roboto_14?: string;
        roboto_16?: string;
        spectral_30?: string;
        spectral_32?: string;
        h4_manrope?: string;
        h4_spectral?: string;
        h4_spectral_small: string;
        custom_body2?: string;
        custom_subtitle2?: string;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariantsOptions {
        custom_caption_manrope?: CSSProperties;
        manrope_12?: CSSProperties;
        manrope_14?: CSSProperties;
        manrope_15?: CSSProperties;
        manrope_16?: CSSProperties;
        manrope_20?: CSSProperties;
        manrope_24?: CSSProperties;
        roboto_12?: CSSProperties;
        roboto_14?: CSSProperties;
        roboto_16?: CSSProperties;
        spectral_30?: CSSProperties;
        spectral_32?: CSSProperties;
        h4_manrope?: CSSProperties;
        h4_spectral?: CSSProperties;
        h4_spectral_small?: CSSProperties;
        custom_body2?: CSSProperties;
        custom_subtitle2?: CSSProperties;
    }

    interface TypographyVariants {
        custom_caption_manrope?: CSSProperties;
        manrope_12?: CSSProperties;
        manrope_14?: CSSProperties;
        manrope_15?: CSSProperties;
        manrope_16?: CSSProperties;
        manrope_20?: CSSProperties;
        manrope_24?: CSSProperties;
        roboto_12?: CSSProperties;
        roboto_14?: CSSProperties;
        roboto_16?: CSSProperties;
        spectral_30?: CSSProperties;
        spectral_32?: CSSProperties;
        h4_manrope?: CSSProperties;
        h4_spectral?: CSSProperties;
        h4_spectral_small?: CSSProperties;
        custom_body2?: CSSProperties;
        custom_subtitle2?: CSSProperties;
    }
}

interface ThemeContextProps {
    toggleTheme: () => void;
    isDarkMode: boolean;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const useThemeContext = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useThemeContext must be used within a ThemeProvider');
    }
    return context;
};

export const CustomThemeProvider: FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [isDarkMode, setIsDarkMode] = useLocalStorage('isDarkMode', false);

    const toggleTheme = () => {
        setIsDarkMode((prev) => !prev);
    };

    const theme = useMemo(
        () => createTheme(getDesignTokens(isDarkMode ? 'dark' : 'light')),
        [isDarkMode]
    );

    return (
        <ThemeContext.Provider value={{ toggleTheme, isDarkMode }}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'dark'
            ? {
                  primary: { main: '#ffffff' },
              }
            : {
                  primary: { main: '#000000' },
              }),
    },
    components: {
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    '.MuiToggleButtonGroup-firstButton': {
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                    },
                    '.MuiToggleButton-root': {
                        borderRadius: '8px',
                        border: '0',
                    },
                    '.MuiToggleButton-root.Mui-selected': {
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #000000',
                        borderRadius: '8px',
                    },
                    '.MuiToggleButton-root.Mui-selected:hover': {
                        backgroundColor: '#FFFFFF',
                    },
                    '.MuiToggleButton-root:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    },
                    '.MuiToggleButtonGroup-lastButton': {
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        marginLeft: '0',
                        border: '0',
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '.Mui-selected': {
                        borderRadius: '8px',
                    },
                    '.MuiListItemButton-root:hover': {
                        borderRadius: '8px',
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '.MuiDialog-paper': {
                        borderRadius: '12px',
                        width: '444px',
                        maxWidth: '100%',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    disableUnderline: true,
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
                        {
                            WebkitAppearance: 'none',
                            margin: 0,
                        },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Manrope',
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    '&.MuiFilledInput-root::before': {
                        borderBottom: 0,
                    },
                    '&.MuiFilledInput-root::after': {
                        borderBottom: 0,
                    },
                    '&.MuiFilledInput-root:hover::before': {
                        borderBottom: 0,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-root': {
                        borderRadius: '8px',
                        textTransform: 'none',
                    },
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '0 16px 16px 16px',
                },
            },
        },
    },
    typography: {
        fontFamily: [
            'Manrope',
            'Arimo',
            'Montserrat',
            'Raleway',
            'Roboto',
            'Spectral',
        ].join(','),
        custom_caption_manrope: {
            fontFamily: 'Manrope',
            fontSize: '12px',
            lineHeight: '19.92px',
            letterSpacing: '0.4px',
        },
        manrope_12: {
            fontFamily: 'Manrope',
            fontSize: '12px',
            lineHeight: '19.92px',
            letterSpacing: '0.4px',
        },
        manrope_14: {
            fontFamily: 'Manrope',
            fontSize: '14px',
            lineHeight: '24px',
        },
        manrope_16: {
            fontFamily: 'Manrope',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.15px',
        },
        manrope_15: {
            fontFamily: 'Manrope',
            fontSize: '15px',
            lineHeight: '26px',
        },
        manrope_20: {
            fontFamily: 'Manrope',
            fontSize: '20px',
            lineHeight: '20px',
            letterSpacing: '0.15px',
        },
        manrope_24: {
            fontFamily: 'Manrope',
            fontSize: '24px',
            lineHeight: '32.02px',
        },
        roboto_12: {
            fontFamily: 'Roboto',
            fontSize: '12px',
            lineHeight: '19.92px',
            letterSpacing: '0.4px',
        },
        roboto_14: {
            fontFamily: 'Roboto',
            fontSize: '14px',
            lineHeight: '20.02px',
            letterSpacing: '0.17px',
        },
        roboto_16: {
            fontFamily: 'Roboto',
            fontSize: '16px',
            lineHeight: '28px',
            letterSpacing: '0.15px',
        },
        spectral_30: {
            fontFamily: 'Spectral',
            fontSize: '30px',
            lineHeight: '39.52px',
            letterSpacing: '-1px',
        },
        spectral_32: {
            fontFamily: 'Spectral',
            fontSize: '32px',
            lineHeight: '39.52px',
            letterSpacing: '-1px',
        },
        h4_manrope: {
            fontFamily: 'Manrope',
            fontSize: '34px',
            lineHeight: '41.99px',
            letterSpacing: '-1px',
        },
        h4_spectral: {
            fontFamily: 'Spectral',
            fontSize: '34px',
            lineHeight: '41.99px',
            letterSpacing: '-1px',
        },
        h4_spectral_small: {
            fontFamily: 'Spectral',
            fontSize: '30px',
            lineHeight: '37.28px',
            letterSpacing: '-1px',
        },
        custom_body2: {
            fontFamily: 'Manrope',
            fontSize: '14px',
            lineHeight: '20.02px',
            letterSpacing: '0.17px',
        },
        custom_subtitle2: {
            fontFamily: 'Manrope',
            fontSize: '14px',
            lineHeight: '21.98px',
            letterSpacing: '0.1px',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            tiny: 480,
            sm: 600,
            tablet: 700,
            md: 935,
            lg: 1200,
            xl: 1536,
        },
    },
});
