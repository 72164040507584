import { CITIZESNSHIP_TYPES, INVESTOR_TYPES } from '../../constants.ts';

export const citizenshipOptions = [
    {
        label: 'U.S. Citizen',
        value: CITIZESNSHIP_TYPES.citizen,
    },
    {
        label: 'U.S. Resident',
        value: CITIZESNSHIP_TYPES.resident,
    },
];

export const idOptions = [
    {
        label: 'State issue ID',
        value: 'State issue ID',
    },
    {
        label: `Driver's license`,
        value: `Driver's license`,
    },
    {
        label: 'Passport',
        value: 'Passport',
    },
];

export const usStatesOptions = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
];

export const worthOptions = [
    '$0 - $99K',
    '$100K - $499K',
    '$500K - $999K',
    '$1M - $5M',
    '$5M - $30M',
    '$30M - $1B',
].map((value) => {
    const [from, to] = value.split('-');
    const label = (
        <div
            className="
                flex w-3/4 mx-auto px-10 items-center justify-between
            "
        >
            <span className="flex-1 text-left">{from}</span>
            <span className="grow-[2] text-center">-</span>
            <span className="flex-1 text-left">{to}</span>
        </div>
    );
    return { label, value };
});

export const timeOptions = [
    {
        value: 'immediately',
        label: 'Immediately',
    },
    {
        value: '4-weeks',
        label: 'Within 4 weeks',
    },
    {
        value: '3-months',
        label: 'Within 3 months',
    },
    {
        value: '6-months',
        label: 'Within 6 months',
    },
];

export const investmentAmountOptions = [
    {
        value: 'less-than-50K',
        label: 'Less than $50K',
    },
    {
        value: '50K-100K',
        label: '$50K - $100K',
    },
    {
        value: '100K-250K',
        label: '$100K - $250K',
    },
    {
        value: '250K-500K',
        label: '$250K - $500K',
    },
    {
        value: 'more-than-500K',
        label: 'More than $500K',
    },
    {
        value: 'not-sure',
        label: "I'm not sure",
    },
];

export const investorTypeOptions = [
    {
        value: INVESTOR_TYPES.individual,
        label: 'Individual',
    },
    {
        value: INVESTOR_TYPES.entity,
        label: 'Entity',
    },
];
