import { Avatar, Typography } from '@mui/material';
import MichaelDavisSrc from 'Assets/images/Michael Davis.png';

export const AuthSideInfo = () => {
    return (
        <div className="w-1/2 side hidden lg:flex bg-gradient-to-tr side-gradient text-white">
            <div className="p-15 flex items-center justify-center">
                <div className="flex flex-col gap-8">
                    <Typography variant="spectral_32">
                        Homegrown helps the best brick & mortar businesses grow
                        successfully by providing capital with payments based on
                        their revenue.
                    </Typography>
                    <Typography variant="spectral_32" component="div">
                        We started Homegrown because we believe that the best
                        neighborhood entrepreneurs deserve better, faster,
                        friendlier, and more straight-forward financing options
                        that are currently unavailable to them.
                    </Typography>
                    <Typography variant="spectral_32" component="div">
                        And for accredited investors, we have created a way to
                        earn strong returns from proven businesses.
                    </Typography>
                    <div className="flex flex-row gap-4 items-center">
                        <Avatar
                            sx={{
                                width: '108px',
                                height: '108px',
                            }}
                            src={MichaelDavisSrc}
                        />
                        <div className="py-6">
                            <Typography
                                variant="manrope_20"
                                sx={{
                                    fontWeight: '700',
                                    lineHeight: '32px',
                                }}
                            >
                                Michael Davis
                            </Typography>
                            <Typography
                                variant="manrope_16"
                                component="div"
                                sx={{
                                    lineHeight: '28px',
                                }}
                            >
                                Co-founder & CEO of Homegrown
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
