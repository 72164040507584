import { useCallback, useEffect, useRef, useState } from 'react';

function useLocalStorage<T>(key: string, initialValue: T) {
    const initialValueRef = useRef(initialValue);
    initialValueRef.current = initialValue;
    // State to store our value
    // Pass  initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? (JSON.parse(item) as T) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // useEffect to update local storage when the state changes
    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    }, [key, storedValue]);

    const removeStoredValue = useCallback(() => {
        localStorage.removeItem(key);
        setStoredValue(initialValueRef.current);
    }, [key, initialValueRef]);
    return [storedValue, setStoredValue, removeStoredValue] as const;
}

export default useLocalStorage;
