import { useMemo } from 'react';
//
import { useGetRequester } from 'Api/hooks/api-request.ts';
import { INVESTOR_SECONDARY } from '../../app-constants.ts';

export enum ENTITYENDPOINTS {
    inviteSecondaryInvestor = 'create_invites',
    getSecondaryInvestorDetails = 'get_entity_invite_details',
    saveSecondaryInvestor = 'save_secondary_investor',
    postSecondaryInvestorDocument = 'post_document',
    completeSecondaryInvestorOnboarding = 'complete_onboarding',
    resendSecondaryInvestorInvite = 'resend_invite',
}

export function useEntityApi() {
    const entityRequester = useGetRequester(INVESTOR_SECONDARY);
    return useMemo(
        () => ({
            inviteSecondaryInvestor: (params: InviteSecondaryInvestorRequest) =>
                entityRequester
                    .post<InviteSecondaryInvestorResponse>(
                        ENTITYENDPOINTS.inviteSecondaryInvestor,
                        params
                    )
                    .then(({ data }) => data),
            resendSecondaryInvestorInvite: (params: { code: string }) =>
                entityRequester
                    .post<{ status: string; expires_on: string }>(
                        ENTITYENDPOINTS.resendSecondaryInvestorInvite,
                        params
                    )
                    .then(({ data }) => data),
        }),
        [entityRequester]
    );
}
