import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo } from 'react';
import axios from 'axios';
import merge from 'lodash/merge';
import * as Sentry from '@sentry/react';

import { DJANGO_API } from 'app-constants.ts';

export function useGetRequester(baseURL = DJANGO_API) {
    const { getAccessTokenSilently } = useAuth0();

    const { axiosInstance, cancel } = useMemo(() => {
        const abortController = new AbortController();
        const axiosInstance = axios.create({
            signal: abortController.signal,
            baseURL: baseURL,
        });

        axiosInstance.interceptors.request.use(async function (config) {
            const token = await getAccessTokenSilently();
            const headers = token ? { Authorization: `Bearer ${token}` } : {};
            return merge({}, config, { headers });
        });
        axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            function (error) {
                if (axios.isCancel(error)) {
                    return Promise.reject();
                }
                if (error.isAxiosError === true) {
                    console.log('Requester Error', error);
                }
                if (error?.response?.status >= 500) {
                    Sentry.captureMessage(error);
                }
                return Promise.reject(error?.response ?? error);
            }
        );

        return {
            axiosInstance,
            cancel: abortController.abort.bind(abortController),
        };
    }, [baseURL, getAccessTokenSilently]);

    //cancel request on unmount
    useEffect(() => cancel, [cancel]);

    return axiosInstance;
}
