import React, {
    createContext,
    useContext,
    useMemo,
    ReactNode,
    FC,
} from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

interface BreakpointsContextProps {
    isMedium: boolean;
    isSmall: boolean;
    isLarge: boolean;
    isTablet: boolean;
    isTiny: boolean;
}

const BreakpointsContext = createContext<BreakpointsContextProps | undefined>(
    undefined
);

export const useBreakpointsContext = (): BreakpointsContextProps => {
    const context = useContext(BreakpointsContext);
    if (!context) {
        throw new Error(
            'useBreakpointsContext must be used within a BreakpointsProvider'
        );
    }
    return context;
};

export const BreakpointsProvider: FC<{ children: ReactNode }> = ({
    children,
}) => {
    const theme = useTheme();
    const smallQuery = useMediaQuery(theme.breakpoints.down('sm'));
    const mediumQuery = useMediaQuery(theme.breakpoints.down('md'));
    const largeQuery = useMediaQuery(theme.breakpoints.down('lg'));
    const tabletQuery = useMediaQuery(theme.breakpoints.down('tablet'));
    const tinyQuery = useMediaQuery(theme.breakpoints.down('tiny'));

    const contextValue = useMemo(
        () => ({
            isMedium: mediumQuery,
            isSmall: smallQuery,
            isLarge: largeQuery,
            isTablet: tabletQuery,
            isTiny: tinyQuery,
        }),
        [mediumQuery, smallQuery, largeQuery, tabletQuery, tinyQuery]
    );

    return (
        <BreakpointsContext.Provider value={contextValue}>
            {children}
        </BreakpointsContext.Provider>
    );
};
